import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./faq.css";
function FAQ() {
  return (
    <>
      <Header />

      {/*
      <Sidebar />
      */}
      <main id="main" className="main">
        <div className="pagetitle">
          {/* <h1>FAQ</h1> */}
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Agent</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive faqs">
          <div class="faq-section">
            <header>
              <h2>FAQs</h2>
              <p>Answers to the most frequently asked questions.</p>
            </header>

            <details>
              <summary>
                <h4>Why use Chekam?</h4>
                <span class="material-symbols-outlined"></span>
              </summary>
              <p>
              Chekam is the smartest way to Real Estate
              </p>
            </details>

            <hr />

            <details>
              <summary>
                <h4>How can i add a listing/property?</h4>
                <span class="material-symbols-outlined">
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </summary>
              <p>
                On your dashboard, Click on the Add Property Button
              </p>
            </details>

            <hr />

            <details>
              <summary>
                <h4>Oh, i made a mistake when adding a property</h4>
                <span class="material-symbols-outlined">
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </summary>
              <p>
                If you make a mistake whilst adding a property, in your dashboard, click on the my properties button and
                look for the property with the issue and click on update property
              </p>
            </details>

            <hr />

            <details>
              <summary>
                <h4>How can i delete a property?</h4>
                <span class="material-symbols-outlined">
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </summary>
              <p>
                Yes, you can delete a property by going to your dashboard and clicking the my properties button, find the 
                property you wish to deletea and clicking on the delete button.
              </p>
            </details>

            <hr />

            <details>
              <summary>
                <h4>I need help adding a property?</h4>
                <span class="material-symbols-outlined">
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </summary>
              <p>if you need extra help adding a property, please contact customer care.</p>
            </details>
          </div>
        </section>
      </main>
    </>
  );
}

export default FAQ;
