import ScrollSpyTabs from "./ScrollSpyTabs";
import React, { useState, useEffect, useRef } from "react";
import "./styles/selected.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import ModalMain from "react-bootstrap/Modal";
import { Button, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import Navbar from "./Navbar";
import firebase from "firebase/app";
import { UserContext } from "../App";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";

import { Swiper, SwiperSlide } from "swiper/react";

import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";

import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBScrollspy,
  MDBScrollspyLink,
  MDBScrollspySubList,
} from "mdb-react-ui-kit";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { CircularProgress } from "@mui/material";
// import required modules
import { Navigation } from "swiper";

import logo from "../images/chekam logo 2(2).png";
import SuscriberModal from "./SuscriberModal";



function ModalShortlet({ id, show, setShow }) {
  
  const [copy, setCopy] = useState(false);
  const [modalshow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const user = React.useContext(UserContext);
  const [progress, setProgress] = useState(false);
  const [share, setShare] = useState(false);
  const [data, setData] = useState(null);
  const [disappear, setDisappear] = useState(
    data?.userRated?.includes(user?.uid) ? "d-none" : ""
  );
  const [activeLink, setActiveLink] = useState("");
  const [ratings, setRating] = useState(1);
  const [users, setUser] = useState(null);
  const [rate, setRate] = useState(false);
  const [close, setCloseModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [cast, setCast] = useState(null);
  const closeModal = () => {setCloseModal(!close);};
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  //console.log(id);
  useEffect(() => {
    setRate(false);
    setShare(false);
    setRating(1);
    setLoading(true);
    async function getPlaces() {
      const docRef = doc(db, "properties", id);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());
      
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getPlaces();
    // setLoading(false);
  }, [id]);

 

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  let naira = "\u20A6";

  useEffect(() => {
    setCast(null);
    // if (ratings === 1) {
    //   setCast(2);
    // } else if (ratings === 2) {
    //   setCast(5);
    // } else if (ratings === 3) {
    //   setCast(7);
    // } else if (ratings === 4) {
    //   setCast(5);
    // } else if (ratings === 5) {
    //   setCast(10);
    // }
    setCast(ratings);
    // setCast()
  }, [ratings]);

  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     const observable = {
  //       home: document.querySelector(".home"),
  //       projects: document.querySelector(".projects"),
  //       media: document.querySelector(".media"),
  //     };
  //     const pos = {
  //       home: observable.home.getBoundingClientRect(),
  //       projects: observable.projects.getBoundingClientRect(),
  //       media: observable.media.getBoundingClientRect(),
  //     };

  //     //console.log(observable);
  //     //console.log(pos);

  //     if (pos.home.top < window.innerHeight && pos.home.bottom >= 0) {
  //       setActiveLink("home");
  //     } else if (
  //       pos.projects.top < window.innerHeight &&
  //       pos.projects.bottom >= 0
  //     ) {
  //       setActiveLink("projects");
  //     } else if (pos.media.top < window.innerHeight && pos.media.bottom >= 0) {
  //       setActiveLink("media");
  //     }
  //   });

  //   // return () => {

  //   // }
  // }, []);

  //console.log(activeLink);

  const handleRating = (e) => {
    e.preventDefault();
  };

  const handleClose = () => setShow(false);
  // console.log(ratings);

  // const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`;
  const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=img&for=${data?.propertyPurpose}`;
  // const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&id=${id}&for=${data?.propertyPurpose}`;

  const userRated = ["1", "2", "3", "7"];

 

  useEffect(() => {
    setDisappear(data?.userRated?.includes(user?.uid) ? "d-none" : "");
    if (data?.userRated?.includes(user?.uid)) {
      setDisappear("d-none");
    } else {
      setDisappear("");
    }
  }, [id]);

  // const userId = localStorage.getItem("user");
  // const mainUser = localStorage.getItem("user", JSON.parse(userId))
 
  // const userRating
  const calculus = (0 + cast) / data?.userRated?.length;


  const handleRate = (e) => {
    setProgress(true);
    setLoading(true);
    e.preventDefault();
    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: "/selected-item?q=" + id + "&m=main",
        },
      });
    } else {
      const examcollref = doc(db, "properties", id);
      updateDoc(examcollref, {
        cast: data?.cast + cast,
        userRated: arrayUnion(user?.uid),
        // userRated: arrayUnion("davidsonjose"),
        userRating: `${(data?.cast + cast) / data?.userRated?.length}`,
      })
        .then((response) => {
          // alert("Successfully rated the property");
          setMessage("Thanks for rating.... 🎉🎉🎉🎉");
          setProgress(false);
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };


  const places = [];

  useEffect(() => {
    async function getPlaces() {
      // setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(
        propertiesRef,
        where("propertyPurpose", "==", "shortlet")
      );
      const array = [];
      getDocs(q).then((data) => {
     
        data?.forEach((doc) => {
          places.push(doc.data());
        

          places?.forEach((data) => {
            const examcollref = doc(db, "properties", data?.id);
            updateDoc(examcollref, {
              userRated: [],
              cast: 0,
            })
              .then((response) => {
                alert("updated");
              })
              .catch((error) => {
                console.log("an error occured");
              });
          });
        });
        setLoading(false);
      });
    }
    getPlaces();
  }, []);

  

  const handleSuscriber = (e) => {
    e.preventDefault();

    // const examcollref = doc(db, "properties", id);
    // updateDoc(examcollref, {
    //   // cast: 0,
    //   // userRated: arrayUnion(user?.uid),
    //   // userRated: arrayUnion("davidsonjose"),
    //   userRating: "5",
    // })
    //   .then((response) => {
    //     // alert("Successfully rated the property");
    //     setMessage("Thanks for rating.... 🎉🎉🎉🎉");
    //     setProgress(false);
    //     // setLoading(false);
    //     // setTimeout(() => {
    //     //   window.location.reload();
    //     // }, 2000);
    //   })
    //   .catch((error) => {
    //     console.log(error.message);
    //   });
    if (!user) {
      navigate("/login");
    } else {
      navigate("/user/suscribe");
    }
  };

  // const handleSuscriber = (e) => {
  //     e.preventDefault();

  //     const examcollref = doc(db, "properties", id);
  //     updateDoc(examcollref, {
  //       sponsored: true
  //     })
  //       .then((response) => {
  //         // alert("Successfully rated the property");
  //         setMessage("Thanks for rating.... 🎉🎉🎉🎉")
  //         setProgress(false)
  //         setLoading(false)

  //         setTimeout(() => {
  //           window.location.reload()
  //         }, 2000)
  //       })
  //       .catch((error) => {
  //         console.log(error.message);
  //       });
  //     // if (!user) {
  //     //   navigate("/login");
  //     // } else {
  //     //   navigate("/user/suscribe");
  //     // }
  //   };

  const handleAgentInfo = (id, e) => {
    // setId(agentid);

    async function getSingleAgent() {
      const docRef = doc(db, "users", id?.agentId);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      setData(docSnap.data());
      
      let agentData = docSnap.data();
      return (window.location.href = `/properties/${id?.agentName}?n=${
        id?.agentId
      }&companyname=${agentData?.company}&image=${
        data?.photoURL === undefined || data?.photoURL === ""
          ? "none"
          : data?.photoUrl
      }`);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  };

  // scrollspy
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const sectionA = useRef(null);
  const sectionB = useRef(null);

  const containerRef = useRef(null);

  const subsections = [sectionA, sectionB];

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);

  
      let agentData = docSnap.data();

     
      setUser(agentData);
    })();
  }, [user]);

  const handleCopy = () => {
    // if (!copy) {

    navigator.clipboard.writeText(shareLink).then(
      function () {
        setCopy(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );

    setTimeout(() => {
      setCopy(false);
    }, 600);
    // }
  };
  return (
    <>
      <ModalMain
        className="main-modal"
        style={{ padding: "0" }}
        show={show}
        onHide={handleClose}
      >
        <ModalMain.Body className="" style={{ padding: "0" }}>
          {loading ? (
            <Dialog open={loading === true} onClose={!loading}>
              <CircularProgress color="secondary" className="m-5" />
            </Dialog>
          ) : (
            <div className="container pt-5">
              <div className="mobile-modal" style={{ height: "300px" }}>
                <Navbar />

                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={
                        data?.mainImage?.length === undefined
                          ? ""
                          : data?.mainImage[0]
                      }
                      style={{ width: "450px", height: "350px" }}
                      alt=""
                      className="img-fluid modal-image"
                    />
                  </SwiperSlide>
                  {data?.images?.map((link) => {
                    return (
                      <SwiperSlide>
                        <>
                          <img
                            src={link}
                            className="img-fluid selected-img"
                            style={{ width: "374px", height: "350px" }}
                            alt=""
                          />
                        </>
                      </SwiperSlide>
                    );
                  })}
                  <div className="main-card-single col-md-6" id="example">
                    <span onClick={() => setShow(!show)}>
                      <i
                        class="fa fa-arrow-left text-white p-3 bg-warning rounded-pill"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                  {/* <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
                </Swiper>
              </div>

              <div className="row pb-1" style={{ padding: "0" }}>
                <div className="col-md-7 respond-me" style={{ padding: "0" }}>
                  <div
                    className="card home-single"
                    style={{ height: "800px", overflowY: "scroll" }}
                  >
                    <img
                      src={
                        data?.mainImage?.length === undefined
                          ? ""
                          : data?.mainImage[0]
                      }
                      style={{ width: "750px", height: "700px" }}
                      alt=""
                      className="img-fluid modal-image hide-modal-image"
                    />
                    <div className="respond-me mt-1">
                      <div className="row">
                        {data?.images?.slice(1).map((link) => {
                          return (
                            <div className="col-md-6 pb-2 image-responsive-selected respond-me">
                              <img
                                src={link}
                                className="img-fluid selected-img hide-selected-img"
                                style={{ width: "374px", height: "350px" }}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="card-footer text-center home-single-footer">
                      <h5 className="text-white">
                        <strong>Interested in touring this home?</strong>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 respond-me">
                  <div className="card-header row">
                    <div className="col-md-6">
                      <img src={logo} style={{ width: "150px" }} alt="" />
                    </div>
                    <div className="main-card-single col-md-6 ">
                      <span className="">
                        <span className="modal-icon">
                          <i
                            class="fa fa-heart-o top-icon"
                            aria-hidden="true"
                          ></i>
                          &nbsp;
                          <span>Save</span>
                        </span>

                        <span
                          className="modal-icon"
                          onClick={() => setShare(!share)}
                        >
                          <i class="fa fa-share-alt" aria-hidden="true"></i>
                          &nbsp;
                          <span>Share</span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <br />

                  <div className="pb-3 hide-share-for-mobile">
                    {share && (
                      <>
                        <FacebookShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                        <TelegramShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>

                        <div className="pt-2 d-flex">
                          <p style={{ fontSize: "20px" }}>
                            <i
                              class="fa fa-clipboard"
                              style={{ color: "purple" }}
                              aria-hidden="true"
                              onClick={() => handleCopy()}
                            ></i>
                          </p>

                          {copy ? (
                            <strong>Copied</strong>
                          ) : (
                            <small>Copy property link</small>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <h2 style={{ fontWeight: "bold", color: "black" }}>
                      {naira}
                      {data?.price?.toLocaleString()}
                    </h2>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBedrooms}
                      </span>
                      bd
                    </span>
                    <span style={{ paddingLeft: "10px" }}>|</span>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBathrooms}
                      </span>
                      ba
                    </span>
                    {/* <span style={{paddingLeft: "10px"}}>|</span> */}
                    {/* <span style={{paddingLeft: "10px"}}><span style={{fontWeight: "bolder", color: "black"}}>1</span> bd</span> */}
                  </div>
                  <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                    <span>
                      {data?.district}, {data?.streetName}
                    </span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      <i
                        class="fa fa-circle sale-icon text-danger"
                        aria-hidden="true"
                      ></i>
                      For {data?.propertyPurpose} | &nbsp; {naira}
                      {data?.price?.toLocaleString()} &nbsp;
                    </span>
                    <br />
                    <span>Product Id: {data?.productId}</span>
                    <br />
                    <div className="main-card-single col-md-6 share-for-mobile">
                      <span className="">
                        <span className="modal-icon">
                          <i
                            class="fa fa-heart-o top-icon"
                            aria-hidden="true"
                          ></i>
                          &nbsp;
                          <span>Save</span>
                        </span>

                        <span
                          className="modal-icon"
                          onClick={() => setShare(!share)}
                        >
                          <i class="fa fa-share-alt" aria-hidden="true"></i>
                          &nbsp;
                          <span>Share</span>
                        </span>

                        <div className="pb-3 share-for-mobile">
                          {share && (
                            <>
                              <FacebookShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <FacebookIcon size={32} round={true} />
                              </FacebookShareButton>
                              <WhatsappShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <WhatsappIcon size={32} round={true} />
                              </WhatsappShareButton>
                              <TwitterShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <TwitterIcon size={32} round={true} />
                              </TwitterShareButton>
                              <LinkedinShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <LinkedinIcon size={32} round={true} />
                              </LinkedinShareButton>
                              <EmailShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <EmailIcon size={32} round={true} />
                              </EmailShareButton>
                              <TelegramShareButton
                                url={shareLink}
                                color="red"
                                size={32}
                                round={true}
                              >
                                <TelegramIcon size={32} round={true} />
                              </TelegramShareButton>

                              <div className="pt-2 d-flex">
                                <p style={{ fontSize: "20px" }}>
                                  <i
                                    class="fa fa-clipboard"
                                    style={{ color: "purple" }}
                                    aria-hidden="true"
                                    onClick={() => handleCopy()}
                                  ></i>
                                </p>

                                {copy ? (
                                  <strong>Copied</strong>
                                ) : (
                                  <small>Copy property link</small>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </span>
                    </div>
                    {/* <span>
                  Est Payment: $1,200/mo
                  <span className="bg-warning dollar-symbol">$</span>
                </span>
                <span>Get pre-qualified</span> */}
                  </div>
                  <br />
                  <div className="row">
                    {message && (
                      <strong className="alert alert-success text-center">
                        {message}
                      </strong>
                    )}
                    <div className="respond-me">
                      <div className="">
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          onClick={toggleVisibility}
                          style={{
                            width: "49%",
                            backgroundColor: "purple",
                            borderColor: "purple",
                            marginRight: "2%",
                          }}
                        >
                          Contact Agent
                        </button>
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          style={{
                            width: "49%",
                            backgroundColor: "purple",
                            borderColor: "purple",
                          }}
                        >
                          Escrow Pay
                        </button>
                      </div>
                      {isVisible && (
                        <div className="p-4">
                          Email: {data?.agentEmail} <br />
                          Contact: {data?.agentPhone}
                          {/* name: Mr Wasiu <br /> Contact: 08099933304 */}
                        </div>
                      )}

                      <button
                        className={`${
                          data?.userRated?.includes(user?.uid) ? "d-none" : ""
                        } btn btn-warning selected-agent-btn text-white`}
                        onClick={() => setRate(!rate)}
                        style={{
                          width: "50%",
                          // backgroundColor: "purple",
                          // borderColor: "purple !important",
                        }}
                      >
                        Rate Property
                      </button>
                      {rate && (
                        <div
                          style={{
                            width: "100% !important",
                            paddingTop: "20px",
                          }}
                          // className={`${disappear}`}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <StarRatings
                              rating={ratings}
                              changeRating={setRating}
                              numberOfStars={5}
                              name="rating"
                              color="purple"
                              style={{
                                width: "300px",
                              }}
                              starRatedColor="yellow"
                              starHoverColor="purple"
                              starDimension="30px"
                            />
                          </div>
                          <button
                            className="d-flex justify-content-center text-white mt-3 rounded-pill text-center mx-auto"
                            style={{
                              background: `${
                                progress === true ? "gray" : "purple"
                              }`,
                              width: "50%",
                              padding: "5px",
                              border: "none !important",
                              opacity: `${progress === true ? "0.8" : ""}`,
                            }}
                            onClick={(e) => handleRate(e)}
                            disabled={progress === true}
                          >
                            Submit Ratings
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="pt-4 pr-3">
                      <strong>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleAgentInfo(data, e)}
                        >
                          {data?.agentName}
                        </a>
                      </strong>
                    </div>
                  </div>
                  <hr />

                  <MDBContainer className="scroll-container">
                    <MDBRow>
                      <MDBCol md="12">
                        <MDBScrollspy container={containerRef}>
                          <MDBScrollspyLink
                            className="scroll-new"
                            targetRef={section1}
                          >
                            Ratings
                          </MDBScrollspyLink>
                          <MDBScrollspyLink targetRef={section2}>
                            Overview
                          </MDBScrollspyLink>
                          <MDBScrollspyLink targetRef={section3}>
                            Features
                          </MDBScrollspyLink>
                        </MDBScrollspy>
                      </MDBCol>

                      <MDBCol md="12">
                        <div
                          id="element"
                          ref={containerRef}
                          className="scrollspy-example"
                        >
                          <section ref={section1} id="section-1">
                            <div className="p-4 home">
                              <span className="text-muted fs-5">Ratings</span>
                              <br />
                              {users?.subscribed === "none" ||
                              users?.subscribed === undefined || data?.verified === 5 ?(
                                <p>
                                  {!data?.verified ? (
                                    <p>N/A, unverified</p>
                                  ): (
                                    <button
                                      className="btn btn-outline-warning selected-agent-btn text-white"
                                      style={{
                                        background: "purple",
                                        width: "50%",
                                        borderColor: "purple",
                                      }}
                                      onClick={(e) => {
                                        setShow(false);
                                        setModalShow(true);
                                      }}
                                    >
                                      Subscribers Only
                                    </button>
                                    )}
                                  </p>
                                ) : (
                                <></>
                              )}
                              {users?.subscribed === "daily" ||
                              users?.subscribed === "weekly" ||
                              users?.subscribed === "monthly" ? (
                                <>
                                  <h2>
                                    Overall Quality Rating:
                                    <StarRatings
                                      rating={
                                        data?.propertyType?.toLowerCase() ===
                                        "shortlet"
                                          ? Number(data?.userRating)
                                          : data?.starRating
                                      }
                                      // rating={5}
                                      // changeRating={data?.starRating}
                                      numberOfStars={5}
                                      name="rating"
                                      color="purple"
                                      style={{ width: "300px" }}
                                      starRatedColor="yellow"
                                      starHoverColor="purple"
                                      starDimension="30px"
                                    />
                                  </h2>
                                  <p>Location: </p>
                                  <p>
                                    Architecture:{" "}
                                    {data?.architectureScore.toFixed(2)}
                                  </p>
                                  <p>
                                    Security: {data?.securityScore.toFixed(2)}
                                  </p>
                                  <p>
                                    Facilities:{" "}
                                    {data?.compoundFacilitiesScore.toFixed(2)}
                                  </p>
                                  <p>
                                    Privacy/Landlord Relations:{" "}
                                    {data?.privacyLandlordRelationshipRating}
                                  </p>
                                  <br />
                                  <h2>
                                    Value For Money Percentile:{" "}
                                    {data?.valueForMoneyScore.toFixed(2)}
                                  </h2>
                                </>
                              ) : (
                                <></>
                              )}
                              <br />
                              <hr />
                            </div>
                          </section>
                          <section ref={section2} id="section-2">
                            <div className="p-4 projects">
                              <span className="text-muted fs-5">Overview</span>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>Property type:</p>
                                </div>
                                <div className="col-md-2">
                                  <p>{data?.propertyType}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>Property Description:</p>
                                </div>
                                <div className="col-md-2 mobile-text-size">
                                  <p>{data?.propertyDescription}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 col-sm-8 mobile-text-size">
                                  <p>No of Bedroom:</p>
                                </div>
                                <div className="col-md-2 col-sm-2 mobile-text-sizes">
                                  <p>{data?.numberOfBedrooms}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>No of Bathroom:</p>
                                </div>
                                <div className="col-md-2 mobile-text-sizes">
                                  <p>{data?.numberOfBathrooms}</p>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </section>
                          <section ref={section3} id="section-3">
                            <div className="p-4 media">
                              <span className="text-muted fs-5">Features</span>
                              <br />
                              <div className="row">
                                <div className="col-md-6">
                                  <p>{data?.additionalFeatures}</p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary overal-botton"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
              <div className="row"></div>
            </div>
          )}
        </ModalMain.Body>
      </ModalMain>

      <SuscriberModal show={modalshow} setShow={setModalShow} />
    </>
  );
}

export default ModalShortlet;
