export default function filterBedroom(
    arr,
    method,
    prod,
    others,
    setDetails,
    setProducts
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const urlsearch = urlParams.get("q");
    if (arr?.length === 0) {
      // setDetails(others);
      // setProducts(others);
      return others;
    } else {
      return prod?.filter((product) => {
        console.log(product)
        const bedroomArray = product?.propertyDescription?.split(" ");
        // console.log(bedroomArray)
        if (arr?.length > 0) {
          if (bedroomArray?.some((r) => arr?.indexOf(r) >= 0)) {
            return product;
          }
        } else {
          return prod;
        }
      });
    }
  }