import Navbar from "./Navbar";
import Footer from "./Footer";
import image from "../images/house.png";
import propertymanager from "../images/property-manager.png";
import agent from "../images/agent.jpeg";
import userimage from "../images/user-image.webp";
import { Link, useNavigate } from "react-router-dom";

const SignUpPro = () => {
  // const navigate = useNavigate();

  return (
    <>
      <Navbar />

      <section className="start-section">

        <section className="signuppro-first">
        
          {/*<div className="show-mobile">
            <div className="top-pro">
              sign up below to
              <ul>
                <li>Use Special Services</li>
                <li>List Properties or</li>
                <li>Manage Properties</li>
              </ul>
            </div>

              <div className="button button-style">
                <h3>User</h3>
                <span className="signuppro-paragraph">
                  <p>You want to optimize your search with our algorithm</p>
                </span>
                
                <a href="signup?type=user">Start</a>
              </div>
              <div className="button button-style">
                <h3>Agent</h3>
                <span className="signuppro-paragraph">
                  <p>You want to list a property</p>
                </span>
                <a href="signup?type=agent">Start</a>
              </div>                
              <div className="button button-style">
              <h3>Agent-Property Manager</h3>
                <span className="signuppro-paragraph">
                  <p>You want to list a property and onboard tenants</p>
                </span>
                <a href="/signup?type=agentpropertymanager">Start</a>
              </div>
              <div className="button button-style">
                <h3>Property Manager</h3>
                <span className="signuppro-paragraph">
                  <p>You want to onboard tenants</p>
                </span>
                <a href="/signup?type=propertymanager" className="goclick">Start</a>
              </div>
              <div className="button button-style">
                <h3>Landlord</h3>
                <span className="signuppro-paragraph">
                  <p>You own a property and wish to list it for management or investment</p>
                </span>
                <a href="#">Start</a>
              </div>

            
          </div>*/}
        </section>


        <section className="below">

          <div className="show-mobile">
            <div className="top-pro">
              Sign up below to
              <ul>
                <li>Use Special Services</li>
                <li>List Properties or</li>
                <li>Manage Properties</li>
              </ul>
            </div>

              <div className="button button-style">
                <h3>User</h3>
                <span className="signuppro-paragraph">
                  <p>You want to optimize your search with our algorithm</p>
                </span>
                
                <a href="signup?type=user">Start</a>
              </div>
              <div className="button button-style">
                <h3>Agent</h3>
                <span className="signuppro-paragraph">
                  <p>You want to list a property</p>
                </span>
                <a href="signup?type=agent">Start</a>
              </div>                
              <div className="button button-style">
              <h3>Agent-Property Manager</h3>
                <span className="signuppro-paragraph">
                  <p>You want to list a property and onboard tenants</p>
                </span>
                <a href="/signup?type=agentpropertymanager">Start</a>
              </div>
              <div className="button button-style">
                <h3>Property Manager</h3>
                <span className="signuppro-paragraph">
                  <p>You want to onboard tenants</p>
                </span>
                <a href="/signup?type=propertymanager" className="goclick">Start</a>
              </div>
              <div className="button button-style">
                <h3>Landlord</h3>
                <span className="signuppro-paragraph">
                  <p>You own a property and wish to list it for management or investment</p>
                </span>
                <a href="/sell">Start</a>
              </div>

            
          </div>







          <h1 className="signuppro-hide">Sign-Up for the Chekam Advantage</h1>
          <section className="signuppro-firsts">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-12 signup-card mobile-signup">
                  <img
                    src={userimage}
                    width="150px"
                    height="150px"
                    alt="user"
                  />
                  <h2>User</h2>
                  <a href="/signup?type=user">Start</a>
                  <p>
                    You wish to use our services to find your rent or purchase
                    target faster
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 signup-card ">
                  <img src={agent} width="150px" height="150px" alt="agent" />
                  <h2>Agent</h2>
                  <a href="/signup?type=agent">Start</a>
                  <p>
                    You have properties to list for sale or rent but do not
                    own or manage the property
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 signup-card ">
                  <img src={propertymanager} width="150px" height="150px" />
                  <h2>Property Manager</h2>
                  <a href="/signup?type=propertymanager">Start</a>
                  <p>
                    You manage properties and onboard tenants
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-md-6 col-sm-12 signup-card ">
                  <img src={image} width="200px" />
                  <h2>Agent / Property Manager</h2>
                  <a href="/signup?type=agentpropertymanager">start</a>
                  <p>
                    If you both list and manage property (onboarding tenants)
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 col-sm-12 col-md-6 col-sm-12 signup-card ">
                  <img src={image} width="200px" />
                  <h2>Landlord</h2>
                  <a href="/signup?type=landlord">Start</a>
                  <p>
                    If you are the owner of a property and wish to <strong>rent it</strong> or
                    <strong>sell it</strong> by yourself
                  </p>
                  
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>

      <Footer />
    </>
  );
};

export default SignUpPro;
