import React from 'react';
import './styles/Buy.css'
import './styles/ProductList.css'
import { Link,useNavigate } from "react-router-dom";
import  Modal  from "./Modal";

function SearchCard({person}) {

  const navigate = useNavigate();
  let naira = "\u20A6"
  return(
        <>


        {/* <div className="row hotel-scroll searchcard" style={{ margin: "auto" }}> */}


 
              <div
                className="col-lg-6 col-md-6 col-sm-6 pb-4 product-card"
                key={person?.id}
              >
              
                
                <div className="card card-style">
                  <div className="product-inner-card">
                  <a
                href="/single-buy"
                style={{ textDecoration: "none" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                  <Link to={`/products/${person.id}`}><img
                      src={person?.image}
                      className="card-img-top img-fluid"
                      alt="..."
                    /></Link>
                  </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {person?.time} hours ago
                      </p>
                      <div>
                        <i
                          class="fa fa-heart-o top-icon"
                          aria-hidden="true"
                          onClick={() => {
                            const { location, image, price, about, id } = person;
                            // console.log(price);
                            let main = [
                              {
                                location,
                                image,
                                price,
                                about,
                                id,
                              },
                            ];
                            // setBook([...book, main]);
                            // useEffect(() => {
                            localStorage.setItem(
                              "houses",
                              JSON.stringify(main)
                            );
                            // }, [book]);
                            // let main = [
                            //   { location, image, price, about },
                            // ];
                            // localStorage.setItem(
                            //   "houses",
                            //   JSON.stringify(main)
                            // );
                            // dispatch(addToCart({ id, name, price, img }));
                            // // props.history.push("/cart");
                            alert("item saved successfull");
                            navigate("/saved-searches");
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{}}>
                    <p className="card-text" style={{ fontSize: "12px" }}>
                      {naira} {person?.price.toLocaleString()}
                      <br />
                      <span>{person?.about}</span>
                      <br />
                      <span>{person?.streetName}</span>
                    </p>
                  </div>
                </div>
              <Modal />
              </div>
      {/* </div> */}









            

            </>











  );
}

export default SearchCard;