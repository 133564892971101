import React, { useState, useEffect } from "react";
import ManagerHeader from "./ManagerHeader";

import { useNavigate } from "react-router";
import Box from '@mui/material/Box';

//import { purple } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import {getDocs,collection,query, where} from "firebase/firestore";
import db from '../../../components/Firebase';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import Badge from '@mui/material/Badge';
import HouseIcon from '@mui/icons-material/House';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
//import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
//import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { getAuth, signOut} from "firebase/auth";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import Checkbox from '@mui/material/Checkbox';

import CommentIcon from '@mui/icons-material/Comment';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import { ThemeProvider, createTheme } from "@mui/material/styles";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const theme = createTheme({
  palette: {
    primary: {
      light: "#FFCC00",
      main: "#FFFFFF",
      dark: "#800080",
    },
    secondary: {
      light: "#FFCC00",
      main: "#FFCC00",
      dark: "#800080",
    },
  },
});




function ManagerHomepage() {
const auth = getAuth();
const user = auth.currentUser;
const navigate = useNavigate();



const [myProperties, setMyProperties] = useState ([]);
const [propertyCount, setPropertyCount] = useState (0);


const [open, setOpen] = React.useState(false);
const [checked, setChecked] = React.useState(true);
const [propertieschecked, setPropertiesChecked] = React.useState([0]);
const [payButton,setPayButton]= React.useState('');






const handleSignOut = () => {
  signOut(getAuth()).then(() => {
    // Sign-out successful.
    navigate('/')
  }).catch((error) => {
    // An error happened.
  });
}


const handleChange = (event) => {
  setChecked(event.target.checked);
};



  const handlePromotePropertyOpen = () => {
    setOpen(true);
    setPayButton('PROMOTE')
  };

  const handlePremiumSubscriptionOpen = () => {
    setOpen(true);
    setPayButton('SUBSCRIBE')
  };

  const handleChekamVerificationOpen = () => {
    setOpen(true);
    setPayButton('PAY')
  };

  const handleClose = () => {
    setOpen(false);
  };

const getCount = async()=>{
  const propertiesRef = collection(db, "properties");
  const q = query(propertiesRef, where("agentEmail", "==", user.email));
  const querySnapshot = await getDocs(q);
  
  if (querySnapshot){setPropertyCount(querySnapshot.size)}
}
getCount()


let charles = []
      async function getProperties(){
          const propertiesRef = collection(db, "properties");
          const q = query(propertiesRef, where("agentEmail", "==", user.email));
          const querySnapshot = await getDocs(q);
     
          querySnapshot.forEach((doc) => {charles.push(doc.data())
            charles = charles.sort((a,b)=>{return a.price-b.price})
            setMyProperties([...charles])  
          
           });
          }
          getProperties()



  return (
    <>
      <ManagerHeader />
      
       
       <Box sx = {{mt:15,mx:5}}>
        <Grid container spacing={4} >
       
             
          <Grid item xs={12} sm={12} md={6}>
           
            <Button  variant = "contained" fullWidth='true' color='secondary' onClick={()=>{navigate("/admin/propertymanager/properties")}} >
              My Properties
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant = 'contained' fullWidth='true'  color='secondary' onClick={()=>{navigate("/admin/propertymanager/properties/SaveApplication")}}>
              Send Tenant Information Form
             </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            < Button variant = 'contained' fullWidth='true' color='secondary' >
              My Applicants
              </Button>
          </Grid>

        <Grid item xs={12} sm={12} md={6}>
            < Button variant = 'contained' fullWidth='true'  color='secondary' >
              My Tenants
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button  variant = 'contained' fullWidth='true'  color='secondary' >
              Payments
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant = 'contained' fullWidth='true'  color='secondary' onClick={()=>{navigate("/admin/agent")}} >
              Switch to Agent Suite
            </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
            <Button variant = 'contained' fullWidth='true'  color='secondary' onClick={()=>{navigate("admin/propertymanager/profile")}} >
              Profile
            </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
            <Button variant = 'contained' fullWidth='true'  color='secondary'  onClick={()=>{navigate("/admin/propertymanager/faqs")}}>
              F.A.Q
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant = 'contained' fullWidth='true'  color='secondary' onClick={handleSignOut}>
              Log Out
            </Button>
        </Grid>
          
          </Grid>
        </Box>
        
          <Dialog
                  fullScreen
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                >
                  <AppBar sx={{ position: 'relative',bgcolor:'#800080' }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Properties
                      </Typography>
                      <Button variant='contained' color="secondary" onClick={handleClose}>
                      {payButton}
                      </Button>
                    </Toolbar>
                  </AppBar>

          {myProperties.map((x,i)=> ((
          <Grid  key = {i} sx = {{m:1, p:1,width: 300, display:'inline-block'}}>
          <Grid item>

              <Card sx={{ display: 'flex' }}>
                
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardMedia
                  component="img"
                  height="50"
                  image = {x.images}
                  alt="image"
                  />
                <CardContent>
                  {/*<Typography gutterBottom variant="h5" component="div">
                  {x.propertyType}
                  </Typography>*/}
                  {/*<Typography variant="body2" color="text.secondary">
                  {x.propertyPurpose}
                </Typography>*/}
                  <Typography variant="body2" color="text.secondary">
                  {x.propertyType}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {x.propertyDescription}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {x.district}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {x.price.toLocaleString("en-NG",{style:"currency",currency:"NGN"})}
                  </Typography>
                </CardContent>
                </Box>
                <CardActions>

                  <Checkbox
                    defaultChecked
                    onChange ={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                </CardActions>
              </Card>
              </Grid>
              
            </Grid>
          )))}
                </Dialog>

     
    </>
  );
}

export default ManagerHomepage;