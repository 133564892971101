const Bathroom = ({ selectedDetached, setDetached }) => {
    const bathrooms = ["Detached"];
    return (
      <>
        {bathrooms.map((bathroom, index) => {
          return (
            <a
              className={
                "dropdown-item size-bath " +
                (selectedDetached?.includes(bathroom) ? "selected-size" : "")
              }
              key={index}
              onClick={() => setDetached(bathroom)}
            >
              {bathroom}
            </a>
          );
        })}
      </>
    );
  };
  
  export default Bathroom;
  