import { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { houses, sublinks, more, details } from "../data";
import Size from "./Size";
import Bedroom from "./Bedroom";
import Bungalow from "./Bungalow";
import Terrace from "./Terrace";
import "./styles/Buy.css";
import filterList from "./test/filterList";
import Detached from "./Detached";
import SemiDetached from "./SemiDetached";
import Triplex from "./Triplex";
import Maisonette from "./Maisonette";
import Studio from "./Studio";
import AlcoveStudio from "./AlcoveStudio";
import ConvertibleStudio from "./ConvertibleStudio";
import ConvertibleLoft from "./ConvertibleLoft";
import DuplexTriplex from "./DuplexTriplex";
import JuniorBedroom from "./JuniorBedroom";
import Garden from "./Garden";
import Railroad from "./Railroad";
import FloorThrough from "./FloorThrough";
import Commercial from "./Commercial";
import Lands from "./Lands";
import Houses from "./Houses";
import Apartments from "./Apartments";

const BuyHeader = ({
  value,
  setList,
  agirlik0,
  agirlik1,
  prices,
  setAgirlik,
  agirlik,
  selectedSizes,
  setSize,
  selectedBedrooms,
  selectedDetached,
  selectedSemiDetached,
  selectedMaisonette,
  selectedTriplex,
  selectedStudio,
  selectedAlcoveStudio,
  selectedConvertibleStudio,
  selectedConvertibleLoft,
  selectedDuplexTriplex,
  selectedJuniorBedroom,
  selectedGarden,
  selectedRailroad,
  selectedFloorThrough,
  selectedCommercial,
  selectedLand,
  selectedHouse,
  selectedApartment,
  selectedTerrace,
  selectedBungalows,
  setBedroom,
  setDetached,
  setMaisonette,
  setTriplex,
  setStudio,
  setAlcoveStudio,
  setConvertibleStudio,
  setConvertibleLoft,
  setDuplexTriplex,
  setJuniorBedroom,
  setGarden,
  setRailroad,
  setFloorThrough,
  setCommercial,
  setLand,
  setHouse,
  setApartment,
  setSemiDetached,
  verifiedYes,
  verifiedNo,
  setBungalow,
  setTerrace,
  filteredVerified,
  filteredUnVerified,
}) => {
  // SHOW DIV
  const [activenav, setActivenav] = useState("");
  const [showDiv, setShowDiv] = useState(false);

  const [showPriceOne, setPriceOne] = useState(true);
  const [showPriceTwo, setPriceTwo] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [defaultPrice, setDefaultPrice] = useState("Price");
  const [houseShow, setHouseShow] = useState("d-none");
  const [apartmentShow, setApartmentShow] = useState("d-none");
  const { innerWidth: width, innerHeight: height } = window;
  // const { height, width } = useWindowDimensions();
  // console.log(height, width);
  const onShowDiv = (e) => {
    e.preventDefault();
    setShowDiv(true);
  };

  const dontRefresh = (e) => {
    e.preventDefault();
  };

  const myString = agirlik0.toString();

  if (agirlik0) {
  }
  const Remove = myString.substring(0, myString.length - 6);
  // console.log(Remove);

  const handlingPrice = () => {
    // console.log(agirlik0.le)
    if (agirlik0.toString().length < 4) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length === 5) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length <= 6) {
      // do this
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
      // return agirlik0 + "K";
    } else if (agirlik0.toString().length === 7) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 8) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 9) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 10) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 9);
      return forThousand + "B";
    }
    // else if (agirlik0.toString().length <= 9) {
    //   return agirlik0 + "M";
    // } else if (agirlik0.toString().length <= 10) {
    //   // do this
    //   return agirlik0 + "B";
    // }
    else {
      return agirlik0;
    }
  };
  const handlingPrice2 = () => {
    // console.log(agirlik0.le)
    if (agirlik0.toString().length < 4) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length === 5) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length <= 6) {
      // do this
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
      // return agirlik0 + "K";
    } else if (agirlik0.toString().length === 7) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 8) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 9) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 10) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 9);
      return forThousand + "B";
    } else {
      return agirlik0;
    }
  };

  const handleShowOne = (e) => {
    e.preventDefault();
    if (showPriceTwo) {
      setPriceTwo(false);
      setPriceOne(true);
    } else {
      setPriceOne(true);
    }
  };
  const handleShowTwo = (e) => {
    e.preventDefault();
    if (showPriceOne) {
      setPriceOne(false);
      setPriceTwo(true);
    } else {
      setPriceTwo(true);
    }
  };

  const handlePriceShow = (starter, e) => {
    e.preventDefault();
    setActivenav(starter);
    if (priceModal) {
      setPriceModal(false);
    } else {
      setPriceModal(true);
    }
  };

  const handleDone = (e) => {
    e.preventDefault();
    setPriceModal(false);
    const thousandValue = agirlik0.toString();
    const forThousand = thousandValue.substring(0, thousandValue.length - 5);
    const thousandValue2 = agirlik1.toString();

    let forThousand2 =
      thousandValue2.length === 10
        ? thousandValue2.substring(0, thousandValue2.length - 9) + "B"
        : thousandValue2.substring(0, thousandValue.length - 5) + "M";
    // if (agirlik1.toString().length === 10) {
    // return  forThousand2 = thousandValue2.substring(0, thousandValue.length - 9);
    //   // forThousand2 + "B";
    //   // console.log(forThousand2)
    // }
    // forThousand2 = agirlik0.toString().length === 10 ? thousandValue2.substring(0, thousandValue.length - 9),  forThousand2 + "B"
    setDefaultPrice(`${forThousand}M - ${forThousand2}`);
  };

  const handleHouseMobile = () => {
    if (houseShow === "") {
      setHouseShow("d-none");
    } else {
      setHouseShow("");
    }
  };

  const handleActive = (starter, e) => {
    e.preventDefault();

    setActivenav(starter);
  };

  return (
    <>
      {/* large screen design start */}
      <div
        className="col-lg-8 d-flex buttons-horizontal main-thing"
        id="navigating-header"
      >
        <div style={{ margin: "2px" }}>
          <div className="dropdown">
            <li
              className="dropdown main-dropdown "
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
            >
              <a
                className={`ui-state-default buy-cat-button ${
                  activenav === "propertytypes" && "active-nav"
                }`}
                onClick={(e) => handleActive("propertytypes", e)}
              >
                Property Types
              </a>
            </li>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                {/* <a className="dropdown-item">House &raquo;</a> */}
                <Houses
                  className="dropdown-item"
                  selectedHouse={selectedHouse}
                  setHouse={setHouse}
                />
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    <Bungalow
                      selectedBungalows={selectedBungalows}
                      setBungalow={setBungalow}
                    />
                    <Terrace
                      selectedTerrace={selectedTerrace}
                      setTerrace={setTerrace}
                    />
                    <Detached
                      selectedDetached={selectedDetached}
                      setDetached={setDetached}
                    />
                    <SemiDetached
                      selectedSemiDetached={selectedSemiDetached}
                      setSemiDetached={setSemiDetached}
                    />
                    <Maisonette
                      selectedMaisonette={selectedMaisonette}
                      setMaisonette={setMaisonette}
                    />
                    <Triplex
                      selectedTriplex={selectedTriplex}
                      setTriplex={setTriplex}
                    />
                  </li>
                </ul>
              </li>
              <li>
                {/* <a className="dropdown-item">Apartment &raquo;</a> */}
                <Apartments
                  className="dropdown-item"
                  selectedApartment={selectedApartment}
                  setApartment={setApartment}
                />
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    {/* <a className="dropdown-item">Studio</a> */}
                    <Studio
                      selectedStudio={selectedStudio}
                      setStudio={setStudio}
                    />
                    <AlcoveStudio
                      selectedAlcoveStudio={selectedAlcoveStudio}
                      setAlcoveStudio={setAlcoveStudio}
                    />
                    <ConvertibleStudio
                      selectedConvertibleStudio={selectedConvertibleStudio}
                      setConvertibleStudio={setConvertibleStudio}
                    />
                    <ConvertibleLoft
                      selectedConvertibleLoft={selectedConvertibleLoft}
                      setConvertibleLoft={setConvertibleLoft}
                    />
                    <DuplexTriplex
                      selectedDuplexTriplex={selectedDuplexTriplex}
                      setDuplexTriplex={setDuplexTriplex}
                    />
                    <JuniorBedroom
                      selectedJuniorBedroom={selectedJuniorBedroom}
                      setJuniorBedroom={setJuniorBedroom}
                    />
                    <Garden
                      selectedGarden={selectedGarden}
                      setGarden={setGarden}
                    />
                    <Railroad
                      selectedRailroad={selectedRailroad}
                      setRailroad={setRailroad}
                    />
                    <FloorThrough
                      selectedFloorThrough={selectedFloorThrough}
                      setFloorThrough={setFloorThrough}
                    />
                  </li>
                </ul>
              </li>
              <li>
                <Commercial
                  selectedCommercial={selectedCommercial}
                  setCommercial={setCommercial}
                />
                <Lands selectedLand={selectedLand} setLand={setLand} />
              </li>
            </ul>
          </div>
        </div>

        <div style={{ margin: "2px" }}>
          <li className="dropdown main-dropdown" onClick={dontRefresh}>
            <a
              className={`ui-state-default buy-cat-button ${
                activenav === "bedandbath" && "active-nav"
              }`}
              onClick={(e) => handleActive("bedandbath", e)}
            >
              Baths & Bed
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarScrollingDropdown"
              style={{ paddingRight: "20px" }}
            >
              <div className="row subs-nav">
                <div className="col-md-4 p-4">
                  <div className="bedroom-div " style={{ paddingLeft: "5px" }}>
                    <div className="bedrooms-section">Bedrooms</div>
                    <Bedroom
                      selectedBedrooms={selectedBedrooms}
                      setBedroom={setBedroom}
                    />
                  </div>
                  <div className="" style={{ paddingLeft: "5px" }}>
                    <div className="bedrooms-section">Baths</div>
                    <Size selectedSizes={selectedSizes} setSize={setSize} />
                  </div>
                </div>
                {/* <div className="col-md-4 p-4">
              <span className="">Bedrooms</span>
              <div className="" style={{ paddingLeft: "20px" }}>
                <Bedroom selectedBedrooms={selectedBedrooms} setBedroom={setBedroom} />
              </div>
            </div> */}
              </div>
              <li>
                <a href="" className="marquee-link"></a>
              </li>
            </ul>
          </li>
        </div>

        <div style={{ margin: "2px" }}>
          <li className="dropdown main-dropdown" onClick={dontRefresh}>
            <a
              href=""
              onClick={(e) => handlePriceShow("two", e)}
              className={`ui-state-default buy-cat-button ${
                activenav === "two" && "active-nav"
              }`}
            >
              {defaultPrice}
            </a>
            {priceModal && activenav === "two" ? (
              <div
                className="card"
                style={{ position: "absolute", width: "400px", zIndex: "100" }}
                aria-labelledby="navbarScrollingDropdown"
              >
                <div className="p-4">
                  {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                  <div className="kategoriler">
                    <span>
                      Price <span>Range</span>
                    </span>
                    <div className="">
                      <div className="d-flex">
                        <input
                          type="number"
                          className="price-input"
                          // value={agirlik0 + "k"}
                          value={agirlik0}
                          placeholder="No min"
                          // value={handlingPrice()}
                          name="argilik0"
                          onChange={(e) =>
                            setAgirlik([e.target.value, agirlik1])
                          }
                          onClick={(e) => handleShowOne(e)}
                          // value={agirlik1}
                          // onChange={(e) => setAgirlik([agirlik0, e.target.value])}
                          // readOnly
                        />
                        <span className="price-space-input p-2">-</span>
                        <input
                          type="number"
                          className="price-input"
                          value={agirlik1}
                          placeholder="No max"
                          name="agirlik1"
                          onChange={(e) =>
                            setAgirlik([agirlik0, e.target.value])
                          }
                          // readOnly
                          onClick={(e) => handleShowTwo(e)}
                        />
                      </div>
                      <div className="d-flex">
                        {showPriceOne ? (
                          <ul className="price-needed">
                            <li>
                              <a onClick={(e) => setAgirlik([0, agirlik1])}>
                                0k +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([10000000, agirlik1])
                                }
                              >
                                10M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([20000000, agirlik1])
                                }
                              >
                                20M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([30000000, agirlik1])
                                }
                              >
                                30M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([40000000, agirlik1])
                                }
                              >
                                40M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([50000000, agirlik1])
                                }
                              >
                                50M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([60000000, agirlik1])
                                }
                              >
                                60M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([70000000, agirlik1])
                                }
                              >
                                70M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([80000000, agirlik1])
                                }
                              >
                                80M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([90000000, agirlik1])
                                }
                              >
                                90M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([100000000, agirlik1])
                                }
                              >
                                100M +
                              </a>
                            </li>
                          </ul>
                        ) : (
                          <ul className="price-needed2">
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 200000000])
                                }
                              >
                                200M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 300000000])
                                }
                              >
                                300M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 400000000])
                                }
                              >
                                400M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 500000000])
                                }
                              >
                                500M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 600000000])
                                }
                              >
                                600M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 700000000])
                                }
                              >
                                700M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 800000000])
                                }
                              >
                                800M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 900000000])
                                }
                              >
                                900M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 1000000000])
                                }
                              >
                                1B +
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                      <Slider
                        value={agirlik}
                        min={prices.hacim_min}
                        max={prices.hacim_max}
                        style={{ color: "#800080" }}
                        onChange={(event, newValue) => setAgirlik(newValue)}
                        valueLabelDisplay="auto"
                        step="1000000"
                        // sort={1000000}
                        // step={1}
                      />
                      <div
                        className="btn"
                        style={{ background: "purple", color: "#fff" }}
                        onClick={(e) => handleDone(e)}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </li>
        </div>

        <div style={{ margin: "2px" }}>
          <div className="dropdown">
            <li
              className="dropdown main-dropdown"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
            >
              <a
                href=""
                className={`ui-state-default buy-cat-button ${
                  activenav === "more" && "active-nav"
                }`}
                onClick={(e) => handleActive("more", e)}
              >
                More
              </a>
            </li>
            <ul
              className="dropdown-menu more-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <li onClick={dontRefresh}>
                <a className="dropdown-item">Property Title &raquo;</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    <a className="dropdown-item">C of O</a>
                    <a className="dropdown-item">R of A</a>
                    <a className="dropdown-item">DOA</a>
                    <a className="dropdown-item">Freehold</a>
                  </li>
                </ul>
              </li>
              <li onClick={dontRefresh}>
                <a className="dropdown-item">Chekam verified &raquo;</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    <a className="dropdown-item" onClick={filteredVerified}>
                      Yes
                    </a>
                    <a className="dropdown-item" onClick={filteredUnVerified}>
                      No
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* large screen design end */}

      {/* mobile screen design start */}
      <div
        className="col-lg-8 d-flex buttons-horizontal "
        id="navigating-header2"
        style={{ paddingTop: "10px" }}
      >
        <div style={{ margin: "2px" }}>
          <li className="dropdown main-dropdown" onClick={dontRefresh}>
            <a
              href=""
              className={`ui-state-default buy-cat-button ${
                activenav === "two" && "active-nav"
              }`}
              // onClick={(e) => handleActive("two", e)}
              onClick={(e) => handlePriceShow("two", e)}
            >
              {defaultPrice}
            </a>
            {priceModal && activenav === "two" ? (
              <div
                className="card"
                style={{ position: "absolute", width: "400px", zIndex: "100" }}
                aria-labelledby="navbarScrollingDropdown"
              >
                <div className="p-4">
                  {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                  <div className="kategoriler">
                    <span>
                      Price <span>Range</span>
                    </span>
                    <div className="">
                      <div className="d-flex">
                        <input
                          type="number"
                          className="price-input"
                          // value={agirlik0 + "k"}
                          value={agirlik0}
                          placeholder="No min"
                          // value={handlingPrice()}
                          name="argilik0"
                          onChange={(e) =>
                            setAgirlik([e.target.value, agirlik1])
                          }
                          onClick={(e) => handleShowOne(e)}
                          // value={agirlik1}
                          // onChange={(e) => setAgirlik([agirlik0, e.target.value])}
                          // readOnly
                        />
                        <span className="price-space-input p-2">-</span>
                        <input
                          type="number"
                          className="price-input"
                          value={agirlik1}
                          placeholder="No max"
                          name="agirlik1"
                          onChange={(e) =>
                            setAgirlik([agirlik0, e.target.value])
                          }
                          // readOnly
                          onClick={(e) => handleShowTwo(e)}
                        />
                      </div>
                      <div className="d-flex">
                        {showPriceOne ? (
                          <ul className="price-needed">
                            <li>
                              <a onClick={(e) => setAgirlik([0, agirlik1])}>
                                0k +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([10000000, agirlik1])
                                }
                              >
                                10M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([20000000, agirlik1])
                                }
                              >
                                20M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([30000000, agirlik1])
                                }
                              >
                                30M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([40000000, agirlik1])
                                }
                              >
                                40M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([50000000, agirlik1])
                                }
                              >
                                50M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([60000000, agirlik1])
                                }
                              >
                                60M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([70000000, agirlik1])
                                }
                              >
                                70M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([80000000, agirlik1])
                                }
                              >
                                80M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([90000000, agirlik1])
                                }
                              >
                                90M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([100000000, agirlik1])
                                }
                              >
                                100M +
                              </a>
                            </li>
                          </ul>
                        ) : (
                          <ul className="price-needed2">
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 200000000])
                                }
                              >
                                200M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 300000000])
                                }
                              >
                                300M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 400000000])
                                }
                              >
                                400M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 500000000])
                                }
                              >
                                500M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 600000000])
                                }
                              >
                                600M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 700000000])
                                }
                              >
                                700M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 800000000])
                                }
                              >
                                800M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 900000000])
                                }
                              >
                                900M +
                              </a>
                            </li>
                            <li>
                              <a
                                onClick={(e) =>
                                  setAgirlik([agirlik0, 1000000000])
                                }
                              >
                                1B +
                              </a>
                            </li>
                          </ul>
                        )}
                      </div>
                      <Slider
                        value={agirlik}
                        min={prices.hacim_min}
                        max={prices.hacim_max}
                        style={{ color: "#800080" }}
                        onChange={(event, newValue) => setAgirlik(newValue)}
                        valueLabelDisplay="auto"
                        step="1000000"
                        // sort={1000000}
                        // step={1}
                      />
                      <div
                        className="btn"
                        style={{ background: "purple", color: "#fff" }}
                        onClick={(e) => handleDone(e)}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </li>
        </div>

        <div style={{ margin: "2px" }}>
          <div className="dropdown">
            <a
              className={`ui-state-default buy-cat-button ${
                activenav === "three" && "active-nav"
              }`}
              onClick={(e) => handleActive("three", e)}
              data-bs-toggle="modal"
              data-bs-target="#moremodal"
            >
              More
            </a>

            {/* <ul
              className="dropdown-menu more-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <li onClick={dontRefresh}>
                <a className="dropdown-item">Property Title &raquo;</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    <a className="dropdown-item">C of O</a>
                    <a className="dropdown-item">R of A</a>
                    <a className="dropdown-item">DOA</a>
                    <a className="dropdown-item">Freehold</a>
                  </li>
                </ul>
              </li>
              <li onClick={dontRefresh}>
                <a className="dropdown-item">Chekam verified &raquo;</a>
                <ul className="dropdown-menu dropdown-submenu">
                  <li>
                    <a className="dropdown-item" onClick={filteredVerified}>
                      Yes
                    </a>
                    <a className="dropdown-item" onClick={filteredUnVerified}>
                      no
                    </a>
                  </li>
                </ul>
              </li>
            </ul> */}
            {/* <!-- Scrollable modal --> */}
          </div>
        </div>

        <div style={{ margin: "2px" }}>
          <div className="dropdown">
            <a
              className={`ui-state-default buy-cat-button ${
                activenav === "four" && "active-nav"
              }`}
              onClick={(e) => handleActive("four", e)}
              data-bs-toggle="modal"
              data-bs-target="#sortby"
            >
              Sort By ?
            </a>
          </div>
        </div>
      </div>
      <div
        class="modal"
        id="sortby"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" id="modal-more">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">More</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" id="modal-body">
              <div style={{ margin: "2px" }}>
                <h3>Sort By:</h3>
                <select
                  value={value}
                  style={{ width: "100%" }}
                  onChange={setList}
                  className="form-control"
                >
                  <option value="Select">Select</option>
                  <option value="Highest to Lowest">Highest Quality</option>
                  <option value="Lowest to Highest">
                    Highest Value for Money
                  </option>
                  <option value="Direct Mandates">Verified</option>
                  <option value="Premium">Premium</option>
                </select>
                <br />
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                style={{ background: "purple", color: "#fff" }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* mobile screen design end */}

      <div
        class="modal"
        id="moremodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" id="modal-more">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Choose an option</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" id="modal-body">
              <div style={{ margin: "2px" }}>
                {/* <h3>Sort By:</h3>
                <select
                  value={value}
                  style={{ width: "100%" }}
                  onChange={setList}
                  className="form-control"
                >
                  <option value="Select">Select</option>
                  <option value="Highest to Lowest">Highest Quality</option>
                  <option value="Lowest to Highest">
                    Highest Value for Money
                  </option>
                  <option value="Direct Mandates">Direct Mandates</option>
                  <option value="Premium">Premium</option>
                </select>
                <br /> */}
                <li className=" main-dropdown" onClick={dontRefresh}>
                  <h3>Bed & Bath</h3>
                  <ul className="more-mobile">
                    <div className="row subs-nav">
                      <div className="col-md-4">
                        <div className="bedroom-div ">
                          <div className="bedrooms-section pb-3">Bedrooms</div>
                          <li>
                            <Bedroom
                              selectedBedrooms={selectedBedrooms}
                              setBedroom={setBedroom}
                            />
                          </li>
                        </div>
                        <div className="">
                          <div className="bedrooms-section pb-3">Baths</div>
                          <li>
                            <Size
                              selectedSizes={selectedSizes}
                              setSize={setSize}
                            />
                          </li>
                        </div>
                      </div>
                      {/* <div className="col-md-4 p-4">
              <span className="">Bedrooms</span>
              <div className="" style={{ paddingLeft: "20px" }}>
                <Bedroom selectedBedrooms={selectedBedrooms} setBedroom={setBedroom} />
              </div>
            </div> */}
                    </div>
                  </ul>
                </li>
              </div>
              <div style={{ margin: "2px" }}>
                <div className="" style={{ textTransform: "capitalize" }}>
                  <h3>Property Types</h3>

                  {/* <a className="dropdown-item">House &raquo;</a> */}
                  <ul className="more-mobile">
                    <li>
                      <a
                        className={
                          "dropdown-item size-bath " +
                          (houseShow === "" ? "selected-size" : "")
                        }
                        // key={index}
                        onClick={() => handleHouseMobile()}
                      >
                        House
                      </a>
                      <ul className={`${houseShow}`}>
                        <li>
                          <Bungalow
                            selectedBungalows={selectedBungalows}
                            setBungalow={setBungalow}
                          />
                        </li>
                        <li>
                          <Terrace
                            selectedTerrace={selectedTerrace}
                            setTerrace={setTerrace}
                          />
                        </li>
                        <li>
                          <Detached
                            selectedDetached={selectedDetached}
                            setDetached={setDetached}
                          />
                        </li>
                        <li>
                          <SemiDetached
                            selectedSemiDetached={selectedSemiDetached}
                            setSemiDetached={setSemiDetached}
                          />
                        </li>
                        <li>
                          <Maisonette
                            selectedMaisonette={selectedMaisonette}
                            setMaisonette={setMaisonette}
                          />
                        </li>
                        <li>
                          <Triplex
                            selectedTriplex={selectedTriplex}
                            setTriplex={setTriplex}
                          />
                        </li>
                      </ul>
                    </li>
                    <li>
                      {/* <a className="dropdown-item">Apartment &raquo;</a> */}
                      <Apartments
                        className="dropdown-item"
                        selectedApartment={selectedApartment}
                        setApartment={setApartment}
                        setApartmentShow={setApartmentShow}
                        apartmentShow={apartmentShow}
                      />
                      <ul className={`${apartmentShow}`}>
                        <li>
                          {/* <a className="dropdown-item">Studio</a> */}
                          <Studio
                            selectedStudio={selectedStudio}
                            setStudio={setStudio}
                          />
                        </li>
                        <li>
                          <AlcoveStudio
                            selectedAlcoveStudio={selectedAlcoveStudio}
                            setAlcoveStudio={setAlcoveStudio}
                          />
                        </li>
                        <li>
                          <ConvertibleStudio
                            selectedConvertibleStudio={
                              selectedConvertibleStudio
                            }
                            setConvertibleStudio={setConvertibleStudio}
                          />
                        </li>
                        <li>
                          <ConvertibleLoft
                            selectedConvertibleLoft={selectedConvertibleLoft}
                            setConvertibleLoft={setConvertibleLoft}
                          />
                        </li>
                        <li>
                          <DuplexTriplex
                            selectedDuplexTriplex={selectedDuplexTriplex}
                            setDuplexTriplex={setDuplexTriplex}
                          />
                        </li>
                        <li>
                          <JuniorBedroom
                            selectedJuniorBedroom={selectedJuniorBedroom}
                            setJuniorBedroom={setJuniorBedroom}
                          />
                        </li>
                        <li>
                          <Garden
                            selectedGarden={selectedGarden}
                            setGarden={setGarden}
                          />
                        </li>
                        <li>
                          <Railroad
                            selectedRailroad={selectedRailroad}
                            setRailroad={setRailroad}
                          />
                        </li>
                        <li>
                          <FloorThrough
                            selectedFloorThrough={selectedFloorThrough}
                            setFloorThrough={setFloorThrough}
                          />
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Commercial
                        selectedCommercial={selectedCommercial}
                        setCommercial={setCommercial}
                      />
                    </li>
                    <li>
                      <Lands selectedLand={selectedLand} setLand={setLand} />
                    </li>
                  </ul>
                </div>
              </div>
              <h3 className="">Chekam verified</h3>
              <ul className="more-mobile">
                <li>
                  <a
                    className={
                      "dropdown-item size-bath " +
                      (verifiedYes === "true" ? "selected-size" : "")
                    }
                    // key={index}
                    onClick={filteredVerified}
                  >
                    Yes
                  </a>
                </li>
                <li>
                  <a
                    className={
                      "dropdown-item size-bath " +
                      (verifiedNo === "true" ? "selected-size" : "")
                    }
                    // key={index}
                    onClick={filteredUnVerified}
                  >
                    No
                  </a>
                </li>
              </ul>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                data-bs-dismiss="modal"
                style={{ background: "purple", color: "#fff" }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyHeader;
