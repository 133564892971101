import React, { useState,useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TenantAppbar from '../../../../components/TenantAppbar';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Divider from '@mui/material/Divider';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { addDoc,collection,serverTimestamp,query,getDocs,where} from "firebase/firestore";
import Autocomplete from '@mui/material/Autocomplete';
//import db from '../../../components/Firebase';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { UserContext } from "../../../../App";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import db from '../../../../components/Firebase';

export default function TenantForm() {

  const user = React.useContext(UserContext)


  const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
  
      //Get Form inputs and assign to variables
       
      let surname = data.get('surname'),
      othernames = data.get('othernames'),
      sex = data.get('sex'),
      nationality = data.get('nationality'),
      state = data.get('state'),
      lga = data.get('lga'),
      city = data.get('city'),
      maritalStatus = data.get('maritalStatus'),
      age = data.get('age'),
      phone = data.get('phone'),
      email = data.get('email'),
      presentResidentialAddress = data.get('presentResidentialAddress'),
      nextOfKinName = data.get('nextOfKinName'),
      nextOfKinAddress = data.get('nextOfKinAddress'),
      nextOfKinPhone = data.get('nextOfKinPhone'),
      nextOfKinRelationship = data.get('nextOfKinRelationship'),
      occupation = data.get('occupation'),
      employer = data.get('employer'),
      employerAddress = data.get('employerAddress'),
      designation = data.get('designation'),
      employerPhone = data.get('employerPhone')

      const addTenant = async () => {
        const docRef = await addDoc(collection(db, "tenants"), {
          surname,
          othernames,
          sex,nationality,
          state,
          lga,
          city,
          maritalStatus,
          age,
          phone,
          email,
          presentResidentialAddress,
          nextOfKinName,
          nextOfKinAddress,
          nextOfKinPhone,
          nextOfKinRelationship,
          occupation,
          employer,
          employerAddress,
          designation,
          employerPhone
          
        });
      }
      addTenant()
  }

return (
    <Box sx={{ bgcolor: '#800080', height:'fullHeight' }}>
    <React.Fragment >
    <TenantAppbar />
     <CssBaseline />
     <Container maxWidth="md">
        <Box sx={{ bgcolor: '#FFFFFF'}} component="form" noValidate onSubmit={handleSubmit}>
        
          <Grid container spacing={4} >
            <Grid item xs={12}> 
            <Divider>  TENANT INFORMATION FORM</Divider>  
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '25ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="surname"  label="Surname" variant="outlined" name ="surname"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '30ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="othernames"  label="Other Names" variant="outlined" name ="othernames"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="sex"  label="Sex" variant="outlined" name ="sex"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="religion"  label="Religion" variant="outlined" name ="religion"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="nationality"  label="Nationality" variant="outlined" name ="nationality"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="state"  label="State" variant="outlined" name ="state"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="lga"  label="LGA" variant="outlined" name ="lga"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="city"  label="City" variant="outlined" name ="city"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="maritalStatus"  label="Marital Status" variant="outlined" name ="maritalStatus"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} type="number" id="age"  label="Age" variant="outlined" name ="age"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="phone"  label="Phone" variant="outlined" name ="phone"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}}  id="email"  label="Email" variant="outlined" name ="email"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '30ch' },}} noValidate autoComplete="off" >
                <TextField sx ={{"& label":{mt:'0'}}} id="presentResidentialAddress"  label="Present Residential Address" variant="outlined" name ="presentResidentialAddress" multiline rows={2}/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '20ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="nextOfKinName"  label="Next Of Kin Name" variant="outlined" name ="nextOfKinName"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '20ch' },}} noValidate autoComplete="off">
              <TextField sx ={{"& label":{mt:'0'}}} id="nextOfKinAddress"  label="Next Of Kin Address" variant="outlined" name ="nextOfKinAddress"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '20ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="nextOfKinPhone"  label="Next Of Kin Phone" variant="outlined" name ="nextOfKinPhone"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '25ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="nextOfKinRelationship"  label="Next Of Kin Relationship" variant="outlined" name ="nextOfKinRelationship"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="occupation"  label="Occupation" variant="outlined" name ="occupation"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="employer"  label="Employer" variant="outlined" name ="employer"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '30ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="employerAddress"  label="Employer Address" variant="outlined" name ="employerAddress" multiline rows={2}/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '15ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="designation"  label="Designation" variant="outlined" name ="designation"/>
              </Box>
            </FormControl>
            </Grid>
            <Grid item xs>
            <FormControl>
              <Box sx={{'& > :not(style)': { m: 1, width: '25ch' },}} noValidate autoComplete="off">
                <TextField sx ={{"& label":{mt:'0'}}} id="employerPhone"  label="Employer Phone" variant="outlined" name ="employerPhone"/>
              </Box>
            </FormControl>
            </Grid>
            
          </Grid>
          <Box sx={{ '& button': { m: 1 } }}>
              <Button type="submit" variant="outlined" size="large" >
                SUBMIT
              </Button>
            </Box>
        </Box>
     </Container>
     </React.Fragment>
     </Box>

)



}