const Bedroom = ({selectedBedrooms,setBedroom}) => {
    const bedrooms = [ '1', '2', '3', '4', '5'];

    return (
        <>
            {
                bedrooms.map((bedroom, index) => {
                    return (
                        <a
                            className={ "size-bath" + (selectedBedrooms?.includes(bedroom) ? " selected-size" : "")}
                                key={index}
                            onClick={() => setBedroom(bedroom)}
                        >
                            {bedroom}
                        </a>
                    )
                })
            }
        </>
    )
}

export default Bedroom