import React, { useState, useEffect } from "react";
import {storage} from "../../../Firebase"
import { UserContext } from "../../../../App";
import { ref,uploadBytes,getDownloadURL } from "firebase/storage";
import { updateProfile} from "firebase/auth";



function EditProfile() {
  const user = React.useContext(UserContext)
  const [photo,setPhoto] = useState(null)
  const [photoURL,setPhotoURL] = useState('https://cdn-icons-png.flaticon.com/512/747/747376.png')
  const [loading,setLoading] = useState (false)
  const [fullName,setFullName] = useState ('')
  const [phone,setPhone] = useState ('')


  const handleImageChange = (e) => {if(e.target.files[0]){setPhoto(e.target.files[0])}}
  const handleFullNameChange = (e) => {setFullName(e.target.value)};
  const handlePhoneChange = (e) => {setPhone(e.target.value)};

  const handleClick = () => {upload(photo,user,setLoading)}

  const handleSaveChanges =()=>{updateProfile(user,
    {displayName:fullName,
     phoneNumber:phone
    
    }
    )}

  useEffect(()=>{
    //console.log(photoURL)
    if(user?.photoURL){setPhotoURL(user.photoURL)}else{setPhotoURL(photoURL)}
    if (user?.displayName){setFullName(user.displayName)}else{setFullName(fullName)}
    if (user?.phoneNumber){setPhone(user.phoneNumber)}else{setFullName(fullName)}
  },[user])
 

  async function upload(file,user,setLoading){
    const fileRef = ref(storage,user.uid +'.png')

    setLoading(true)
    const snapShot = await uploadBytes(fileRef,file)
    const profileURL = await getDownloadURL(fileRef)
    updateProfile(user,{photoURL:profileURL})
    setLoading(false)
    alert('file uploaded')
  }


  return (
    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
      {/* <!-- Profile Edit Form --> */}
          <form>
            <div className="row mb-3">
              <label
                for="profileImage"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Profile Image
              </label>
              <div className="col-md-8 col-lg-9">
              <img
                    src={photoURL}
                    alt="No image Uploaded"
                    className=""
                    style={{
                      borderRadius: "100%",
                      height: "50px",
                      width: "50px",
                    }}
                  />
                <div className="pt-2">
                <input
                    type="file"
                    name="image"
                    className="btn btn-primary btn-sm"
                    onChange = {handleImageChange}
                    />
                    <button disabled = {loading||!photo} onClick={handleClick}>UPLOAD</button>
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="fullName"
                className="col-md-4 col-lg-3 col-form-label"
              >
                full Name
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="fullname"
                  type="text"
                  className="form-control"
                  id="fullName"
                  value = {fullName}
                  onChange = {handleFullNameChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="about" className="col-md-4 col-lg-3 col-form-label">
                About
              </label>
              <div className="col-md-8 col-lg-9">
                <textarea
                  name="about"
                  className="form-control"
                  id="about"
                  style={{ height: "100px" }}
                  value=""
                >
                  I am a Property Manager
                </textarea>
              </div>
            </div>

            <div className="row mb-3">
              <label for="Job" className="col-md-4 col-lg-3 col-form-label">
                Position
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="position"
                  type="text"
                  className="form-control"
                  id="Job"
                  value="Property Manager"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Country" className="col-md-4 col-lg-3 col-form-label">
                Country
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="country"
                  type="text"
                  className="form-control"
                  id="Country"
                  value="Nigeria"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Address" className="col-md-4 col-lg-3 col-form-label">
                Address
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="address"
                  type="text"
                  className="form-control"
                  id="Address"
                  value=""
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Phone" className="col-md-4 col-lg-3 col-form-label">
                Phone
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="phone"
                  type="text"
                  className="form-control"
                  value = {phone}
                  onChange={handlePhoneChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Email" className="col-md-4 col-lg-3 col-form-label">
                Email
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  id="Email"
                  value=""
                />
              </div>
            </div>

            <div className="text-center">
              <button onClick={handleSaveChanges} type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
      {/* <!-- End Profile Edit Form --> */}
    </div>
  );
}

export default EditProfile;