import React from "react";
import "./modal.css"

function ModalProperty() {
  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content admin-modal-property">
          <div class="modal-body">
            <div className="container pt-5">
              <div className="row pb-1">
                Name of tenant: <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter name of tenant"
                />
                <br />
                <br />
                Occupation: <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter occupation"
                />
                <br />
                <br />
                Age: <br />
                <input
                  type="number"
                  className="form-control"
                  placeholder="enter age"
                />
                <br />
                <br />
                Marital Status: <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter marital Status"
                />
                <br />
                <br />
                No of Children: <br />
                <input
                  type="text"
                  className="form-control"
                  placeholder="enter occupation"
                />
                <br />
                <br />
                <button className="form-control btn btn-success" type="submit">
                  Submit
                </button>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalProperty;