import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { PaystackButton } from "react-paystack";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import { UserContext } from "../App";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import axios from "axios";
import API_URL from "../config/index";
function DasModal({
  setShow,
  show,
  inspected,
  loading,
  setLoading,
  setErr,
  setMessage,
  data,
  service,
}) {
  const user = React.useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState([5000, 50, 100]);

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";

  const calculate = () => {
    if (Number(inspected >= 3)) {
      return Number(inspected) * 5000;
    } else if (Number(inspected == 2)) {
      return Number(inspected) * 10000;
    } else {
      return Number(inspected) * 15000;
    }
  };

  // console.log(calculate())
  // const calculate =
  //   Number(inspected) >= 3
  //     ? Number(inspected) * 5000
  //     : Number(inspected) * 15000;
  const amount = calculate() * 100;

  console.log(amount);

  let formated = moment(new Date()).format("l h:mm:ss A");
  // console.log(formated, "testing date")

  const expiredNextMonth = (date) => {
    // var date = new Date();
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      splittingdate[1]
    );
    return moment(monthexpireddate).format("l h:mm:ss A");
  };
  function nextWeek() {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let formated = moment(nextweek).format("l h:mm:ss A");
    return formated;
  }
  function getTomorrow() {
    var today = new Date();
    var tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );

    let formated = moment(tomorrow).format("l h:mm:ss A");
    return formated;
  }

  const expireDaily = getTomorrow();
  const expireWeekly = nextWeek();
  const expireMonthly = expiredNextMonth();

  // console.log(expireDaily, "daily");
  // console.log(expireWeekly, "weekly");
  // console.log(expireMonthly, "monthly");

  const componentRatingsProps = {
    email: data?.email,
    amount,
    metadata: {
      name: data?.name,
      phone: data?.phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      setLoading(true);
      setErr(null);
      setMessage(null);
      const dnsData = {
        name: data?.name,
        location: data?.location,
        inspected: data?.inspected,
        agentphone: data?.agentphone,
        email_type: "das",
        service: service === true ? "YES" : "NO",
        date: value,
        phone: data?.phone,
        email: data?.email,
        title: `${data?.name} paid for D.A.S, details below`,
        //   phone: data?.phone,
      };

      axios
        .post(API_URL, dnsData)
        .then(() => {
          setLoading(false);
          setShow(false);
          setMessage("Payment successful we'll get back to you soon");

          setTimeout(() => {
            return window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
      //   alert("Payment Successful! close and continue");
    },
    onClose: () => alert("closed"),
  };

  const urlParams = new URLSearchParams(window.location.search);
  const urltype = urlParams.get("type");
  const newParams = urlParams.get("q");

  const handleRefresh = () => {
    return (window.location.href = `/selected-item?q=${newParams}`);
  };

  const handleDas = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: `/das`,
        },
      });
    } else {
    }
  };

  // var startTime = new Date("01/12/2013 2:00:00 AM");
  // var endTime = new Date("01/12/2013 1:00:00 AM");

  // if (startTime < endTime) {
  //   alert("start time is lesser");
  // }else {
  //   alert("")
  // }

  const fivek = 5000;
  const tenk = 10000;
  const fifteenk = 15000;

  return (
    <div id="mode-modal">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Inspection Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Inspection plan details</Form.Label>
          <ul>
            <li>
              &#8358;{fifteenk?.toLocaleString()} per inspection for 1
              inspection ordered
            </li>
            <li>
              &#8358;{tenk?.toLocaleString()} per inspection for 2 inspections
              ordered
            </li>
            <li>
              &#8358;{fivek?.toLocaleString()} for 3 or more inspections ordered
            </li>
          </ul>

          <div className="pt-4 d-flex align-items-center justify-content-between align-content-center">
            <strong>
              Cost for {inspected ? inspected : "15"} properties to be
              inspected:
            </strong>
            <strong>&#8358; {calculate()?.toLocaleString()}</strong>
          </div>
          {!user ? (
            <Button onClick={(e) => handleDas(e)}>Pay now</Button>
          ) : (
            <PaystackButton {...componentRatingsProps} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          {/*
          <Button variant="primary" onClick={() => handleSuscribe()}>
            Understood
          </Button>
        */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DasModal;
