import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./styles/AboutUs.css";
import AskChekamButton from "./AskChekamButton";


const AboutUs = () => {

  return (
    <>
      <Navbar />
      <Body />
      <Footer />
    </>
  );
};

const Body = () => {
 

  return (
    <>


      <main className="main">
        <h1 className="Header">About Us</h1>

        <div className="textbox">
        <AskChekamButton />
          <p>
            Finding a property to buy or rent in Nigeria , whether a house, an
            apartment or commercial space, or Land is a difficult chore....
          </p>

          <p>
            One would usually have to go searching with a well meaning agent ,
            spending a lot of time and energy , often to visit places you would
            never dream of living or working in , until you get tired and have
            to settle for less, not being sure if your pick was the best quality
            or the best value for your money.
          </p>

          <p>
            Thankfully there is Chekam.com , where we have developed an
            algorithm using standards of architecture, governing laws and market
            intelligence to accurately provide you with a rating/ranking of
            property in terms of quality and value for money. These Quality and
            Value-For-Money (VFM) rankings allow you select a range of choices
            worth actually inspecting and helps you make the best choice!
          </p>

          <p>
            We also shortly intend to link potential buyers and renters with
            financing solutions , so that once they have found their ideal place
            , they can secure it with ease. Get started now with finding your
            happy place and remember to always <em>Chekam</em> before you "Check
            in!"
          </p>
        </div>
      </main>

      




      {/*ratings payment*/}
      {/*<Dialog
        open={ratingsPaymentOpen}
        onClose={handleRatingsPaymentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={0} direction="column" alignItems="center">
              <Typography fontSize="6">KINDLY MAKE PAYMENT</Typography>
              <Grid item xs>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="name"
                    label="Name"
                    required
                    variant="outlined"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="email"
                    label="Email"
                    required
                    variant="outlined"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="phone"
                    label="Phone"
                    required
                    variant="outlined"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Subscription Packages
                  </FormLabel>
                  <RadioGroup
                    required
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="5000"
                      control={<Radio />}
                      label="N5,000 Daily"
                    />
                    <FormControlLabel
                      value="10000"
                      control={<Radio />}
                      label="N10,000 Weekly"
                    />
                    <FormControlLabel
                      value="15000"
                      label="N15,000 Monthly"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <br />
              <br />
              <Grid item>
                {!user ? (
                  <Button onClick={(e) => handleSuscriber(e)}>Pay now</Button>
                ) : (
                  <PaystackButton {...componentProps} />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRatingsPaymentClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>*/}





    </>
  );
};

export default AboutUs;
