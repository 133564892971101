import React, { useState, useEffect } from "react";
function ProfileOverview() {

  //   console.log(user)
  return (
    <div
      className="tab-pane fade show active profile-overview"
      id="profile-overview"
    >
      <h5 className="card-title">About</h5>
      <p className="small fst-italic">
       I am a agent and i love selling houses ....
      </p>

      <h5 className="card-title">Profile Details</h5>

      <div className="row">
        <div className="col-lg-3 col-md-4 label ">Username</div>
        <div className="col-lg-9 col-md-8">Agent Username</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Country</div>
        <div className="col-lg-9 col-md-8">Nigeria</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Phone</div>
        <div className="col-lg-9 col-md-8">
          5935835935793
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Email</div>
        <div className="col-lg-9 col-md-8">agentemail@gmail.com</div>
      </div>
    </div>
  );
}

export default ProfileOverview;