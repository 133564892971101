import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import { Helmet } from "react-helmet";
import "./index.css";
import FormAppbar from "../../../components/FormAppbar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HelpIcon from '@mui/icons-material/Help';
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import {doc,getDoc,updateDoc} from "firebase/firestore";
import Autocomplete from "@mui/material/Autocomplete";
import { storage } from "../../../components/Firebase";
import ImagesDropZone from "../../../components/ImagesDropZone";
import MainImageDropZone from "../../../components/MainImageDropZone";
import FormControlLabel from "@mui/material/FormControlLabel";
import ImageElement from "../../../components/ImageElement";
import db from "../../../components/Firebase";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
//import {serverTimestamp} from "firebase/firestore";
import { ref,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { RiDeleteBin5Line } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import kitchenexplanation from "../../../images/kitchenexplanation.png";
import { getAuth } from "firebase/auth";
import {addDoc,collection,serverTimestamp,query,getDocs,where,} from "firebase/firestore";

function UpdateProperties() {
  const location = useLocation()
  const { data } = location.state

  
  const auth = getAuth();
  const user = auth.currentUser;

  const district1 = [
    "Bwari",
    "DeiDei",
    "Gwagwalada",
    "Kuje",
    "Kukwuaba",
    "Mararaba",
    "Nyanya",
    "Orozo",
    "Pyakassa",
    "Suburban District",
    "Apongbon",
    "Lagos Island",
  ];
  const district2 = ["Kabusa", "Kuchingoro", "Agege", "Mushin", "Oshodi"];
  const district3 = [
    "Dakwo",
    "Duboyi",
    "Jukwoyi",
    "Kafe",
    "Karsanaa",
    "Kurudu",
    "Alimosho",
    "Badagry",
    "Broad Street",
    "Ebute Metta",
    "Ifo",
    "Obafemi Owode",
    "Obalende",
  ];
  const district4 = [
    "Karimo",
    "Lugbe",
    "Abule Egba",
    "Costain",
    "Egbe Idimu",
    "Egbeda",
    "Ikorodu",
    "Ikotun",
    "Ojo",
    "Orile",
  ];
  const district5 = [
    "Dape",
    "Dutse",
    "Gwagwa",
    "Amuwo Odofin",
    "Arepo",
    "Ilaje",
    "Ipaja",
    "Oworoshoki",
  ];
  const district6 = ["Karu", "Wumba", "Anthony", "Ejigbo", "Ketu", "Kosofe"];
  const district7 = [
    "Kubwa",
    "Mpape",
    "Epe",
    "Isolo",
    "Marina",
    "Ojuelegba",
    "Sangotedo",
  ];
  const district8 = ["Apo Extension", "Durumi", "Iju", "Onikan"];
  const district9 = [
    "Dawaki",
    "Dakibiyu",
    "Festac",
    "Ogba Egbema Ndoni",
    "Ojota",
    "Okota",
    "Shomolu",
  ];
  const district10 = [
    "Gaduwa",
    "Galadinmawa",
    "Katampe",
    "Katampe Extension",
    "Ajah",
    "Apapa",
    "Ibeju Lekki",
    "Ilupeju",
    "Ojodu",
  ];
  const district11 = [
    "Idu",
    "Lokogoma",
    "Mbora",
    "Ikate",
    "Ikeja",
    "Maryland",
    "Ogba",
    "Ogudu",
  ];
  const district12 = [
    "Central Business District",
    "Garki Area 1",
    "Garki Area 2",
    "Garki Area 3",
    "Garki Area 6",
    "Garki Area 7",
    "Garki Area 8",
    "Garki Area 11",
    "Gwarimpa",
    "Games Village",
    "Gbagada",
  ];
  const district13 = [
    "Garki 2",
    "Gudu",
    "Jahi",
    "Kado",
    "Life Camp",
    "Mabushi",
    "Ikoyi",
    "Surulere",
    "Yaba",
  ];
  const district14 = ["Apo", "Lekki Phase 2", "Magodo"];
  const district15 = [
    "Guzape",
    "Jabi",
    "Wuse Zone 1",
    "Wuse Zone 2",
    "Wuse Zone 3",
    "Wuse Zone 4",
    "Wuse Zone 5",
    "Wuse Zone 6",
    "Wuse Zone 7",
  ];
  const district16 = [
    "Maitama",
    "Utako",
    "Wuse 2",
    "Wuye",
    "Lekki Phase 1",
    "Banana Island",
  ];
  const district17 = ["Asokoro", "Eko Atlantic", "Victoria Island"];


  const abujaDistricts = [
    "Bwari",
    "DeiDei",
    "Gwagwalada",
    "Kuje",
    "Kukwuaba",
    "Mararaba",
    "Nyanya",
    "Orozo",
    "Pyakassa",
    "Suburban District",
    "Kabusa",
    "Kuchingoro",
    "Dakwo",
    "Duboyi",
    "Jukwoyi",
    "Kafe",
    "Karsanaa",
    "Kurudu",
    "Karimo",
    "Lugbe",
    "Dape",
    "Dutse",
    "Gwagwa",
    "Karu",
    "Wumba",
    "Kubwa",
    "Mpape",
    "Apo Extension",
    "Durumi",
    "Dawaki",
    "Dakibiyu",
    "Gaduwa",
    "Galadinmawa",
    "Katampe",
    "Katampe Extension",
    "Idu",
    "Lokogoma",
    "Mbora",
    "Central Business District",
    "Garki Area 1",
    "Garki Area 2",
    "Garki Area 3",
    "Garki Area 6",
    "Garki Area 7",
    "Garki Area 8",
    "Garki Area 11",
    "Gwarimpa",
    "Games Village",
    "Garki 2",
    "Gudu",
    "Jahi",
    "Kado",
    "Life Camp",
    "Mabushi",
    "Apo",
    "Guzape",
    "Jabi",
    "Wuse Zone 1",
    "Wuse Zone 2",
    "Wuse Zone 3",
    "Wuse Zone 4",
    "Wuse Zone 5",
    "Wuse Zone 6",
    "Wuse Zone 7",
    "Maitama",
    "Utako",
    "Wuse 2",
    "Wuye",
    "Asokoro",
  ];

  const lagosDistricts = [
    "Apongbon",
    "Lagos Island",
    "Agege",
    "Mushin",
    "Oshodi",
    "Alimosho",
    "Badagry",
    "Broad Street",
    "Ebute Metta",
    "Ifo",
    "Obafemi Owode",
    "Obalende",
    "Abule Egba",
    "Costain",
    "Egbe Idimu",
    "Egbeda",
    "Ikorodu",
    "Ikotun",
    "Ojo",
    "Orile",
    "Amuwo Odofin",
    "Arepo",
    "Ilaje",
    "Ipaja",
    "Oworoshoki",
    "Anthony",
    "Ejigbo",
    "Ketu",
    "Kosofe",
    "Epe",
    "Isolo",
    "Marina",
    "Ojuelegba",
    "Sangotedo",
    "Iju",
    "Onikan",
    "Festac",
    "Ogba Egbema Ndoni",
    "Ojota",
    "Okota",
    "Shomolu",
    "Ajah",
    "Apapa",
    "Ibeju Lekki",
    "Ilupeju",
    "Ojodu",
    "Ikate",
    "Ikeja",
    "Maryland",
    "Ogba",
    "Ogudu",
    "Gbagada",
    "Ikoyi",
    "Surulere",
    "Yaba",
    "Magodo",
    "Lekki Phase 1",
    "Lekki Phase 2",
    "Eko Atlantic",
    "Banana Island",
    "Victora Island",
  ];


  
  const [type, setType] = useState(data.propertyType);
  const [purpose, setPurpose] = useState(data.propertyPurpose);
  const [propertyDescription, setPropertyDescription] = useState(data.propertyDescription);
  const [verified, setVerified] = useState(data.verified);
  const [price, setPrice] = useState(data.price);
  const [realtor, setRealtor] = useState(data.realtor);
  const [agencyPercentage, setAgencyPercentage] = useState(5);
  const [agencyFee, setAgencyFee] = useState(data.agencyFee);
  const [legalFee, setLegalFee] = useState(data.legalFee);
  const [cautionFee, setCautionFee] = useState(data.cautionFee);
  const [country, setCountry] = useState(data.country);
  const [state, setState] = useState(data.state);
  const [district, setDistrict] = useState(data.district);
  const [streetName, setStreetName] = useState(data.streetName);
  const [houseNumber,setHouseNumber,] = useState(data.houseNumber);
  const [additionalFeatures,setAdditionalFeatures,] = useState(data.additionalFeatures);

  const [eastings,setEastings] = useState(0);
  const [northings,setNorthings] = useState(0);
  //const [listing,setListing] = useState(x.listing);
  //const [titledocument, setTitledocument] = useState("");
  const [sizeOfLand,setSizeOfLand] = useState("");
  const [mainImage,setMainImage] = useState([]);
  const [mainImageURL,setMainImageURL] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageURL,setImageURL] = useState([]);
  const [liveInEstate,setLiveInEstate]= useState('yes');
  const [entryArrangementInput,setEntryArrangementInput] = useState ([{'entryArrangement':'2'}])
  const [livingRoomArrangementInput, setLivingRoomArrangementInput] = useState([{'livingRoomArrangement':'2'}]);
  const [diningRoomArrangementInput, setDiningRoomArrangementInput] = useState([{'diningRoomArrangement':'2'}]);
  const [bedroomArrangementInput, setBedroomArrangementInput] = useState([{'bedroomArrangement':'2'}]);
  const [kitchenArrangementInput, setKitchenArrangementInput] = useState([{'kitchenArrangement':'2'}]);
  const [visitorsToiletArrangementInput, setVisitorsToiletArrangementInput] = useState([{'visitorsToiletArrangement':'2'}]);
  const [residentToiletArrangementInput, setResidentToiletArrangementInput] = useState([{'residentToiletArrangement':'2'}]);
  const [otherRoomArrangementInput, setOtherRoomArrangementInput] = useState([{'otherRoomArrangement':'2'}]);
  const [kitchenInput, setKitchenInput] = useState([{ lengthA: '0', lengthB: '0', lengthC: '0' }]);
  const [livingRoomInput,setLivingRoomInput] = useState([{ length: '0', breadth: '0' }]);
  const [diningRoomInput,setDiningRoomInput] = useState([{ length: '0', breadth: '0' }]);
  const [bedroomInput, setBedroomInput] = useState([{ length: '0', breadth: '0' }]);
  const [bathroomInput, setBathroomInput] = useState([{ length: '0', breadth: '0' }]);
  const [premiumPaid, setPremiumPaid] = useState(false);
  const [chekamVerified, setChekamVerified] = useState("no");
  const [chekamVerifiedPaid,setChekamVerifiedPaid] = useState(false);
  const [kitchenTriangleExplanationOpen, setKitchenTriangleExplanationOpen] = useState(false);
  // const [userRated, setUserRated] = useState([])
  // const [userRating, setUserRating] = useState("")
  // const [cast, setCast] = useState(0)
  

const handleDeletePicture = (url) => {
    
  
    let pictureRef = storage.refFromURL(url);
   
    pictureRef.delete().then(() => {
        alert("Picture is deleted successfully!");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const metadata = {
    contentType: "image/jpeg",
  };


  const handleChangeOrderUp = (index) => {
    // If first, ignore
    if (index !== 0) {
      const newArray = [...imageList];
      const intermediate = newArray[index - 1];
      newArray[index - 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const handleChangeOrderDown = (index) => {
    // If last, ignore
    if (index < imageList.length - 1) {
      const newArray = [...imageList];
      const intermediate = newArray[index + 1];
      newArray[index + 1] = newArray[index];
      newArray[index] = intermediate;
      setImageList(newArray);
    }
  };

  const changeImageField = (index, parameter, value) => {
    const newArray = [...imageList];
    newArray[index][parameter] = value;
    setImageList(newArray);
  };

  const changeMainImageField = (index, parameter, value) => {
    const newArray = [...mainImage];
    newArray[index][parameter] = value;
    setMainImage(newArray);
  };



  const handleDeleteImage = (index) => {
    //imageList[index].storageRef
    // .delete()
    // .then(() => {
    const newArray = [...imageList];
    newArray.splice(index, 1);
    setImageList(newArray);
    // })
    // .catch((error) => {
    //    console.log("Error deleting file:", error);
    // });
  };

  useEffect(() => {
    mainImage.forEach((image, index) => {
      if (image.status === "FINISH" || image.status === "UPLOADING") return;
      changeMainImageField(index, "status", "UPLOADING");

      const fileName = image.file.name;
      const imagesRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(imagesRef, image.file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete

          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            mainImageURL.push(downloadURL);
            changeMainImageField(index, "downloadURL", downloadURL);
            changeMainImageField(index, "status", "FINISH");
          });
        }
      );
    });
  });

  useEffect(() => {
    imageList.forEach((image, index) => {
      if (image.status === "FINISH" || image.status === "UPLOADING") return;
      changeImageField(index, "status", "UPLOADING");

      const fileName = image.file.name;
      const imagesRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(imagesRef, image.file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        () => {
          // Handle successful uploads on complete

          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            imageURL.push(downloadURL);
            changeImageField(index, "downloadURL", downloadURL);
            changeImageField(index, "status", "FINISH");
          });
        }
      );
    });
  });
  
  const handleVerified = () => {
    if (verified === false){return 1}else{return 5}
  }

  const handlePropertyTypeChange = (e) => {
    setType(e.target.value);
  };
  const handlePropertyPurposeChange = (e) => {
    setPurpose(e.target.value);
  };
  const handlePropertyDescriptionChange = (e) => {
    setPropertyDescription(e.target.value);
  };
  /*const handleVerifiedChange = (e) => {
    setVerified(e.target.value);
  };*/
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleRealtorChange = (e) => {
    setRealtor(e.target.value);
  };
  const handleAgencyFeeChange = (e) => {
    setAgencyFee(e.target.value);
  };
  const handleLegalFeeChange = (e) => {
    setLegalFee(e.target.value);
  };
  const handleCautionFeeChange = (e) => {
    setCautionFee(e.target.value);
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
  };
  const handleDistrictChange = (e) => {
    setDistrict(e.target.value);
  };
  const handleStreetNameChange = (e) => {
    setStreetName(e.target.value);
  };

  const handleHouseNumberChange = (e) => {
    setHouseNumber(e.target.value);
  };

  const handleAdditionalFeaturesChange = (e) => {
    setAdditionalFeatures(e.target.value);
  };

  const handleEastingsChange = (e) => {
    setEastings(e.target.value);
  };
  const handleNorthingsChange = (e) => {
    setNorthings(e.target.value);
  };

  // const handleTitledocumentChange = (e) => {
  //   setTitledocument(e.target.value);
  // };
  const handleSizeOfLandChange = (e) => {
    setSizeOfLand(e.target.value);
  };

  let handleLiveInEstateChange = (e) => {
    setLiveInEstate(e.target.value);
  };

 
let handleEntryArrangementChange = (index, event) => {
    let data = [...entryArrangementInput];
    data[index][event.target.name] = event.target.value;
    setEntryArrangementInput(data);
  };

  let handleLivingRoomArrangementChange = (index, event) => {
    let data = [...livingRoomArrangementInput];
    data[index][event.target.name] = event.target.value;
    setLivingRoomArrangementInput(data);
  };

  let handleDiningRoomArrangementChange = (index, event) => {
    let data = [...diningRoomArrangementInput];
    data[index][event.target.name] = event.target.value;
    setDiningRoomArrangementInput(data);
  };

  let handleKitchenArrangementChange = (index, event) => {
    let data = [...kitchenArrangementInput];
    data[index][event.target.name] = event.target.value;
    setKitchenArrangementInput(data);
  };

  let handleBedroomArrangementChange = (index, event) => {
    let data = [...bedroomArrangementInput];
    data[index][event.target.name] = event.target.value;
    setBedroomArrangementInput(data);
  };

  let handleVisitorsToiletArrangementChange = (index, event) => {
    let data = [...visitorsToiletArrangementInput];
    data[index][event.target.name] = event.target.value;
    setVisitorsToiletArrangementInput(data);
  };

  let handleResidentToiletArrangementChange = (index, event) => {
    let data = [...residentToiletArrangementInput];
    data[index][event.target.name] = event.target.value;
    setResidentToiletArrangementInput(data);
  };

  let handleOtherRoomArrangementChange = (index, event) => {
    let data = [...otherRoomArrangementInput];
    data[index][event.target.name] = event.target.value;
    setOtherRoomArrangementInput(data);
  };

  let addEntryArrangementInput = () => {
    let newEntryArrangementInput = { entryArrangement: "1" };
    setEntryArrangementInput([
      ...entryArrangementInput,
      newEntryArrangementInput,
    ]);
  };

  let addLivingRoomArrangementInput = () => {
    let newLivingRoomArrangementInput = { livingRoomArrangement: "1" };
    setLivingRoomArrangementInput([
      ...livingRoomArrangementInput,
      newLivingRoomArrangementInput,
    ]);
  };

  let addDiningRoomArrangementInput = () => {
    let newDiningRoomArrangementInput = { diningRoomArrangement: "1" };
    setDiningRoomArrangementInput([
      ...diningRoomArrangementInput,
      newDiningRoomArrangementInput,
    ]);
  };

  let addKitchenArrangementInput = () => {
    let newKitchenArrangementInput = { kitchenArrangement: "1" };
    setKitchenArrangementInput([
      ...kitchenArrangementInput,
      newKitchenArrangementInput,
    ]);
  };

  let addBedroomArrangementInput = () => {
    let newBedroomArrangementInput = { bedroomArrangement: "1" };
    setBedroomArrangementInput([
      ...bedroomArrangementInput,
      newBedroomArrangementInput,
    ]);
  };

  let addVisitorsToiletArrangementInput = () => {
    let newVisitorsToiletArrangementInput = { visitorsToiletArrangement: "1" };
    setVisitorsToiletArrangementInput([
      ...visitorsToiletArrangementInput,
      newVisitorsToiletArrangementInput,
    ]);
  };

  let addResidentToiletArrangementInput = () => {
    let newResidentToiletArrangementInput = { residentToiletArrangement: "1" };
    setResidentToiletArrangementInput([
      ...residentToiletArrangementInput,
      newResidentToiletArrangementInput,
    ]);
  };

  let addOtherRoomArrangementInput = () => {
    let newOtherRoomArrangementInput = { otherRoomArrangement: "1" };
    setOtherRoomArrangementInput([
      ...otherRoomArrangementInput,
      newOtherRoomArrangementInput,
    ]);
  };

  let removeEntryArrangementInput = (i) => {
    let newEntryArrangementInput = [...entryArrangementInput];
    newEntryArrangementInput.splice(i, 1);
    setEntryArrangementInput(newEntryArrangementInput);
  };

  let removeLivingRoomArrangementInput = (i) => {
    let newLivingRoomArrangementInput = [...livingRoomArrangementInput];
    newLivingRoomArrangementInput.splice(i, 1);
    setLivingRoomArrangementInput(newLivingRoomArrangementInput);
  };

  let removeDiningRoomArrangementInput = (i) => {
    let newDiningRoomArrangementInput = [...diningRoomArrangementInput];
    newDiningRoomArrangementInput.splice(i, 1);
    setDiningRoomArrangementInput(newDiningRoomArrangementInput);
  };

  let removeKitchenArrangementInput = (i) => {
    let newKitchenArrangementInput = [...kitchenArrangementInput];
    newKitchenArrangementInput.splice(i, 1);
    setKitchenArrangementInput(newKitchenArrangementInput);
  };

  let removeBedroomArrangementInput = (i) => {
    let newBedroomArrangementInput = [...bedroomArrangementInput];
    newBedroomArrangementInput.splice(i, 1);
    setBedroomArrangementInput(newBedroomArrangementInput);
  };

  let removeVisitorsToiletArrangementInput = (i) => {
    let newVisitorsToiletArrangementInput = [...visitorsToiletArrangementInput];
    newVisitorsToiletArrangementInput.splice(i, 1);
    setVisitorsToiletArrangementInput(newVisitorsToiletArrangementInput);
  };

  let removeResidentToiletArrangementInput = (i) => {
    let newResidentToiletArrangementInput = [...residentToiletArrangementInput];
    newResidentToiletArrangementInput.splice(i, 1);
    setResidentToiletArrangementInput(newResidentToiletArrangementInput);
  };

  let removeOtherRoomArrangementInput = (i) => {
    let newOtherRoomArrangementInput = [...otherRoomArrangementInput];
    newOtherRoomArrangementInput.splice(i, 1);
    setOtherRoomArrangementInput(newOtherRoomArrangementInput);
  };

  let addKitchenInput = () => {
    let newKitchenInput = { lengthA: "", lengthB: "", lengthC: "" };
    setKitchenInput([...kitchenInput, newKitchenInput]);
  };

  let addLivingRoomInput = () => {
    let newLivingRoomInput = { length: "", breadth: "" };
    setLivingRoomInput([...livingRoomInput, newLivingRoomInput]);
  };

  let addDiningRoomInput = () => {
    let newDiningRoomInput = { length: "", breadth: "" };
    setDiningRoomInput([...diningRoomInput, newDiningRoomInput]);
  };

  let addBedroomInput = () => {
    let newBedroomInput = { length: "", breadth: "" };
    setBedroomInput([...bedroomInput, newBedroomInput]);
  };

  let addBathroomInput = () => {
    let newBathroomInput = { length: "", breadth: "" };
    setBathroomInput([...bathroomInput, newBathroomInput]);
  };

  let removeKitchenInput = (i) => {
    let newKitchenInput = [...kitchenInput];
    newKitchenInput.splice(i, 1);
    setKitchenInput(newKitchenInput);
  };

  let removeLivingRoomInput = (i) => {
    let newLivingRoomInput = [...livingRoomInput];
    newLivingRoomInput.splice(i, 1);
    setLivingRoomInput(newLivingRoomInput);
  };

  let removeDiningRoomInput = (i) => {
    let newDiningRoomInput = [...diningRoomInput];
    newDiningRoomInput.splice(i, 1);
    setDiningRoomInput(newDiningRoomInput);
  };

  let removeBedroomInput = (i) => {
    let newBedroomInput = [...bedroomInput];
    newBedroomInput.splice(i, 1);
    setBedroomInput(newBedroomInput);
  };

  let removeBathroomInput = (i) => {
    let newBathroomInput = [...bathroomInput];
    newBathroomInput.splice(i, 1);
    setBathroomInput(newBathroomInput);
  };

  let handleKitchenInputChange = (index, event) => {
    let data = [...kitchenInput];
    data[index][event.target.name] = event.target.value;
    setKitchenInput(data);
  };

  let handleLivingRoomInputChange = (index, event) => {
    let data = [...livingRoomInput];
    data[index][event.target.name] = event.target.value;
    setLivingRoomInput(data);
  };

  let handleDiningRoomInputChange = (index, event) => {
    let data = [...diningRoomInput];
    data[index][event.target.name] = event.target.value;
    setDiningRoomInput(data);
  };

  let handleBedroomInputChange = (index, event) => {
    let data = [...bedroomInput];
    data[index][event.target.name] = event.target.value;
    setBedroomInput(data);
  };

  let handleBathroomInputChange = (index, event) => {
    let data = [...bathroomInput];
    data[index][event.target.name] = event.target.value;
    setBathroomInput(data);
  };

  const handleKitchenTriangleExplanationOpen = () => {
    setKitchenTriangleExplanationOpen(true);
  };

  const handleKitchenTriangleExplanationClose = () => {
    setKitchenTriangleExplanationOpen(false);
  };


   async function maxSizeOfLand(y) {
    const propertiesRef = collection(db, "properties");
    const q = query(propertiesRef, where("propertyType", "==", "land"));
    const array = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      array.push(doc.data().price);
      y = Math.max(array);
      return y;
    });
  }
  

  async function maxSizeOfCommercialSpace(y) {
    const propertiesRef = collection(db, "properties");
    const q = query(
      propertiesRef,
      where("propertyType", "==", "commercialSpace")
    );
    const array = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      array.push(doc.data().price);
      y = Math.max(array);
      return y;
    });
  }

  
  async function averageSizeOfHotelFloorsInDatabase() {}

  // reuseable functions
  function sum(...theArgs) {
    return theArgs.reduce((previous, current) => {
      return previous + current;
    });
  }


  // function getProductId(length) {
  //   var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  //   var charLength = chars.length;
  //   var result = '';
  //   for ( var i = 0; i < length; i++ ) {
  //      result += chars.charAt(Math.floor(Math.random() * charLength));
  //   }
  //   return result;
  // }

  const average = (arr) =>
    arr.reduce((afa, charles) => afa + charles, 0) / arr.length;

  function area(a, b) {
    return a * b;
  }


  // function calcDistance(fromLat, fromLng, toLat, toLng) {
  //   return parseFloat(
  //     (
  //       google.maps.geometry.spherical.computeDistanceBetween(
  //         new google.maps.LatLng(fromLat, fromLng),
  //         new google.maps.LatLng(toLat, toLng)
  //       ) / 1000
  //     ).toFixed(1)
  //   );
  // }

  let { mean, std } = require("mathjs");

  function sanitise(x) {
    if (isNaN(x)) {
      return 0;
    }
    return x;
  }


  const handleSave = async (event) => {
    event.preventDefault();
    let newData = new FormData(event.currentTarget);

    //Get Form inputs and assign to variables

      let numberOfBedrooms = parseInt(newData.get("numberOfBedrooms")),
      numberOfBathrooms = parseInt(newData.get("numberOfBathrooms")),
      numberOfToilets = parseInt(newData.get("numberOfToilets")),
      numberOfBathroomsEnsuite = parseInt(newData.get("numberOfBathroomsEnsuite")),
      numberOfToiletsEnsuite = parseInt(newData.get("numberOfToiletsEnsuite")),
      titledocument = parseInt(newData.get("titledocuments")),
      
      // architecture

      doorsWindowsCondition = parseInt(newData.get("doorsWindowsCondition")),
      floorsCondition = parseFloat(newData.get("floorsCondition")),
      wallsCondition = parseInt(newData.get("wallsCondition")),
      ceilingCondition = parseInt(newData.get("ceilingCondition")),
      toiletSinksCondition = parseInt(newData.get("toiletSinksCondition")),
      plumblingCondtion = parseInt(newData.get("plumblingCondtion")),
      commercialFloorSize = parseInt(newData.get("commercialFloorSize")),
      
      //security

      nonEstateEntryAndExit = parseInt(newData.get("nonEstateEntryAndExit")),
      callGate = newData.get("callGate"),
      gateEntryCode = newData.get("gateEntryCode"),
      securityResponse = newData.get("securityResponse"),
      regularPatrols = newData.get("regularPatrols"),
      securityScoreOfStreet = parseInt(newData.get("nonEstateEntryAndExit")),
      securityOfHouseGate = newData.get("securityOfHouseGate"),
      securityOfHouseBuildingAccess = newData.get("securityOfHouseBuildingAccess"),
      securityOfHouseApartmentAccess = newData.get("securityOfHouseApartmentAccess"),
      securityOfHouseSecurityPersonnel = newData.get("securityOfHouseSecurityPersonnel"),
      sameStreetAsSensitiveLocation = parseInt(newData.get("sameStreetAsSensitiveLocation")),
      
      //privacy and landlord relationship rating
      
      privacyLandlordRelationshipRating = parseInt(newData.get("privacyLandlordRelationshipRating")),
      
      //compound facilities
      
      powerFactor = parseInt(newData.get("powerFactor")),
      waterFactor = parseInt(newData.get("waterFactor")),
      garbageFactor = parseInt(newData.get("garbageFactor")),
      drainageScore = parseInt(newData.get("drainageScore")),
      drainageAltitude = parseInt(newData.get("drainageAltitude")),
      numberOfCarsThatCanFit = parseFloat(newData.get("numberOfCarsThatCanFit")),
      lengthOfCommercialFloorSpace = parseFloat(newData.get("lengthOfCommercialFloorSpace")),
      breadthOfCommercialFloorSpace = parseFloat(newData.get("breadthOfCommercialFloorSpace")),
      lengthOfCommercialCarPark = parseFloat(newData.get("lengthOfCommercialCarPark")),
      breadthOfCommercialCarPark = parseFloat(newData.get("breadthOfCommercialCarPark")),
      sizeOfLand = newData.get("sizeOfLand"),
      sizeOfCommercialSpace = newData.get("sizeOfCommercialSpace"),
      
      // estate management

      estateRating = parseFloat(newData.get("estateRating")),
      estateManagement = parseInt(newData.get("estateManagement")),
      
      // hotel

      starRating = parseFloat(newData.get("starRating")),
      hotelSizeOfFloor = parseInt(newData.get("hotelSizeOfFloor")),
      hotelState = newData.get("hotelState"),
      hotelCity = newData.get("hotelCity"),
      hotelDistrict = newData.get("hotelDistrict"),
      hotelName = newData.get("hotelName");


//reuseable variables

    //General
    
    //let agencyFee = (agencyPercentage / 100) * price;

     
    //verified = sanitise(verified);
    //titledocument = sanitise(titledocument);

    //arrangement
    let entryArrangement = average(entryArrangementInput.map((element) =>parseFloat(element.entryArrangement)));
    let livingRoomArrangement = average(livingRoomArrangementInput.map((element) =>parseFloat(element.livingRoomArrangement)));
    let diningRoomArrangement = average(diningRoomArrangementInput.map((element) =>parseFloat(element.diningRoomArrangement)));
    let kitchenArrangement = average(kitchenArrangementInput.map((element) =>parseFloat(element.kitchenArrangement)));
    let bedroomArrangement = average(bedroomArrangementInput.map((element) =>parseFloat(element.bedroomArrangement)));
    let visitorsToiletArrangement = average(visitorsToiletArrangementInput.map((element) =>parseFloat(element.visitorsToiletArrangement)));
    let residentToiletArrangement = average(residentToiletArrangementInput.map((element) =>parseFloat(element.residentToiletArrangement)));
    let otherRoomArrangement = average(otherRoomArrangementInput.map((element) =>parseFloat(element.otherRoomArrangement)));

    let roomArrangementScore = sum(entryArrangement,livingRoomArrangement,diningRoomArrangement,kitchenArrangement,bedroomArrangement,visitorsToiletArrangement,residentToiletArrangement,otherRoomArrangement);
    let aggregateArrangementScore = 20;
    let maxAggregateArrangementScore = sum(
      aggregateArrangementScore,
      entryArrangementInput.length - 1,
      livingRoomArrangementInput.length - 1,
      diningRoomArrangementInput.length - 1,
      kitchenArrangementInput.length - 1,
      bedroomArrangementInput.length - 1,
      visitorsToiletArrangementInput.length - 1,
      residentToiletArrangementInput.length - 1,
      otherRoomArrangementInput.length - 1
    );

    // size
    let kitchenTriangleLengthA = average(kitchenInput.map((element) =>parseFloat(element.kitchenTriangleLengthA)))
    let kitchenTriangleLengthB = average(kitchenInput.map((element) =>parseFloat(element.kitchenTriangleLengthB)))
    let kitchenTriangleLengthC = average(kitchenInput.map((element) =>parseFloat(element.kitchenTriangleLengthC)))
     
    let kitchenTriangle = sum(kitchenTriangleLengthA,kitchenTriangleLengthB,kitchenTriangleLengthC);
    let kitchenTriangleShortestLength = Math.min(kitchenTriangleLengthA,kitchenTriangleLengthB,kitchenTriangleLengthC);

    let livingRoomLength = average(livingRoomInput.map((element) => parseFloat(element.livingRoomLength)))
    let livingRoomBreadth = average(livingRoomInput.map((element) =>parseFloat(element.livingRoomBreadth)))
    let livingRoomArea = area(livingRoomLength, livingRoomBreadth);

    let diningRoomLength = average(diningRoomInput.map((element) => parseFloat(element.diningRoomLength)))
    let diningRoomBreadth = average(diningRoomInput.map((element) => parseFloat(element.diningRoomBreadth)))
    let diningRoomArea = area(diningRoomLength, diningRoomBreadth);

    let bedroomLength =  average(bedroomInput.map((element) => parseFloat(element.bedroomLength)))
    let bedroomBreadth = average(bedroomInput.map((element) => parseFloat(element.bedroomBreadth)))
    let bedroomArea = area(bedroomLength, bedroomBreadth);

    let bathroomLength = average(bathroomInput.map((element) => parseFloat(element.bathroomLength)))
    let bathroomBreadth = average(bathroomInput.map((element) => parseFloat(element.bathroomBreadth)))
    let bathroomArea = area(bathroomLength, bathroomBreadth);
    let commercialMaxSize = 50000;
    let maxDatabaseFloorSize = 50000;

    //security
    let sameStreetAsSensitiveLocationMax = 2;
    let securityOfAreaOfTownMax = 10;

    //compound facilities
    let totalAreaOfCommercialFloorSpace = area(lengthOfCommercialFloorSpace,breadthOfCommercialFloorSpace);
    let sizeOfCommercialCarPark = area(lengthOfCommercialCarPark,breadthOfCommercialCarPark);

    //Architecture
    let architectureScore = () => {
      let livingRoomSizeScore = () => {
        if (
          livingRoomBreadth >= 2.1 &&
          livingRoomBreadth < 3 &&
          livingRoomArea >= 6.3
        ) {
          return 1;
        } else if (
          livingRoomBreadth >= 3 &&
          livingRoomBreadth < 3.7 &&
          livingRoomArea >= 9
        ) {
          return 2;
        } else if (
          livingRoomBreadth >= 3 &&
          livingRoomBreadth < 3.7 &&
          livingRoomArea >= 12
        ) {
          return 3;
        } else if (livingRoomBreadth >= 3.7 && livingRoomArea >= 20.35) {
          return 4;
        } else if (livingRoomBreadth >= 4.6 && livingRoomArea >= 39.1) {
          return 5;
        } else {
          return 0;
        }
      };

      let diningRoomSizeScore = () => {
        if (diningRoomBreadth >= 1.5 && diningRoomArea >= 2.7) {
          return 1;
        } else if (diningRoomBreadth >= 2.7 && diningRoomArea >= 8.1) {
          return 2;
        } else if (diningRoomBreadth >= 3.4 && diningRoomArea >= 15) {
          return 3;
        } else if (diningRoomBreadth >= 3.7 && diningRoomArea >= 18.13) {
          return 4;
        } else if (diningRoomBreadth >= 4.9 && diningRoomArea >= 24.97) {
          return 5;
        } else {
          return 0;
        }
      };

      let bedRoomSizeScore = () => {
        if (
          bedroomBreadth >= 2.13 &&
          bedroomBreadth < 2.75 &&
          bedroomArea >= 6.41
        ) {
          return 1;
        } else if (
          bedroomBreadth >= 2.75 &&
          bedroomBreadth < 2.9 &&
          bedroomArea >= 7.56
        ) {
          return 2;
        } else if (
          bedroomBreadth >= 2.75 &&
          bedroomBreadth < 2.9 &&
          bedroomArea >= 8.25
        ) {
          return 3;
        } else if (bedroomBreadth >= 2.9 && bedroomArea >= 8.85) {
          return 4;
        } else if (bedroomBreadth >= 2.9 && bedroomArea >= 10.18) {
          return 5;
        } else {
          return 0;
        }
      };

      let kitchenSizeScore = () => {
        if (
          kitchenTriangleShortestLength >= 1.2 &&
          kitchenTriangleShortestLength < 1.6 &&
          kitchenTriangle >= 7.9
        ) {
          return 1;
        } else if (
          kitchenTriangleShortestLength >= 1.6 &&
          kitchenTriangleShortestLength < 1.98 &&
          kitchenTriangle >= 7.9
        ) {
          return 2;
        } else if (
          kitchenTriangleShortestLength >= 1.98 &&
          kitchenTriangleShortestLength < 2.36 &&
          kitchenTriangle >= 7.9
        ) {
          return 3;
        } else if (
          kitchenTriangleShortestLength >= 2.36 &&
          kitchenTriangleShortestLength < 2.75 &&
          kitchenTriangle >= 7.9
        ) {
          return 4;
        } else if (
          kitchenTriangleShortestLength >= 2.75 &&
          kitchenTriangle >= 7.9
        ) {
          return 5;
        } else {
          return 0;
        }
      };

      let bathRoomSizeScore = () => {
        if (bathroomBreadth >= 0.9144 && bathroomArea >= 1.39) {
          return 1;
        } else if (bathroomBreadth >= 1.22 && bathroomArea >= 2.33) {
          return 2;
        } else if (bathroomBreadth >= 1.83 && bathroomArea >= 5.02) {
          return 3;
        } else if (bathroomBreadth >= 2.44 && bathroomArea >= 5.946) {
          return 4;
        } else if (bathroomBreadth >= 3 && bathroomArea >= 11.15) {
          return 5;
        } else {
          return 0;
        }
      };

      let rentSizeScore = () => {
        const averageOfSizes = mean(
          livingRoomSizeScore(),
          diningRoomSizeScore(),
          bedRoomSizeScore(),
          kitchenSizeScore(),
          bathRoomSizeScore()
        );
        const standardDeviationOfSizes = std(
          livingRoomSizeScore(),
          diningRoomSizeScore(),
          bedRoomSizeScore(),
          kitchenSizeScore(),
          bathRoomSizeScore()
        );
        if (standardDeviationOfSizes <= 1) {
          return averageOfSizes;
        } else {
          return averageOfSizes / standardDeviationOfSizes;
        }
      };

      let commercialRentSizeScore =
        5 ^
        (1 - (commercialMaxSize - commercialFloorSize) / maxDatabaseFloorSize);

       let hotelSizeScore =
         hotelSizeOfFloor / averageSizeOfHotelFloorsInDatabase();

      let conditionScore = () => {
        const averageOfCondition = mean(
          doorsWindowsCondition,
          floorsCondition,
          wallsCondition,
          ceilingCondition,
          toiletSinksCondition,
          plumblingCondtion
        );
        const standardDeviationOfCondition = std(
          doorsWindowsCondition,
          floorsCondition,
          wallsCondition,
          ceilingCondition,
          toiletSinksCondition,
          plumblingCondtion
        );
        if (standardDeviationOfCondition <= 1) {
          return averageOfCondition;
        } else {
          return averageOfCondition / standardDeviationOfCondition;
        }
      };

      if (type === "house" || "apartment") {
        return (
          ((roomArrangementScore / maxAggregateArrangementScore) * 5 +
            conditionScore() +
            rentSizeScore()) /
          3
        );
      } else if (type === "commercial space") {
        return mean(commercialRentSizeScore, conditionScore());
      } else if (type === "hotel") {
        return mean(hotelSizeScore, conditionScore());
      } else {
        return 0;
      }
    };
    architectureScore = sanitise(architectureScore());
    //Security

    let securityScore = () => {
      let securityScoreOfEstate = () => {
        if (nonEstateEntryAndExit === "yes") {
          return 0;
        } else if (
          callGate === "yes" &&
          gateEntryCode === "no" &&
          securityResponse === "no" &&
          regularPatrols === "no"
        ) {
          return 1;
        } else if (
          callGate === "yes" &&
          gateEntryCode === "no" &&
          securityResponse === "yes" &&
          regularPatrols === "no"
        ) {
          return 2;
        } else if (
          callGate === "yes" &&
          gateEntryCode === "no" &&
          securityResponse === "yes" &&
          regularPatrols === "yes"
        ) {
          return 3;
        } else if (
          gateEntryCode === "yes" &&
          securityResponse === "yes" &&
          regularPatrols === "no"
        ) {
          return 4;
        } else if (
          gateEntryCode === "yes" &&
          securityResponse === "yes" &&
          regularPatrols === "yes"
        ) {
          return 5;
        } else {
          return 0;
        }
      };

      let securityScoreOfHouse = () => {
        if (
          securityOfHouseGate === "Heavy Duty + Electronic Access" &&
          securityOfHouseBuildingAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 7;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 6;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Security Doors" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 4.5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 3;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "Unregistered"
        ) {
          return 2.5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "None"
        ) {
          return 2;
        } else if (
          securityOfHouseGate === "None" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "None"
        ) {
          return 1;
        } else {
          return 0;
        }
      };

      let securityScoreOfApartment = () => {
        if (
          securityOfHouseGate === "Heavy Duty + Electronic Access" &&
          securityOfHouseBuildingAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseApartmentAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 7;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseApartmentAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 6;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Security Doors" &&
          securityOfHouseApartmentAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseApartmentAccess ===
            "Security Doors + Electronic Access" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 4.5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseApartmentAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "Professional"
        ) {
          return 3;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseApartmentAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "Unregistered"
        ) {
          return 2.5;
        } else if (
          securityOfHouseGate === "Manual Access" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseApartmentAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "None"
        ) {
          return 2;
        } else if (
          securityOfHouseGate === "None" &&
          securityOfHouseBuildingAccess === "Ordinary Door" &&
          securityOfHouseApartmentAccess === "Ordinary Door" &&
          securityOfHouseSecurityPersonnel === "None"
        ) {
          return 1;
        } else {
          return 0;
        }
      };

      let securityOfAreaOfTown = () => {
        if (district1.includes(district)) {
          return 1;
        } else if (district2.includes(district)) {
          return 1.5;
        } else if (district3.includes(district)) {
          return 2;
        } else if (district4.includes(district)) {
          return 2.5;
        } else if (district5.includes(district)) {
          return 3;
        } else if (district6.includes(district)) {
          return 3.5;
        } else if (district7.includes(district)) {
          return 4;
        } else if (district8.includes(district)) {
          return 4.5;
        } else if (district9.includes(district)) {
          return 5;
        } else if (district10.includes(district)) {
          return 5.5;
        } else if (district11.includes(district)) {
          return 6;
        } else if (district12.includes(district)) {
          return 6.5;
        } else if (district13.includes(district)) {
          return 7;
        } else if (district14.includes(district)) {
          return 7.5;
        } else if (district15.includes(district)) {
          return 8;
        } else if (district16.includes(district)) {
          return 8.5;
        } else if (district17.includes(district)) {
          return 9;
        } else return 0;
      };

      if (type === "house" && liveInEstate === "yes") {
        return (
          (5 *
            (securityScoreOfEstate() +
              securityScoreOfHouse() +
              securityOfAreaOfTown() +
              sameStreetAsSensitiveLocation)) /
          24
        );
      } else if (type === "apartment" && liveInEstate === "yes") {
        return (
          (5 *
            (securityScoreOfEstate() +
              securityScoreOfApartment() +
              securityOfAreaOfTown() +
              sameStreetAsSensitiveLocation)) /
          24
        );
      } else if (type === "house" && liveInEstate === "no") {
        return (
          (5 *
            (securityScoreOfStreet +
              securityScoreOfHouse() +
              securityOfAreaOfTown() +
              sameStreetAsSensitiveLocation)) /
          24
        );
      } else if (type === "apartment" && liveInEstate === "no") {
        return (
          (5 *
            (securityScoreOfStreet +
              securityScoreOfApartment() +
              securityOfAreaOfTown() +
              sameStreetAsSensitiveLocation)) /
          24
        );
      } else if (purpose === "sale") {
        return (
          ((sameStreetAsSensitiveLocation + securityOfAreaOfTown()) /
            (sameStreetAsSensitiveLocationMax + securityOfAreaOfTownMax)) *
          5
        );
      } else if (type === "hotel" || "commercial space") {
        return (securityOfAreaOfTown + sameStreetAsSensitiveLocation) / 24;
      } else {
        return 0;
      }
    };
    securityScore = sanitise(securityScore());
    //Privacy and Landlord Relations

    let privacyLandlordRelationshipScore = () => {
      return 5 * (privacyLandlordRelationshipRating / 4);
    };
    privacyLandlordRelationshipScore = sanitise(
      privacyLandlordRelationshipScore()
    );

    //Compound Facilities
    let compoundFacilitiesScore = () => {
      let parkingFacilitiesFactor = (x) => {
        x = 5 * (numberOfCarsThatCanFit / numberOfBedrooms);
        if (x >= 5) {
          return 5;
        } else {
          return x;
        }
      };
      let commercialParkingFacilitiesFactor = (x) => {
        x =
          ((sizeOfCommercialCarPark / totalAreaOfCommercialFloorSpace) * 5) / 3;
        if (x >= 5) {
          return 5;
        } else {
          return x;
        }
      };

      let powerFactorMax = 3,
        waterFactorMax = 4,
        garbageFactorMax = 3,
        drainageFactorMax = 5,
        parkingFacilitiesFactorMax = 5;
      let drainageFactor = drainageScore * drainageAltitude;

      if (purpose === "rent" || "sale") {
        return (
          5 *
          (sum(
            powerFactor,
            waterFactor,
            garbageFactor,
            drainageFactor,
            parkingFacilitiesFactor()
          ) /
            sum(
              powerFactorMax,
              waterFactorMax,
              garbageFactorMax,
              drainageFactorMax,
              parkingFacilitiesFactorMax
            ))
        );
      } else if (type === "commercial space") {
        return (
          5 *
          (sum(
            powerFactor,
            waterFactor,
            garbageFactor,
            drainageFactor,
            commercialParkingFacilitiesFactor()
          ) /
            sum(
              powerFactorMax,
              waterFactorMax,
              garbageFactorMax,
              drainageFactorMax,
              parkingFacilitiesFactorMax
            ))
        );
      } else {
        return 0;
      }
    };
    compoundFacilitiesScore = sanitise(compoundFacilitiesScore());
    // Optimal location

    function calcDistance(fromLat, fromLng, toLat, toLng) {
      function degreesToRadians(degrees) {
          return degrees * (Math.PI / 180);
      }
  
      const earthRadiusKm = 6371;
  
      const deltaLat = degreesToRadians(toLat - fromLat);
      const deltaLng = degreesToRadians(toLng - fromLng);
  
      const lat1 = degreesToRadians(fromLat);
      const lat2 = degreesToRadians(toLat);
  
      const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
          Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2) * Math.cos(lat1) * Math.cos(lat2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
      const distance = earthRadiusKm * c;
      
      return parseFloat(distance.toFixed(1));
  }
  
  let optimalLocationScore = (northings, eastings, type, state, trafficDensity, maxTrafficDensity) => {
      let maxPropertyDistance = 40,
          propertyDistanceLagos = Math.min(
              calcDistance(northings, eastings, 6.4281, 3.4219),
              calcDistance(northings, eastings, 6.4362, 3.9588),
              calcDistance(northings, eastings, 6.6144, 3.3581)
          ),
          propertyDistanceAbuja = calcDistance(
              northings,
              eastings,
              9.0833,
              7.5361
          );
  
      if (
          (type === "house" || type === "apartment" || type === "hotel") &&
          state === "Lagos"
      ) {
          return (
              5 **
              ((maxPropertyDistance - propertyDistanceLagos) / maxPropertyDistance)
          );
      } else if (
          (type === "house" || type === "apartment" || type === "hotel") &&
          state === "FCT"
      ) {
          return (
              5 **
              ((maxPropertyDistance - propertyDistanceAbuja) / maxPropertyDistance)
          );
      } else if (type === "commercial space" && state === "Lagos") {
          return mean(
              5 ** (trafficDensity / maxTrafficDensity),
              5 **
              (maxPropertyDistance - propertyDistanceLagos / maxPropertyDistance)
          );
      } else if (type === "commercial space" && state === "FCT") {
          return mean(
              5 ** (trafficDensity / maxTrafficDensity),
              5 **
              (maxPropertyDistance - propertyDistanceAbuja / maxPropertyDistance)
          );
      } else return 0;
  };

      //let optimalLocationScore = () => {return 4};
      optimalLocationScore = sanitise(optimalLocationScore());
      

    //estate and quality of estate management.


      let estateManagedWell = estateManagement / 5;
      let liveInEstateRating = () => {if (liveInEstate === "yes"){return 2 }else{return 1}};
      let estateFactorScore = liveInEstateRating() * estateManagedWell;
      estateFactorScore = sanitise(estateFactorScore);
 

    //Land Score
    let landScore = () => {
      let CS =
        (optimalLocationScore *
          mean(
            securityScore,
            estateFactorScore,
            compoundFacilitiesScore,
            architectureScore,
            handleVerified(),
            titledocument
          )) /
        5;
      let x = sizeOfLand / price;
      let xmax = maxSizeOfLand() / price;
      return (CS ** (x / xmax) + CS) / 2;
    };

    landScore = sanitise(landScore());

    //Commercial Score

    let commercialScore = () => {
      let CS =
        (optimalLocationScore *
          mean(
            securityScore,
            estateFactorScore,
            compoundFacilitiesScore,
            architectureScore,
            handleVerified()
          )) /
        5;
      let x = sizeOfCommercialSpace / price;
      let xmax = maxSizeOfCommercialSpace() / price;
      return (CS ** (x / xmax) + CS) / 2;
    };

    commercialScore = sanitise(commercialScore);

    //overall Score

    const overallScore = () => {
      if (purpose === "rent")
        return mean(
          securityScore,
          privacyLandlordRelationshipScore,
          compoundFacilitiesScore,
          architectureScore
        );
      else if (purpose === "sale" && type !== "land")
        return (
          (optimalLocationScore *
            mean(
              securityScore,
              estateFactorScore,
              compoundFacilitiesScore,
              architectureScore,
              handleVerified(),
              titledocument
            )) /
          5
        );
      else if (type === "land") return landScore;
      else if (type === "commercial space") return commercialScore;
      else if (type === "hotel")
        return mean(
          securityScore,
          starRating,
          estateFactorScore,
          architectureScore,
          handleVerified()
        );
      else return 0;
    };

    // Value For Money
    const valueForMoneyScore = () => {
      return overallScore() / price;
    };

console.log(verified,handleVerified(),overallScore(),valueForMoneyScore())


  
    const propertiesRef = doc(db, "properties", data.id);
    const docSnap = await getDoc(propertiesRef);
    if (docSnap.exists()) {
      await updateDoc(propertiesRef, {
        lastUpdated: serverTimestamp(),
        propertyType: type,
        propertyPurpose: purpose,
        propertyDescription: propertyDescription,
        verified: verified,
        price: price,
        realtor: realtor,
        agencyFee: agencyFee,
        legalFee: legalFee,
        cautionFee: cautionFee,
        country: country,
        state: state,
        district: district,
        streetName: streetName,
        houseNumber:houseNumber,
        easting: eastings,
        northing: northings,
        //listing:listing,
        additionalFeatures:additionalFeatures,
        //proofOfMandate:mandateURL,
        titledocument:titledocument,
        //chekamVerified:chekamVerified,
        sizeOfLand:sizeOfLand,
        mainImage:mainImageURL,
        images: imageURL,
        mainImage: mainImageURL,
        
        numberOfBedrooms:numberOfBedrooms,
        numberOfBathrooms:numberOfBathrooms,
        numberOfToilets:numberOfToilets,
        numberOfBathroomsEnsuite:numberOfBathroomsEnsuite,
        numberOfToiletsEnsuite:numberOfToiletsEnsuite,
        
        entryArrangement:entryArrangement,
        livingRoomArrangement:livingRoomArrangement,
        diningRoomArrangement:diningRoomArrangement,
        kitchenArrangement:kitchenArrangement,
        bedroomArrangement:bedroomArrangement,
        visitorsToiletArrangement:visitorsToiletArrangement, 
        residentToiletArrangement:residentToiletArrangement,
        otherRoomArrangement:otherRoomArrangement,
        livingRoomLength:livingRoomLength,
        livingRoomBreadth:livingRoomBreadth, 
               
        diningRoomLength:diningRoomLength,
        diningRoomBreadth:diningRoomBreadth, 
        bedroomLength:bedroomLength,
        bedroomBreadth:bedroomBreadth,
        kitchenTriangleLengthA:kitchenTriangleLengthA,
        kitchenTriangleLengthB:kitchenTriangleLengthB,
        kitchenTriangleLengthC:kitchenTriangleLengthC,
        bathroomLength:bathroomLength,
        bathroomBreadth:bathroomBreadth,
        doorsWindowsCondition:doorsWindowsCondition, 
        floorsCondition:floorsCondition,
        wallsCondition:wallsCondition,
        ceilingCondition:ceilingCondition,
        toiletSinksCondition:toiletSinksCondition,
        plumblingCondtion:plumblingCondtion,
        
        sizeOfCommercialSpace:sizeOfCommercialSpace,
        
        nonEstateEntryAndExit:nonEstateEntryAndExit,
        callGate:callGate,
        gateEntryCode:gateEntryCode,
        securityResponse:securityResponse,
        regularPatrols:regularPatrols,
        securityScoreOfStreet:securityScoreOfStreet,
        securityOfHouseGate:securityOfHouseGate,
        securityOfHouseBuildingAccess:securityOfHouseBuildingAccess,
        securityOfHouseApartmentAccess:securityOfHouseApartmentAccess,
        securityOfHouseSecurityPersonnel:securityOfHouseSecurityPersonnel,
        sameStreetAsSensitiveLocation:sameStreetAsSensitiveLocation,
        privacyLandlordRelationshipRating:privacyLandlordRelationshipRating,
        powerFactor:powerFactor,
        waterFactor:waterFactor,
        garbageFactor:garbageFactor,
        drainageScore:drainageScore,
        drainageAltitude:drainageAltitude,
        estateManagement:estateManagement,
        hotelName:hotelName,
        starRating:starRating,
        hotelState,
        hotelCity:hotelCity,
        hotelDistrict:hotelDistrict,
        hotelSizeOfFloor:hotelSizeOfFloor,
        architectureScore:architectureScore,
        securityScore:securityScore,
        privacyLandlordRelationshipScore:privacyLandlordRelationshipScore,
        compoundFacilitiesScore:compoundFacilitiesScore,
        optimalLocationScore:optimalLocationScore,
        estateFactorScore:estateFactorScore,
        overallScore:overallScore(),
        valueForMoneyScore:valueForMoneyScore()
      });
     
    }
    alert("Property Updated");
  };


 

  return (
    <Box sx={{ bgcolor: "#800080", height: "fullHeight" }}>
    <Helmet>
    <title>Chekam: Upload property.</title>
      <meta name="description" content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click" />
    </Helmet>
      <React.Fragment>
        <FormAppbar />
        <CssBaseline />
        <Container maxWidth="md">
          <Box
            sx={{ bgcolor: "#FFFFFF" }}
            component="form"
            noValidate
            onSubmit={handleSave}
          >
            <Grid
              container
              spacing={4}
              sx={{ display: type === "hotel" ? "none" : "content" }}
            >
              <Grid item xs={12}>
                <Divider> GENERAL INFORMATION</Divider>
              </Grid>
                      <Grid item xs>
                        <FormControl sx={{ m: 1, minWidth: 180 }}>
                          <InputLabel sx={{ mt: "0" }} id="propertyType">
                            Property Type
                          </InputLabel>
                          <Select
                            labelId="propertyType"
                            id="propertyType"
                            label="Property Type"
                            name="propertyType"
                            onChange = {handlePropertyTypeChange}
                            value = {type}
                          >
                            <MenuItem selected value={"house"}>
                              House
                            </MenuItem>
                            <MenuItem value={"apartment"}>Apartment</MenuItem>
                            <MenuItem value={"commercial space"}>
                              Commercial Space
                            </MenuItem>
                            <MenuItem value={"land"}>Land</MenuItem>
                            <MenuItem value={"hotel"}>Hotel</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel sx={{ mt: "0" }} id="propertyPurpose">
                            Property Purpose
                          </InputLabel>
                          <Select
                            labelId="propertyPurpose"
                            id="propertyPurpose"
                            label="propertyPurpose"
                            name="propertyPurpose"
                            onChange={handlePropertyPurposeChange}
                            value={purpose}
                          >
                            <MenuItem value={"rent"}>Rent</MenuItem>
                            <MenuItem value={"sale"}>Sale</MenuItem>
                            <MenuItem value={"shortlet"}>Short Let</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs
                        sx={{ display: type === "land" ? "none" : "content" }}
                      >
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel sx={{ mt: "0" }} id="propertyDescription">
                            Property Description
                          </InputLabel>
                          <Select
                            labelId="propertyDescription"
                            id="propertyDescription"
                            label="propertyDescription"
                            name="propertyDescription"
                            onChange={handlePropertyDescriptionChange}
                            value={propertyDescription}
                          >
                            <MenuItem value={"Bungalow"}>Bungalow</MenuItem>
                            <MenuItem value={"Flat"}>Flat</MenuItem>
                            <MenuItem value={"Terrace"}>Terrace</MenuItem>
                            <MenuItem value={"Detached"}>Detached</MenuItem>
                            <MenuItem value={"Semi Detached"}>
                              Semi Detached
                            </MenuItem>
                            <MenuItem value={"Maisonette"}>Maisonette</MenuItem>
                            <MenuItem value={"Duplex"}>Duplex</MenuItem>
                            <MenuItem value={"Apartment"}>Apartment</MenuItem>
                            <MenuItem value={"Penthouse"}>Penthouse</MenuItem>
                            <MenuItem value={"Studio"}>Studio</MenuItem>
                            <MenuItem value={"Alcove Studio"}>
                              Alcove Studio
                            </MenuItem>
                            <MenuItem value={"Convertible Studio"}>
                              Convertible Studio
                            </MenuItem>
                            <MenuItem value={"Convertible (Flex) Loft"}>
                              Convertible (Flex) Loft
                            </MenuItem>
                            <MenuItem value={"Duplex/Triplex"}>
                              Duplex/Triplex
                            </MenuItem>
                            <MenuItem value={"Junior 1 Bedroom"}>
                              Junior 1 Bedroom
                            </MenuItem>
                            <MenuItem value={"Garden Apartment"}>
                              Garden Apart
                            </MenuItem>
                            <MenuItem value={"Railroad Apartment"}>
                              Railroad Apartment
                            </MenuItem>
                            <MenuItem value={"Floor-through"}>
                              Penthouse
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/*<Grid item xs>
                        <FormControl sx={{ m: 1, minWidth: 150 }}>
                          <InputLabel sx={{ mt: "0" }} id="verified">
                            Verified by you?
                          </InputLabel>
                          <Select
                            labelId="verified"
                            id="verified"
                            label="verified"
                            name="verified"
                            onChange={handleVerifiedChange}
                            value={verified}
                          >
                            <MenuItem value={"5"}>Yes</MenuItem>
                            <MenuItem value={"0"}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>*/}

                      <Grid item xs>
                        <FormControl>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "18ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="price"
                              label={
                                purpose === "shortlet"
                                  ? "Price Per Night"
                                  : "Price"
                              }
                              variant="outlined"
                              name="price"
                              onChange={handlePriceChange}
                              value={price}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs>
                        <FormControl>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "15ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              id="realtor"
                              label="Realtor"
                              variant="outlined"
                              name="realtor"
                              onChange={handleRealtorChange}
                              value={realtor}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs>
                        <FormControl>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "15ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              id="agencyPercentage"
                              label="Agency Fee "
                              variant="outlined"
                              name="agencyPercentage"
                              onChange={handleAgencyFeeChange}
                              value={agencyPercentage}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                      {purpose === "rent" ? (
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "15ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                                id="legalFee"
                                label="Legal Fee"
                                variant="outlined"
                                name="legalFee"
                                onChange={handleLegalFeeChange}
                                value={legalFee}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      ) : null}
                      {purpose === "rent" ? (
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "15ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="cautionFee"
                                label="Caution Fee(Full Amount) "
                                variant="outlined"
                                name="cautionFee"
                                onChange={handleCautionFeeChange}
                                value={cautionFee}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                      ) : null}
                      <Grid item xs>
                        <FormControl>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "15ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              defaultValue="NIGERIA"
                              id="country"
                              label="Country"
                              variant="outlined"
                              name="country"
                            />
                          </Box>
                        </FormControl>
                      </Grid>

                      <Grid item xs>
                        <FormControl sx={{ m: 1, minWidth: 60 }}>
                          <InputLabel
                            sx={{ mt: "0" }}
                            variant="standard"
                            htmlFor="uncontrolled-native"
                          >
                            State
                          </InputLabel>
                          <NativeSelect
                            onChange={handleStateChange}
                            value={state}
                            inputProps={{
                              name: "state",
                              id: "state",
                            }}
                          >
                            <option value="FCT">FCT</option>
                            <option value="Lagos">Lagos</option>
                          </NativeSelect>
                        </FormControl>
                      </Grid>

                      <Grid item xs>
                        <FormControl sx={{ m: 1, width: "20ch" }}>
                          <Autocomplete
                            id="district"
                            name="district"
                            onChange={(event, value) => setDistrict(event.target.value)}
                            sx={{ mt: "0" }}
                            options={
                              state === "FCT"
                                ? abujaDistricts.map((option) => option)
                                : lagosDistricts.map((option) => option)
                            }
                            renderInput={(params) => (
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                {...params}
                                label="District"
                                name="district"
                                value={district}
                                onChange={handleDistrictChange}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs>
                        <FormControl>
                          <Box
                            sx={{
                              "& > :not(style)": { m: 1, width: "25ch" },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              id="outlined-basic"
                              label="Street Name"
                              variant="outlined"
                              name="streetName"
                              onChange={handleStreetNameChange}
                              value={streetName}
                            />
                          </Box>
                        </FormControl>
                      </Grid>
                       <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "15ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="outlined-basic"
                                label="House Number"
                                variant="outlined"
                                name="houseNumber"
                                onChange={handleHouseNumberChange}
                                value={houseNumber}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "15ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                type="number"
                                value={northings}
                                id="outlined-basic"
                                label="northing"
                                variant="outlined"
                                name="northing"
                                onChange={handleNorthingsChange}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& .MuiTextField-root": { m: 1, width: "15ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                type="number"
                                value={eastings}
                                id="outlined-basic"
                                label="easting"
                                variant="outlined"
                                name="easting"
                                onChange={handleEastingsChange}
                              />
                            </Box>
                          </FormControl>
                            </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              component="form"
                              sx={{
                                "& .MuiTextField-root": { m: 1, width: "25ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="Additional Features"
                                label="Additional Features"
                                multiline
                                maxRows={50}
                                value={additionalFeatures}
                                onChange={handleAdditionalFeaturesChange}
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                       {purpose === "sale" ? (
                          <Grid item xs>
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <InputLabel
                                sx={{ mt: "1" }}
                                variant="standard"
                                htmlFor="uncontrolled-native"
                              >
                                Title Document
                              </InputLabel>
                              <NativeSelect
                                inputProps={{
                                  name: "titledocuments",
                                  id: "titledocuments",
                                }}
                              >
                                <option value={5}>Certificate of Occupancy</option>
                                <option value={3}>Right of Occupancy</option>
                                <option value={2}>Deed of Assignment/Power of Attorney</option>
                                <option value={1}>Freehold</option>
                              </NativeSelect>
                            </FormControl>
                          </Grid>
                        ) : null}

                           {/*<Grid item xs>
                          <FormControl sx={{ m: 1 }}>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              LISTING
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={listing}
                              onChange={handleListingChange}
                            >
                              <FormControlLabel
                                value="regular"
                                control={<Radio />}
                                label="Regular"
                              />
                              <FormControlLabel
                                value="premium"
                                control={<Radio />}
                                label="Premium"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl sx={{ m: 1 }}>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              CHEKAM TO VERIFY?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={chekamVerified}
                              onChange={handleChekamVerifiedChange}
                              variant="outlined"
                            >
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                            </RadioGroup>
                          </FormControl>
                              </Grid>*/}


                        <Grid item xs sx={{ display: type === "land" ? "content" : "none" }}>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="outlined-basic"
                              label="Size of Land (Square Meters)"
                              variant="outlined"
                              name="sizeOfLand"
                              InputLabelProps={{ style: { fontSize: 12 } }}
                              value={sizeOfLand}
                              onChange={handleSizeOfLandChange}
                            />
                          </FormControl>
                        </Grid>

                        <Box bgcolor="primary" p={1}>
                              {data.mainImage.map((image, index) => {
                                return (
                                  <Grid
                                      container
                                      spacing={1}
                                      key={index}
                                      sx={{ m: 1, p: 1, width: 300, display: "inline-block" }}
                                    >
                                  <Grid item >
                                    <img
                                      src={data.mainImage[index]}
                                      alt={`Upload Preview ${index + 1}`}
                                      style={{
                                        maxHeight: "40%",
                                        maxWidth: "40%"
                                      }}
                                  />
                                  <IconButton
                                        aria-label="Delete Image"
                                        onClick={() => handleDeletePicture(data.mainImage[index])}
                                    >
                                    <RiDeleteBin5Line />
                                  </IconButton>
                                  </Grid>
                                  </Grid>
                                );
                              })}
                            </Box>
                        
                            <Box bgcolor="primary" p={1}>
                              {data.images.map((image, index) => {
                                return (
                                  <Grid
                                      container
                                      spacing={1}
                                      key={index}
                                      sx={{ m: 1, p: 1, width: 300, display: "inline-block" }}
                                    >
                                  <Grid item >
                                    <img
                                      src={data.images[index]}
                                      alt={`Upload Preview ${index + 1}`}
                                      style={{
                                        maxHeight: "40%",
                                        maxWidth: "40%"
                                      }}
                                  />
                                  <IconButton
                                        aria-label="Delete Image"
                                        onClick={() => handleDeletePicture(data.images[index])}
                                    >
                                    <RiDeleteBin5Line />
                                  </IconButton>
                                  </Grid>
                                  </Grid>
                                );
                              })}
                            </Box>

                            <Grid
                            container
                            direction="column"
                            alignItems="center"
                            spacing={2}
                          >
                            <Box border={1} margin={4} padding={3}>
                              <Grid
                                item
                                container
                                direction="column"
                                alignItems="center"
                                xs={12}
                                spacing={1}
                              >
                                <Grid item container xs={12} justify="center">
                                  <MainImageDropZone setMainImage={setMainImage} />
                                </Grid>
                              </Grid>
                            </Box>
                            {mainImage.length > 0 && (
                              <Box bgcolor="primary" p={4}>
                                {mainImage.map((image, index) => {
                                  return (
                                    <Grid item key={image.file.size + index}>
                                      <ImageElement
                                        image={image}
                                        index={index}
                                        isFirstElement={index === 0}
                                        isLastElement={index === mainImage.length - 1}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Box>
                            )}
                          </Grid>
            
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            spacing={2}
                          >
                            <Box border={1} margin={4} padding={3}>
                              <Grid
                                item
                                container
                                direction="column"
                                alignItems="center"
                                xs={12}
                                spacing={1}
                              >
                                <Grid item container xs={12} justify="center">
                                  <ImagesDropZone setImageList={setImageList} />
                                </Grid>
                              </Grid>
                            </Box>
                            {imageList.length > 0 && (
                              <Box bgcolor="primary" p={4}>
                                {imageList.map((image, index) => {
                                  return (
                                    <Grid item key={image.file.size + index}>
                                      <ImageElement
                                        image={image}
                                        index={index}
                                        isFirstElement={index === 0}
                                        isLastElement={index === imageList.length - 1}
                                        handleChangeOrderUp={handleChangeOrderUp}
                                        handleChangeOrderDown={handleChangeOrderDown}
                                        handleDeleteImage={handleDeleteImage}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Box>
                            )}
                          </Grid>
                         
                        
                        <Grid item xs sx={{display: type === "house" || type === "apartment"? "content": "none",}}>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="outlined-basic"
                              label="Number of Cars to Fit Parking Space"
                              variant="outlined"
                              name="numberOfCarsThatCanFit"
                              InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs sx={{display: type === "commercial space" ? "content" : "none",}}>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="outlined-basic"
                              label="Size of Commercial Space (Square Meters)"
                              variant="outlined"
                              name="sizeOfCommercialSpace"
                              InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{
                            display: type === "commercial space" ? "content" : "none",
                          }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="outlined-basic"
                              label="Length of Commercial Car Park (meters)"
                              variant="outlined"
                              name="lengthOfCommercialCarPark"
                              InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{
                            display: type === "commercial space" ? "content" : "none",
                          }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              type="number"
                              id="outlined-basic"
                              label="Breadth of Commerical Car Park (meters)"
                              variant="outlined"
                              name="breadthOfCommercialCarPark"
                              InputLabelProps={{ style: { fontSize: 12 } }}
                            />
                          </FormControl>
                        </Grid>
                  
                        
                       
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> BEDROOMS/BATHROOMS/TOILETS </Divider>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Number of Bedrooms
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "numberOfBedrooms",
                                id: "numberOfBedrooms",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                              <option value={11}>11</option>
                              <option value={12}>12</option>
                              <option value={13}>13</option>
                              <option value={14}>14</option>
                              <option value={15}>15</option>
                              <option value={16}>16</option>
                              <option value={17}>17</option>
                              <option value={18}>18</option>
                              <option value={19}>19</option>
                              <option value={20}>20</option>
                              <option value={21}>21</option>
                              <option value={22}>22</option>
                              <option value={23}>23</option>
                              <option value={24}>24</option>
                              <option value={25}>25</option>
                              <option value={26}>26</option>
                              <option value={27}>27</option>
                              <option value={28}>28</option>
                              <option value={29}>29</option>
                              <option value={30}>30</option>
                              <option value={31}>11</option>
                              <option value={32}>32</option>
                              <option value={33}>33</option>
                              <option value={34}>34</option>
                              <option value={35}>35</option>
                              <option value={36}>36</option>
                              <option value={37}>37</option>
                              <option value={38}>38</option>
                              <option value={39}>39</option>
                              <option value={40}>40</option>
                              <option value={41}>41</option>
                              <option value={42}>42</option>
                              <option value={43}>43</option>
                              <option value={44}>44</option>
                              <option value={45}>45</option>
                              <option value={46}>46</option>
                              <option value={47}>47</option>
                              <option value={48}>48</option>
                              <option value={49}>49</option>
                              <option value={50}>50</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Number of Bathrooms
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "numberOfBathrooms",
                                id: "numberOfBathrooms",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Number of Toilet
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "numberOfToilets",
                                id: "numberOfToilets",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 170 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Number of Bathrooms Ensuite
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "numberOfBathroomsEnsuite",
                                id: "numberOfBathroomsEnsuite",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Number of Toilets Ensuite
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "numberOfToiletsEnsuite",
                                id: "numberOfToiletsEnsuite",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                              <option value={6}>6</option>
                              <option value={7}>7</option>
                              <option value={8}>8</option>
                              <option value={9}>9</option>
                              <option value={10}>10</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> ARCHITECTURE </Divider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <span> ARRANGEMENT</span>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {entryArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Entry Arranagement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addEntryArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleEntryArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "entryArrangement" }}
                                  >
                                    <option value={2}>Main Door</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleEntryArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "entryArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeEntryArrangementInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {livingRoomArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Living Room Arranagement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addLivingRoomArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleLivingRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "livingRoomArrangement" }}
                                  >
                                    <option value={2}>Entry Vestible</option>
                                    <option value={1}>Main Door</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleLivingRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "livingRoomArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() =>
                                      removeLivingRoomArrangementInput(index)
                                    }
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {diningRoomArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Dining Arranagement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addDiningRoomArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleDiningRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "diningRoomArrangement" }}
                                  >
                                    <option value={2}>Living Room</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleDiningRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "diningRoomArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() =>
                                      removeDiningRoomArrangementInput(index)
                                    }
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {kitchenArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Kitchen Arranagement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addKitchenArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                  
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleKitchenArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "kitchenArrangement" }}
                                  >
                                    <option value={2}>Dining Room</option>
                                    <option value={1}>Living Room</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleKitchenArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "kitchenArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeKitchenArrangementInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {bedroomArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Bedroom Arrangement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addBedroomArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleBedroomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "bedroomArrangement" }}
                                  >
                                    <option value={2}>
                                      Vestibule/Hall/Staircase/Neutral Area
                                    </option>
                                    <option value={1}>Living Room</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleBedroomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "bedroomArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeBedroomArrangementInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {visitorsToiletArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Visitor's Toilet Arrangement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addVisitorsToiletArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleVisitorsToiletArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "visitorsToiletArrangement" }}
                                  >
                                    <option value={2}>Entry Vestibule</option>
                                    <option value={1}>Living Room</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleVisitorsToiletArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "visitorsToiletArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() =>
                                      removeVisitorsToiletArrangementInput(index)
                                    }
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {residentToiletArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Resident Toilet Arrangement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addResidentToiletArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleResidentToiletArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "residentToiletArrangement" }}
                                  >
                                    <option value={2}>Ensuite in Bedroom</option>
                                    <option value={0}>Shared</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleResidentToiletArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "residentToiletArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() =>
                                      removeResidentToiletArrangementInput(index)
                                    }
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {otherRoomArrangementInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <InputLabel
                                  sx={{ mt: "0" }}
                                  variant="standard"
                                  htmlFor="uncontrolled-native"
                                >
                                  Other Room Arrangement
                                  <IconButton
                                    aria-label="add"
                                    size="small"
                                    onClick={() => addOtherRoomArrangementInput()}
                                  >
                                    <AddBoxIcon fontSize="inherit" />
                                  </IconButton>
                                </InputLabel>
                                {index === 0 ? (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleOtherRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "otherRoomArrangement" }}
                                  >
                                    <option value={2}>Appropriate</option>
                                    <option value={0}>Other</option>
                                  </NativeSelect>
                                ) : (
                                  <NativeSelect
                                    onChange={(e) =>
                                      handleOtherRoomArrangementChange(index, e)
                                    }
                                    inputProps={{ name: "otherRoomArrangement" }}
                                  >
                                    <option value={1}>Appropriate</option>
                                    <option value={0}>Inappropriate</option>
                                  </NativeSelect>
                                )}
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeOtherRoomArrangementInput(index)}
                                  >
                                    <HelpIcon fontSize="small" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <span> SIZES</span>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            {kitchenInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={() => addKitchenInput()}
                                >
                                  <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={handleKitchenTriangleExplanationOpen}
                                >
                                  <HelpIcon fontSize="inherit" />
                                </IconButton>
                              <Dialog
                                
                                  open={kitchenTriangleExplanationOpen}
                                  onClose={handleKitchenTriangleExplanationClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle id="alert-dialog-title">
                                    {"What is a kitchen Triangle?"}
                                  </DialogTitle>
                                  <DialogContent>
                                  <DialogContentText>
                                
                                  </DialogContentText>
                                  <Box sx={{width: '500px',}}>
                                  <img variant = 'contained' src={kitchenexplanation} alt="kitchenTriangleExplanation" />
                                  </Box>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={handleKitchenTriangleExplanationClose}>Close</Button>
                                  </DialogActions>
                              </Dialog>
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  id="outlined-basic"
                                  label="Kitchen Triangle Length A (Meters)"
                                  variant="outlined"
                                  name="kitchenTriangleLengthA"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleKitchenInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  id="outlined-basic"
                                  label="Kitchen Triangle Length B (Meters)"
                                  variant="outlined"
                                  name="kitchenTriangleLengthB"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleKitchenInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  id="outlined-basic"
                                  label="Kitchen Triangle Length B (Meters)"
                                  variant="outlined"
                                  name="kitchenTriangleLengthC"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleKitchenInputChange(index, event)
                                  }
                                />
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeKitchenInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            {livingRoomInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={() => addLivingRoomInput()}
                                >
                                  <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Living Room Length (Meters)"
                                  variant="outlined"
                                  name="livingRoomLength"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleLivingRoomInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Living Room Breadth (Meters)"
                                  variant="outlined"
                                  name="livingRoomBreadth"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleLivingRoomInputChange(index, event)
                                  }
                                />
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeLivingRoomInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            {diningRoomInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={() => addDiningRoomInput()}
                                >
                                  <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Dining Room Length (Meters)"
                                  variant="outlined"
                                  name="diningRoomLength"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleDiningRoomInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Dining Room Breadth (Meters)"
                                  variant="outlined"
                                  name="diningRoomBreadth"
                                  InputLabelProps={{
                                    shrink: true,
                                    style: { fontSize: 14 },
                                  }}
                                  onChange={(event) =>
                                    handleDiningRoomInputChange(index, event)
                                  }
                                />
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeDiningRoomInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            {bedroomInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={() => addBedroomInput()}
                                >
                                  <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Bedroom Length (Meters)"
                                  variant="outlined"
                                  name="bedroomLength"
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  onChange={(event) =>
                                    handleBedroomInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Bedroom Breadth (Meters)"
                                  variant="outlined"
                                  name="bedroomBreadth"
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  onChange={(event) =>
                                    handleBedroomInputChange(index, event)
                                  }
                                />
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeBedroomInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            {bathroomInput.map((element, index) => (
                              <div className="form-inline" key={index}>
                                <IconButton
                                  aria-label="add"
                                  size="small"
                                  onClick={() => addBathroomInput()}
                                >
                                  <AddBoxIcon fontSize="inherit" />
                                </IconButton>
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Bathroom Length (Meters)"
                                  variant="outlined"
                                  name="bathroomLength"
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  onChange={(event) =>
                                    handleBathroomInputChange(index, event)
                                  }
                                />
                                <TextField
                                  sx={{ "& label": { mt: "0" } }}
                                  type="number"
                                  label="Bathroom Breadth (Meters)"
                                  variant="outlined"
                                  name="bathroomBreadth"
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  onChange={(event) =>
                                    handleBathroomInputChange(index, event)
                                  }
                                />
                                {index ? (
                                  <IconButton
                                    aria-label="cancel"
                                    size="small"
                                    onClick={() => removeBathroomInput(index)}
                                  >
                                    <CancelPresentationIcon fontSize="inherit" />
                                  </IconButton>
                                ) : null}
                              </div>
                            ))}
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <span>CONDITION</span>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Doors and Windows
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "doorsWindowsCondition",
                                id: "doorsWindowsCondition",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={0}>Damaged</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Floors
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "floorsCondition",
                                id: "floorsCondition",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={1}>Imperfectly Laid</option>
                              <option value={0}>Damaged</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Walls
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "wallsCondition",
                                id: "wallsCondition",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={0}>Damaged</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Ceiling
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "ceilingCondition",
                                id: "ceilingCondition",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={0}>Damaged</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Toilets and Sinks
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "toiletSinksCondition",
                                id: "toiletSinksCondition",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={1}>Hindered Flow</option>
                              <option value={0}>Broken</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Plumbing
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "plumblingCondtion",
                                id: "plumblingCondtion",
                              }}
                            >
                              <option value={5}>Perfect</option>
                              <option value={0}>Damaged</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> ESTATE </Divider>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Live in Estate?
                            </InputLabel>
                            <NativeSelect
                              onChange={handleLiveInEstateChange}
                              value={liveInEstate}
                              inputProps={{
                                name: "estateRating",
                                id: "estateRating",
                              }}
                            >
                              <option value={"yes"}>Yes</option>
                              <option value={"no"}>No</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Estate Management
                            </InputLabel>
                            <NativeSelect
                              placeholder="Select"
                              inputProps={{
                                name: "estateManagement",
                                id: "estateManagement",
                              }}
                            >
                              <option value={0}>Select</option>
                              <option value={1}>Poor</option>
                              <option value={2}>Fair</option>
                              <option value={3}>Good</option>
                              <option value={4}>Very Good</option>
                              <option value={5}>Excellent</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> SECURITY </Divider>
                        </Grid>
                        {liveInEstate === "no" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                              <InputLabel sx={{ mt: "0" }} id="EntryAndExit">
                                Non Estate Entry and Exit
                              </InputLabel>
                              <Select
                                labelId="nonEntryAndExit"
                                id="nonEntryAndExit"
                                label="nonEntryAndExit"
                                name="nonEstateEntryAndExit"
                              >
                                <MenuItem value={2}>
                                  Manned Street gate [ call necessary]
                                </MenuItem>
                                <MenuItem value={1}>
                                  Manned Street gate [No call necessary]
                                </MenuItem>
                                <MenuItem value={0}>Street Entry/Exit Free</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        {liveInEstate === "yes" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                              <InputLabel sx={{ mt: "0" }} id="callGate">
                                Call Gate
                              </InputLabel>
                              <Select
                                labelId="callGate"
                                id="callGate"
                                label="callGate"
                                name="callGate"
                              >
                                <MenuItem value={"yes"}>Yes</MenuItem>
                                <MenuItem value={"no"}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        {liveInEstate === "yes" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                              <InputLabel sx={{ mt: "0" }} id="gateEntryCode">
                                Gate Entry Code
                              </InputLabel>
                              <Select
                                labelId="gateEntryCode"
                                id="gateEntryCode"
                                label="gateEntryCode"
                                name="gateEntryCode"
                              >
                                <MenuItem value={"yes"}>Yes</MenuItem>
                                <MenuItem value={"no"}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        {liveInEstate === "yes" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                              <InputLabel sx={{ mt: "0" }} id="securityResponse">
                                Security Response
                              </InputLabel>
                              <Select
                                labelId="securityResponse"
                                id="securityResponse"
                                label="securityResponse"
                                name="securityResponse"
                              >
                                <MenuItem value={"yes"}>Yes</MenuItem>
                                <MenuItem value={"no"}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        {liveInEstate === "yes" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 150 }}>
                              <InputLabel sx={{ mt: "0" }} id="regularPatrols">
                                Regular Patrols
                              </InputLabel>
                              <Select
                                labelId="regularPatrols"
                                id="regularPatrols"
                                label="regularPatrols"
                                name="regularPatrols"
                              >
                                <MenuItem value={"yes"}>Yes</MenuItem>
                                <MenuItem value={"no"}>No</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel sx={{ mt: "0" }} id="securityOfHouseGate">
                              Security of House Gate
                            </InputLabel>
                            <Select
                              labelId="securityOfHouseGate"
                              id="securityOfHouseGate"
                              label="securityOfHouseGate"
                              name="securityOfHouseGate"
                            >
                              <MenuItem value={"Heavy Duty + Electronic Access"}>
                                Heavy Duty + Electronic Access
                              </MenuItem>
                              <MenuItem value={"Manual Access"}>Manual Access</MenuItem>
                              <MenuItem value={"None"}>None</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              id="securityOfHouseBuildingAccess"
                            >
                              Security of House Building Access
                            </InputLabel>
                            <Select
                              labelId="securityOfHouseBuildingAccess"
                              id="securityOfHouseBuildingAccess"
                              label="securityOfHouseBuildingAccess"
                              name="securityOfHouseBuildingAccess"
                            >
                              <MenuItem value={"Security Doors + Electronic Access"}>
                                Security Doors + Electronic Access
                              </MenuItem>
                              <MenuItem value={"Security Doors"}>Security Doors</MenuItem>
                              <MenuItem value={"Ordinary Door"}>Ordinary Door</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {type === "apartment" ? (
                          <Grid
                            item
                            xs
                            sx={{ display: type === "land" ? "none" : "content" }}
                          >
                            <FormControl sx={{ m: 1, minWidth: 220 }}>
                              <InputLabel
                                sx={{ mt: "0" }}
                                id="securityOfHouseApartmentAccess"
                              >
                                Security of House Apartment Access
                              </InputLabel>
                              <Select
                                labelId="securityOfHouseApartmentAccess"
                                id="securityOfHouseApartmentAccess"
                                label="securityOfHouseApartmentAccess"
                                name="securityOfHouseApartmentAccess"
                              >
                                <MenuItem value={"Security Doors + Electronic Access"}>
                                  Security Doors + Electronic Access
                                </MenuItem>
                                <MenuItem value={"Ordinary Door"}>Ordinary Door</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              id="securityOfHouseSecurityPersonnel"
                            >
                              Security of House Security Personnel
                            </InputLabel>
                            <Select
                              labelId="securityOfHouseSecurityPersonnel"
                              id="securityOfHouseSecurityPersonnel"
                              label="securityOfHouseSecurityPersonnel"
                              name="securityOfHouseSecurityPersonnel"
                            >
                              <MenuItem value={"Professional"}>Professional</MenuItem>
                              <MenuItem value={"Unregistered"}>Unregistered</MenuItem>
                              <MenuItem value={"None"}>None</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              id="sameStreetAsSensitiveLocation"
                            >
                              Same Street as Sensitive Location
                            </InputLabel>
                            <Select
                              labelId="sameStreetAsSensitiveLocation"
                              id="sameStreetAsSensitiveLocation"
                              label="sameStreetAsSensitiveLocation"
                              name="sameStreetAsSensitiveLocation"
                            >
                              <MenuItem value={1}>Yes</MenuItem>
                              <MenuItem value={0}>No</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> PRIVACY AND LANDLORD RELATIONSHIP </Divider>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Privacy and Landlord Relationship
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "privacyLandlordRelationshipRating",
                                id: "privacyLandlordRelationshipRating",
                              }}
                            >
                              <option value={4}>Certified Agent Managed</option>
                              <option value={3}>Lawyer Managed</option>
                              <option value={2}>Landlord Managed</option>
                              <option value={1}>Landlord Relation Managed</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <Divider> COMPOUND FACILITIES </Divider>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Power
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "powerFactor",
                                id: "powerFactor",
                              }}
                            >
                              {type === "house" && purpose === "sale" ? (
                                <option value={3}>Owner's Choice</option>
                              ) : null}
                              <option value={3}>Working Prepaid Meter</option>
                              <option value={2}>Postpaid Meter Fully paid</option>
                              <option value={0}>Postpaid Meter Outstanding Bill</option>
                              <option value={0}>Estimated Billing</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Water
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "waterFactor",
                                id: "waterFactor",
                              }}
                            >
                              {type === "house" && purpose === "sale" ? (
                                <option value={4}>
                                  Municipal Water Supply/Estate Supply
                                </option>
                              ) : null}
                              {purpose === "rent" ? (
                                <option value={4}>Stable Municipal Water Source</option>
                              ) : null}
                              {purpose === "rent" ? (
                                <option value={3}>Borehole as part of rent</option>
                              ) : null}
                              {purpose === "rent" ? (
                                <option value={2}>Borehole managed by tenants</option>
                              ) : null}
                              <option value={3}>
                                borehole or functioning water source
                              </option>
                              <option value={0}>No water supply</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Garbage
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "garbageFactor",
                                id: "garbageFactor",
                              }}
                            >
                              {purpose !== "rent" ? (
                                <option value={3}>
                                  Municipal or Estate Garbage Collections available
                                </option>
                              ) : null}
                              {purpose === "rent" ? (
                                <option value={3}>
                                  Municipal Collection Paid by Landlord
                                </option>
                              ) : null}
                              {purpose === "rent" ? (
                                <option value={1}>
                                  Municipal Collection Paid by Tenants
                                </option>
                              ) : null}
                              <option value={0}>No Municipal Collection</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Drainage
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "drainageScore",
                                id: "drainageScore",
                              }}
                            >
                              <option value={5}>
                                Present in compound and headed downhill from compound
                              </option>
                              <option value={1}>
                                Not present in compound but compound slopes downhill
                                towards exterior
                              </option>
                              <option value={0}>
                                Present/Not present but slopes upward to exterior
                              </option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs
                          sx={{ display: type === "land" ? "none" : "content" }}
                        >
                          <FormControl sx={{ m: 1, minWidth: 150 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Drainage Altitude
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "drainageAltitude",
                                id: "drainageAltitude",
                              }}
                            >
                              <option value={1}>Above road level</option>
                              <option value={0}>Below road level</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={4}
                        sx={{
                          display: type === "hotel" ? "content" : "none",
                          height: "80vh",
                        }}
                      >
                        <Grid item xs={12}>
                          <Divider> HOTEL </Divider>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "25ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="outlined-basic"
                                label="Hotel Name"
                                variant="outlined"
                                name="hotelName"
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel
                              sx={{ mt: "0" }}
                              variant="standard"
                              htmlFor="uncontrolled-native"
                            >
                              Hotel Rating
                            </InputLabel>
                            <NativeSelect
                              inputProps={{
                                name: "starRating",
                                id: "starRating",
                              }}
                            >
                              <option value={1}>1</option>
                              <option value={1.5}>1S</option>
                              <option value={2}>2</option>
                              <option value={2.5}>2S</option>
                              <option value={3}>3</option>
                              <option value={3.5}>3S</option>
                              <option value={4}>4</option>
                              <option value={4.5}>4S</option>
                              <option value={5}>5</option>
                              <option value={5.5}>5S</option>
                            </NativeSelect>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "25ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ mt: "0" }}
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                name="hotelState"
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "25ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                name="hotelCity"
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl>
                            <Box
                              sx={{
                                "& > :not(style)": { m: 1, width: "25ch" },
                              }}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                sx={{ "& label": { mt: "0" } }}
                                id="outlined-basic"
                                label="District"
                                variant="outlined"
                                name="hotelDistrict"
                              />
                            </Box>
                          </FormControl>
                        </Grid>
                        <Grid item xs>
                          <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <TextField
                              sx={{ "& label": { mt: "0" } }}
                              defaultValue="0"
                              id="outlined-basic"
                              label="Hotel Size of Floor"
                              variant="outlined"
                              name="hotelSizeOfFloor"
                            />
                          </FormControl>
                            </Grid>
                     </Grid>
           

           <Box sx={{ "& button": { m: 1 } }}>
             <Button type="submit" variant="outlined" size="large">
               SAVE
             </Button>
           </Box>
         </Box>

        
       </Container>
     </React.Fragment>
   </Box>
  );
}

export default UpdateProperties;