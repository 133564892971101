import React from "react";
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getAuth, signOut} from "firebase/auth";
import { UserContext } from "../../../App";
import logo from "../../../images/chekamlogo.jpeg";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';


function Header() {
 
  const user = React.useContext(UserContext)
  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(getAuth()).then(() => {
      // Sign-out successful.
      navigate('/')
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
              <img className="" style={{ width: "100px" }} src={logo} />
          </Link>
        </div>
       
        <Button  onClick = {()=> navigate('/admin/agent')} color="secondary" variant = 'contained' size='small' >Dashboard</Button>
       

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            <li className="nav-item pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                <span className="d-none d-md-block dropdown-toggle ps-2">
                {user?.email}
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
               
                  <span>Agent</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/admin/agent/profile"
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/admin/agent/profile"
                  >
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/admin/agent/faqs"
                  >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <Typography onClick={handleSignOut}>Logout</Typography>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;