import React from "react";
import { UserContext } from "../../../../App";


function ProfileOverview() {

const user = React.useContext(UserContext)
  return (
    <div
      className="tab-pane fade show active profile-overview"
      id="profile-overview"
    >
      <h5 className="card-title">About</h5>
      <p className="small fst-italic">
       I am a Property Manager ....
      </p>

      <h5 className="card-title">Profile Details</h5>

      <div className="row">
        <div className="col-lg-3 col-md-4 label ">Username</div>
        <div className="col-lg-9 col-md-8">{user?user.displayName:null}</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Country</div>
        <div className="col-lg-9 col-md-8">Nigeria</div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Phone</div>
        <div className="col-lg-9 col-md-8">
        {user?user.phoneNumber:null}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-md-4 label">Email</div>
        <div className="col-lg-9 col-md-8">{user?user.email:null}</div>
      </div>
    </div>
  );
}

export default ProfileOverview;