// // import { useState } from 'react'
// import React, { useEffect, useState } from "react";
// import Modal from "./Modals";
// import { useNavigate } from "react-router-dom";
// import "./styles/ProductList.css";
// import "./styles/selected.css";
// import { Link } from "react-router-dom";

// import Pagination from "./Pagination";
// import ModalShortlet from "./ModalShortlet";
// import PaginationShortlet from "./PaginationShortlet";
// import { Helmet } from "react-helmet";

// export function NewHelmet(id) {
//   return (
//     <Helmet>
//       <title>{id}</title>
//       {/*<meta property="og:title" content={title} />
//       <meta name="description" content={description} />
//       <meta property="og:description" content={description} />
//       <meta property="og:image" content={imageUrl} />
//       <meta property="og:url" content={url}/>*/}
//     </Helmet>
//   );
// }

// const ProductList = ({
//   products,
//   propertyType,
//   type,
//   blank,
//   show,
//   setShow,
// }) => {
//   const [productId, setId] = useState(null);
//   // const [show, setShow] = useState(false);
//   const [title, setTitle] = useState("Smartest");
//   const navigate = useNavigate();

//   // convert time
//   // function convertHMS(value) {
//   //   const sec = parseInt(value, 10); // convert value to number if it's string
//   //   let hours   = Math.floor(sec / 3600); // get hours
//   //   let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
//   //   let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
//   //   // add 0 if value < 10; Example: 2 => 02
//   //   if (hours   < 10) {hours   = "0"+hours;}
//   //   if (minutes < 10) {minutes = "0"+minutes;}
//   //   if (seconds < 10) {seconds = "0"+seconds;}
//   //   return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
//   // }

//   // convert timestamp from seconds to day func
//   function secondsToDhms(seconds) {
//     seconds = Number(seconds);
//     var mo = Math.floor(seconds / (36000 * 744));
//     var d = Math.floor(seconds / (3600 * 24));
//     var h = Math.floor((seconds % (3600 * 24)) / 3600);
//     var m = Math.floor((seconds % 3600) / 60);
//     var s = Math.floor(seconds % 60);

//     var moDisplay = mo > 0 ? mo + (mo == 1 ? " month, " : " months, ") : "";
//     var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
//     var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
//     var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
//     var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
//     return moDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
//   }

//   const handleClick = (id, e) => {
//     e.preventDefault();
//     navigate(`/selected-item?q=${id}`, "_blank", "noopener,noreferrer");
//   };

//   const openInNewTab = (url) => {
//     const newWindow = window.open(url, "_blank", "noopener,noreferrer");
//     if (newWindow) newWindow.opener = null;
//   };
//   const handleId = (id, e) => {
//     // $('.modal-body').scrollspy('refresh');
//     // $('[data-spy="scroll"]').each(function () {
//     //   $(this).scrollspy("refresh");
//     // });
//     // document.querySelector(".modal-body").scrollspy("refresh")
//     setShow(true);
//     e.preventDefault();
//     setId(id);
//     // const refreshSroll = document.Q
//     // $('.modal-body').scrollspy("refresh")
//   };

//   // pagination start
//   const [pageNumber, setPageNumber] = useState(0);

//   const productPerPage = 20;

//   return (
//     <>
//       <Helmet>
//         <title>Chekam: The smartest way to real estate.</title>
//         <meta
//           name="description"
//           content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
//         />
//         <meta
//           property="og:title"
//           content="Chekam: The smartest way to real estate."
//         />
//         <meta
//           property="og:description"
//           content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
//         />
//         <meta property="og:image" content={`data?.images[0]`} />
//         <meta property="og:url" content={`https://godaddy.com}`} />
//       </Helmet>
//       <div
//         className="row scroll-hotel"
//         style={{ margin: "1px", overflowX: "hidden !important" }}
//       >
//         {propertyType === "shortlet" ? (
//           <PaginationShortlet
//             pageNumber={pageNumber}
//             productPerPage={productPerPage}
//             setPageNumber={setPageNumber}
//             products={products}
//             handleId={handleId}
//             propertyType={propertyType}
//             type={type}
//           />
//         ) : (
//           <Pagination
//             pageNumber={pageNumber}
//             productPerPage={productPerPage}
//             setPageNumber={setPageNumber}
//             products={products}
//             handleId={handleId}
//             propertyType={propertyType}
//             type={type}
//           />
//         )}

//         {propertyType === "shortlet" ? (
//           <ModalShortlet id={productId} show={show} setShow={setShow} />
//         ) : (
//           <Modal id={productId} show={show} setShow={setShow} />
//         )}
//       </div>
//       <a id="back-to-top" href="#" style={{ padding: "20px" }}>
//         <i
//           class="fa fa-arrow-up"
//           style={{ color: "#fff" }}
//           aria-hidden="true"
//         ></i>
//       </a>
//     </>
//   );
// };

// export default ProductList;




import React, { useEffect, useState } from "react";
import Modal from "./Modals";
import { useNavigate } from "react-router-dom";
import "./styles/ProductList.css";
import "./styles/selected.css";
import { Link } from "react-router-dom";

import Pagination from "./Pagination";
import ModalShortlet from "./ModalShortlet";
import PaginationShortlet from "./PaginationShortlet";
import { Helmet } from "react-helmet";


export function NewHelmet(id) {
  return (
    <Helmet>
      <title>{id}</title>
      {/*<meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url}/>*/}
    </Helmet>
      );

  }





const ProductList = ({ products, propertyType, type }) => {
  const [productId, setId] = useState(null);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('Smartest');
  const navigate = useNavigate();


 
  // convert time
  // function convertHMS(value) {
  //   const sec = parseInt(value, 10); // convert value to number if it's string
  //   let hours   = Math.floor(sec / 3600); // get hours
  //   let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
  //   let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
  //   // add 0 if value < 10; Example: 2 => 02
  //   if (hours   < 10) {hours   = "0"+hours;}
  //   if (minutes < 10) {minutes = "0"+minutes;}
  //   if (seconds < 10) {seconds = "0"+seconds;}
  //   return hours+':'+minutes+':'+seconds; // Return is HH : MM : SS
  // }

  // convert timestamp from seconds to day func
  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var mo = Math.floor(seconds / (36000 * 744));
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var moDisplay = mo > 0 ? mo + (mo == 1 ? " month, " : " months, ") : "";
    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return moDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const handleClick = (id, e) => {
    e.preventDefault();
    navigate(`/selected-item?q=${id}`, "_blank", "noopener,noreferrer");
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const handleId = (id, e) => {
    // $('.modal-body').scrollspy('refresh');
    // $('[data-spy="scroll"]').each(function () {
    //   $(this).scrollspy("refresh");
    // });
    // document.querySelector(".modal-body").scrollspy("refresh")
    setShow(true);
    e.preventDefault();
    setId(id);
    // const refreshSroll = document.Q
    // $('.modal-body').scrollspy("refresh")
  };

  // pagination start
  const [pageNumber, setPageNumber] = useState(0);

  const productPerPage = 20;

  return (
    <>
    <Helmet>
        <title>Chekam: The smartest way to real estate.</title>
          <meta name="description" content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click" />
          <meta property="og:title" content="Chekam: The smartest way to real estate." />
          <meta property="og:description" content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click" />
          <meta property="og:image" content={`data?.images[0]`} /> 
          <meta property="og:url" content= {`https://godaddy.com}`} />
    </Helmet>
      <div
        className="row scroll-hotel"
        style={{ margin: "1px", overflowX: "hidden !important" }}
      >
        {propertyType === "shortlet" ? (
          <PaginationShortlet
            pageNumber={pageNumber}
            productPerPage={productPerPage}
            setPageNumber={setPageNumber}
            products={products}
            handleId={handleId}
            propertyType={propertyType}
            type={type}
          />
        ) : (
          <Pagination
            pageNumber={pageNumber}
            productPerPage={productPerPage}
            setPageNumber={setPageNumber}
            products={products}
            handleId={handleId}
            propertyType={propertyType}
            type={type}
          />
        )}

        {propertyType === "shortlet" ? (
          <ModalShortlet id={productId} show={show} setShow={setShow} />
        ) : (
          <Modal id={productId} show={show} setShow={setShow} />
        )}
      </div>
      <a id="back-to-top" href="#" style={{ padding: "20px" }}>
        <i
          class="fa fa-arrow-up"
          style={{ color: "#fff" }}
          aria-hidden="true"
        ></i>
      </a>
    </>
  );
};

export default ProductList;
