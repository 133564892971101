import ProductsList from "../ProductsList";

const Products = ({ products, propertyType, type, blank, show, setShow }) => {
  return (
    <>
      <ProductsList
        products={products}
        type={type}
        propertyType={propertyType}
        blank={blank}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default Products;
