import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router";
import Header from "../ManagerHeader";
import Sidebar from "../ManagerSidebar";
import ModalApplication from "./ModalApplication";
import ModalProperty from "./ModalProperty";
import { UserContext } from "../../../../App";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import db from '../../../../components/Firebase';
import {getDocs,collection,query, where} from "firebase/firestore";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

function ManagerProperties() {

  const [myProperties, setMyProperties] = useState ([]);
  const user = React.useContext(UserContext)
  const navigate = useNavigate();


  useEffect (async () => { 
    let charles = []
    
         const propertiesRef = collection(db, "properties");
         const q = query(propertiesRef, where("agentEmail", "==", user.email));
         const querySnapshot = await getDocs(q);
    
         querySnapshot.forEach((doc) => {charles.push(doc.data())
           charles = charles.sort((a,b)=>{return a.price-b.price})
           setMyProperties([...charles])  
          });
   },[]);



  function handleMove() {
    navigate("/admin/agent/properties/update");
  }
  function handleMoveProperties() {
    navigate("/admin/property-manager/properties/view");
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">My Properties</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Property Manager</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive agent-main">
        {myProperties.map((x,i)=> ((
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-6 pb-4 product-card"
              key = {i}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/Buying(1).png"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                  {x.price.toLocaleString("en-NG",{style:"currency",currency:"NGN"})}
                    <br />
                    {x.propertyDescription}
                    <br />
                    {x.district}
                  </p>
                </div>
              </div>
              <div className="" style={{ paddingLeft: "50px" }}>
                <button
                  className="btn text-white"
                  style={{ background: "purple" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModa"
                >
                  Send Appication Suite
                </button>
              </div>
              <div
                className="d-flex"
                style={{ paddingLeft: "40px", paddingTop: "10px" }}
              >
                <div style={{ paddingRight: "3px" }}>
                  <button
                    className="btn text-white"
                    style={{ background: "yellow" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
                <div>
                  <button
                    className="btn text-white"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ background: "purple" }}
                  >
                    Property Taken
                  </button>
                </div>
                <ModalProperty />
                <ModalApplication />
              </div>
            </div>
          </div>
          )))}
        </section>
      </main>
    </>
  );
}

export default ManagerProperties;