import React, { useEffect } from 'react';


function GoogleAds(props){

   const {currentPath} = props

    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
        }, [currentPath])


    return (
        <div key ={currentPath}>
        <ins className="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-6674353005156641"
            data-ad-slot="7757906873"
            data-ad-format="auto"
            data-full-width-responsive="true">
        </ins>
        </div>
        );
    }
export default GoogleAds;