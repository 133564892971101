import { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { houses, sublinks, mores, details } from "../data";
import Size from "./Size";
import Bedroom from "./Bedroom";
import "./styles/Buy.css";
import filterList from "./test/filterList";

const HouseHeader = ({
  agirlik0,
  agirlik1,
  prices,
  setPrices,
  setAgirlik,
  agirlik,
  selectedSizes,
  setSize,
  selectedBedrooms,
  setBedroom,
}) => {
  // SHOW DIV
  const [showDiv, setShowDiv] = useState(false);
  const onShowDiv = (e) => {
    e.preventDefault();
    setShowDiv(true);
  };

  const dontRefresh = (e) => {
    e.preventDefault();
  };
  const handlingPrice = () => {
    // console.log(agirlik0.le)
    if (agirlik0.toString().length < 4) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length === 5) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length <= 6) {
      // do this
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
      // return agirlik0 + "K";
    } else if (agirlik0.toString().length === 7) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 8) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 9) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 10) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 9);
      return forThousand + "B";
    }
    // else if (agirlik0.toString().length <= 9) {
    //   return agirlik0 + "M";
    // } else if (agirlik0.toString().length <= 10) {
    //   // do this
    //   return agirlik0 + "B";
    // }
    else {
      return agirlik0;
    }
  };
  return (
    <>
      <div className="col-lg-8 d-flex buttons-horizontal">
        <div style={{ margin: "5px" }} className="single-button">
          <li className="dropdown main-dropdown" >
            <a href="/buy" className="ui-state-default buy-cat-button">For Sale</a>
            <ul className="dropdown-menu overwrite-dropdown2"
              aria-labelledby="navbarScrollingDropdown" style={{ width: "40%"}}>
              <div className="">
                <li>
                  <a href="/rent" className="dropdown-item" style={{width: "100%"}}>For Rent</a>
                </li>
                <li>
                  <a href="/hotel" className="dropdown-item" style={{width: "100%"}}>Hotel</a>
                </li>
              </div>
            </ul>
          </li>
        </div>


        <div style={{ margin: "5px" }}>
          <div className="dropdown">
            <li
              className="dropdown main-dropdown"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
            >
              <a className="ui-state-default buy-cat-button">
                Property Types
              </a>
            </li>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {sublinks.map((link) => {
                const { links, title, url } = link;
                // console.log(links)
                // console.log(title)
                // console.log(url)
                return (
                  <li>
                    <a className="dropdown-item" href={url}>
                      {title} &raquo;
                    </a>
                    {links.length !== 0 ? (
                      <>
                        <ul className="dropdown-menu dropdown-submenu">
                          {links.map((item) => {
                            return (
                              <li>
                                <a className="dropdown-item" href={item.url}>
                                  {item.label}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        

        


        <div style={{ margin: "5px" }}>
          <li className="dropdown main-dropdown" onClick={dontRefresh}>
            <a href="" className="ui-state-default buy-cat-button">
              Baths & Bed
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarScrollingDropdown"
              style={{ paddingRight: "20px" }}
            >
              <div className="row subs-nav">
                <div className="col-md-4 p-4">
                  <div className="bedroom-div " style={{ paddingLeft: "5px" }}>
                    <div className="bedrooms-section">Bedrooms</div>
                    <Bedroom
                      selectedBedrooms={selectedBedrooms}
                      setBedroom={setBedroom}
                    />
                  </div>
                  <div className="" style={{ paddingLeft: "5px" }}>
                    <div className="bedrooms-section">Baths</div>
                    <Size selectedSizes={selectedSizes} setSize={setSize} />
                  </div>
                </div>
                {/* <div className="col-md-4 p-4">
                  <span className="">Bedrooms</span>
                  <div className="" style={{ paddingLeft: "20px" }}>
                    <Bedroom selectedBedrooms={selectedBedrooms} setBedroom={setBedroom} />
                  </div>
                </div> */}
              </div>
              <li>
                <a href="" className="marquee-link"></a>
              </li>
            </ul>
          </li>
        </div>

        <div style={{ margin: "5px" }}>
          <li className="dropdown main-dropdown" onClick={dontRefresh}>
            <a href="" className="ui-state-default buy-cat-button">
              Price
            </a>
            <ul
              className="dropdown-menu "
              aria-labelledby="navbarScrollingDropdown"
            >
              {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
              <div className="kategoriler">
                <span>
                  Price <span>Range</span>
                </span>
                <div className="kategori">
                  <div>
                    <input
                      type="text"
                      className="price-input"
                      // value={agirlik0 + "k"}
                      value={handlingPrice()}
                      name={agirlik0}
                      onChange={(e) => setAgirlik([agirlik1, e.target.name])}
                      // value={agirlik1}
                      // onChange={(e) => setAgirlik([agirlik0, e.target.value])}
                      readOnly
                    />
                    <span className="price-space-input"></span>
                    <input
                      type="text"
                      className="price-input"
                      value={1 + "B"}
                      name={agirlik1}
                      onChange={(e) => setAgirlik([agirlik0, e.target.name])}
                      readOnly
                    />
                  </div>
                  <Slider
                    value={agirlik}
                    min={prices.hacim_min}
                    max={prices.hacim_max}
                    style={{ color: "#800080" }}
                    onChange={(event, newValue) => setAgirlik(newValue)}
                    valueLabelDisplay="auto"
                    step="10000"
                    // sort={1000000}
                    // step={1}
                  />
                </div>
              </div>
            </ul>
          </li>
        </div>
        {/* PRICE COMPONENT SLIDER 
                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={onShowDiv}>
                       <a
                         href=""
                         className="ui-state-default buy-cat-button"
                       >
                         Price
                       </a>
                       {showDiv ? 
                       <ul className="dropdown-menu " aria-labelledby="navbarScrollingDropdown">
                       
                           <input type="range" class="slider" id="myRange" />
                          
                       </ul> : null}
                     </li>
                   </div> */}
        
        <div style={{ margin: "5px" }}>
        <div className="dropdown">
            <li
              className="dropdown main-dropdown"
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
            >
              <a className="ui-state-default buy-cat-button">
                More
              </a>
            </li>
                <ul className="dropdown-menu more-menu" aria-labelledby="dropdownMenuButton">
                  {mores.map((link) => {
                    const { links, text, url } = link;
                      return (
                    <li onClick={dontRefresh}>
                      <a className="dropdown-item" href={url}>
                      {text} &raquo;
                      </a>
                      
                        <ul className="dropdown-menu dropdown-submenu">
                          {links.map((item) => {
                            return (
                            <li>
                              <a className="dropdown-item" href={item.url}>
                                {item.label} 
                              </a>
                            </li>
                            );
                          })}
                        </ul>
                        
                    </li>
                    );
                  })}
                </ul>
          </div>
        </div>



      </div>
    </>
  );
};

export default HouseHeader;