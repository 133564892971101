import { details } from "../../data";

export default function filterBedroom(arr, method, prod, others) {
  if (arr?.length === 0) {
    // setDetails(others);
    // setProducts(others);
    return others;
  } else {
    return prod?.filter((product) => {
      const bedroomArray = product?.numberOfBedrooms?.toString().split(" ");
      if (arr?.length > 0) {
        if (bedroomArray?.some((r) => arr?.indexOf(r) >= 0)) {
          return product;
        }
      } else {
        return prod;
      }
    });
  }
}