import React, { useState, useEffect } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ExcelExport from "react-html-table-to-excel";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";

import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import moment from "moment";

function AllUsers() {
  const [users, setUser] = useState(null);

  const handleSelect = (data, index) => {
    // console.log(data?.split(" "));
    const isSplit = data?.split(" ");
    if (isSplit === "") {
      return "null";
    } else {
      if (isSplit) {
        if (isSplit?.length !== 0) {
          return isSplit[index];
        } else {
          return "null";
        }
      } else {
        return "null";
      }
    }
  };

  const documents = [];

  useEffect(() => {
    async function getPlaces() {
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        documents.push(doc.data());
        // documents.push(doc.data());
      });

      //   console.log(documents);
      setUser(documents);

      //   let uniqueChars = [...new Set(documents)];
      //   setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);

  //   console.log(users);

  const handleDate = (main) => {
    console.log(main);
    const formatDate = new Date(
      main?.seconds * 1000 + main?.nanoseconds / 1000000
    );
    // console.log(format);
    const mainDate = moment(formatDate).format("l");
    return mainDate;
  };

  return (
    <div className="container">
      <Table
        style={{ background: "white", overflow: "scroll" }}
        striped
        bordered
        hover
        size="sm"
        id="emp-table"
        className="table table-responsive"
      >
        <thead className="" style={{ background: "purple" }}>
          <tr className="text-center">
            <th >S/n</th>
            <th >FIRSTNAME</th>
            {/* <th>status</txh> */}
            <th >LASTNAME</th>
            <th >USERNAME</th>
            <th >EMAIL</th>
            <th >PHONE NO</th>
            <th >USER TYPE</th>
            <th >DATE</th>
            <th >REFERRAL</th>
          </tr>
        </thead>
        {users?.map((data, index) => {
          return (
            <>
              <tbody>
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <strong className="text-uppercase">
                      {handleSelect(data?.name, 0)}
                    </strong>
                  </td>
                  <td>{handleSelect(data?.name, 1)}</td>
                  <td>{data?.company ? data?.company : "null"}</td>
                  <td>{data?.email}</td>
                  <td>{data?.phone}</td>
                  <td>{data?.status}</td>
                  <td>{handleDate(data?.date)}</td>
                  <td>{data?.referral}</td>
                </tr>
              </tbody>
            </>
          );
        })}
      </Table>
      <ExcelExport
        className="btn btn-info"
        table="emp-table"
        sheet="Sheet"
        buttonText="export to excel"
        filename="All Users"
      />
    </div>
  );
}

export default AllUsers;
