import {collection,query,getDocs,where,} from "firebase/firestore";
import db from "./components/Firebase";

const places = [];

async function getPlaces() {
  const propertiesRef = collection(db, "properties");
  const q = query(propertiesRef, where("propertyType", "==", "house"));
  const array = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    places.push(doc.data());
      
  });
}

getPlaces();




export const questions = [
  {
    id: 1,
    title: "Do I have to accept cookies?",
    info: "This will be the best in the world and you will definitely miss out. This will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss out",
  },
  {
    id: 2,
    title: "What information do I input in the form?",
    info: "This will be the best in the world and you will definitely miss out. This will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss out",
  },
  {
    id: 3,
    title: "How many form are we to fill?",
    info: "This will be the best in the world and you will definitely miss out. This will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss out",
  },
  {
    id: 4,
    title: "Where do you recruit from?",
    info: "This will be the best in the world and you will definitely miss out. This will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss outThis will be the best in the world and you will definitely miss out",
  },
];

export const agents = [
  {
    id: 1,
    name: "Tayo Aina",
    contact: "08022299933",
    email: "tayoaina@gmail.com",
  },
  {
    id: 1,
    name: "Tayo Aina",
    contact: "08022299933",
    email: "tayoaina@gmail.com",
  },
  {
    id: 2,
    name: "Ayodeji Balogun",
    contact: "080244399673",
    email: "ayodeji234@gmail.com",
  },
  {
    id: 3,
    name: "Olamide Adedayo",
    contact: "08029805433",
    email: "olamide892@gmail.com",
  },
  {
    id: 4,
    name: "Tobiloba Emmanuel",
    contact: "08084902843",
    email: "emma.nuel258@gmail.com",
  },
  {
    id: 5,
    name: "Wilson Benson",
    contact: "08045708248",
    email: "willy783@gmail.com",
  },
  {
    id: 6,
    name: "Henry Matthew",
    contact: "0803337782",
    email: "matt.henry2934@gmail.com",
  },
];

export const houses = [
  //   { maps: "../../images/mapper.jpg" },
  {
    id: 1,
    image: "../images/hotel 3(1).jpg",
    price: 500000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    // time: 2012-04-23T18:25:43.511Z
    time: "2 hours ago",
    baths: 5,
    beds: 1,
  },
  {
    id: 2,
    image: "../images/hotel 1(1).jpg",
    price: 200000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    // time: new Date('2018-06-01'),
    time: "2 hours ago",
    baths: 4,
    beds: 2,
  },
  {
    id: 3,
    image: "../images/hotel(2).png",
    price: 400000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    // time: new Date('2019-06-01'),
    time: "2 hours ago",
    baths: 3,
    beds: 3,
  },
  {
    id: 4,
    image: "../images/hotel 4.jpg",
    price: 100000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    // time: new Date('2020-06-01'),
    time: "2 hours ago",
    baths: 2,
    beds: 4,
  },
  {
    id: 5,
    image: "../images/hotel 4.jpg",
    price: 300000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "15A Yaba Lagos",
    // time: new Date('2021-06-01'),
    time: "2 hours ago",
    baths: 1,
    beds: 5,
  },
];

export const sublinks = [
  {
    id: 1,
    url: "/house",
    title: "Houses",
    links: [
      { label: "Bungalow", url: "/houses/bungalow" },
      { label: "Terrace", url: "/houses/terrace" },
      { label: "Detached", url: "/houses/detached" },
      { label: "Semi-Detached", url: "/houses/semi-detached" },
      { label: "Maisonette", url: "/houses/maisonette" },
      { label: "Duplex", url: "/houses/duplex" },
      { label: "Triplex", url: "/houses/triplex" },
    ],
  },
  {
    id: 2,
    url: "/apartment",
    title: "Apartment",
    links: [
      { label: "Studio", url: "/apartments/studio" },
      { label: "Alcove Studio", url: "/apartments/alcove-studio" },
      { label: "Convertible Studio", url: "/apartments/Convertible-studio" },
      { label: "Convertible (Flex) Loft", url: "/apartments/convertible-loft" },
      { label: "Duplex/Triplex", url: "/apartments/duplex-and-triplex" },
      { label: "Junior 1 Bedroom", url: "/apartments/junior-bedroom" },
      { label: "Garden Apartment", url: "/apartments/garden-apartment" },
      { label: "Railroad Apartment", url: "/apartments/railroad" },
      { label: "Floor-through", url: "/apartments/floor-through" },
    ],
  },
  {
    id: 3,
    url: "/commercial-space",
    title: "Commercial Space",
    links: [],
  },
  {
    id: 3,
    url: "/land",
    title: "Land",
    links: [],
  },
];

export const more = [
  {
    id: 1,
    url: "property-title",
    text: "Property Title",
    links: [
      { label: "C of O", url: "/houses/cofa" },
      { label: "R of A", url: "/" },
      { label: "DOA", url: "/" },
      { label: "Freehold", url: "/" },
    ],
  },
  // {
  //   id: 2,
  //   url: "optimal-location",
  //   text: "Find Optimal Location",
  //   links: [
  //     { label: "Most Visited", url: "/optimal-location/most-visited" },
  //     { label: "Within Skims", url: "/" },
  //     { label: "Within 304kms", url: "/" },
  //     { label: "Within 50kms", url: "/" },
  //   ],
  // },
  {
    id: 3,
    url: "chekam-verified",
    text: "Chekam Verified",
    links: [{ label: "Yes" }, { label: "No" }],
  },
];

export const mores = [
  {
    id: 1,
    url: "chekam-verified",
    text: "Chekam Verified",
    links: [{ label: "Yes" }, { label: "No" }],
  },
];

export const mainLinks = [
  { link: "For Rent", url: "/rent" },
  { link: "For Sale", url: "/buy" },
  { link: "Hotel", url: "/hotel" },
];

// search component
export const initialDetails = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "Jane Doe",
    email: "janedoe@gmail.com",
    address: "New Delhi, India",
  },
  {
    id: 2,
    imgPath: "../images/hotel 3(1).jpg",
    name: "Mary Rosamund",
    email: "agra@rosie.com",
    address: "Tbilisi, India",
  },
  {
    id: 3,
    imgPath: "../images/hotel 3(1).jpg",
    name: "Sherlock Watson",
    email: "irene@johnlock.com",
    address: "Baker Street, India",
  },
  {
    id: 4,
    imgPath: "../images/hotel 3(1).jpg",
    name: "John Holmes",
    email: "mary@johnlock.com",
    address: "Baker Street, India",
  },
  {
    id: 5,
    imgPath: "../images/hotel 3(1).jpg",
    name: "Mycroft Lestrade",
    email: "britishgovt@gmail.com",
    address: "London, India",
  },
];

export const moreDetails = [
  {
    id: 1,
    images: [
      {
        image: "../../images/hotel 3(1).jpg",
        otherImages: [
          { image: "../../images/hotel 3(1).jpg" },
          { image: "../../images/hotel 3(1).jpg" },
        ],
      },
    ],
    price: 35000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    time: "9 hours ago",
    overview:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto laudantium fuga, adipisci libero omnis modi reprehenderit voluptate aut accusamus natus.",
    features:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iusto laudantium fuga, adipisci libero omnis modi reprehenderit voluptate aut accusamus natus.",
  },
  {
    id: 2,
    images: [
      {
        image: "../../images/hotel 1(1).jpg",
        otherImages: [
          { image: "../../images/hotel 3(1).jpg" },
          { image: "../../images/hotel 3(1).jpg" },
        ],
      },
    ],
    price: 35000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    time: "9 hours ago",
  },
  {
    id: 3,
    images: [
      {
        image: "../../images/hotel(2).png",
        otherImages: [
          { image: "../../images/hotel 3(1).jpg" },
          { image: "../../images/hotel 3(1).jpg" },
        ],
      },
    ],
    price: 35000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    time: "9 hours ago",
  },
  {
    id: 4,
    images: [
      {
        image: "../../images/hotel 4.jpg",
        otherImages: [
          { image: "../../images/hotel 3(1).jpg" },
          { image: "../../images/hotel 3(1).jpg" },
        ],
      },
    ],
    price: 35000,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    location: "12 Ikeja Lagos",
    time: "9 hours ago",
  },
];

// search component
export const details = [...places]
// console.log(details)
// export const details = [
//   {
//     id: 1,
//     imgPath: "../images/hotel 3(1).jpg",
//     name: "3 bedroom flat",
//     email: "janedoe@gmail.com",
//     address: "18, Abeokuta Street",
//     city: "Yaba",
//     state: "Lagos",
//     coordinates: [],
//     price: 95000,
//     time: 9,
//     about: "3 bd 1 ba 1,4567 - Townhouse for sale",
//     bedroom: "3",
//     bathroom: "3",
//     size: "4",
//     prices: 10000,
//   },
//   {
//     id: 2,
//     imgPath: "../images/hotel 1(1).jpg",
//     name: "Duplex",
//     email: "agra@rosie.com",
//     address: "22, Bakare Dawodu Street",
//     city: "Ifako",
//     state: "Lagos",
//     coordinates: [],
//     price: 6000000,
//     prices: 265000,
//     time: 2,
//     about: "3 bd 1 ba 1,4567 - Townhouse for sale",
//     bedroom: "5+",
//     bathroom: "2",
//     size: "2",
//     landSize: 80000,
//   },
//   {
//     id: 3,
//     imgPath: "../images/hotel(2).png",
//     name: "2 bedroom flat",
//     email: "irene@johnlock.com",
//     address: "83, Community Road",
//     city: "Akoka",
//     state: "Lagos",
//     coordinates: [],
//     price: 400000,
//     prices: 550354,
//     time: 11,
//     about: "3 bd 1 ba 1,4567 - Townhouse for sale",
//     bedroom: "2",
//     bathroom: "1",
//     size: "5+",
//     landSize: 30000,
//   },
//   {
//     id: 4,
//     imgPath: "../images/hotel 4.jpg",
//     name: "mini flat",
//     email: "mary@johnlock.com",
//     address: "12, Bode Thomas Street",
//     city: "Surulere",
//     state: "Lagos",
//     coordinates: [],
//     price: 215000,
//     prices: 445300,
//     time: 19,
//     about: "3 bd 1 ba 1,4567 - Townhouse for sale",
//     bedroom: "1",
//     bathroom: "5+",
//     size: "3",
//     landSize: 20000,
//   },
//   {
//     id: 5,
//     imgPath: "../images/h2.jpg",
//     name: "mini flat",
//     email: "britishgovt@gmail.com",
//     address: "14a, Brown street",
//     city: "Oshodi",
//     state: "Lagos",
//     coordinates: [6.55949, 3.34784],
//     price: 55000,
//     prices: 89000,
//     time: 21,
//     about: "3 bd 1 ba 1,4567 - Townhouse for sale",
//     bedroom: "4",
//     bathroom: "4",
//     size: "1",
//     landSize: 40000,
//   },
// ];

export const detailsRent = [
  {
    id: 1,
    imgPath: "../images/hotel 1(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 550000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 2(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 500000,
  },
  {
    id: 3,
    imgPath: "../images/hotel 3.jpg",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 1.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 740000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h4.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 75000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const sell = [
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const land = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Land for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Land for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Land for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Land for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Land for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const apartment = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Apartment",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Apartment",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Apartment",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Apartment",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Apartment",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const commercial = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Commercial Space for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const house = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];
export const hotel = [
  {
    id: 1,
    imgPath: "../images/hotel 3(1).jpg",
    name: "3 bedroom flat",
    email: "janedoe@gmail.com",
    address: "18, Abeokuta Street",
    city: "Yaba",
    state: "Lagos",
    coordinates: [],
    price: 95000,
    time: 9,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "3",
    bathroom: "3",
    size: "4",
    prices: 10000,
  },
  {
    id: 2,
    imgPath: "../images/hotel 1(1).jpg",
    name: "Duplex",
    email: "agra@rosie.com",
    address: "22, Bakare Dawodu Street",
    city: "Ifako",
    state: "Lagos",
    coordinates: [],
    price: 6000000,
    prices: 265000,
    time: 2,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "5+",
    bathroom: "2",
    size: "2",
    landSize: 80000,
  },
  {
    id: 3,
    imgPath: "../images/hotel(2).png",
    name: "2 bedroom flat",
    email: "irene@johnlock.com",
    address: "83, Community Road",
    city: "Akoka",
    state: "Lagos",
    coordinates: [],
    price: 400000,
    prices: 550354,
    time: 11,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "2",
    bathroom: "1",
    size: "5+",
    landSize: 30000,
  },
  {
    id: 4,
    imgPath: "../images/hotel 4.jpg",
    name: "mini flat",
    email: "mary@johnlock.com",
    address: "12, Bode Thomas Street",
    city: "Surulere",
    state: "Lagos",
    coordinates: [],
    price: 215000,
    prices: 445300,
    time: 19,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "1",
    bathroom: "5+",
    size: "3",
    landSize: 20000,
  },
  {
    id: 5,
    imgPath: "../images/h2.jpg",
    name: "mini flat",
    email: "britishgovt@gmail.com",
    address: "14a, Brown street",
    city: "Oshodi",
    state: "Lagos",
    coordinates: [6.55949, 3.34784],
    price: 55000,
    prices: 89000,
    time: 21,
    about: "3 bd 1 ba 1,4567 - Townhouse for sale",
    bedroom: "4",
    bathroom: "4",
    size: "1",
    landSize: 40000,
  },
];

export const data = [
  {
    id: "3546354635463456",
    text: "ALL",
    value: 4,
  },
  {
    id: "354635463633456",
    text: "No transfers",
    value: 0,
  },
  {
    id: "3546345654",
    text: "1 transfer",
    value: 1,
  },
  {
    id: "356356253654635",
    text: "2 transfer",
    value: 2,
  },
  {
    id: "3546786578657",
    text: "3 transfer",
    value: 3,
  },
];

export const config = [
  {
    id: "cc222da8-0e13-492e-9c0b-dac7b3890d8b",
    transfers: 3,
  },
  {
    id: "6d34e96a-9a10-43e5-b99b-d37c67d0e742",
    transfers: 1,
  },
  {
    id: "fdc3dfe0-c664-4872-b6d6-64874208451d",
    transfers: 0,
  },
  {
    id: "768d166f-e7e7-4ba5-b627-98ae03d7bdb9",
    transfers: 2,
  },
  {
    id: "339852a4-c5e5-4bbf-b8a4-726eee8633f0",
    transfers: 3,
  },
  {
    id: "cbd43f45-d2ba-46dd-b271-d30e19033a97",
    transfers: 3,
  },
  {
    id: "5e7fa137-0849-45c5-ab93-15e447d9b6cc",
    transfers: 1,
  },
  {
    id: "056a80f8-aba8-48a0-bec1-787472285a97",
    transfers: 0,
  },
  {
    id: "21052b3c-e9bc-4eae-9b08-4b231237f98d",
    tansfers: 1,
  },
  {
    id: "659cb1b1-fe66-408b-ac90-c4f1ccb446ed",
    transfers: 0,
  },
];