import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { PaystackButton } from "react-paystack";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import { UserContext } from "../App";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
function SuscriberModal({ setShow, show }) {
  const user = React.useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState([5000, 50, 100]);

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";

  const amount = value * 100;

  let formated = moment(new Date()).format("l h:mm:ss A");
  // console.log(formated, "testing date")

  const expiredNextMonth = (date) => {
    // var date = new Date();
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      splittingdate[1]
    );
    return moment(monthexpireddate).format("l h:mm:ss A");
  };
  function nextWeek() {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let formated = moment(nextweek).format("l h:mm:ss A");
    return formated;
  }
  function getTomorrow() {
    var today = new Date();
    var tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );

    let formated = moment(tomorrow).format("l h:mm:ss A");
    return formated;
  }

  const expireDaily = getTomorrow();
  const expireWeekly = nextWeek();
  const expireMonthly = expiredNextMonth();

  // console.log(expireDaily, "daily");
  // console.log(expireWeekly, "weekly");
  // console.log(expireMonthly, "monthly");

  const componentRatingsProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      const userRef = doc(db, "users", user?.uid);
      if (amount === "5000") {
        updateDoc(userRef, { subscribed: "daily", expiring: expireDaily });
      } else if (amount === "10000") {
        updateDoc(userRef, { subscribed: "weekly", expiring: expireWeekly });
      } else if (amount === "15000") {
        updateDoc(userRef, { subscribed: "monthly", expiring: expireMonthly });
      }
      alert("Payment Successful! close and continue");
    },
    onClose: () => alert("closed"),
  };

  const handleSuscribe = () => {
    const userRef = doc(db, "users", user?.uid);
    updateDoc(userRef, { subscribed: "none", expiring: "expired" }).then(() => {
      alert("Updated successfully");
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    // console.log(event.target.value);
  };

  //   const tomorrow = getTomorrow();
  //   console.log(moment(tomorrow).format("l"));

  //   var date = new Date();
  //   var montlysuscribedate = new Date(date.getFullYear(), date.getMonth(), 9);
  //   var monthexpireddate = new Date(date.getFullYear(), date.getMonth() + 1, 9);

  //   console.log(montlysuscribedate);
  //   console.log(monthexpireddate);
  const urlParams = new URLSearchParams(window.location.search);
  const urltype = urlParams.get("type");
  const newParams = urlParams.get("q");

  const handleRefresh = () => {
    return (window.location.href = `/selected-item?q=${newParams}`);
  };

  const handleSuscriber = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: `/selected-item?q=${newParams}&plan=${value}&name=${name}&phone=${phone}&email=${email}&type=suscribe`,
        },
      });
    } else {
      navigate("/user/suscribe");
    }
  };

  // var startTime = new Date("01/12/2013 2:00:00 AM");
  // var endTime = new Date("01/12/2013 1:00:00 AM");

  // if (startTime < endTime) {
  //   alert("start time is lesser");
  // }else {
  //   alert("")
  // }
  return (
    <div id="mode-modal">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suscribers Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              onChange={(e) => handleChange(e)}
            >
              <Form.Label>Subscription Package</Form.Label>
              <RadioGroup
                required
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="5000"
                  control={<Radio />}
                  label="N5,000 Daily"
                />
                <FormControlLabel
                  value="10000"
                  control={<Radio />}
                  label="N10,000 Weekly"
                />
                <FormControlLabel
                  value="15000"
                  label="N15,000 Monthly"
                  control={<Radio />}
                />
              </RadioGroup>
            </Form.Group>
            {!user ? (
              <Button onClick={(e) => handleSuscriber(e)}>Pay now</Button>
            ) : (
              <PaystackButton {...componentRatingsProps} />
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          {/*
          <Button variant="primary" onClick={() => handleSuscribe()}>
            Understood
          </Button>
        */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SuscriberModal;
