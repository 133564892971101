import ScrollSpyTabs from "./ScrollSpyTabs";
import React, { useState, useEffect, useRef } from "react";
import "./styles/selected.css";
import Divider from "@mui/material/Divider";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SuscriberModal from "./SuscriberModal";
import ModalMain from "react-bootstrap/Modal";
import { Button, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import Navbar from "./Navbar";
import firebase from "firebase/app";
import { UserContext } from "../App";
import Scrollspy from "react-scrollspy";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
// import $ from 'jquery';

import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
// import $ from "jquery";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import logo from "../images/chekamlogo.jpeg";
import PropTypes from "prop-types";
import { PaystackButton } from "react-paystack";
import { RiContactsBookLine } from "react-icons/ri";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Home from "./Home";
import ScrollspyNav from "react-scrollspy-nav";
//import { UserContext } from "../../../App";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBScrollspy,
  MDBScrollspyLink,
  MDBScrollspySubList,
} from "mdb-react-ui-kit";

function Modal({ id, show, setShow }) {
  let listingPrice, agencyFee, legalFee, cautionFee;

  const user = React.useContext(UserContext);
  const navigate = useNavigate();
  const [copy, setCopy] = useState(false);
  const [ratingsPaymentOpen, setRatingsPaymentOpen] = React.useState(false);
  const [modalshow, setModalShow] = useState(false);
  const [escrowDialogOpen, setEscrowDialogOpen] = React.useState(false);
  const [escrowPaymentOpen, setEscrowPaymentOpen] = React.useState(false);
  const [progress, setProgress] = useState(false);
  const [share, setShare] = useState(false);
  const [data, setData] = useState(null);
  const [disappear, setDisappear] = useState(
    data?.userRated?.includes(user?.uid) ? "d-none" : ""
  );
  const [activeLink, setActiveLink] = useState("");
  const [ratings, setRating] = useState(1);
  const [rate, setRate] = useState(false);
  const [close, setCloseModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [cast, setCast] = useState(null);
  const [email, setEmail] = useState("");
  const [users, setUser] = useState({subscribed:true});
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const closeModal = () => {
    setCloseModal(!close);
  };
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  function sanitise(x) {
    if (isNaN(x)) {
      return 0;
    }
    return parseInt(x);
  }

  listingPrice = data?.price;
  agencyFee = data?.agencyFee;
  cautionFee = sanitise(data?.cautionFee);
  legalFee = sanitise(data?.legalFee);

  const totalCost = listingPrice + agencyFee + cautionFee + legalFee;
  const calculate = 0.01 * totalCost;

  const escrowFee = calculate > 15000 ? 15000 : calculate;
  const grandTotal = totalCost + escrowFee;

  const handleEscrowDialogOpen = () => {
    function sanitise(x) {
      if (isNaN(x)) {
        return 0;
      }
      return parseInt(x);
    }
    setShow(false);
    setEscrowDialogOpen(true);
  };

  const handleEscrowDialogClose = () => {
    setEscrowDialogOpen(false);
  };

  const handleEscrowPaymentOpen = () => {
    setEscrowPaymentOpen(true);
  };
  const handleEscrowPaymentClose = () => {
    setEscrowPaymentOpen(false);
  };

  const handleRatingsPaymentOpen = () => {
    function sanitise(x) {
      if (isNaN(x)) {
        return 0;
      }
      return parseInt(x);
    }
    setShow(false);
    setRatingsPaymentOpen(true);
  };

  // const handleRatingsPaymentOpen = () => {
  //   setRatingsPaymentOpen(true);
  // };
  const handleRatingsPaymentClose = () => {
    setRatingsPaymentOpen(false);
  };
  //console.log(id);
  useEffect(() => {
    setRate(false);
    setShare(false);
    setRating(1);
    setLoading(true);
    async function getPlaces() {
      const docRef = doc(db, "properties", id);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());

      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getPlaces();
    // setLoading(false);
  }, [id]);

  // //console.log(data)

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  let naira = "\u20A6";

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";

  // const amount = componentProps ? grandTotal * 100 : 50 * 100;

  const [value, setValue] = useState([5000, 50, 100]);
  const [values, setValues] = useState([]);

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(event.target.value);
  };

  const handleChanges = (event) => {
    setValues(event.target.value);
    console.log(event.target.value);
  };

  const amount = ratingsPaymentOpen ? value * 100 : grandTotal * 100;

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      alert("Payment Successful! close and continue");
    },

    onClose: () => alert("closed"),
  };

  const componentRatingsProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      const userRef = doc(db, "users", user?.uid);
      if (amount === "5000") {
        updateDoc(userRef, { subscribed: "daily" });
      } else if (amount === "10000") {
        updateDoc(userRef, { subscribed: "weekly" });
      } else if (amount === "15000") {
        updateDoc(userRef, { subscribed: "monthly" });
      }
      alert("Payment Successful! close and continue");
    },
    onClose: () => alert("closed"),
  };

  useEffect(() => {
    // $("body").scrollspy("refresh");
    // document.querySelector(".modal-body").
    // $('[data-spy="scroll"]').each(function () {
    //   $(this).scrollspy("refresh");
    // });
    setCast(null);

    setCast(ratings);
    // setCast()
  }, [ratings]);

  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     const observable = {
  //       home: document.querySelector(".home"),
  //       projects: document.querySelector(".projects"),
  //       media: document.querySelector(".media"),
  //     };
  //     const pos = {
  //       home: observable.home.getBoundingClientRect(),
  //       projects: observable.projects.getBoundingClientRect(),
  //       media: observable.media.getBoundingClientRect(),
  //     };

  //     //console.log(observable);
  //     //console.log(pos);

  //     if (pos.home.top < window.innerHeight && pos.home.bottom >= 0) {
  //       setActiveLink("home");
  //     } else if (
  //       pos.projects.top < window.innerHeight &&
  //       pos.projects.bottom >= 0
  //     ) {
  //       setActiveLink("projects");
  //     } else if (pos.media.top < window.innerHeight && pos.media.bottom >= 0) {
  //       setActiveLink("media");
  //     }
  //   });

  //   // return () => {

  //   // }
  // }, []);

  const handleRating = (e) => {
    e.preventDefault();
  };

  const handleClose = () => setShow(false);

  const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=img&for=${data?.propertyPurpose}`;
  // const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`;
  // const shareLink = `https://chekam.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&id=${id}&for=${data?.propertyPurpose}`;

  const userRated = ["1", "2", "3", "7"];

  const calculus = (0 + cast) / data?.userRated?.length;

  const handleRate = (e) => {
    setProgress(true);
    setLoading(true);
    e.preventDefault();
    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: "/selected-item?q=" + id + "&m=main",
        },
      });
    } else {
      const examcollref = doc(db, "properties", id);
      updateDoc(examcollref, {
        cast: data?.cast + cast,
        userRated: arrayUnion(user?.uid),
        // userRated: arrayUnion("davidsonjose"),
        userRating: `${(data?.cast + cast) / data?.userRated?.length}`,
      })
        .then((response) => {
          // alert("Successfully rated the property");
          setMessage("Thanks for rating.... 🎉🎉🎉🎉");
          setProgress(false);
          setLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  // console.log(cast);

  const places = [];

  useEffect(() => {
    async function getPlaces() {
      // setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(propertiesRef, where("propertyPurpose", "==", "sale"));
      const array = [];
      getDocs(q).then((data) => {
        data?.forEach((doc) => {
          places.push(doc.data());

          // setDetails([...places]);
          // setProducts([...places]);
          // setLocation([...places]);
          // setOthers([...places]);

          places?.forEach((data) => {
            // console.log(data, "properties")
            const examcollref = doc(db, "properties", data?.id);
            updateDoc(examcollref, {
              userRated: [],
              cast: null,
            })
              .then((response) => {
                alert("updated");
              })
              .catch((error) => {
                console.log(error.message);
              });
          });
        });
        setLoading(false);
      });
    }
    getPlaces();
  }, []);

  // console.log(data, "single property");

  // const handleSuscriber = (e) => {
  //   e.preventDefault();

  //   const examcollref = doc(db, "properties", id);
  //   updateDoc(examcollref, {
  //     sponsored: true,
  //   })
  //     .then((response) => {
  //       // alert("Successfully rated the property");
  //       setMessage("Thanks for rating.... 🎉🎉🎉🎉");
  //       setProgress(false);
  //       setLoading(false);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  //   // if (!user) {
  //   //   navigate("/login");
  //   // } else {
  //   //   navigate("/user/suscribe");
  //   // }
  // };

  // useEffect(() => {
  // const examcollref = doc(db, "properties", id);
  // updateDoc(examcollref, {
  //   sponsored: true
  // })
  //   .then((response) => {
  //     // alert("Successfully rated the property");
  //     setMessage("Thanks for rating.... 🎉🎉🎉🎉")
  //     setProgress(false)
  //     setLoading(false)

  //     setTimeout(() => {
  //       window.location.reload()
  //     }, 2000)
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });
  // }, [])

  const handleSuscriber = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: `/selected-item?q=${id}&plan=${value}&name=${name}&phone=${phone}&email=${email}&type=suscribe`,
        },
      });
    } else {
      navigate("/user/suscribe");
    }
  };

  const handleAgentInfo = (id, e) => {
    // setId(agentid);

    async function getSingleAgent() {
      const docRef = doc(db, "users", id?.agentId);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();
      return (window.location.href = `/properties/${id?.agentName}?n=${
        id?.agentId
      }&companyname=${agentData?.company}&image=${
        data?.photoURL === undefined || data?.photoURL === ""
          ? "none"
          : data?.photoUrl
      }`);
    }
    getSingleAgent();
  };

  // scrollspy
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const sectionA = useRef(null);
  const sectionB = useRef(null);

  const containerRef = useRef(null);

  const subsections = [sectionA, sectionB];

  // escrow disable button
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      // setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();

      setUser(agentData);
    })();
  }, [user]);

  const handleCopy = () => {
    // if (!copy) {

    navigator.clipboard.writeText(shareLink).then(
      function () {
        setCopy(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );

    setTimeout(() => {
      setCopy(false);
    }, 600);
    // }
  };
  return (
    <>
      <Helmet>
        <title>Chekam: The smartest way to real estate.</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
        <meta
          property="og:title"
          content="Chekam: The smartest way to real estate."
        />
        <meta
          property="og:description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
        <meta property="og:image" content={`data?.images[0]`} />
        <meta
          property="og:url"
          content={`https://chekam.com/selected-item?q=${id}`}
        />
      </Helmet>
      <ModalMain
        className="main-modal"
        style={{ padding: "0" }}
        show={show}
        onHide={handleClose}
      >
        <ModalMain.Body className="" style={{ padding: "0" }}>
          {loading ? (
            <Dialog open={loading === true} onClose={!loading}>
              <CircularProgress color="secondary" className="m-5" />
            </Dialog>
          ) : (
            <div className="container pt-5">
              <div className="mobile-modal" style={{ height: "300px" }}>
                <Navbar />

                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={
                        data?.mainImage?.length === undefined
                          ? data?.images[0]
                          : data?.mainImage[0]
                      }
                      style={{ width: "450px", height: "350px" }}
                      alt=""
                      className="img-fluid modal-image"
                    />
                  </SwiperSlide>
                  {data?.images?.map((link) => {
                    return (
                      <SwiperSlide>
                        <>
                          <img
                            src={link}
                            className="img-fluid selected-img"
                            style={{ width: "374px", height: "350px" }}
                            alt=""
                          />
                        </>
                      </SwiperSlide>
                    );
                  })}
                  <div className="main-card-single col-md-6" id="example">
                    <span onClick={() => setShow(!show)}>
                      <i
                        class="fa fa-arrow-left text-white p-3 bg-warning rounded-pill"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                  {/* <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
                </Swiper>
              </div>

              <div className="row pb-1" style={{ padding: "0" }}>
                <div className="col-md-7 respond-me" style={{ padding: "0" }}>
                  <div
                    className="card home-single"
                    style={{ height: "800px", overflowY: "scroll" }}
                  >
                    <img
                      src={
                        data?.mainImage?.length === undefined
                          ? data?.images[0]
                          : data?.mainImage[0]
                      }
                      style={{ width: "750px", height: "700px" }}
                      alt=""
                      className="img-fluid modal-image hide-modal-image"
                    />
                    <div className="respond-me mt-1">
                      <div className="row">
                        {data?.images?.map((link) => {
                          return (
                            <div className="col-md-6 pb-2 image-responsive-selected respond-me">
                              <img
                                src={link}
                                className="img-fluid selected-img hide-selected-img"
                                style={{ width: "374px", height: "350px" }}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="card-footer text-center home-single-footer">
                      <h5 className="text-white">
                        <strong>Interested in touring this home?</strong>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 respond-me">
                  <div className="card-header row">
                    <div className="col-md-6">
                      <img src={logo} style={{ width: "150px" }} alt="" />
                    </div>

                    <div className="main-card-single col-md-6">
                      <span className="modal-icon">
                        <i
                          class="fa fa-heart-o top-icon"
                          aria-hidden="true"
                        ></i>
                        <span>Save</span>
                      </span>

                      <span
                        className="modal-icon"
                        onClick={() => setShare(!share)}
                      >
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        &nbsp;
                        <span>Share</span>
                      </span>
                    </div>
                  </div>
                  <br />

                  <div className="pb-3 hide-share-mobile">
                    {share && (
                      <>
                        <FacebookShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                          quote={`2bdrm Apartment `}
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                        <TelegramShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>

                        <div className="pt-2 d-flex">
                          <p style={{ fontSize: "20px" }}>
                            <i
                              class="fa fa-clipboard"
                              style={{ color: "purple" }}
                              aria-hidden="true"
                              onClick={() => handleCopy()}
                            ></i>
                          </p>

                          {copy ? (
                            <strong>Copied</strong>
                          ) : (
                            <small>Copy property link</small>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <h2 style={{ fontWeight: "bold", color: "black" }}>
                      {naira}
                      {data?.price?.toLocaleString()}
                    </h2>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBedrooms}
                      </span>
                      bd
                    </span>
                    <span style={{ paddingLeft: "10px" }}>|</span>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBathrooms}
                      </span>
                      ba
                    </span>
                    {/* <span style={{paddingLeft: "10px"}}>|</span> */}
                    {/* <span style={{paddingLeft: "10px"}}><span style={{fontWeight: "bolder", color: "black"}}>1</span> bd</span> */}
                  </div>
                  <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                    <span>
                      {data?.district}, {data?.streetName}
                    </span>

                    <br />
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      <i
                        class="fa fa-circle sale-icon text-danger"
                        aria-hidden="true"
                      ></i>
                      For {data?.propertyPurpose} | &nbsp; {naira}
                      {data?.price?.toLocaleString()} &nbsp;
                      {/*<a
                        onClick={() =>
                          openInNewTab(
                            `/selected-item?q=${id}&details=house|for:${data?.propertyPurpose},property-type:${data?.propertyType},${data?.numberOfBedrooms}bedrooms,${data?.numberOfBathrooms}bathrooms`
                          )
                        }
                        style={{ cursor: "pointer" }}
                        className="text-warning veiw-details"
                      >
                        view details
                      </a>*/}
                    </span>
                    <br />
                    <span>Product Id: {data?.productId}</span>
                    <br />
                    <span
                      className="modal-icon hide-share"
                      onClick={() => setShare(!share)}
                    >
                      <i class="fa fa-share-alt" aria-hidden="true"></i>
                      &nbsp;
                      <span>Share</span>
                    </span>
                    <br />
                    <div className="pb-3 show-share-mobile">
                      {share && (
                        <>
                          <FacebookShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <FacebookIcon size={32} round={true} />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <WhatsappIcon size={32} round={true} />
                          </WhatsappShareButton>
                          <TwitterShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <TwitterIcon size={32} round={true} />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <EmailIcon size={32} round={true} />
                          </EmailShareButton>
                          <TelegramShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <TelegramIcon size={32} round={true} />
                          </TelegramShareButton>

                          <div className="pt-2 d-flex">
                            <p style={{ fontSize: "20px" }}>
                              <i
                                class="fa fa-clipboard"
                                style={{ color: "purple" }}
                                aria-hidden="true"
                                onClick={() => handleCopy()}
                              ></i>
                            </p>

                            {copy ? (
                              <strong>Copied</strong>
                            ) : (
                              <small>Copy property link</small>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {/* <span>
                  Est Payment: $1,200/mo
                  <span className="bg-warning dollar-symbol">$</span>
                </span>
                <span>Get pre-qualified</span> */}
                  </div>
                  <br />
                  <div className="row">
                    {message && (
                      <strong className="alert alert-success text-center">
                        {message}
                      </strong>
                    )}
                    <div className="respond-me">
                      <div className="">
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          onClick={toggleVisibility}
                          style={{
                            width: "50%",
                            backgroundColor: "purple",
                            borderColor: "purple",
                            marginRight: "2%",
                          }}
                        >
                          Contact Agent
                        </button>
                        <button
                          onClick={handleEscrowDialogOpen}
                          className="btn btn-outline-warning selected-agent-btn"
                          style={{
                            width: "45%",
                            color: "purple",
                            backgroundColor: "transparent",
                            borderColor: "purple",
                          }}
                        >
                          Escrow pay
                        </button>
                      </div>
                      {isVisible && (
                        <div className="p-4">
                          Email: {data?.agentEmail} <br />
                          Contact: {data?.agentPhone}
                          {/* name: Mr Wasiu <br /> Contact: 08099933304 */}
                        </div>
                      )}
                      {data?.propertyType?.toLowerCase() === "shortlet" && (
                        <button
                          className={`${
                            data?.userRated?.includes(user?.uid) ? "d-none" : ""
                          } btn btn-warning selected-agent-btn text-white`}
                          onClick={() => setRate(!rate)}
                          style={{
                            width: "45%",
                            // backgroundColor: "purple",
                            // borderColor: "purple !important",
                          }}
                        >
                          Rate Property
                        </button>
                      )}
                      {data?.propertyPurpose?.toLowerCase() === "shortlet" && (
                        <>
                          {rate && (
                            <div
                              style={{
                                width: "100% !important",
                                paddingTop: "20px",
                              }}
                              // className={`${disappear}`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <StarRatings
                                  rating={ratings}
                                  changeRating={setRating}
                                  numberOfStars={5}
                                  name="rating"
                                  color="purple"
                                  style={{
                                    width: "300px",
                                  }}
                                  starRatedColor="yellow"
                                  starHoverColor="purple"
                                  starDimension="30px"
                                />
                              </div>
                              <button
                                className="d-flex justify-content-center text-white mt-3 rounded-pill text-center mx-auto"
                                style={{
                                  background: `${
                                    progress === true ? "gray" : "purple"
                                  }`,
                                  width: "50%",
                                  padding: "5px",
                                  border: "none !important",
                                  opacity: `${progress === true ? "0.8" : ""}`,
                                }}
                                onClick={(e) => handleRate(e)}
                                disabled={progress === true}
                              >
                                Submit Ratings
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="pt-4 pr-3">
                      <strong>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleAgentInfo(data, e)}
                        >
                          {data?.agentName}
                        </a>
                      </strong>
                    </div>
                  </div>
                  <hr />

                  <MDBContainer className="scroll-container">
                    <MDBRow>
                      <MDBCol md="12">
                        <MDBScrollspy container={containerRef}>
                          <MDBScrollspyLink
                            className="scroll-new"
                            targetRef={section1}
                          >
                            Ratings
                          </MDBScrollspyLink>
                          <MDBScrollspyLink targetRef={section2}>
                            Overview
                          </MDBScrollspyLink>
                          <MDBScrollspyLink targetRef={section3}>
                            Features
                          </MDBScrollspyLink>
                        </MDBScrollspy>
                      </MDBCol>

                      <MDBCol md="12">
                        <div
                          id="element"
                          ref={containerRef}
                          className="scrollspy-example"
                        >
                          <section ref={section1} id="section-1">
                            <div className="p-4 home">
                              <span className="text-muted fs-5">Ratings</span>
                              <br />
                              {users?.subscribed === undefined ||
                              users?.subscribed === "none" || data?.verified === 5 ? (
                                <p>
                                  {!data?.verified ? (
                                    <p>N/A, unverified</p>
                                  ): (
                                    <button
                                    className="btn btn-outline-warning selected-agent-btn text-white"
                                    style={{
                                      background: "purple",
                                      width: "50%",
                                      borderColor: "purple",
                                    }}
                                    onClick={() => {
                                      setShow(false);
                                      setModalShow(true);
                                    }}
                                  >
                                    Subscribers Only
                                  </button>
                                  )}
                                </p>
                              ) : (
                                <>
                                  <p>N/A, unverified</p>
                                </>
                              )}
                              {users?.subscribed === "daily" ||
                              users?.subscribed === "weekly" ||
                              users?.subscribed === "monthly" ? (
                                <>
                                <strong>Overall Quality Rating:
                                  <StarRatings
                                    rating={
                                      data?.propertyType?.toLowerCase() ===
                                      "shortlet"
                                        ? Number(data?.userRating)
                                        : data?.starRating
                                    }
                                    // rating={5}
                                    // changeRating={data?.starRating}
                                    numberOfStars={5}
                                    name="rating"
                                    color="purple"
                                    style={{ width: "300px" }}
                                    starRatedColor="yellow"
                                    starHoverColor="purple"
                                    starDimension="30px"
                                  />
                                </strong>
                                <p>Location:{data?.optimalLocationScore.toFixed(2)}</p>
                                <p>Architecture: {data?.architectureScore.toFixed(2)}</p>
                                <p>Security: {data?.securityScore.toFixed(2)}</p>
                                <p>Facilities: {data?.compoundFacilitiesScore.toFixed(2)}</p>
                                <p>Privacy/Landlord Relations: {data?.privacyLandlordRelationshipRating}</p>
                                <br />
                                <p><strong>Value For Money Percentile: {data?.valueForMoneyScore.toFixed(2)}</strong></p>
                                </>
                              ) : (
                                <></>
                              )}
                              <br />
                              <hr />
                            </div>
                          </section>
                          <section ref={section2} id="section-2">
                            <div className="p-4 projects">
                              <span className="text-muted fs-5">Overview</span>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>Property type:</p>
                                </div>
                                <div className="col-md-2">
                                  <p>{data?.propertyType}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>Property Description:</p>
                                </div>
                                <div className="col-md-2 mobile-text-size">
                                  <p>{data?.propertyDescription}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 col-sm-8 mobile-text-size">
                                  <p>No of Bedroom:</p>
                                </div>
                                <div className="col-md-2 col-sm-2 mobile-text-sizes">
                                  <p>{data?.numberOfBedrooms}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8 mobile-text-size">
                                  <p>No of Bathroom:</p>
                                </div>
                                <div className="col-md-2 mobile-text-sizes">
                                  <p>{data?.numberOfBathrooms}</p>
                                </div>
                              </div>
                              <hr />
                            </div>
                          </section>
                          <section ref={section3} id="section-3">
                            <div className="p-4 media">
                              <span className="text-muted fs-5">Features</span>
                              <br />
                              <div className="row">
                                <div className="col-md-6">
                                  <p>{data?.additionalFeatures}</p>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary overal-botton"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
              <div className="row"></div>
            </div>
          )}
        </ModalMain.Body>
      </ModalMain>

      <Dialog open={escrowDialogOpen} onClose={handleEscrowDialogClose}>
        <DialogTitle>ESCROW PAYMENT BREAKDOWN</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <List>
              <ListItem
                sx={{
                  display:
                    data?.propertyType === "rent" || "sale"
                      ? "content"
                      : "none",
                }}
              >
                <ListItemText primary="Listing Price" />
                <ListItemText
                  primary={data?.price.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>

              <ListItem
                sx={{
                  display:
                    data?.propertyType === "shortlet" ? "content" : "none",
                }}
              >
                <ListItemText primary="Price Per Night " />
                <ListItemText
                  primary={data?.price.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Agency Fee"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        5%
                      </Typography>{" "}
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary={data?.agencyFee?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Caution Fee" />
                <ListItemText
                  primary={cautionFee?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Legal Fee" />
                <ListItemText
                  primary={legalFee?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Total Cost" />
                <ListItemText
                  primary={totalCost?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary="Escrow Fee"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        1% | N15k Max
                      </Typography>
                      {""}
                    </React.Fragment>
                  }
                />
                <ListItemText
                  primary={escrowFee?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="Grand Total" />
                <ListItemText
                  primary={grandTotal?.toLocaleString("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  })}
                />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <Grid>
          <FormControl component="fieldset">
            <RadioGroup
              required
              aria-label="gender"
              name="gender11"
              value={values}
              onChange={handleChanges}
            >
              <FormControlLabel
                value="y"
                control={<Radio onClick={setDisabled} />}
                label={
                  <div>
                    <span>I agree to the </span>
                    <Link to={"/escrow-terms"}>
                      Escrow Terms and Conditions
                    </Link>
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <DialogActions>
          <Button
            disabled={!disabled}
            style={{ backgroundColor: "#800080", borderColor: "#800080" }}
            onClick={handleEscrowPaymentOpen}
          >
            Proceed to Escrow Payment
          </Button>
        </DialogActions>

        <div>
          <Dialog
            open={escrowPaymentOpen}
            onClose={handleEscrowPaymentClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                >
                  <Typography fontSize="6">KINDLY MAKE PAYMENT</Typography>
                  <Grid item xs>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="name"
                        label="Name"
                        variant="outlined"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <PaystackButton {...componentRatingsProps} />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEscrowPaymentClose}>CLOSE</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Dialog>

      {/*ratings payment*/}
      <Dialog
        open={ratingsPaymentOpen}
        onClose={handleRatingsPaymentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={0} direction="column" alignItems="center">
              <Typography fontSize="6">KINDLY MAKE PAYMENT</Typography>
              <Grid item xs>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="name"
                    label="Name"
                    required
                    variant="outlined"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="email"
                    label="Email"
                    required
                    variant="outlined"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    id="phone"
                    label="Phone"
                    required
                    variant="outlined"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Subscription Packages
                  </FormLabel>
                  <RadioGroup
                    required
                    aria-label="gender"
                    name="gender1"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="5000"
                      control={<Radio />}
                      label="N5,000 Daily"
                    />
                    <FormControlLabel
                      value="10000"
                      control={<Radio />}
                      label="N10,000 Weekly"
                    />
                    <FormControlLabel
                      value="15000"
                      label="N15,000 Monthly"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <br />
              {/*<Checkbox value="Terms & Conditions" color="secondary" />
                  <span>Agree to Chekam's<a href="/escrow-terms">Terms</a></span>*/}
              <br />
              <Grid item>
                {!user ? (
                  <Button onClick={(e) => handleSuscriber(e)}>Pay now</Button>
                ) : (
                  <PaystackButton {...componentProps} />
                )}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRatingsPaymentClose}>CLOSE</Button>
        </DialogActions>
      </Dialog>

      <SuscriberModal show={modalshow} setShow={setModalShow} />
    </>
  );
}

export default Modal;