import React from "react";
import "./styles/Buy.css";
import Navbar from "./Navbar";
import Select from "react-select";
import { Helmet } from "react-helmet";

function Help() {
  return (
    <>
      <Helmet>
        <title>Real Estate & Homes For Sale | Chekam</title>
      </Helmet>

      <Navbar />

      {/*      
        <section id="hero1" className="hero">
            <div className="inner">
            <div className="copy">
                <h1>Help !!!!!</h1>
                <div className="accordion container" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                    >
                        FAQS
                    </button>
                    </h2>
                    <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                    >
                    <div className="accordion-body">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hThree">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cThree"
                            aria-expanded="false"
                            aria-controls="cThree"
                            >
                            Why should i use Chekam / What is so different about
                            Chekam?
                            </button>
                        </h2>
                        <div
                            id="cThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="hThree"
                        >
                            <div className="accordion-body"> CIAO </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hffour">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cffour"
                            aria-expanded="false"
                            aria-controls="cffour"
                            >
                            How do i list a property ?
                            </button>
                        </h2>
                        <div
                            id="cffour"
                            className="accordion-collapse collapse"
                            aria-labelledby="hffour"
                        >
                            <div className="accordion-body">
                            There are different method you can use to list a
                            property... lorem ipsum{" "}
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hffive">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cffive"
                            aria-expanded="false"
                            aria-controls="cffive"
                            >
                            How do i use the algorithm to optimize my search?
                            </button>
                        </h2>
                        <div
                            id="cffive"
                            className="accordion-collapse collapse"
                            aria-labelledby="hffive"
                        >
                            <div className="accordion-body">
                            welcome here you can use the .... lorem ipsum davi..
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hfsix">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cfsix"
                            aria-expanded="false"
                            aria-controls="cfsix"
                            >
                            Why should i trust the agent on this site?
                            </button>
                        </h2>
                        <div
                            id="cfsix"
                            className="accordion-collapse collapse"
                            aria-labelledby="hfsix"
                        >
                            <div className="accordion-body">
                            Agent are tested and trusted you can use agent reviews
                            ... lorem ipsum
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hfseven">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cfseven"
                            aria-expanded="false"
                            aria-controls="cfseven"
                            >
                            How will i be sure that i will not be duped?
                            </button>
                        </h2>
                        <div
                            id="cfseven"
                            className="accordion-collapse collapse"
                            aria-labelledby="hfseven"
                        >
                            <div className="accordion-body">
                            hahaha... lorem ipsum davids....
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="heading10">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse10"
                        aria-expanded="false"
                        aria-controls="collapse10"
                    >
                        {" "}
                        Explainer video
                    </button>
                    </h2>
                    <div
                    id="collapse10"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading10"
                    data-bs-parent="#accordionExample"
                    >
                    <div className="accordion-body">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hThree">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cThree"
                            aria-expanded="false"
                            aria-controls="cThree"
                            >
                            How to search for the perfect property using our
                            algorithm
                            </button>
                        </h2>
                        <div
                            id="cThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="hThree"
                        >
                            <div className="accordion-body">
                            use the lorem ipsum restumtion
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hfour">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cfour"
                            aria-expanded="false"
                            aria-controls="cfour"
                            >
                            How to Sign Up
                            </button>
                        </h2>
                        <div
                            id="cfour"
                            className="accordion-collapse collapse"
                            aria-labelledby="hfour"
                        >
                            <div className="accordion-body">
                            there are different ways to sign up lorem ipsum blaaaa
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hfive">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#cfive"
                            aria-expanded="false"
                            aria-controls="cfive"
                            >
                            How to List a property
                            </button>
                        </h2>
                        <div
                            id="cfive"
                            className="accordion-collapse collapse"
                            aria-labelledby="hfive"
                        >
                            <div className="accordion-body">
                            there are different ways to list a property
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="hsix">
                            <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#csix"
                            aria-expanded="false"
                            aria-controls="csix"
                            >
                            How Manage Property / Onboard a tenant
                            </button>
                        </h2>
                        <div
                            id="csix"
                            className="accordion-collapse collapse"
                            aria-labelledby="hsix"
                        >
                            <div className="accordion-body">
                            there are different ways Manage Property / Onboard a
                            tenant
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingContact">
                    <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseContact"
                        aria-expanded="false"
                        aria-controls="collapseContact"
                    >
                        Contact Us
                    </button>
                    </h2>
                    <div
                    id="collapseContact"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingContact"
                    data-bs-parent="#accordionExample"
                    >
                    <div className="accordion-body">
                        contact us at +234 chekam.com
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
      */}
      <div className="" style={{ background: "purple", height: "100vh" }}>
        <h3
          className="text-white pt-4 pb-3 container "
          style={{ fontWeight: "bolder" }}
        >
          Help Menu
        </h3>
        <div
          className="accordion container "
          id="accordionExample"
          style={{ background: "purple" }}
        >
          <div className="accordion-item mb-3">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                FAQS
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cThree"
                      aria-expanded="false"
                      aria-controls="cThree"
                      style={{ fontWeight: "bolder" }}
                    >
                      Why should i use Chekam / What is so different about
                      Chekam?
                    </button>
                  </h2>
                  <div
                    id="cThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="hThree"
                  >
                    <div className="accordion-body">
                      <p>
                        Chekam.com allows you identify and focus your property
                        search on the best properties iin terms of quality and
                        value for money, thereby saving you time and money spent
                        in the traditional method of getting an agent/agents and
                        randomly searching and visiting properties.
                      </p>
                      <br />
                      <p>When you randomly search without Chekam.com:</p>
                      <ol>
                        <li>
                          You cannot be sure if you have found the best value
                          for your money, or the best quality on the market
                        </li>
                        <li>
                          You waste needless time before you find what is right
                          for you, if you are even lucky enough to find it
                        </li>
                        <li>
                          You waste money in "inspection" fees to see rubbish,
                          and "Agent contracting" fees
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hffour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cffour"
                      aria-expanded="false"
                      aria-controls="cffour"
                      style={{ fontWeight: "bolder" }}
                    >
                      How do i list a property ?
                    </button>
                  </h2>
                  <div
                    id="cffour"
                    className="accordion-collapse collapse"
                    aria-labelledby="hffour"
                  >
                    <div className="accordion-body">
                      <p>To list a property:</p>
                      <ul>
                        <li>
                          Click "list" on the home page or on the menu pane(top
                          right hand corner for Mobile users) or Navbar (Top
                          edge of screen for PC users)
                        </li>
                        <li>
                          Clicking "List" will take you to a sign-up page where
                          you may sign up as an "Agent" or "Agent-Property
                          Manager"
                        </li>
                        <li>
                          nce signed up, you will enter your agent dashboard,
                          where you will see the button titled "add property"
                          (See the explainer on how to add a property, to learn
                          how to fully fill the "Add property" form)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hffive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cffive"
                      aria-expanded="false"
                      aria-controls="cffive"
                      style={{ fontWeight: "bolder" }}
                    >
                      How do i use the algorithm to optimize my search?
                    </button>
                  </h2>
                  <div
                    id="cffive"
                    className="accordion-collapse collapse"
                    aria-labelledby="hffive"
                  >
                    <div className="accordion-body">
                      <p>
                        The algorithm is used through the "Sort by" function.
                        You simply click "sort by" button(when on mobile), or
                        the "sort by" drop down when on PC, and you will be able
                        to sort by Quality or Value-For-Money.
                      </p>
                      <br />
                      <p>
                        The algorithm also assigns a quality score and a value
                        for money score, which is visible to Subscribers when
                        they click on an item to view its details.
                      </p>
                      <p>To Subscribe:</p>
                      <strong>Step 1:</strong>&nbsp;
                      <span>
                        Sign Up as a User by clicking the "Sign-Up" on the Menu
                        pane (for mobile users), and on the top right corner of
                        the web page (for PC users).
                      </span>
                      <br />
                      <br />
                      <strong>Step 2:</strong>&nbsp;
                      <span>
                        Click on "dashboard" which will appear on the menu pane(
                        for mobile users), once you have signed up, or for PC
                        users, will appear below the avatar at the top right
                        hand corner of the site once the avatar is clicked.
                      </span>
                      <br />
                      <br />
                      <span>
                        Once within your dashboard, you can click "subscribe",
                        this will show you various affordable subscription
                        packages and after selecting and making payment, you
                        will have access to all the Intelligent features of the
                        site.
                      </span>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hfsix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cfsix"
                      aria-expanded="false"
                      style={{ fontWeight: "bolder" }}
                      aria-controls="cfsix"
                    >
                      Why should i trust the agent on this site?
                    </button>
                  </h2>
                  <div
                    id="cfsix"
                    className="accordion-collapse collapse"
                    aria-labelledby="hfsix"
                  >
                    <div className="accordion-body">
                      <p>
                        All Agents submit their personal and company information
                        when signing up and can be traced whenever necessary.
                      </p>
                      <p>
                        We also place properties that have been inspected by us
                        under the "Verified" tab, for further security.
                      </p>
                      <br />
                      <p>
                        You can be sure that any property you see is from a
                        registered agent and any verified property has been
                        personally inspected by Chekam.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hfseven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cfseven"
                      aria-expanded="false"
                      aria-controls="cfseven"
                      style={{ fontWeight: "bolder" }}
                    >
                      How will i be sure that i will not be scammed?
                    </button>
                  </h2>
                  <div
                    id="cfseven"
                    className="accordion-collapse collapse"
                    aria-labelledby="hfseven"
                  >
                    <div className="accordion-body">
                      <p>
                        Your security is our greatest priority, so we offer the
                        following protections:
                      </p>
                      <br />
                      <ul>
                        <li>
                          We allow you escrow your payment by paying to us, and
                          We only release the payment to the landlord and agent
                          when you have taken possession of the property.
                        </li>
                        <li>
                          We allow you chat with the agent directly on our
                          website so that we have all records of discussions and
                          can protect you in any case of a dispute
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item mb-3">
            <h2 className="accordion-header" id="heading10">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse10"
                aria-expanded="false"
                aria-controls="collapse10"
              >
                {" "}
                Explainer video
              </button>
            </h2>
            <div
              id="collapse10"
              className="accordion-collapse collapse"
              aria-labelledby="heading10"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cThree"
                      aria-expanded="false"
                      aria-controls="cThree"
                      style={{ fontWeight: "bolder" }}
                    >
                      How to search for the perfect property using our algorithm
                    </button>
                  </h2>
                  <div
                    id="cThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="hThree"
                  >
                    <div className="accordion-body">
                      <iframe
                        src="https://drive.google.com/file/d/1pai2kBG9EIGx9_bIhS_BvCoR_uAAaB9N/preview"
                        // width="640"
                        // height="480"
                        allow="autoplay"
                        title="How to use our algorithm"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hfour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cfour"
                      aria-expanded="false"
                      aria-controls="cfour"
                      style={{ fontWeight: "bolder" }}
                    >
                      How to Sign Up
                    </button>
                  </h2>
                  <div
                    id="cfour"
                    className="accordion-collapse collapse"
                    aria-labelledby="hfour"
                  >
                    <div className="accordion-body">....</div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hfive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#cfive"
                      aria-expanded="false"
                      aria-controls="cfive"
                      style={{ fontWeight: "bolder" }}
                    >
                      How to List a property
                    </button>
                  </h2>
                  <div
                    id="cfive"
                    className="accordion-collapse collapse"
                    aria-labelledby="hfive"
                  >
                    <div className="accordion-body">
                      <iframe
                        // width="420"
                        // height="345"
                        src="https://www.youtube.com/embed/O4VJAW0LreY"
                        title="how to list a property"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="hsix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#csix"
                      aria-expanded="false"
                      aria-controls="csix"
                      style={{ fontWeight: "bolder" }}
                    >
                      How Manage Property / Onboard a tenant
                    </button>
                  </h2>
                  <div
                    id="csix"
                    className="accordion-collapse collapse"
                    aria-labelledby="hsix"
                  >
                    <div className="accordion-body">...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingContact">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseContact"
                aria-expanded="false"
                aria-controls="collapseContact"
              >
                Contact Us
              </button>
            </h2>
            <div
              id="collapseContact"
              className="accordion-collapse collapse"
              aria-labelledby="headingContact"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="d-flex center">
                  <span
                    className="fa fa-phone pl-4 fa-lg"
                    style={{ paddingRight: "30px" }}
                  ></span>
                  <span style={{ fontWeight: "bolder" }}>08066459904</span>
                </div>
                <div className="d-flex center">
                  <span
                    className="fa fa-whatsapp pl-4 fa-lg"
                    style={{
                      paddingRight: "30px",
                      color: "green",
                    }}
                  ></span>
                  <span style={{ fontWeight: "bolder" }}>08066459904</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Help;
