import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import background from "../images/c.png";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import PasswordResetModal from "./PasswordResetModal";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { Spinner } from "react-bootstrap";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import db from "./Firebase";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      fontFamily="Raleway"
    >
      {"Copyright © "}
      <Link color="rgb(12,12,12)" to="/">
        chekam
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const [users, setUser] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const redirect = location?.state?.redirect;
  const redirectUrl = location?.state?.redirectUrl;
  const [err, setErr] = useState(null);
  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const navigate = useNavigate();
  const [to, setTo] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setErr(false);
    setLoading(true);
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    const password = data.get("password");
    const email = data.get("email");
    if (email === "" && password === "") {
      setLoading(false);
      setErr("Please fill in all fields");
    } else if (!email) {
      setLoading(false);
      setErr("Email is required");
    } else if (!password) {
      setLoading(false);
      setErr("Password is required");
    } else {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          
          // Signed in
          const user = userCredential.user;
          async function getSingleAgent() {
            const docRef = doc(db, "users", user?.uid);
            const docSnap = await getDoc(docRef);
            setLoading(false);
            setUser(docSnap.data());

            const main = docSnap.data();

            if (main?.status === "agent") {
              setTo("/admin/agent");
            } else if (main?.status === "agent and property manager") {
              setTo("/admin/agent");
            } else if (main?.status === "property manager") {
              setTo("/admin/propertymanager/managerhomepage");
            } else if (main?.status === "admin") {
              setTo("/admin/admin");
            } else if (main?.status === "das") {
              setTo("/admin/DAS/Dashboard");
              
            } else {
              setTo("/login#");
            }
          }
          getSingleAgent();
          localStorage.setItem("user", JSON.stringify(user));
        })
        .then(() => {
          if (redirect) {
            navigate(redirectUrl);
          } else {
            // navigate("/");
            setLoading(false);
            setMessage("Login successful");

            setTimeout(() => {
              setMessage2("Please wait while we redirect you");
            }, 2000);
            setTimeout(() => {
              setReady(true);
            }, 3000);
          }
        })
        .catch((error) => {
          setLoading(false);

          setErr("Inccorect credentials");
        });

      const handlePasswordReset = () => {
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            // ..
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(error.message);
            // ..
          });
      };
    }
  };

  useEffect(() => {
    if (ready) {
      setLoading(true);
      setTimeout(() => {
        return (window.location.href = to);
      }, 1000);
    }
  }, [ready]);
  return (
    <ThemeProvider theme={theme}>
      {/*  
    <Helmet>
      <title>Login | Chekam</title>
    </Helmet>
   */}
      <Navbar />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        {loading && (
          <Dialog
            style={{ overflow: "hidden !important" }}
            open={loading === true}
            onClose={!loading}
          >
            <CircularProgress color="secondary" className="m-5" />
          </Dialog>
        )}
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontFamily="Raleway">
              Log in
            </Typography>
            {err && (
              <Snackbar
                open={err}
                autoHideDuration={20000}
                onClose={() => setErr(null)}
              >
                <Alert
                  onClose={() => setErr(null)}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {err}
                </Alert>
              </Snackbar>
            )}
            {message && (
              <Snackbar
                open={message}
                autoHideDuration={20000}
                onClose={() => {
                  setMessage2(null);
                  setMessage(null);
                }}
              >
                <Alert
                  onClose={() => {
                    setMessage2(null);
                    setMessage(null);
                  }}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  {message} <br />
                  {message2}
                </Alert>
              </Snackbar>
            )}
            <Box
              component="form"
              onSubmit={(e) => handleSubmit(e)}
              sx={{ mt: 1 }}
            >
              <TextField
                sx={{ "& label": { mt: "0" } }}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                color="secondary"
              />

              <TextField
                sx={{ "& label": { mt: "0" } }}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                color="secondary"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="secondary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "secondary.main" }}
              >
                <Typography sx={{ fontFamily: "Raleway" }}>Log In </Typography>
              </Button>
              <Grid container>
                <Grid item xs>
                  <PasswordResetModal />
                </Grid>
                <Grid item>
                  <Button>
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#800080",
                        fontSize: "1.1rem",
                        textTransform: "capitalize",
                      }}
                      to="/Signup"
                    >
                      Don't have an account? Sign Up
                    </Link>
                  </Button>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
