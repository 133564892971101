import { useState } from 'react'
import Navbar from './Navbar';
import Footer from './Footer'
import './styles/Sell.css'
import RightImg from '../images/sale 1.png'
import {questions} from '../data'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Sell = () => {


const [allQuestions, setAllQuestions] = useState(questions)
    const [showInfo, setShowInfo] = useState(false)
    const displayInfo = () => {
        setShowInfo(!showInfo)
    }


    return (
        <>
        {/*
        <Helmet>
            <title>Real Estate & Homes For Rent | Chekam</title>
        </Helmet>
    
        */}
            <Navbar />

<nav id="navbar-example2" class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="#">Navbar</a>
  <ul class="nav nav-pills">
    <li class="nav-item">
      <a class="nav-link" href="#fat">@fat</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#mdo">@mdo</a>
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
      <div class="dropdown-menu">
        <a class="dropdown-item" href="#one">one</a>
        <a class="dropdown-item" href="#two">two</a>
        <div role="separator" class="dropdown-divider"></div>
        <a class="dropdown-item" href="#three">three</a>
      </div>
    </li>
  </ul>
</nav>
<div class="scrollspy-example" data-spy="scroll" data-target="#navbar-example2" data-offset="50">
  <h4 id="fat">@fat</h4>
  <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente 
  synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't 
  heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before 
  they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur 
  keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. 
  VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
  <h4 id="mdo">@mdo</h4>
  <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente 
  synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't 
  heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before 
  they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur 
  keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. 
  VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
  <h4 id="one">one</h4>
  <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente 
  synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't 
  heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before 
  they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur 
  keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. 
  VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
  <h4 id="two">two</h4>
  <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente 
  synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't 
  heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before 
  they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur 
  keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. 
  VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
  <h4 id="three">three</h4>
  <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente 
  synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't 
  heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before 
  they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur 
  keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. 
  VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
</div>

            <section className='sell-section'>
                

                <div className='sell-property'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='sell-property-card-top'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 card-left'>
                                            <div className='sell-property-text'>
                                                <h3>Sell Your Property With an Agent</h3>
                                                <p>When you work with a real estate agent, you'll get selling support at every step, 
                                                    from prepping and listing your home to marketing that gets buyers in the door.</p>
                                                <Link to="/find-agent"><button>Find an agent</button></Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 card-right'>
                                            <img src={RightImg} alt="" class="image3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='sell-property'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='sell-property-card'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12 card-left'>
                                            <div className='sell-property-text'>
                                                <h3>Sell Your Property yourself</h3>
                                                <p>Deciding to sell your home yourself is referred to as for-sale-by-owner (FSBO). 
                                                    The FSBO process is similar to traditional selling, but without the help of a real estate agent.
                                                    In this case, you’re responsible for the home prep, marketing, showings, and negotiations..</p>
                                                <Link to="/find-agent"><button>Make a Post</button></Link>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-12 card-right'>
                                            <img src={RightImg} alt="" class="image3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='frequently-asked-questions'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='faq-card'>
                                {allQuestions.map((question, index) => {
                                    return <article key={index}>
                                        <header>
                                            <button className='question-title-button' onClick={(id) => displayInfo(id)}>
                                                <p className='question-title'>{question.title}</p>
                                                <button className='plus-icon' >{showInfo ? "-" : "+"}</button>
                                            </button>
                                        </header>
                                        {showInfo && <p className='question-body'>{question.info}</p>}
                                    </article>
                                })}
                                




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

            </section>
            <Footer />
        </>
    )
}

export default Sell