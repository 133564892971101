import React, { useState, useEffect } from "react";
import { storage } from "../../../Firebase";
import { UserContext } from "../../../../App";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import db from "../../../Firebase";
import { doc,getDoc,updateDoc,FieldValue,arrayUnion } from "firebase/firestore";

function EditProfile({ setLoading, loading, setErr, setMessage }) {
  const user = React.useContext(UserContext);
  const [data, setData] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [photoURL, setPhotoURL] = useState( "https://cdn-icons-png.flaticon.com/512/747/747376.png");
  // const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [email, setEmail] = useState("");
  const handleImageChange = (e) => {if (e.target.files[0]) {setPhoto(e.target.files[0]); }};
  const handleFullNameChange = (e) => {setFullName(e.target.value)};
  const handlePhoneChange = (e) => {setPhone(e.target.value)};
  const handleClick = () => {upload(photo, user, setLoading)};
  const handleSaveChanges = (e) => {e.preventDefault();setLoading(true);

  const examcollref = doc(db, "users", user?.uid);
    updateDoc(examcollref, {
      email: email,
      name: fullName,
      phone: phone,
      about: about,
      address: address,
    })
      .then(() => {
        updateProfile(user, {
          displayName: fullName,
          phoneNumber: phone,
          email: email,
        })
          .then(() => {
            setLoading(false);
            setMessage("Profile updated successfully");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setErr("Opps an error occured");
      });
    // updateProfile(user, { displayName: fullName, phoneNumber: phone })
    //   .then(() => {
    //     setLoading(false);
    //     console.log("user has been updated");
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    //console.log(photoURL)
    if (user?.photoURL) {
      setPhotoURL(user.photoURL);
    } else {
      setPhotoURL(photoURL);
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    async function getSingleAgent() {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());
      let datas = docSnap.data();

      setFullName(datas?.name);
      setPhone(datas?.phone);
      setAbout(datas?.about);
      setAddress(datas?.address);
      setEmail(datas?.email);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  }, [user]);

  const handleUpdate = () => {};

  async function upload(file, user, setLoading) {
    const fileRef = ref(storage, user.uid + ".png");

    setLoading(true);
    const snapShot = await uploadBytes(fileRef, file);
    const profileURL = await getDownloadURL(fileRef);
    updateProfile(user, { photoURL: profileURL });
    setLoading(false);
    alert("file uploaded");
  }

  return (
    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
      {/* <!-- Profile Edit Form --> */}
      <form onSubmit={handleSaveChanges}>
        <div className="row mb-3">
          <label
            for="profileImage"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Profile Image
          </label>
          <div className="col-md-8 col-lg-9">
            <img
              src={photoURL}
              alt="No image Uploaded"
              className=""
              style={{
                borderRadius: "100%",
                height: "50px",
                width: "50px",
              }}
            />
            <div className="pt-2">
              <input
                type="file"
                name="image"
                className="btn btn-primary btn-sm"
                onChange={handleImageChange}
              />
              <button disabled={loading || !photo} onClick={handleClick}>
                UPLOAD
              </button>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <label for="fullName" className="col-md-4 col-lg-3 col-form-label">
            full Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="fullname"
              type="text"
              className="form-control"
              id="fullName"
              value={fullName}
              onChange={handleFullNameChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label for="about" className="col-md-4 col-lg-3 col-form-label">
            About
          </label>
          <div className="col-md-8 col-lg-9">
            <textarea
              name="about"
              className="form-control"
              id="about"
              style={{ height: "100px" }}
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="row mb-3">
          <label for="Job" className="col-md-4 col-lg-3 col-form-label">
            Position
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="position"
              type="text"
              className="form-control"
              id="Job"
              value="Agent"
            />
          </div>
        </div>

        <div className="row mb-3">
          <label for="Country" className="col-md-4 col-lg-3 col-form-label">
            Country
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="country"
              type="text"
              className="form-control"
              id="Country"
              value="Nigeria"
            />
          </div>
        </div>

        <div className="row mb-3">
          <label for="Address" className="col-md-4 col-lg-3 col-form-label">
            Address
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="address"
              type="text"
              className="form-control"
              id="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label for="Phone" className="col-md-4 col-lg-3 col-form-label">
            Phone
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="phone"
              type="text"
              className="form-control"
              id="Phone"
              value={phone}
              onChange={handlePhoneChange}
            />
          </div>
        </div>

        <div className="row mb-3">
          <label for="Email" className="col-md-4 col-lg-3 col-form-label">
            Email
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="email"
              type="email"
              className="form-control"
              id="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>
      </form>
      {/* <!-- End Profile Edit Form --> */}
    </div>
  );
}

export default EditProfile;
