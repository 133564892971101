import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Grid } from '@mui/material';
import Box from "@mui/material/Box";
import { getDocs, collection, query, where } from "firebase/firestore";
import db from "../../../components/Firebase";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



const Properties = () => {
  //const classes = useStyles();
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filter, setFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {

    const fetchData = async () => {
      try {
        const data = await getDocs(collection(db, "properties"));
        const fetchedProperties = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProperties(fetchedProperties);
        setFilteredProperties(fetchedProperties);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    fetchData();
  }, []);

  useEffect(() => {
    filterProperties();
  }, [filter, properties]);

  const filterProperties = () => {
    if (filter === '') {
      setFilteredProperties(properties);
    } else {
      const filtered = properties.filter((property) => property.propertyPurpose === filter);
      setFilteredProperties(filtered);
      console.log('world',filtered)
    }
    
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const filtered = properties.filter((property) =>
      property.productId.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredProperties(filtered);
  };

  
  return (
    <Box sx={{ mt: 5, mx: 5 }} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="Filter by Property Type"
            value={filter}
            onChange={handleFilterChange}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="sale">Sale</MenuItem>
            <MenuItem value="rent">Rent</MenuItem>
            <MenuItem value="shortLet">Short Let</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Search by Product ID"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        {filteredProperties.map((property) => (
          <Box sx={{ minWidth: 275, p:2}}>
            <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Product ID: {property.productId}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Property Purpose: {property.propertyPurpose}
                  </Typography>
                  <Typography variant="body2">
                    Architecture Score: {property.architectureScore}
                  </Typography>
                  <Typography variant="body2">
                    Security Score: {property.securityScore}
                  </Typography>
                  <Typography variant="body2">
                    Privacyand Landlord Relationship Score: {property.privacyLandlordRelationshipScore}
                  </Typography>
                  <Typography variant="body2">
                    Compound Facilities Score: {property.compoundFacilitiesScore}
                  </Typography>
                  <Typography variant="body2">
                    Optimal Location Score: {property.optimalLocationScore}
                  </Typography>
                  <Typography variant="body2">
                    estateFactorScore: {property.estateFactorScore}
                  </Typography>
                  <Typography variant="body2">
                    overallScore: {property.overallScore}
                  </Typography>
                  <Typography variant="body2">
                    valueForMoneyScore: {property.valueForMoneyScore}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Learn More</Button>
                </CardActions>
              </React.Fragment>
            </Card>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default Properties;