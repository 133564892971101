import ScrollSpyTabs from "./ScrollSpyTabs";
import React, { useState, useEffect } from "react";
import "./styles/selected.css";
import Divider from "@mui/material/Divider";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import ModalMain from "react-bootstrap/Modal";
import { Button, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import Navbar from "./Navbar";
import firebase from "firebase/app";
import { UserContext } from "../App";
import Scrollspy from "react-scrollspy";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
// import $ from 'jquery';

import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
// import $ from "jquery";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import logo from "../images/chekamlogo.jpeg";
import PropTypes from "prop-types";
import { PaystackButton } from "react-paystack";
import { RiContactsBookLine } from "react-icons/ri";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import Grid from "@mui/material/Grid";

import ScrollspyNav from "react-scrollspy-nav";

function Modal({ id, show, setShow }) {
  let listingPrice,
    agencyFee,
    legalFee = 0,
    cautionFee = 0;
  // scrollspy js

  const [escrowDialogOpen, setEscrowDialogOpen] = React.useState(false);
  const [escrowPaymentOpen, setEscrowPaymentOpen] = React.useState(false);
  const [totalcost, setTotalCost] = React.useState(0);
  const [escrowfee, setEscrowFee] = React.useState(0);
  const [grandtotal, setGrandTotal] = React.useState(0);

  const navigate = useNavigate();
  const user = React.useContext(UserContext);
  const [progress, setProgress] = useState(false);
  const [share, setShare] = useState(false);
  const [data, setData] = useState(null);
  const [disappear, setDisappear] = useState(
    data?.userRated?.includes(user?.uid) ? "d-none" : ""
  );
  const [activeLink, setActiveLink] = useState("");
  const [ratings, setRating] = useState(1);
  const [rate, setRate] = useState(false);
  const [close, setCloseModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [cast, setCast] = useState(null);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const closeModal = () => {
    setCloseModal(!close);
  };
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  function sanitise(x) {
    if (isNaN(x)) {
      return 0;
    }
    return parseInt(x);
  }

  listingPrice = data?.price;
  agencyFee = data?.agencyFee;
  cautionFee = sanitise(data?.cautionFee);
  legalFee = sanitise(data?.legalFee);

  const totalCost = listingPrice + agencyFee + cautionFee + legalFee;
  // const escrowFee = Math.min((1 / 100) * cautionFee + legalFee);

  const calculate = (1 / 100) * (15 / totalCost);
  console.log(calculate);

  const escrowFee = calculate > 15000 ? 15000 : calculate;
  const grandTotal = totalCost + escrowFee;

  console.log(escrowFee, "excro fee");

  console.log(listingPrice);
  console.log(agencyFee);
  console.log(cautionFee);
  console.log(legalFee);
  const handleEscrowDialogOpen = () => {
    function sanitise(x) {
      if (isNaN(x)) {
        return 0;
      }
      return parseInt(x);
    }
    setShow(false);
    setEscrowDialogOpen(true);

    // listingPrice = data?.price;
    // agencyFee = data?.agencyFee;
    // cautionFee = sanitise(data.cautionFee);
    // legalFee = sanitise(data.legalFee);

    // const totalCost = listingPrice + agencyFee + cautionFee + legalFee;
    // const escrowFee = Math.min((1 / 100) * cautionFee + legalFee);

    // setTotalCost(listingPrice + agencyFee + cautionFee + legalFee);
    // setEscrowFee(Math.min((1 / 100) * totalCost, 15000));
    //setCautionFee(sanitise(data.cautionFee));
    //setLegalFee(sanitise(data.legalFee));

    //setTotalCost(listingPrice+agencyFee+cautionFee+legalFee)

    /*const escrowFeeCalculation = () => {
      const percentage = 1/100*(totalCost)
      const max = 15000
    
      setEscrowFee(Math.min(percentage,max))
    }*/

    //escrowFeeCalculation();

    // setGrandTotal(totalCost + escrowFee);
  };

  const handleEscrowDialogClose = () => {
    setEscrowDialogOpen(false);
  };

  const handleEscrowPaymentOpen = () => {
    setEscrowPaymentOpen(true);
  };

  const handleEscrowPaymentClose = () => {
    setEscrowPaymentOpen(false);
  };
  //console.log(id);
  useEffect(() => {
    setRate(false);
    setShare(false);
    setRating(1);
    setLoading(true);
    async function getPlaces() {
      const docRef = doc(db, "properties", id);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());

      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getPlaces();
    // setLoading(false);
  }, [id]);

  // //console.log(data)

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  let naira = "\u20A6";

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";
  const amount = grandTotal * 100;

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      alert("Payment Successful! close and continue");
    },

    onClose: () => alert("closed"),
  };
  useEffect(() => {
    // $("body").scrollspy("refresh");
    // document.querySelector(".modal-body").
    // $('[data-spy="scroll"]').each(function () {
    //   $(this).scrollspy("refresh");
    // });
    setCast(null);

    setCast(ratings);
    // setCast()
  }, [ratings]);

  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     const observable = {
  //       home: document.querySelector(".home"),
  //       projects: document.querySelector(".projects"),
  //       media: document.querySelector(".media"),
  //     };
  //     const pos = {
  //       home: observable.home.getBoundingClientRect(),
  //       projects: observable.projects.getBoundingClientRect(),
  //       media: observable.media.getBoundingClientRect(),
  //     };

  //     //console.log(observable);
  //     //console.log(pos);

  //     if (pos.home.top < window.innerHeight && pos.home.bottom >= 0) {
  //       setActiveLink("home");
  //     } else if (
  //       pos.projects.top < window.innerHeight &&
  //       pos.projects.bottom >= 0
  //     ) {
  //       setActiveLink("projects");
  //     } else if (pos.media.top < window.innerHeight && pos.media.bottom >= 0) {
  //       setActiveLink("media");
  //     }
  //   });

  //   // return () => {

  //   // }
  // }, []);

  const handleRating = (e) => {
    e.preventDefault();
  };

  const handleClose = () => setShow(false);

  const shareLink = `https://main-chekam.herokuapp.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`;
  console.log(shareLink);
  const userRated = ["1", "2", "3", "7"];

  const calculus = (0 + cast) / data?.userRated?.length;

  const handleRate = (e) => {
    setProgress(true);
    setLoading(true);
    e.preventDefault();
    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: "/selected-item?q=" + id + "&m=main",
        },
      });
    } else {
      const examcollref = doc(db, "properties", id);
      updateDoc(examcollref, {
        cast: data?.cast + cast,
        userRated: arrayUnion(user?.uid),
        // userRated: arrayUnion("davidsonjose"),
        userRating: `${(data?.cast + cast) / data?.userRated?.length}`,
      })
        .then((response) => {
          // alert("Successfully rated the property");
          setMessage("Thanks for rating.... 🎉🎉🎉🎉");
          setProgress(false);
          setLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  // console.log(cast);

  const places = [];

  useEffect(() => {
    async function getPlaces() {
      // setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(propertiesRef, where("propertyPurpose", "==", "sale"));
      const array = [];
      getDocs(q).then((data) => {
        data?.forEach((doc) => {
          places.push(doc.data());

          // setDetails([...places]);
          // setProducts([...places]);
          // setLocation([...places]);
          // setOthers([...places]);

          places?.forEach((data) => {
            // console.log(data, "properties")
            const examcollref = doc(db, "properties", data?.id);
            updateDoc(examcollref, {
              userRated: [],
              cast: null,
            })
              .then((response) => {
                alert("updated");
              })
              .catch((error) => {
                console.log(error.message);
              });
          });
        });
        setLoading(false);
      });
    }
    getPlaces();
  }, []);

  // console.log(data, "single property");

  // const handleSuscriber = (e) => {
  //   e.preventDefault();

  //   const examcollref = doc(db, "properties", id);
  //   updateDoc(examcollref, {
  //     sponsored: true,
  //   })
  //     .then((response) => {
  //       // alert("Successfully rated the property");
  //       setMessage("Thanks for rating.... 🎉🎉🎉🎉");
  //       setProgress(false);
  //       setLoading(false);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  //   // if (!user) {
  //   //   navigate("/login");
  //   // } else {
  //   //   navigate("/user/suscribe");
  //   // }
  // };

  // useEffect(() => {
  // const examcollref = doc(db, "properties", id);
  // updateDoc(examcollref, {
  //   sponsored: true
  // })
  //   .then((response) => {
  //     // alert("Successfully rated the property");
  //     setMessage("Thanks for rating.... 🎉🎉🎉🎉")
  //     setProgress(false)
  //     setLoading(false)

  //     setTimeout(() => {
  //       window.location.reload()
  //     }, 2000)
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });
  // }, [])

  const handleSuscriber = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login");
    } else {
      navigate("/user/suscribe");
    }
  };

  const handleAgentInfo = (id, e) => {
    // setId(agentid);

    async function getSingleAgent() {
      const docRef = doc(db, "users", id?.agentId);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();
      return (window.location.href = `/properties/${id?.agentName}?n=${id?.agentId}&company-name=${agentData?.company}`);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  };

  return (
    <>
      <ModalMain
        className="main-modal"
        style={{ padding: "0" }}
        show={show}
        onHide={handleClose}
      >
        <ModalMain.Body className="" style={{ padding: "0" }}>
          {loading ? (
            <Dialog open={loading === true} onClose={!loading}>
              <CircularProgress color="secondary" className="m-5" />
            </Dialog>
          ) : (
            <div className="container pt-5">
              <div className="mobile-modal" style={{ height: "300px" }}>
                <Navbar />

                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={
                        data?.images?.length === undefined
                          ? ""
                          : data?.images[0]
                      }
                      style={{ width: "450px", height: "350px" }}
                      alt=""
                      className="img-fluid modal-image"
                    />
                  </SwiperSlide>
                  {data?.images?.slice(1).map((link) => {
                    return (
                      <SwiperSlide>
                        <>
                          <img
                            src={link}
                            className="img-fluid selected-img"
                            style={{ width: "374px", height: "350px" }}
                            alt=""
                          />
                        </>
                      </SwiperSlide>
                    );
                  })}
                  <div className="main-card-single col-md-6" id="example">
                    <span onClick={() => setShow(!show)}>
                      <i
                        class="fa fa-arrow-left text-white p-3 bg-warning rounded-pill"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>
                  {/* <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
                </Swiper>
              </div>

              <div className="row pb-1" style={{ padding: "0" }}>
                <div className="col-md-7 respond-me" style={{ padding: "0" }}>
                  <div
                    className="card home-single"
                    style={{ height: "800px", overflowY: "scroll" }}
                  >
                    <img
                      src={
                        data?.images?.length === undefined
                          ? ""
                          : data?.images[0]
                      }
                      style={{ width: "750px", height: "700px" }}
                      alt=""
                      className="img-fluid modal-image hide-modal-image"
                    />
                    <div className="respond-me mt-1">
                      <div className="row">
                        {data?.images?.slice(1).map((link) => {
                          return (
                            <div className="col-md-6 pb-2 image-responsive-selected respond-me">
                              <img
                                src={link}
                                className="img-fluid selected-img hide-selected-img"
                                style={{ width: "374px", height: "350px" }}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="card-footer text-center home-single-footer">
                      <h5 className="text-white">
                        <strong>Interested in touring this home?</strong>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 respond-me">
                  <div className="card-header row">
                    <div className="col-md-6">
                      <img src={logo} style={{ width: "150px" }} alt="" />
                    </div>

                    <div className="main-card-single col-md-6">
                      <span className="modal-icon">
                        <i
                          class="fa fa-heart-o top-icon"
                          aria-hidden="true"
                        ></i>
                        <span>Save</span>
                      </span>

                      <span
                        className="modal-icon"
                        onClick={() => setShare(!share)}
                      >
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        &nbsp;
                        <span>Share</span>
                      </span>

                      {/* <span className="modal-icons">
                        <i class="fa fa-bars" aria-hidden="true"></i> &nbsp;
                        <span>more</span>
                      </span> */}
                    </div>
                  </div>
                  <br />
                  {/* <div className="davidson">
                    <span className="">
                      <span className="modal-icon">
                        <i
                          class="fa fa-heart-o top-icon"
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                        <span>Save</span>
                      </span>

                      <span
                        className="modal-icon"
                        onClick={() => setShare(!share)}
                      >
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                        &nbsp;
                        <span>Share</span>
                      </span>

                      <span
                        className="modal-icons"
                        style={{ cursor: "pointer" }}
                      >
                        <i class="fa fa-bars" aria-hidden="true"></i>&nbsp;
                        <span>more</span>
                      </span>
                    </span>
                  </div> */}
                  <div className="pb-3 hide-share-mobile">
                    {share && (
                      <>
                        {/* <img src={data?.images[0]} alt="Property Image" />*/}
                        <FacebookShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                          quote={`2bdrm Apartment `}
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <EmailShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                        <TelegramShareButton
                          url={shareLink}
                          color="red"
                          size={32}
                          round={true}
                        >
                          <TelegramIcon size={32} round={true} />
                        </TelegramShareButton>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <h2 style={{ fontWeight: "bold", color: "black" }}>
                      {naira}
                      {data?.price?.toLocaleString()}
                    </h2>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBedrooms}
                      </span>
                      bd
                    </span>
                    <span style={{ paddingLeft: "10px" }}>|</span>
                    <span style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "bolder", color: "black" }}>
                        {data?.numberOfBathrooms}
                      </span>
                      ba
                    </span>
                    {/* <span style={{paddingLeft: "10px"}}>|</span> */}
                    {/* <span style={{paddingLeft: "10px"}}><span style={{fontWeight: "bolder", color: "black"}}>1</span> bd</span> */}
                  </div>
                  <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                    <span>
                      {data?.district}, {data?.streetName}
                    </span>
                    <br />
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      <i
                        class="fa fa-circle sale-icon text-danger"
                        aria-hidden="true"
                      ></i>
                      For {data?.propertyPurpose} | &nbsp; {naira}
                      {data?.price?.toLocaleString()} &nbsp;
                      <a
                        onClick={() =>
                          openInNewTab(
                            `https://main-chekam.herokuapp.com/selected-item?q=${id}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`
                          )
                        }
                        style={{ cursor: "pointer" }}
                        className="text-warning veiw-details"
                      >
                        view details
                      </a>
                    </span>
                    <span
                      className="modal-icon hide-share"
                      onClick={() => setShare(!share)}
                    >
                      <i class="fa fa-share-alt" aria-hidden="true"></i>
                      &nbsp;
                      <span>Share</span>
                    </span>
                    <br />
                    <div className="pb-3 show-share-mobile">
                      {share && (
                        <>
                          <FacebookShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <FacebookIcon size={32} round={true} />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <WhatsappIcon size={32} round={true} />
                          </WhatsappShareButton>
                          <TwitterShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <TwitterIcon size={32} round={true} />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <LinkedinIcon size={32} round={true} />
                          </LinkedinShareButton>
                          <EmailShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <EmailIcon size={32} round={true} />
                          </EmailShareButton>
                          <TelegramShareButton
                            url={shareLink}
                            color="red"
                            size={32}
                            round={true}
                          >
                            <TelegramIcon size={32} round={true} />
                          </TelegramShareButton>
                        </>
                      )}
                    </div>
                    {/* <span>
                  Est Payment: $1,200/mo
                  <span className="bg-warning dollar-symbol">$</span>
                </span>
                <span>Get pre-qualified</span> */}
                  </div>
                  <br />
                  <div className="row">
                    {message && (
                      <strong className="alert alert-success text-center">
                        {message}
                      </strong>
                    )}
                    <div className="respond-me">
                      <div className="">
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          onClick={toggleVisibility}
                          style={{
                            width: "50%",
                            backgroundColor: "purple",
                            borderColor: "purple",
                            marginRight: "2%",
                          }}
                        >
                          Contact Agent
                        </button>
                        <button
                          onClick={handleEscrowDialogOpen}
                          className="btn btn-outline-warning selected-agent-btn"
                          style={{
                            width: "45%",
                            color: "purple",
                            backgroundColor: "transparent",
                            borderColor: "purple",
                          }}
                        >
                          Escrow pay
                        </button>
                      </div>
                      {isVisible && (
                        <div className="p-4">
                          Email: {data?.agentEmail} <br />
                          Contact: {data?.agentPhone}
                          {/* name: Mr Wasiu <br /> Contact: 08099933304 */}
                        </div>
                      )}
                      {data?.propertyType?.toLowerCase() === "shortlet" && (
                        <button
                          className={`${
                            data?.userRated?.includes(user?.uid) ? "d-none" : ""
                          } btn btn-warning selected-agent-btn text-white`}
                          onClick={() => setRate(!rate)}
                          style={{
                            width: "45%",
                            // backgroundColor: "purple",
                            // borderColor: "purple !important",
                          }}
                        >
                          Rate Property
                        </button>
                      )}
                      {data?.propertyPurpose?.toLowerCase() === "shortlet" && (
                        <>
                          {rate && (
                            <div
                              style={{
                                width: "100% !important",
                                paddingTop: "20px",
                              }}
                              // className={`${disappear}`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "end",
                                }}
                              >
                                <StarRatings
                                  rating={ratings}
                                  changeRating={setRating}
                                  numberOfStars={5}
                                  name="rating"
                                  color="purple"
                                  style={{
                                    width: "300px",
                                  }}
                                  starRatedColor="yellow"
                                  starHoverColor="purple"
                                  starDimension="30px"
                                />
                              </div>
                              <button
                                className="d-flex justify-content-center text-white mt-3 rounded-pill text-center mx-auto"
                                style={{
                                  background: `${
                                    progress === true ? "gray" : "purple"
                                  }`,
                                  width: "50%",
                                  padding: "5px",
                                  border: "none !important",
                                  opacity: `${progress === true ? "0.8" : ""}`,
                                }}
                                onClick={(e) => handleRate(e)}
                                disabled={progress === true}
                              >
                                Submit Ratings
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="pt-4 pr-3">
                      <strong>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={(e) => handleAgentInfo(data, e)}
                        >
                          {data?.agentName}
                        </a>
                      </strong>
                    </div>
                  </div>
                  <hr />

                  {/* test scrollspy */}

                  <nav
                    id="navbar-example2"
                    class="navbar px-3 justify-content-center"
                  >
                    <ul className="nav nav-pills under-ul">
                      <li className="nav-item">
                        <a className="nav-link" href="#scrollspyHeading1">
                          Ratings
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#scrollspyHeading2">
                          Overview
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#scrollspyHeading3">
                          Features
                        </a>
                      </li>
                    </ul>
                  </nav>

                  {/* new */}

                  <div
                    // data-bs-spy="scroll"
                    // data-bs-target="#navbar-example2"
                    // data-bs-offset="0"
                    class="scrollspy-example"
                    data-spy="scroll"
                    data-target="#navbar-example2"
                    data-offset="50"
                  >
                    <section
                      id="scrollspyHeading1"
                      className="scroll-spy-final"
                    ></section>
                    <div className="p-4 home">
                      <span className="text-muted fs-5">Ratings</span>
                      <br />
                      {user?.suscriber === undefined && (
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          style={{
                            background: "purple",
                            width: "50%",
                            borderColor: "purple",
                          }}
                          onClick={(e) => handleSuscriber(e)}
                        >
                          Subscribers Only
                        </button>
                      )}
                      {user?.suscriber || !user ? (
                        <StarRatings
                          rating={
                            data?.propertyType?.toLowerCase() === "shortlet"
                              ? Number(data?.userRating)
                              : data?.starRating
                          }
                          // rating={5}
                          // changeRating={data?.starRating}
                          numberOfStars={5}
                          name="rating"
                          color="purple"
                          style={{ width: "300px" }}
                          starRatedColor="yellow"
                          starHoverColor="purple"
                          starDimension="30px"
                        />
                      ) : (
                        <></>
                      )}
                      <br />
                      <hr />
                    </div>

                    <section id="scrollspyHeading2">
                      <div className="p-4 projects">
                        <span className="text-muted fs-5">Overview</span>
                        <div className="row">
                          <div className="col-md-8 mobile-text-size">
                            <p>Property type:</p>
                          </div>
                          <div className="col-md-2">
                            <p>{data?.propertyType}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mobile-text-size">
                            <p>Property Description:</p>
                          </div>
                          <div className="col-md-2 mobile-text-size">
                            <p>{data?.propertyDescription}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8 col-sm-8 mobile-text-size">
                            <p>No of Bedroom:</p>
                          </div>
                          <div className="col-md-2 col-sm-2 mobile-text-sizes">
                            <p>{data?.numberOfBedrooms}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8 mobile-text-size">
                            <p>No of Bathroom:</p>
                          </div>
                          <div className="col-md-2 mobile-text-sizes">
                            <p>{data?.numberOfBathrooms}</p>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </section>
                    <section id="scrollspyHeading3">
                      <div className="p-4 media">
                        <span className="text-muted fs-5">Features</span>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <p>{data?.additionalFeatures}</p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-secondary overal-botton"
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
              <div className="row"></div>
            </div>
          )}
        </ModalMain.Body>
      </ModalMain>
    </>
  );
}

export default Modal;
