import { useState, useEffect } from "react";
import "./styles/Findagent.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { agents } from "../data";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import { useNavigate } from "react-router";
import { doc, getDoc } from "firebase/firestore";

const Findagent = () => {
  const [agentEmail, setAgentEmail] = useState(null);
  const [agentPhone, setAgentPhone] = useState(null);
  const [agentName, setAgentName] = useState(null);
  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        // documents.push(doc.data().agentName);
        documents.push(doc.data().agentEmail);
      });
      //console.log(documents);

      let uniqueChars = [...new Set(documents)];
      setAgentEmail(uniqueChars);
      //   setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);

  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        // documents.push(doc.data().agentName);
        documents.push(doc.data().agentPhone);
      });

      console.log(documents); 

      let uniqueChars = [...new Set(documents)];
      setAgentPhone(uniqueChars);
      //   setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);
  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        // documents.push(doc.data().agentName);
        documents.push(doc.data().agentName);
      });
      //console.log(documents);

      let uniqueChars = [...new Set(documents)];
      setAgentName(uniqueChars);
      //   setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);
  const [allAgents, setAllAgents] = useState(agents);

  return (
    <>
      <Navbar />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="">
                {allAgents.map((agent) => {
                  return <article></article>;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="table-section">
        {/* <!--for demo wrap--> */}
        <h1>Contact our agents</h1>
        <div className="tbl-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <table cellpadding="0" cellspacing="0" border="0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                </table>
                <div className="tbl-content">
                  <table cellpadding="0" cellspacing="0" border="0">
                    {/* {agents.map((agent) => {
                      return (
                        <tbody>
                          <tr>
                            <td>{agent.name}</td>
                            <td>{agent.contact} </td>
                            <td>{agent.email}</td>
                          </tr>
                        </tbody>
                      );
                    })} */}
                    <tbody>
                      <tr>
                        <td>
                          {agentName?.map((data) => {
                            return <p>{data}</p>;
                          })}
                        </td>
                        <td>
                          {agentPhone?.map((data) => {
                            return <p>{data}</p>;
                          })}
                        </td>
                        {/* <td>{agentEmail?.map((data)=> data)}</td><br/> */}
                        <td>
                          {agentEmail?.map((data) => {
                            return <p>{data}</p>;
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Findagent;