import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Button, Form, Spinner } from "react-bootstrap";

function UploadProperties() {
  const price = 5000;
  let naira = "\u20A6";
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Upload Properties</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Agent</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <h2 className="display-5 text-center m-5">
            Upload Properties
          </h2>
          <Form
            className="w-50 mx-auto agent-form"
            method="post"
            style={{ width: "50%", margin: "auto" }}
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bathroom Breadth</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bathroom breadth"
                className="w-100"
                name="bathroomBreadth"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bathroom length</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bathroom length"
                className="w-100"
                name="bathroomLength"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bathroom Arrangement</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bathroom arrangement"
                className="w-100"
                name="bathroomArrangement"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bedroom Arrangement</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bedroom arrangement"
                className="w-100"
                name="bedroomArrangement"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bedroom Breadth</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bedroom breadth"
                className="w-100"
                name="bedrooomBreadth"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Bedroom Length</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter bedroom length"
                className="w-100"
                name="bedrooomLength"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Caution fee</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Caution fee"
                className="w-100"
                name="cautionfee"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Country"
                className="w-100"
                name="country"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Dinning Room Breadth</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter dinning room breadth"
                className="w-100"
                name="dinningRoomBreadth"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Dinning Room Arrangement</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter dinning room Arrangement"
                className="w-100"
                name="dinningRoomArrangement"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Dinning Room Length</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter dinning room length"
                className="w-100"
                name="dinningRoomLength"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>District</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter District"
                className="w-100"
                name="district"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Price"
                className="w-100"
                name="price"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Property Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Property Description"
                className="w-100"
                name="propertyDescription"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Property Purpose</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Property Purpose"
                className="w-100"
                name="propertyPurpose"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Property Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Property Type"
                className="w-100"
                name="propertyType"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Realtor</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Realtor"
                className="w-100"
                name="realtor"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Security Of House Building Access</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Security Of House Building Access"
                className="w-100"
                name="securityOfHouseBuildingAccess"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Security Of House Gate</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Security Of House Gate"
                className="w-100"
                name="securityOfHouseHouseGate"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Security Of House Secret Personel</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Security Of Secret Personel"
                className="w-100"
                name="securityOfHouseSecretPersonel"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter State"
                className="w-100"
                name="state"
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Street Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="enter Street Name"
                className="w-100"
                name="streetName"
                // required
              />
            </Form.Group>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Security Response</option>
              <option value="Yes" name="no">
                {/* {data?.status} */}
                Yes
              </option>
              <option value="no" name="no">
                {/* {suscribedstatus} */}
                No
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Regular Patrol</option>
              <option value="Yes" name="no">
                {/* {data?.status} */}
                Yes
              </option>
              <option value="no" name="no">
                {/* {suscribedstatus} */}
                No
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Number of Bedroom</option>
              <option value="1" name="1">
                {/* {data?.status} */}1
              </option>
              <option value="2" name="2">
                {/* {suscribedstatus} */}2
              </option>
              <option value="3" name="3">
                {/* {suscribedstatus} */}3
              </option>
              <option value="4" name="4">
                {/* {suscribedstatus} */}4
              </option>
              <option value="5 or more" name="5 or more">
                {/* {suscribedstatus} */}5 or more
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Number of Bathroom</option>
              <option value="1" name="1">
                {/* {data?.status} */}1
              </option>
              <option value="2" name="2">
                {/* {suscribedstatus} */}2
              </option>
              <option value="3" name="3">
                {/* {suscribedstatus} */}3
              </option>
              <option value="4" name="4">
                {/* {suscribedstatus} */}4
              </option>
              <option value="5 or more" name="5 or more">
                {/* {suscribedstatus} */}5 or more
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Number of Bathroom Ensuite</option>
              <option value="1" name="1">
                {/* {data?.status} */}1
              </option>
              <option value="2" name="2">
                {/* {suscribedstatus} */}2
              </option>
              <option value="3" name="3">
                {/* {suscribedstatus} */}3
              </option>
              <option value="4" name="4">
                {/* {suscribedstatus} */}4
              </option>
              <option value="5 or more" name="5 or more">
                {/* {suscribedstatus} */}5 or more
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Number of Toilet</option>
              <option value="1" name="1">
                {/* {data?.status} */}1
              </option>
              <option value="2" name="2">
                {/* {suscribedstatus} */}2
              </option>
              <option value="3" name="3">
                {/* {suscribedstatus} */}3
              </option>
              <option value="4" name="4">
                {/* {suscribedstatus} */}4
              </option>
              <option value="5 or more" name="5 or more">
                {/* {suscribedstatus} */}5 or more
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Number of Toilet Ensuite</option>
              <option value="1" name="1">
                {/* {data?.status} */}1
              </option>
              <option value="2" name="2">
                {/* {suscribedstatus} */}2
              </option>
              <option value="3" name="3">
                {/* {suscribedstatus} */}3
              </option>
              <option value="4" name="4">
                {/* {suscribedstatus} */}4
              </option>
              <option value="5 or more" name="5 or more">
                {/* {suscribedstatus} */}5 or more
              </option>
            </Form.Select>
            <Form.Select
              aria-label="Default select example"
              className="form-control mb-3"
            >
              <option>Call Gate</option>
              <option value="Yes" name="no">
                {/* {data?.status} */}
                Yes
              </option>
              <option value="no" name="no">
                {/* {suscribedstatus} */}
                No
              </option>
            </Form.Select>
            <Button variant="primary" type="submit">
              Create Property
            </Button>
            {/* <Button variant="outline-warning">Go Back</Button> */}
          </Form>
        </section>
      </main>
    </>
  );
}

export default UploadProperties;