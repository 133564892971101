import React from "react";
import ReactPaginate from "react-paginate";
import ShortletPaginated from "./ShortletPaginated";
import { Spinner } from "react-bootstrap";
function PaginationShortlet({
  pageNumber,
  productPerPage,
  products,
  setPageNumber,
  handleId,
  propertyType,
  type,
}) {
  const pageVisited = pageNumber * productPerPage;

  const hotelDisplayed = products
    ?.slice(pageVisited, pageVisited + productPerPage)
    .map((data) => (
      <ShortletPaginated data={data} type={type} handleId={handleId} />
    ));

  const pageCount = Math.ceil(products?.length / productPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleShow = () => {
    if (type === "search") {
      if (products?.length === undefined || products?.length === 0) {
        return (
          <div
            className="alert alert-danger w-50 d-flex text-center mx-auto"
            role="alert"
          >
            No property matches your search
          </div>
        );
      }
    } else if (type === "none") {
      if (products?.length === undefined || products?.length === 0) {
        return (
          <div
            className="alert alert-danger w-50 d-flex text-center mx-auto"
            role="alert"
          >
            No House for Shortlet has been uploaded yet
          </div>
        );
      }
    }
  };

  const handleSelectedProperty = () => {
    if (type === "search") {
      return (
        <p style={{ fontWeight: "bold" }}>
          {products?.length} properties found
        </p>
      );
    } else if (type === "filter") {
      return (
        <p style={{ fontWeight: "bold" }}>
          {products?.length} properties found
        </p>
      );
    }
  };
  return (
    <>
      {handleSelectedProperty()}
      {hotelDisplayed}
      {window.navigator.onLine ? (
        <>
          {handleShow()}
          {products?.length !== 0 ||
            (products?.length !== undefined && (
              <ReactPaginate
                previousLabel={"Pre"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            ))}
        </>
      ) : (
        <div
          className="alert alert-danger w-50 d-flex text-center mx-auto"
          role="alert"
        >
          Oooops you are not connected to the internet... make sure you are
          connected and try again
        </div>
      )}
    </>
  );
}

export default PaginationShortlet;
