import React from "react";
import EditProfile from "./EditProfile";
import ProfileOverview from "./ProfileOverview";
import ProfilePasswordUpdate from "./ProfilePasswordUpdate";
import Header from "../ManagerHeader";
import Sidebar from "../ManagerSidebar";
import { UserContext } from "../../../../App";


function ManagerProfile() {

const user = React.useContext(UserContext)


return (
    <>
      <Header />
      <Sidebar />

      <main id="main" className="main" style={{ background: "#fff" }}>
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">Property Manager</li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                <img
                    src={user?.photoURL}
                    alt="profile image"
                    className=""
                    style={{borderRadius: "100%", height: "100px", width: "100px"}}
                  />
                 {user?<h2>{user.displayName}</h2>:null}
                  {/* <h3>Web Designer</h3> */}
                  
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                      >
                        Change Password
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <ProfileOverview />

                    <EditProfile />

                    <ProfilePasswordUpdate />
                  </div>
                  {/* <!-- End Bordered Tabs --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default ManagerProfile;