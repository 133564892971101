import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import { getDocs, collection, query, where,updateDoc,getDoc,addDoc,doc} from "firebase/firestore";
import { db, storage } from "../../../components/Firebase";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import NavigationIcon from "@mui/icons-material/Navigation";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getAuth, signOut } from "firebase/auth";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { useDropzone } from 'react-dropzone';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';


const dropzoneStyles = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};



function HomePage() {
  
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false)
  const [myProperties, setMyProperties] = useState([]);
  const [verificationProperties, setVerificationProperties] = useState([]);
  const [open, setOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [idType, setIdType] = useState(null);
  const [idNumber, setIdNumber] = useState(null)
  

  const auth = getAuth();
  const user = auth.currentUser;


  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};
  const handleVerifyOpen = () => {if (verified === true){alert('You are already verified')} else {setVerifyOpen(true)}};
  const handleVerifyClose = () => {setVerifyOpen(false)};
  const handleIdTypeChange = (event) => {setIdType(event.target.value)};
  const handleIdNumberChange = (event) => {setIdNumber(event.target.value)};
  const handleSignOut = () => {signOut(getAuth()).then(() => {navigate("/");}).catch((error) => {});};
  const handleAgentVerification = () => {if (!idType||!idNumber) alert('Please fill all fields')}

  /*const functions = getFunctions();
  const addMessage = httpsCallable(functions, 'changeMessage');
  addMessage({ text: "joey" })
  .then((result) => {
    // Read result of the Cloud Function.
    /** @type {any} */
   /* const data = result.data;
    console.log(data)
    const sanitizedMessage = data.text;
  });*/

  //const handleRequest = (data) => { functions.httpsCallable('changeMessage').then(result=>{console.log(result.data)})}


  
  const getProperties = async () => {
    const propertiesRef = collection(db, "properties");
    const q = query(propertiesRef, where("agentEmail", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {myProperties.push(doc.data())});
    
  }
  getProperties()

  const verifiedAgents = async () => {
    const propertiesRef = collection(db, "properties");
    const q = query(propertiesRef, where("agentEmail", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {myProperties.push(doc.data())});
    
  }
  getProperties()


  const handleVerificationPropertiesChange = (e) => {setVerificationProperties(e.target.value)}
  const handleRequestVerification = () => {
    
     let documents = []
     verificationProperties.forEach((element) => {documents.push(element.id);});
         async function updateNow  (i){
          const propertiesRef = doc(db, 'properties', i);
          const docSnap = await getDoc(propertiesRef);
          if (docSnap.exists()) {await updateDoc (propertiesRef, {verificationStatus:"pending"})}
          }
       for (const x of documents){updateNow(x)}
       alert("Your Verification Request has been documented... A Chekam Agent will contact you ")
        
}



  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);

    // Display image preview if needed
    const reader = new FileReader();
    reader.onloadend = () => {
      // Do something with the result, e.g., set it as the source of an image tag
      console.log(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024, // 5MB
    onDrop,
  });

  const uploadFile = async () => {
    const storageRef = ref(storage, 'idcards/' + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading file:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          // Save download URL to Firestore
          try {
            if (user !== null) {
            const email = user.email;
            const docRef = await addDoc(collection(db, 'agentverificationrequest'), {
              downloadURL,
              timestamp: new Date(),
              idType,
              idNumber,
              email,
              verified:false
            });
            console.log('Document written with ID: ', docRef.id);
            alert('Your verification request has been submitted')
            handleVerifyClose()
            } 
          }
          catch (error) {
            console.error('Error adding document: ', error);
          }
        });
      }
    );
  };
  
  return (
    <div style={{ overflow: "scroll !important" }}>
      <Header />
      <Helmet>
        <title>Agent Dashboard</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
      </Helmet>

      <Box sx={{ mt: 15, mx: 5 }} >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Button variant="contained" fullWidth="true" color="secondary">
              Metrics
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/admin/agent/PropertyForm");
              }}
            >
              Add Properties
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/admin/agent/properties");
              }}
            >
              My Properties
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              // onClick={handlePromotePropertyOpen}
              onClick={()=> navigate("/admin/agent/properties")}
            >
              <NavigationIcon sx={{ mr: 1, bgColor: "success" }} />
              Promote Property
            </Button>
          </Grid>

          {/*<Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={handlePromotePropertyOpen}
            >
              <NavigationIcon sx={{ mr: 1, bgColor: "success" }} />
              Pay for Inspections
            </Button>
            </Grid>*/}
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/admin/propertymanager");
              }}
            >
              Switch to Property Manager
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/admin/agent/profile");
              }}
            >
              Profile
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {navigate("/admin/agent/faqs"); }}>
              F.A.Q
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={handleOpen}
            >
             VERIFY PROPERTIES
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={handleVerifyOpen}
            >
             BECOME VERIFIED
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={handleSignOut}
            >
              Log Out
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/*<Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        style={{ overflow: "scroll !important" }}
      >
        <AppBar sx={{ position: "relative", bgcolor: "#800080" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Properties
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {payButton + "  ALL"}
            </Button>
          </Toolbar>
        </AppBar>

        {myProperties.map((x, i) => (
          <Grid
            key={i}
            sx={{ m: 1, p: 1, width: 300, display: "inline-block" }}
          >
            <Grid item>
              <Card sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardMedia
                    component="img"
                    height="50"
                    image={x.images}
                    alt="image"
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {x.propertyType}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.propertyDescription}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.district}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.price.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </Typography>
                  </CardContent>
                </Box>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePromotePaymentOpen}
                  >
                    {payButton}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        ))}

        <div>
          <Dialog
            open={promotePaymentOpen}
            onClose={handlePromotePaymentClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                >
                  <Typography fontSize="6">
                    KINDLY MAKE PAYMENT TO PROMOTE PROPERTY
                  </Typography>
                  ;
                  <Grid item xs>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="name"
                        label="Name"
                        variant="outlined"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="email"
                        label="Email"
                        variant="outlined"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <TextField
                        sx={{ "& label": { mt: "0" } }}
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <PaystackButton {...componentProps} />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePromotePaymentClose}>CLOSE</Button>
            </DialogActions>
          </Dialog>
        </div>
        </Dialog>*/}

      <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Request for Chekam Verification</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To request verification, please select the productId(s) of the properties you would like to be verified
                </DialogContentText>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={myProperties}
                    getOptionLabel={(option) => option.productId}
                    
                    onChange={(event, value) => setVerificationProperties(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Multiple values"
                        placeholder="product ID"
                        
                        onChange={handleVerificationPropertiesChange}
                      />
                    )}
                  />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleRequestVerification}>Request</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={verifyOpen} onClose={handleVerifyClose}>
              <DialogContent>
                <DialogContentText>
                  To become verified, please upload your identification
                </DialogContentText>
                <Box sx={{ minWidth: 120, mt:5}}>
                  <FormControl fullWidth>
                    <InputLabel  sx={{ mt: "2" }} id="idtype">Identification Type</InputLabel>
                    <Select
                      labelId="idtype"
                      id="idtype"
                      value={idType}
                      label="idtype"
                      onChange={handleIdTypeChange}
                    >
                      <MenuItem value={'internationalpassport'}>International Passport</MenuItem>
                      <MenuItem value={'nationalid'}>National Id</MenuItem>
                      <MenuItem value={'driverslicense'}>Driver's License</MenuItem>
                      <MenuItem value={'voterscard'}>Voter's Card</MenuItem>
                      <MenuItem value={'ninslip'}>NIN Slip</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { mt: 5, minwidth: 120 },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      required
                      id="idNumber"
                      label="Id Number"
                      value={idNumber}
                      onChange={handleIdNumberChange}
                    />
                  </div>
                </Box>

                <Box sx={{ minWidth: 120, mt:5}}>
                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>Drag & drop an your ID here, or click to select</p>
                  </div>
                  {file && (
                    <div>
                      <p>Selected File: {file.name}</p>
                      {/* <button onClick={uploadFile}>Upload</button> */}
                      <Button variant="contained" color="secondary" onClick={uploadFile}>Upload & Request Verification</Button>
                      <p>Upload Progress: {uploadProgress}%</p>
                    </div>
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" onClick={handleVerifyClose}>Cancel</Button>
                {/* <Button variant="contained" color="secondary" onClick={handleAgentVerification}>Request Verification</Button> */}
              </DialogActions>
            </Dialog>


    </div>
  );
}

export default HomePage;
