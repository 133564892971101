const Bathroom = ({ selectedConvertibleStudio, setConvertibleStudio }) => {
  const bathrooms = ["Convertible Studio"];
  return (
    <>
      {bathrooms.map((bathroom, index) => {
        return (
          <a
            className={
              "dropdown-item size-bath " +
              (selectedConvertibleStudio?.includes(bathroom)
                ? "selected-size"
                : "")
            }
            key={index}
            onClick={() => setConvertibleStudio(bathroom)}
          >
            {bathroom}
          </a>
        );
      })}
    </>
  );
};

export default Bathroom;
