import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import { Helmet } from "react-helmet";
import "./index.css";
import { UserContext } from "../../../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {collection,query,getDocs,where,deleteDoc,doc} from "firebase/firestore";
import db from "../../../components/Firebase";


function AgentProperties() {
  const user = React.useContext(UserContext);
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

const handleDelete = async (x) =>{
  await deleteDoc(doc(db, "properties", x.id));
}

  const [myProperties, setMyProperties] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["productId", "price"]);

  let charles = [];
  async function getProperties() {
    const propertiesRef = collection(db, "properties");
    const q = query(propertiesRef, where("agentEmail", "==", user.email));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      charles.push(doc.data());
    });
    setMyProperties([...charles]);
    setIsLoaded(true);
  }
  getProperties();

  return (
    <>
      <Header />
      <Helmet>
        <title>Chekam: Agent Properties</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
      </Helmet>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { mt: 10, mx: 2, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          sx={{ "& label": { mt: "0" } }}
          color="secondary"
          helperText="Search by pirce or product Id"
          id="outlined-search"
          label="SEARCH PROPERTIES "
          type="search"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
      </Box>




      {!isLoaded ? (
        <LinearProgress color="secondary" />
      ) : (
        <Box sx={{ mx: 2 }}>
          {search(myProperties).map((x, i) => (
            <Grid
              container
              spacing={2}
              key={i}
              sx={{ m: 1, p: 1, width: 300, display: "inline-block" }}
            >
              <Grid item>
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{ bgcolor: purple[500] }}
                        aria-label="property"
                      ></Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={x.realtor}
                  />

                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {x.propertyType}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.propertyDescription}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.price.toLocaleString("en-NG", {
                        style: "currency",
                        currency: "NGN",
                      })}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {x.productId}
                    </Typography>
                  </CardContent>

                  <CardActions>
                  <Button sx={{bgcolor:'#800080', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}  ><Link  style={{textDecoration: "none", color: "#FFFFFF"}}to="/admin/agent/UpdateProperties" state={{x}}>UPDATE</Link></Button>
                  <Button sx={{bgcolor:'#FFCC00', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}  onClick = {()=>{handleDelete(x)}}> DELETE</Button>
                  </CardActions>
                </Card>
              </Grid>

            </Grid>
          ))}
        </Box>
      )}
    </>
  );
}

export default AgentProperties;