import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCwJWRiR4tl_3n0M19IcjNb-hDcefAOqHw",
  authDomain: "chekam-a4366.firebaseapp.com",
  projectId: "chekam-a4366",
  storageBucket: "chekam-a4366.appspot.com",
  messagingSenderId: "354623294089",
  appId: "1:354623294089:web:e228e88600a8a8a205d458",
  measurementId: "G-150BSCBWWD",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// export const auth = app.auth();
//export const admin = admin.auth(app)
//export const storageRef = ref(storage);

export default db;
