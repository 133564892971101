import React, { useEffect, useState } from "react";
import "./styles/selected.css";
import { useParams } from "react-router-dom";
import { moreDetails } from "../data";
import Navbar from "./Navbar";
import Footer from "./Footer";
import db from "./Firebase";
import { Helmet } from "react-helmet";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import { Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { UserContext } from "../App";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
} from "react-share";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";

import metaThumbnail from "./images/grep.png";
import MetaDecorator from "./MetaDecorator";
import MetaTags from "react-meta-tags";
import SuscriberModal from "./SuscriberModal";
import { findLastIndex } from "lodash";

const content = require("./content");

const items = moreDetails;

function SelectedItem() {
  const [modalshow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const user = React.useContext(UserContext);
  const [ratings, setRating] = useState(1);
  const [cast, setCast] = useState(null);
  const [message, setMessage] = useState(null);
  const [share, setShare] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState(null);
  const [rate, setRate] = useState(false);
  // const toggleVisibility = () => setIsVisible(!isVisible);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const urlParams = new URLSearchParams(window.location.search);
  // console.log(urlParams);
  const urlsearch = urlParams.get("q");

  const redirectUrl = urlParams.get("m");

  useEffect(() => {
    async function getPlaces() {
      setLoading(true);
      const docRef = doc(db, "properties", urlsearch);
      const docSnap = await getDoc(docRef);
      setData(docSnap.data());
      setLoading(false);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    if (redirectUrl && data?.userRated?.includes(user?.uid)) {
      setRate(true);
    }
    getPlaces();
  }, []);

  const handleRate = (e) => {
    setProgress(true);
    setLoading(true);
    e.preventDefault();
    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: "/selected-item?q=" + urlsearch + "&m=main",
        },
      });
    } else {
      const examcollref = doc(db, "properties", urlsearch);
      updateDoc(examcollref, {
        cast: data?.cast + cast,
        userRated: arrayUnion("newuser"),
        // userRated: arrayUnion("davidsonjose"),
        userRating: `${(data?.cast + cast) / data?.userRated?.length}`,
      })
        .then((response) => {
          // alert("Successfully rated the property");
          setMessage("Thanks for rating.... 🎉🎉🎉🎉");
          setProgress(false);
          setLoading(false);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error.message);
          setLoading(false);
          setProgress(false);
        });
    }
  };
  const urltype = urlParams.get("type");
  console.log(urltype);
  useEffect(() => {
    if (urltype === "suscribe") {
      setModalShow(true);
    }
  }, []);

  //2bd apartment in Location, state, for sale

  const shareLink = `https://chekam.com/selected-item/?q=${urlsearch}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`;
  // const shareLink = `https://main-chekam.herokuapp.com/selected-item/?q=${urlsearch}&details=${data?.numberOfBathrooms}bd+${data?.propertyType}+in+${data?.state}+for+${data?.propertyPurpose}&image=${data?.images[0]}&for=${data?.propertyPurpose}`;

  // console.log(data?.images?.length);
  let naira = "\u20A6";

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      // setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();

      console.log(agentData, "here is agent data");
      setUser(agentData);
    })();
  }, [user]);
  return (
    <>
      {/*<MetaDecorator 
        description={content.pageDescription} 
        title={content.pageTitle} 
        imageUrl={metaThumbnail} 
        imageAlt={content.metaImageAlt}
      />*/}

      {/*<Helmet>
      <meta property="og:image:type" content="image/jpeg"/>
      <link rel="image_src" href="https://assets.jiji.ng/static/img/preview.jpg"/>
      <meta property="og:image" content="https://assets.jiji.ng/static/img/preview.jpg"/>
    </Helmet>*/}
      <MetaTags>
        <title>Page 1</title>
        <meta name="description" content="Some description." />
        <meta property="og:title" content="MyApp" />
        <meta property="og:image" content="path/to/image.jpg" />
      </MetaTags>

      <Navbar />

      <Helmet
        title={`${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr for ${data?.propertyPurpose}`}
        meta={[
          {
            name: "description",
            content: `${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr ${data?.propertType} in ${data?.streetName}, $data?.address} for
          ${data?.propertyPurpose}`,
          },
          {
            name: "keywords",
            content: `${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr ${data?.propertType} in ${data?.streetName}, $data?.address} for
          ${data?.propertyPurpose}`,
          },
          {
            property: "og:title",
            content: `${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr for ${data?.propertyPurpose}`,
          },
          { property: "og:image", content: data?.images[0] },
          { property: "og:url", content: window.location.href },
        ]}
      ></Helmet>
      {/*
    
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr ${data?.propertType} in ${data?.streetName}, $data?.address} for
          ${data?.propertyPurpose}`}
          data-react-helmet="true"
        />
        <title>
          {`${data?.numberOfBedrooms}bdrm, ${data?.numberOfBathrooms}bathr for ${data?.propertyPurpose}`}
        </title>
        <link rel="apple-touch-icon" href={data?.images[0]} />
      </Helmet>
      */}
      <div className="container pt-5">
        {/*mobile display*/}
        <div className="mobile-modal" style={{ height: "300px" }}>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <img
                src={data?.images?.length === undefined ? "" : data?.images[0]}
                style={{ width: "450px", height: "350px" }}
                alt=""
                className="img-fluid modal-image"
              />
            </SwiperSlide>
            {data?.images?.slice(1).map((link) => {
              return (
                <SwiperSlide>
                  <>
                    <img
                      src={link}
                      className="img-fluid selected-img"
                      style={{ width: "374px", height: "350px" }}
                      alt=""
                    />
                  </>
                </SwiperSlide>
              );
            })}
            {/*} <div className="main-card-single col-md-6" id="example">
                    <span onClick={() => setShowModal(!show)}>
                      <i
                        class="fa fa-arrow-left text-white p-3 bg-warning rounded-pill"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </div>*/}
            {/* <SwiperSlide>Slide 3</SwiperSlide>
                <SwiperSlide>Slide 4</SwiperSlide>
                <SwiperSlide>Slide 5</SwiperSlide>
                <SwiperSlide>Slide 6</SwiperSlide>
                <SwiperSlide>Slide 7</SwiperSlide>
                <SwiperSlide>Slide 8</SwiperSlide>
                <SwiperSlide>Slide 9</SwiperSlide> */}
          </Swiper>
        </div>

        <div className="row pb-1">
          <div className="col-md-6">
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div
                  className="card home-single"
                  style={{ height: "700px", overflowY: "scroll" }}
                >
                  <img
                    src={
                      data?.images?.length === undefined ? "" : data?.images[0]
                    }
                    style={{ width: "750px", height: "600px" }}
                    alt=""
                    className="img-fluid modal-image"
                  />
                  <div className="respond-me mt-1">
                    <div className="row">
                      {data?.images?.slice(1).map((link) => {
                        return (
                          <div className="col-md-6 pb-2 image-responsive-selected respond-me">
                            <img
                              src={link}
                              className="img-fluid selected-img"
                              style={{ width: "374px", height: "350px" }}
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="card-footer text-center home-single-footer">
                    <h5 className="text-white">
                      <strong>Interested in touring this home?</strong>
                    </h5>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-6">
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <h2 style={{ fontWeight: "bold", color: "black" }}>
                    {naira}
                    {data?.price?.toLocaleString()}
                  </h2>
                  <span style={{ paddingLeft: "10px" }}>
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {data?.numberOfBedrooms}
                    </span>{" "}
                    bd
                  </span>
                  <span style={{ paddingLeft: "10px" }}>|</span>
                  <span style={{ paddingLeft: "10px" }}>
                    <span style={{ fontWeight: "bolder", color: "black" }}>
                      {data?.numberOfBathrooms}
                    </span>{" "}
                    ba
                  </span>
                  {/* <span style={{paddingLeft: "10px"}}>|</span> */}
                  {/* <span style={{paddingLeft: "10px"}}><span style={{fontWeight: "bolder", color: "black"}}>1</span> bd</span> */}
                </div>
                <div style={{ fontSize: "15px", lineHeight: "30px" }}>
                  <span>
                    {data?.district}, {data?.streetName}
                  </span>
                  <br />
                  <span
                    className=""
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    <i
                      class="fa fa-circle sale-icon text-danger"
                      aria-hidden="true"
                    ></i>
                    For {data?.propertyPurpose} | &nbsp; {naira}
                    {data?.price?.toLocaleString()} &nbsp;
                  </span>
                  <br />
                  <div className="pt-5 w-100">
                    {message && (
                      <strong className="mt-5 alert alert-success text-center">
                        {message}
                      </strong>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-outline-warning selected-agent-btn text-white"
                  onClick={toggleVisibility}
                  style={{
                    width: "48%",
                    backgroundColor: "purple",
                    marginRight: "2%",
                  }}
                >
                  Contact Agent
                </button>
                {isVisible && (
                  <div className="p-4">
                    Email: {data?.agentEmail} <br />
                    Contact: {data?.agentPhone}
                    {/* name: Mr Wasiu <br /> Contact: 08099933304 */}
                  </div>
                )}
                <button
                  // onClick={handleEscrowDialogOpen}
                  className="btn btn-outline-warning selected-agent-btn"
                  style={{
                    width: "48%",
                    color: "purple",
                    backgroundColor: "transparent",
                    borderColor: "purple",
                  }}
                >
                  Escrow pay
                </button>
              </>
            )}

            <div
              className="pb-3"
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                paddingTop: "14px",
              }}
            >
              {share && (
                <>
                  <FacebookShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                  <TwitterShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                  <LinkedinShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                  <EmailShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                  <TelegramShareButton
                    url={shareLink}
                    color="red"
                    size={32}
                    round={true}
                  >
                    <TelegramIcon size={32} round={true} />
                  </TelegramShareButton>
                </>
              )}
            </div>
            {data?.propertyPurpose?.toLowerCase() === "shortlet" && (
              <button
                className={`${
                  data?.userRated?.includes(user?.uid) ? "d-none" : ""
                } btn btn-warning selected-agent-btn text-white`}
                onClick={() => setRate(!rate)}
                style={{
                  width: "50%",
                  // backgroundColor: "purple",
                  // borderColor: "purple !important",
                }}
              >
                Rate Property
              </button>
            )}

            {data?.propertyPurpose?.toLowerCase() === "shortlet" && (
              <>
                {rate && (
                  <div
                    style={{
                      width: "100% !important",
                      paddingTop: "20px",
                    }}
                    // className={`${disappear}`}
                  >
                    <div>
                      <StarRatings
                        rating={ratings}
                        changeRating={setRating}
                        numberOfStars={5}
                        name="rating"
                        color="purple"
                        style={{
                          width: "300px",
                        }}
                        starRatedColor="yellow"
                        starHoverColor="purple"
                        starDimension="30px"
                      />
                    </div>
                    <button
                      className="text-white mt-3 rounded-pill text-center"
                      style={{
                        background: `${progress === true ? "gray" : "purple"}`,
                        width: "50%",
                        padding: "5px",
                        border: "none !important",
                        opacity: `${progress === true ? "0.8" : ""}`,
                      }}
                      onClick={(e) => handleRate(e)}
                      disabled={progress === true}
                    >
                      Submit Ratings
                    </button>
                  </div>
                )}
              </>
            )}
            <div className="ratings-overview">
              {/* main */}
              <main data-target="#navbar-example2">
                <nav
                  id="navbar-example2"
                  class="navbar navbar-light bg-light px-3 justify-content-center"
                >
                  <ul class="nav nav-pills under-ul">
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading1">
                        Ratings
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading2">
                        Overview
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading3">
                        Features
                      </a>
                    </li>
                  </ul>
                </nav>

                <div
                  data-bs-spy="scroll"
                  data-bs-target="#navbar-example2"
                  data-bs-offset="0"
                  class="scrollspy-example"
                  tabindex="0"
                >
                  <section id="scrollspyHeading1">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Ratings</span>
                      <br />
                      {users?.subscribed === undefined ||
                      users?.subscribed === "none" ? (
                        <button
                          className="btn btn-outline-warning selected-agent-btn text-white"
                          style={{
                            background: "purple",
                            width: "50%",
                            borderColor: "purple",
                          }}
                          onClick={() => setModalShow(true)}
                        >
                          Subscribers Only
                        </button>
                      ) : (
                        <></>
                      )}
                      {users?.subscribed === "daily" ||
                      users?.subscribed === "weekly" ||
                      users?.subscribed === "monthly" ? (
                        <StarRatings
                          rating={
                            data?.propertyType?.toLowerCase() === "shortlet"
                              ? Number(data?.userRating)
                              : data?.starRating
                          }
                          // rating={5}
                          // changeRating={data?.starRating}
                          numberOfStars={5}
                          name="rating"
                          color="purple"
                          style={{ width: "300px" }}
                          starRatedColor="yellow"
                          starHoverColor="purple"
                          starDimension="30px"
                        />
                      ) : (
                        <></>
                      )}
                      <br />
                      <hr />
                    </div>
                  </section>
                  <section id="scrollspyHeading2">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Overview</span>
                      <div className="row">
                        <div className="col-md-8 mobile-text-size">
                          <p>Property type:</p>
                        </div>
                        <div className="col-md-2 mobile-text-sizes">
                          <p>{data?.propertyType}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 mobile-text-size">
                          <p>Property Description:</p>
                        </div>
                        <div className="col-md-2 mobile-text-sizes">
                          <p>{data?.propertyDescription}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 mobile-text-size">
                          <p>No of Bedroom:</p>
                        </div>
                        <div className="col-md-2 mobile-text-sizes">
                          <p>{data?.numberOfBedrooms}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8 mobile-text-size">
                          <p>No of Bathroom:</p>
                        </div>
                        <div className="col-md-2 mobile-text-sizes">
                          <p>{data?.numberOfBathrooms}</p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </section>
                  <section id="scrollspyHeading3">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Features</span>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <p>{data?.additionalFeatures}</p>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-4">
                          <p>Building Security:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.securityOfHouseBuildingAccess}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <p>Patrols:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.regularPatrols}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <p>Security Personnel:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.securityOfHouseSecurityPersonnel}</p>
                        </div>
                      </div> */}
                    </div>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <SuscriberModal show={modalshow} setShow={setModalShow} />
      <Footer />
    </>
  );
}

export default SelectedItem;
