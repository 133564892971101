const Lands = ({ selectedLand, setLand }) => {
  const bathrooms = ["Land"];
  return (
    <>
      {bathrooms.map((bathroom, index) => {
        return (
          <a
            className={
              "dropdown-item size-bath " +
              (selectedLand?.includes(bathroom) ? "selected-size" : "")
            }
            key={index}
            onClick={() => setLand(bathroom)}
          >
            {bathroom}
          </a>
        );
      })}
    </>
  );
};

export default Lands;