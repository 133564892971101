import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// uncle willy
function AdminSidebar() {
  return (
    <>
      {/* <!-- ======= AdminSidebar ======= --> */}
      <aside
        id="sidebar"
        className="sidebar"
        style={{ background: "purple", color: "#fff !important" }}
      >
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link " href="/admin">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          {/* <!-- End Dashboard Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Properties</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="components-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/admin/properties">
                  <i className="bi bi-circle text-white"></i>
                  <span className="text-white">All Properties</span>
                </a>
              </li>
              <li>
                <a href="/admin/properties/saved-properties">
                  <i className="bi bi-circle text-white"></i>
                  <span className="text-white">Saved Properties</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/admin/properties/highest-rated"
            >
              <i className="bi bi-person"></i>
              <span>Highest Rated Properties</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/agents">
              <i className="bi bi-person"></i>
              <span>Agents</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/users">
              <i className="bi bi-person"></i>
              <span>Users</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/property-managers">
              <i className="bi bi-person"></i>
              <span>Property Manager</span>
            </a>
          </li>
          {/* <!-- End Profile Page Nav --> */}

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/profile">
              <i className="bi bi-person"></i>
              <span>Profile</span>
            </a>
          </li>
          {/* <!-- End Profile Page Nav --> */}

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/faqs">
              <i className="bi bi-question-circle"></i>
              <span>F.A.Q</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed">
              <i class="bi bi-box-arrow-left"></i>
              <span>LOGOUT</span>
            </a>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default AdminSidebar;