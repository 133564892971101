import React, { useState, useEffect } from "react";
import EditProfile from "./EditProfile";
import ProfileOverview from "./ProfileOverview";
import ProfilePasswordUpdate from "./ProfilePasswordUpdate";
import Header from "../AdminHeader";
import Sidebar from "../AdminSidebar";
import { useParams, useNavigate } from "react-router-dom";

function AgentProfileAdmin() {
  const navigate = useNavigate();
  const price = 5000;
  let naira = "\u20A6";

  function handleMove() {
    navigate("/admin/agent/properties/update");
  }
  function handleMoveProperties() {
    navigate("/admin/agent/properties/view");
  }
  return (
    <>
      <Header />
      <Sidebar />

      <main id="main" className="main" style={{ background: "#fff" }}>
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item">Agent</li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src="https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
                    alt="No image Uploaded"
                    className=""
                    style={{
                      borderRadius: "100%",
                      height: "100px",
                      width: "100px",
                    }}
                  />
                  <h2>Agent Full Name</h2>
                  {/* <h3>Web Designer</h3> */}
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                      >
                        Change Password
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <ProfileOverview />

                    <EditProfile />

                    <ProfilePasswordUpdate />
                  </div>
                  {/* <!-- End Bordered Tabs --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <h3 className="text-center p-3">Agent Properties</h3>
        <div className="row">
          <div
            className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
            // key={data?.id}
          >
            <div className="card card-style agent-properties">
              <div className="product-inner-card">
                <a
                  // href="/single-buy"
                  style={{ textDecoration: "none" }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  // onClick={(e) => handleClick(data?.id, e)}
                  //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                >
                  <a>
                    <img
                      src="../../../images/Buying(1).png"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </a>
                  <div className="top-house">
                    <p className="text-danger top-time text-white">
                      {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                    </p>
                  </div>
                </a>
              </div>

              <div className="card-body" style={{}}>
                <p className="card-text" style={{ fontSize: "12px" }}>
                  {naira} {price.toLocaleString()}
                  <br />
                  <span>3bd 5hr Money to buy</span>
                  <br />
                  <span>Ikeja, Lagos state</span>
                </p>
              </div>
            </div>
            <div
              className="d-flex properties-btn"
              style={{ paddingLeft: "50px" }}
            >
              <div className="" style={{ paddingRight: "10px" }}>
                <button
                  className="btn text-white"
                  style={{ background: "purple" }}
                  onClick={handleMove}
                >
                  Update
                </button>
              </div>
              <div>
                <button
                  className="btn text-white"
                  style={{ background: "yellow" }}
                  onClick={handleMoveProperties}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
            // key={data?.id}
          >
            <div className="card card-style agent-properties">
              <div className="product-inner-card">
                <a
                  // href="/single-buy"
                  style={{ textDecoration: "none" }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  // onClick={(e) => handleClick(data?.id, e)}
                  //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                >
                  <a>
                    <img
                      src="../../../images/hotel 1(1).jpg"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </a>
                  <div className="top-house">
                    <p className="text-danger top-time text-white">
                      {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                    </p>
                  </div>
                </a>
              </div>

              <div className="card-body" style={{}}>
                <p className="card-text" style={{ fontSize: "12px" }}>
                  {naira} {price.toLocaleString()}
                  <br />
                  <span>3bd 5hr Money to buy</span>
                  <br />
                  <span>Ikeja, Lagos state</span>
                </p>
              </div>
            </div>
            <div
              className="d-flex properties-btn"
              style={{ paddingLeft: "50px" }}
            >
              <div className="" style={{ paddingRight: "10px" }}>
                <button
                  className="btn text-white"
                  style={{ background: "purple" }}
                  onClick={handleMove}
                >
                  Update
                </button>
              </div>
              <div>
                <button
                  className="btn text-white"
                  style={{ background: "yellow" }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
            // key={data?.id}
          >
            <div className="card card-style agent-properties">
              <div className="product-inner-card">
                <a
                  // href="/single-buy"
                  style={{ textDecoration: "none" }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  // onClick={(e) => handleClick(data?.id, e)}
                  //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                >
                  <a>
                    <img
                      src="../../../images/hotel 3(1).jpg"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </a>
                  <div className="top-house">
                    <p className="text-danger top-time text-white">
                      {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                    </p>
                  </div>
                </a>
              </div>

              <div className="card-body" style={{}}>
                <p className="card-text" style={{ fontSize: "12px" }}>
                  {naira} {price.toLocaleString()}
                  <br />
                  <span>3bd 5hr Money to buy</span>
                  <br />
                  <span>Ikeja, Lagos state</span>
                </p>
              </div>
            </div>
            <div
              className="d-flex properties-btn"
              style={{ paddingLeft: "50px" }}
            >
              <div className="" style={{ paddingRight: "10px" }}>
                <button
                  className="btn text-white"
                  style={{ background: "purple" }}
                  onClick={handleMove}
                >
                  Update
                </button>
              </div>
              <div>
                <button
                  className="btn text-white"
                  style={{ background: "yellow" }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
            // key={data?.id}
          >
            <div className="card card-style agent-properties">
              <div className="product-inner-card">
                <a
                  // href="/single-buy"
                  style={{ textDecoration: "none" }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModal"
                  // onClick={(e) => handleClick(data?.id, e)}
                  //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                >
                  <a>
                    <img
                      src="../../../images/Hotel 2(1).jpg"
                      className="card-img-top img-fluid"
                      alt="..."
                    />
                  </a>
                  <div className="top-house">
                    <p className="text-danger top-time text-white">
                      {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                    </p>
                  </div>
                </a>
              </div>

              <div className="card-body" style={{}}>
                <p className="card-text" style={{ fontSize: "12px" }}>
                  {naira} {price.toLocaleString()}
                  <br />
                  <span>3bd 5hr Money to buy</span>
                  <br />
                  <span>Ikeja, Lagos state</span>
                </p>
              </div>
            </div>
            <div
              className="d-flex properties-btn"
              style={{ paddingLeft: "50px" }}
            >
              <div className="" style={{ paddingRight: "10px" }}>
                <button
                  className="btn text-white"
                  style={{ background: "purple" }}
                  onClick={handleMove}
                >
                  Update
                </button>
              </div>
              <div>
                <button
                  className="btn text-white"
                  style={{ background: "yellow" }}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default AgentProfileAdmin;