import React, { useEffect, useState } from "react";
// import "./styles/selected.css";
import { useParams } from "react-router-dom";
// import { moreDetails } from "../data";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import db from "../../Firebase";
import { useNavigate } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import { Spinner } from "react-bootstrap";


function SelectedPropertiesAdmin() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  // const toggleVisibility = () => setIsVisible(!isVisible);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const urlParams = new URLSearchParams(window.location.search);
  // console.log(urlParams);
  const urlsearch = urlParams.get("q");

  useEffect(() => {
    async function getPlaces() {
      setLoading(true);
      const docRef = doc(db, "properties", "0z4MTCjxdr7J1FjNNjjo");
      const docSnap = await getDoc(docRef);
      setData(docSnap.data());
      setLoading(false);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    getPlaces();
  }, []);

  // console.log(data?.images?.length);
  let naira = "\u20A6";
  return (
    <>
      <Navbar />
      <div className="container pt-5">
        <div className="row pb-1">
          <div className="col-md-6">
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <div>
                  <img
                    src={
                      data?.images?.length === undefined ? "" : data?.images[0]
                    }
                    className="img-fluid"
                    style={{ width: "550px", height: "320px" }}
                    alt=""
                  />
                </div>
                <div className="respond-me mt-3 srolling-to">
                  <div className="row">
                    {data?.images?.slice(1).map((link) => {
                      return (
                        <div className="col-md-6 pb-3 image-responsive-selected respond-me">
                          <img
                            src={link}
                            className="img-fluid selected-img"
                            style={{ width: "274px", height: "250px" }}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-6">
            <br />
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <h4>
                  {data?.district}, {data?.streetName}
                </h4>
                <br />
                <h4>
                  <i class="fa fa-circle sale-icon" aria-hidden="true"></i>
                  For {data?.propertyPurpose} | &nbsp; {naira}{" "}
                  {data?.price?.toLocaleString()} &nbsp;
                </h4>
                <br />
                <button
                  className="btn btn-outline-warning selected-agent-btn text-white"
                  onClick={toggleVisibility}
                  style={{ width: "50%", backgroundColor: "purple" }}
                >
                  Contact Agent
                </button>
                {isVisible && (
                  <div className="p-4">
                    Email: {data?.agentEmail} <br />
                    Contact: {data?.agentPhone}
                    {/* name: Mr Wasiu <br /> Contact: 08099933304 */}
                  </div>
                )}
                <button
                  className="btn btn-outline-warning selected-agent-btn"
                  style={{
                    width: "50%",
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                >
                  Share
                </button>
              </>
            )}

            <div className="ratings-overview">
              {/* main */}
              <main data-target="#navbar-example2">
                <nav
                  id="navbar-example2"
                  class="navbar navbar-light bg-light px-3 justify-content-center"
                >
                  <ul class="nav nav-pills under-ul">
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading1">
                        Ratings
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading2">
                        Overview
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyHeading3">
                        Features
                      </a>
                    </li>
                  </ul>
                </nav>

                <div
                  data-bs-spy="scroll"
                  data-bs-target="#navbar-example2"
                  data-bs-offset="0"
                  class="scrollspy-example"
                  tabindex="0"
                >
                  <section id="scrollspyHeading1">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Ratings</span>
                      <br />
                      <button
                        className="btn btn-outline-warning selected-agent-btn text-white"
                        style={{ background: "purple", width: "50%" }}
                      >
                        Subscribers Only
                      </button>
                      <br />
                      <hr />
                    </div>
                  </section>
                  <section id="scrollspyHeading2">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Overview</span>
                      <div className="row">
                        <div className="col-md-8">
                          <p>Property type:</p>
                        </div>
                        <div className="col-md-2">
                          <p>{data?.propertyType}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <p>Property Description:</p>
                        </div>
                        <div className="col-md-2">
                          <p>{data?.propertyDescription}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <p>No of Bedroom:</p>
                        </div>
                        <div className="col-md-2">
                          <p>{data?.numberOfBedrooms}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <p>No of Bathroom:</p>
                        </div>
                        <div className="col-md-2">
                          <p>{data?.numberOfBathrooms}</p>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </section>
                  <section id="scrollspyHeading3">
                    <div className="bg-light p-4">
                      <span className="text-muted fs-5">Features</span>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <p>{data?.additionalFeatures}</p>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-4">
                          <p>Building Security:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.securityOfHouseBuildingAccess}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <p>Patrols:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.regularPatrols}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <p>Security Personnel:</p>
                        </div>
                        <div className="col-md-6">
                          <p>{data?.securityOfHouseSecurityPersonnel}</p>
                        </div>
                      </div> */}
                    </div>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SelectedPropertiesAdmin;