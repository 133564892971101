import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// console.log(getAdmin());
function AdminProfileUser() {

  return (
    <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
      {/* <!-- Profile Edit Form --> */}
          <form>
            <div className="row mb-3">
              <label
                for="profileImage"
                className="col-md-4 col-lg-3 col-form-label"
              >
                Profile Image
              </label>
              <div className="col-md-8 col-lg-9">
                <img src="https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="No image uploaded yet" />
                <div className="pt-2">
                  <input
                    type="file"
                    name="image"
                    className="btn btn-primary btn-sm"
                  />
                  {/* <i className="bi bi-upload"></i> */}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <label
                for="fullName"
                className="col-md-4 col-lg-3 col-form-label"
              >
                full Name
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="fullname"
                  type="text"
                  className="form-control"
                  id="fullName"
                  value="User Full Name"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="about" className="col-md-4 col-lg-3 col-form-label">
                About
              </label>
              <div className="col-md-8 col-lg-9">
                <textarea
                  name="about"
                  className="form-control"
                  id="about"
                  style={{ height: "100px" }}
                  value="I am a programmer and love doing everything in a very good way"
                >
                  I am a programmer and love doing everything in a very good way
                </textarea>
              </div>
            </div>

            <div className="row mb-3">
              <label for="Job" className="col-md-4 col-lg-3 col-form-label">
                Position
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="position"
                  type="text"
                  className="form-control"
                  id="Job"
                  value="User"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Country" className="col-md-4 col-lg-3 col-form-label">
                Country
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="country"
                  type="text"
                  className="form-control"
                  id="Country"
                  value="Nigeria"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Address" className="col-md-4 col-lg-3 col-form-label">
                Address
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="address"
                  type="text"
                  className="form-control"
                  id="Address"
                  value="Lagos Nigeria"
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Phone" className="col-md-4 col-lg-3 col-form-label">
                Phone
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="phone"
                  type="text"
                  className="form-control"
                  id="Phone"
                  value={"08053y535y8"}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label for="Email" className="col-md-4 col-lg-3 col-form-label">
                Email
              </label>
              <div className="col-md-8 col-lg-9">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  id="Email"
                  value="Useremail@gmail.com"
                />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
      {/* <!-- End Profile Edit Form --> */}
    </div>
  );
}

export default AdminProfileUser;