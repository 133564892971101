import { useState, useEffect } from "react"
import Slider from '@mui/material/Slider';
import { houses, sublinks, mainLinks, details } from "../data";
import Size from './Size'
import './styles/Buy.css'
import filterList from './test/filterList'



const CommercialSpaceHeader = ({agirlik0, agirlik1, prices, setPrices, setAgirlik,agirlik,
  agirlik0s, agirlik1s, pricess, setPricess, setAgirliks,agirliks,
}) => {
    // SHOW DIV
  const [showDiv, setShowDiv] = useState(false)
  const onShowDiv = (e) => {
    e.preventDefault()
    setShowDiv(true)
  }

  const dontRefresh = (e) => {
    e.preventDefault()
  }




  
  
  // useEffect(() => {
  //   setProducts(filterList([], null));
  //   if(JSON.parse(localStorage.getItem("cart"))) {
  //     setCart(JSON.parse(localStorage.getItem("cart")));
  //   }
  // }, [])


    return (
        <>




            <div className="col-lg-8 d-flex buttons-horizontal">
              <div style={{ margin: "5px" }} className="single-button">
                <li className="dropdown main-dropdown" >
                   <a href="" className="ui-state-default buy-cat-button">For Sale</a>
                   <ul className="dropdown-menu overwrite-dropdown2"
                     aria-labelledby="navbarScrollingDropdown" style={{ width: "40%"}}>
                      <div className="">
                        <li>
                          <a href="/rent" className="dropdown-item" style={{width: "100%"}}>For Rent</a>
                        </li>
                        <li>
                          <a href="/hotel" className="dropdown-item" style={{width: "100%"}}>Hotel</a>
                        </li>
                    </div>
                  </ul>
                </li>
              </div>
                   <div style={{ margin: "5px" }}>
                    <div className="dropdown">
                      <li
                        className="dropdown main-dropdown  "
                        type="button"
                        id="dropdownMenuButton" 
                      >
                        <a href="" className="ui-state-default buy-cat-button">
                          Property Types
                        </a>
                      </li>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {sublinks.map((link) => {
                              const { links, title, url } = link;
                                return (
                              <li>
                                <a className="dropdown-item" href={url}>
                                {title} &raquo;
                                </a>
                                
                                  <ul className="dropdown-menu dropdown-submenu">
                                    {links.map((item) => {
                                      return (
                                      <li>
                                        <a className="dropdown-item" href={item.url}>
                                          {item.label} 
                                        </a>
                                      </li>
                                      );
                                    })}
                                  </ul>
                                  
                              </li>
                              );
                            })}
                          </ul>
                          
                    </div>
                  </div>









                  
                  

                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={dontRefresh}>
                       <a href="" className="ui-state-default buy-cat-button">
                         Floor Size
                       </a>
                       <ul
                         className="dropdown-menu "
                         aria-labelledby="navbarScrollingDropdown"
                       >
                         {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                           <div className="kategoriler">
                       <span STYLE={{marginLeft: "5px"}}>
                         Floor Size in SQM
                       </span>
                       <div className="kategori">
                         <div>
                           <input 
                             type="text"
                             className="price-input"
                             value={agirlik0s}
                             onChange={(e) => setAgirliks([e.target.value, agirlik1s])}
                           />
                           <input
                             type="text"
                             className="price-input"
                             value={agirlik1s}
                             onChange={(e) => setAgirliks([agirlik0s, e.target.value])}
                           />
                        </div>
                       <Slider
                          value={agirliks}
                          min={pricess.hacim_mins}
                          max={pricess.hacim_maxs}
                          style={{ color: "#800080" }}
                          onChange={(event, newValue) => setAgirliks(newValue)}
                          valueLabelDisplay="auto"
                          // step={100000}
                          // sort={1000000}
                          step="10000"
                        />
                      </div>
                          </div>
                      </ul>
                    </li>
                  </div>

                  <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={dontRefresh}>
                       <a href="" className="ui-state-default buy-cat-button">
                         Price
                       </a>
                       <ul
                         className="dropdown-menu "
                         aria-labelledby="navbarScrollingDropdown"
                       >
                         {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                           <div className="kategoriler">
                       <span>
                         Price <span>Range</span>
                       </span>
                       <div className="kategori">
                         <div>
                           <input 
                             type="text"
                             className="price-input"
                             value={agirlik0}
                             onChange={(e) => setAgirlik([e.target.value, agirlik1])}
                           />
                           <input
                             type="text"
                             className="price-input"
                             value={agirlik1}
                             onChange={(e) => setAgirlik([agirlik0.toLocaleString(), e.target.value])}
                           />
                        </div>
                       <Slider
                          value={agirlik}
                          min={prices.hacim_min}
                          max={prices.hacim_max}
                          style={{ color: "#800080" }}
                          onChange={(event, newValue) => setAgirlik(newValue)}
                          valueLabelDisplay="auto"
                          // step={100000}
                          // sort={1000000}
                          step="10000"
                        />
                      </div>
                          </div>
                      </ul>
                    </li>
                  </div>

                   {/* PRICE COMPONENT SLIDER 
                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={onShowDiv}>
                       <a
                         href=""
                         className="ui-state-default buy-cat-button"
                       >
                         Price
                       </a>
                       {showDiv ? 
                       <ul className="dropdown-menu " aria-labelledby="navbarScrollingDropdown">
                       
                           <input type="range" class="slider" id="myRange" />
                          
                       </ul> : null}
                     </li>
                   </div> */}
                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown">
                       <a
                         href=""
                         className="ui-state-default buy-cat-button"
                       >
                         More
                       </a>
                       <ul
                         className="dropdown-menu overwrite-dropdown"
                         aria-labelledby="navbarScrollingDropdown" style={{width: "50%"}}
                       >
                         <div className="row subs-nav">
                             <span className="">Property Title</span>
                             <div className="" style={{paddingLeft: "20px"}}>
                               <li>
                                 <a href="" className="marquee-link">C of O</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">Rof O</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link"> DOA</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">Freehold</a>
                               </li>
                             </div>
                           {/* <div className="col-md-4 p-4">
                             <span className="">Find Optimal Location</span>
                             <div className="" style={{paddingLeft: "20px"}}>
                               <li>
                                 <a href="" className="marquee-link">Most Visited</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">Within Skims</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">Within 304kms</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">Within 50kms</a>
                               </li>
                             </div>
                           </div> */}
                           <div className="col-md-4 p-4">
                             <span className="">Checkam Verified</span>
                             <div className="" style={{paddingLeft: "20px"}}>
                               <li>
                                 <a href="" className="marquee-link">Yes</a>
                               </li>
                               <li>
                                 <a href="" className="marquee-link">No</a>
                               </li>
                             </div>
                           </div>
                         </div>
                         <li>
                           <a href="" className="marquee-link"></a>
                         </li>
                       </ul>
                     </li>
                   </div>


                 </div>
            
        </>
    )
}

export default CommercialSpaceHeader