import React, { useState, useEffect } from "react";
import EditProfile from "./EditProfile";
import ProfileOverview from "./ProfileOverview";
import ProfilePasswordUpdate from "./ProfilePasswordUpdate";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { UserContext } from "../../../../App";
import db from "../../../Firebase";
import { Helmet } from "react-helmet";
import Snackbar from "@mui/material/Snackbar";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import { CircularProgress, Dialog } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AgentProfile() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);
  const [message, setMessage] = useState(null);
  const user = React.useContext(UserContext);

  console.log(user);

  useEffect(() => {
    setLoading(true);
    async function getSingleAgent() {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data(), "here is the data logged", user?.uid);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  }, [user]);

  return (
    <>
      <Header />
      <Helmet>
        <title>Agent: {user?.displayName} Profile</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
      </Helmet>
      <Sidebar />

      {loading && (
        <Dialog
          style={{ overflow: "hidden !important" }}
          open={loading === true}
          onClose={() => console.log("trying to close me")}
        >
          <CircularProgress color="secondary" className="m-5" />
        </Dialog>
      )}

      {err && (
        <Snackbar
          open={err}
          autoHideDuration={20000}
          onClose={() => setErr(null)}
        >
          <Alert
            onClose={() => setErr(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        </Snackbar>
      )}
      {message && (
        <Snackbar
          open={message}
          autoHideDuration={20000}
          onClose={() => {
            setMessage(null);
          }}
        >
          <Alert
            onClose={() => {
              setMessage(null);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
            <p style={{ display: "none" }}>
              {setTimeout(() => {
                window.location.reload();
              }, 2000)}
            </p>
          </Alert>
        </Snackbar>
      )}

      <main id="main" className="main" style={{ background: "#fff" }}>
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin/agent/">Home</a>
              </li>
              <li className="breadcrumb-item">Agent</li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={user?.photoURL}
                    alt="profile image"
                    className=""
                    style={{
                      borderRadius: "100%",
                      height: "100px",
                      width: "100px",
                    }}
                  />
                  {user ? <h2>{user.displayName}</h2> : null}
                  {/* <h3>Web Designer</h3> */}
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  {/* <!-- Bordered Tabs --> */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-overview"
                      >
                        Overview
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                      >
                        Edit Profile
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                      >
                        Change Password
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <ProfileOverview
                      loading={loading}
                      setLoading={setLoading}
                      data={data}
                      err={err}
                      setErr={setErr}
                      message={message}
                      setMessage={setMessage}
                    />

                    <EditProfile
                      data={data}
                      loading={loading}
                      setLoading={setLoading}
                      err={err}
                      setErr={setErr}
                      message={message}
                      setMessage={setMessage}
                    />

                    <ProfilePasswordUpdate
                      loading={loading}
                      setLoading={setLoading}
                      data={data}
                      err={err}
                      setErr={setErr}
                      message={message}
                      setMessage={setMessage}
                    />
                  </div>
                  {/* <!-- End Bordered Tabs --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}
    </>
  );
}

export default AgentProfile;