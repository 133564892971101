const Apartments = ({ selectedApartment, setApartment, setApartmentShow, apartmentShow }) => {
  const bathrooms = ["Apartment"];
  const handleApartment = (bathroom) => {
    setApartment(bathroom);
    if (apartmentShow === "") {
      setApartmentShow("d-none");
    } else {
      setApartmentShow("");
    }
  };
  return (
    <>
      {bathrooms.map((bathroom, index) => {
        return (
          <a
            className={
              "dropdown-item size-bath " +
              (selectedApartment?.includes(bathroom) ? "selected-size" : "")
            }
            key={index}
            onClick={() => handleApartment(bathroom)}
          >
            {bathroom}
          </a>
        );
      })}
    </>
  );
};

export default Apartments;
