import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import background from "../images/c.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import API_URL from "../config";
import {
  getAuth,
  updateProfile,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import db, { functions } from "./Firebase";
import { serverTimestamp, doc, setDoc } from "firebase/firestore";
import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";

const referralOptions = [
  'The Returnees',
  'Social Media',
  'Search',
  'Orisha',
  'Ads',
  'other'
]


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" to="/">
        chekam
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Signup() {
  const [dashboard, setDashboard] = useState("");
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [err, setErr] = useState(null);
  const [error, setError] = useState("");
  const [agentChecked, setAgentChecked] = React.useState(false);
  const [propertyManagerChecked, setPropertyManagerChecked] = React.useState(false);
  const [value, setValue] = React.useState('')
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const loginType = urlParams.get("type");

  useEffect(() => {
    if (!loginType) {
      navigate("/signuppro");
    }
  }, []);

  const handleAgentChange = (event) => {
    setAgentChecked(event.target.checked);
  };

  const handlePropertyManagerChange = (event) => {
    setPropertyManagerChecked(event.target.checked);
  };

 

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErr(null);
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const names = data.get("name");
    const company = data.get("company");
    const phone = data.get("phone");


    const agentMail = {
      name: names,
      email: email,
      email_type: "agent-signup",
    };
    const userMail = {
      name: "user",
      email: email,
      email_type: "user",
    };

    if (loginType === "agent") {
      if (
        email === "" &&
        password === "" &&
        company === "" &&
        phone === "" &&
        names === "" &&
        value === ""
      ) {
        setLoading(false);
        setErr("Please fill in all fields");
        return false
      } else if (!email) {
        setLoading(false);
        setErr("Email is required");
        return false
      } else if (!password) {
        setLoading(false);
        setErr("Password is required");
        return false
      } else if (!names) {
        setLoading(false);
        setErr("Name is required");
        return false
      } else if (!company) {
        setLoading(false);
        setErr("name of company is required");
        return false
      } else if (!phone) {
        setLoading(false);
        setErr("Tel no is required");
        return false
      }
      else if (!value) {
        setLoading(false);
        setErr("How did you here about Chekam is required");
        return false
      }

      
      async function addAgent(user) {
        await setDoc(doc(db, "users", user.uid), {
          name: names,
          company: company,
          email: email,
          phone: phone,
          status: "agent",
          referral:value,
          date: serverTimestamp(),
          about: "",
          address: "",
        })
          .then(() => {
            // navigate("/");
            axios
              .post(API_URL, agentMail)
              .then(() => {
                setLoading(false);
                setMessage(
                  "Account created successfully... A mail has been sent "
                );
                setDashboard("/admin/agent");
                setTimeout(() => {
                  setMessage2("Please wait while we redirect you");
                }, 2000);
                setTimeout(() => {
                  setReady(true);
                }, 3000);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErr(err?.message);
          });
      }
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          updateProfile(auth.currentUser, {
            displayName: names,
            photoURL: "",
            phoneNumber: phone,
            company: company,
            userStatus: "agent",
          }).then(addAgent(user));
        })

        .catch((error) => {
          console.log(error?.code);
          setLoading(false);
          setErr(error?.code);
        });
    } else if (loginType === "propertymanager") {
      if (
        email === "" &&
        password === "" &&
        company === "" &&
        phone === "" &&
        names === "" &&
        value === ""
      ){
        setLoading(false);
        setErr("Please fill in all fields");
        return false
      } else if (!email) {
        setLoading(false);
        setErr("Email is required");
        return false
      } else if (!password) {
        setLoading(false);
        setErr("Password is required");
        return false
      } else if (!names) {
        setLoading(false);
        setErr("Name is required");
        return false
      } else if (!company) {
        setLoading(false);
        setErr("name of company is required");
        return false
      } else if (!phone) {
        setLoading(false);
        setErr("Tel no is required");
        return false
      }
      else if (!value) {
        setLoading(false);
        setErr("How did you here about Chekam is required");
        return false
      }

      async function addPropertyManager(user) {
        await setDoc(doc(db, "users", user.uid), {
          name: names,
          company: company,
          email: email,
          about: "",
          address: "",
          phone: phone,
          status: "property manager",
          referral:value,
          date: serverTimestamp(),
        })
          .then(() => {
            setLoading(false);
            setMessage("Account created successfully... A mail has been sent ");
            setDashboard("/admin/propertymanager/managerhomepage");
            setTimeout(() => {
              setMessage2("Please wait while we redirect you");
            }, 2000);
            setTimeout(() => {
              setReady(true);
            }, 3000);
            // axios
            //   .post(API_URL, agentMail)
            //   .then(() => {
            //     setLoading(false);
            //     setMessage(
            //       "Account created successfully... A mail has been sent "
            //     );
            //     setDashboard("/admin/agent");
            //     setTimeout(() => {
            //       setMessage2("Please wait while we redirect you");
            //     }, 2000);
            //     setTimeout(() => {
            //       setReady(true);
            //     }, 3000);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErr(err?.message);
          });
      }

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(auth.currentUser, {
            displayName: names,
            photoURL: "",
            phoneNumber: phone,
            company: company,
            userStatus: "property manager",
            referral:value,
          }).then(addPropertyManager(user));
        })
        .catch((error) => {
          setLoading(false);
          setErr(error?.code);
        });
    } else if (loginType === "user") {
      if (email === "" && password === "") {
        setLoading(false);
        setErr("Please fill in all fields");
        return false
      } else if (!email) {
        setLoading(false);
        setErr("Email is required");
        return false
      } else if (!password) {
        setLoading(false);
        setErr("Password is required");
        return false
      } else if (!value) {
        setLoading(false);
        setErr("How did you here about Chekam is required");
        return false
      }

      async function addUser(user) {
        await setDoc(doc(db, "users", user.uid), {
          name: names,
          email: email,
          status: "user",
          referral:value,
          date: serverTimestamp(),
        })
          .then(() => {
            // navigate("/");
            axios
              .post(API_URL, userMail)
              .then(() => {
                setLoading(false);
                setMessage(
                  "Account created successfully... A mail has been sent "
                );
                setDashboard("/admin/user");
                setTimeout(() => {
                  setMessage2("Please wait while we redirect you");
                }, 2000);
                setTimeout(() => {
                  setReady(true);
                }, 3000);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErr(err?.message);
          });
      }

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(auth.currentUser, {
            displayName: names,
            photoURL: "",
            phoneNumber: phone,
            status: "user",
          }).then(addUser(user));
        })
        .catch((error) => {
          setLoading(false);
          setErr(error?.code);
        });
    } else if (loginType === "agentpropertymanager") {
      if (email === "" && password === "") {
        setLoading(false);
        setErr("Please fill in all fields");
      } else if (!email) {
        setLoading(false);
        setErr("Email is required");
      } else if (!password) {
        setLoading(false);
        setErr("Password is required");
      } else if (!value) {
        setLoading(false);
        setErr("How did you here about Chekam is required");
      }

      async function addAgentAndPropertyManager(user) {
        await setDoc(doc(db, "users", user.uid), {
          name: names,
          email: email,
          status: "agent and property manager",
          referral:value,
          date: serverTimestamp(),
          about: "",
          address: "",
        })
          .then(() => {
            // navigate("/");
            axios
              .post(API_URL, agentMail)
              .then(() => {
                setLoading(false);
                setMessage(
                  "Account created successfully... A mail has been sent "
                );
                setDashboard("/");
                setTimeout(() => {
                  setMessage2("Please wait while we redirect you");
                }, 2000);
                setTimeout(() => {
                  setReady(true);
                }, 3000);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            setErr(err?.message);
          });
      }

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          updateProfile(auth.currentUser, {
            displayName: names,
            photoURL: "",
            phoneNumber: phone,
            userStatus: "agent and property manager",
          }).then(addAgentAndPropertyManager(user));
        })
        .catch((error) => {
          setLoading(false);
          setErr(error?.code);
        });
    }
  };

  useEffect(() => {
    if (ready) {
      setLoading(true);

      setTimeout(() => {
        return (window.location.href = dashboard);
      }, 1000);
    }
  }, [ready]);

  return (
    <ThemeProvider theme={theme}>
      {/* 
      <Helmet>
        <title>Sign Up | Chekam</title>
      </Helmet>
    */}
      <Navbar />

      {loading && (
        <Dialog
          style={{ overflow: "hidden !important" }}
          open={loading === true}
          onClose={() => console.log("trying to close me")}
        >
          <CircularProgress color="secondary" className="m-5" />
        </Dialog>
      )}

      {err && (
        <Snackbar
          open={err}
          autoHideDuration={20000}
          onClose={() => setErr(null)}
        >
          <Alert
            onClose={() => setErr(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        </Snackbar>
      )}
      {message && (
        <Snackbar
          open={message}
          autoHideDuration={20000}
          onClose={() => {
            setMessage2(null);
            setMessage(null);
          }}
        >
          <Alert
            onClose={() => {
              setMessage2(null);
              setMessage(null);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message} <br />
            {message2}
          </Alert>
        </Snackbar>
      )}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 2,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: "Nunito Sans" }}
            >
              Sign Up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                sx={{ "& label": { mt: "0" } }}
                margin="normal"
                size="small"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                color="secondary"
              />
              <TextField
                sx={{ "& label": { mt: "0" } }}
                margin="normal"
                size="small"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                color="secondary"
              />

              {loginType === "agentpropertymanager" && (
                <>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="name"
                    label="Name"
                    id="name"
                    autoComplete="name"
                    color="secondary"
                  />
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="company"
                    label="Company"
                    id="company"
                    autoComplete="company"
                    color="secondary"
                  />

                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="current-password"
                    color="secondary"
                  />
                </>
              )}
              {loginType === "propertymanager" && (
                <>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="name"
                    label="Name"
                    id="name"
                    autoComplete="name"
                    color="secondary"
                  />
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="company"
                    label="Company"
                    id="company"
                    autoComplete="company"
                    color="secondary"
                  />

                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="current-password"
                    color="secondary"
                  />
                </>
              )}
              {loginType === "agent" && (
                <>
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="name"
                    label="Name"
                    id="name"
                    autoComplete="name"
                    color="secondary"
                  />
                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="company"
                    label="Company"
                    id="company"
                    autoComplete="company"
                    color="secondary"
                  />

                  <TextField
                    sx={{ "& label": { mt: "0" } }}
                    margin="normal"
                    size="small"
                    fullWidth
                    name="phone"
                    label="Phone"
                    type="phone"
                    id="phone"
                    autoComplete="current-password"
                    color="secondary"
                  />
                </>
              )}

              <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
        
              disablePortal
             
              options={referralOptions}
              sx={{ width: 300 }}
              
              renderInput={(params) => <TextField sx={{ "& label": { mt: "0" } }} {...params} label="How did you hear about Chekam?" variant="standard"   />}
            />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={loginType === "user"}
                    value="propertyUser"
                    color="secondary"
                    disabled={
                      loginType === "landlord" ||
                      loginType === "agent" ||
                      loginType === "propertymanager" ||
                      loginType === "agentpropertymanager"
                    }
                  />
                }
                label="User"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="agent"
                    color="secondary"
                    onChange={handleAgentChange}
                    checked={
                      loginType === "agent" ||
                      loginType === "agentpropertymanager"
                    }
                  />
                }
                disabled={
                  loginType === "landlord" ||
                  loginType === "user" ||
                  loginType === "propertymanager"
                }
                label="Agent"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="propertyManager"
                    color="secondary"
                    onChange={handlePropertyManagerChange}
                    // checked={propertyManagerChecked}
                    checked={
                      loginType === "propertymanager" ||
                      loginType === "agentpropertymanager"
                    }
                    disabled={
                      loginType === "landlord" ||
                      loginType === "user" ||
                      loginType === "agent"
                    }
                  />
                }
                label="Property Manager"
              />
              <br />

              <FormControlLabel
                control={
                  <Checkbox value="Terms & Conditions" color="secondary" />
                }
                component={Link}
                to="/privacypolicy"
              />
              <span>I agree to Chekam's <a href="/terms">Terms</a> & <a href="/privacy-policy">Privacy policy</a></span>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: "secondary.main" }}
              >
                Sign Up
              </Button>
              <Grid container>
                <Grid item>
                  <p>
                    <Link
                      style={{ textDecoration: "none", color: "#800080" }}
                      to="/Login"
                    >
                      Already have an account? Login
                    </Link>
                  </p>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
