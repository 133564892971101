import React from "react";
import Navbar from "./Navbar";
import "./styles/Buy.css";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

function Hotel() {
  return (
    <>
      {/*
      <Helmet>
        <title>Hotels | Chekam</title>
      </Helmet>
      */}
      <Navbar />

      <div className="container" style={{ textAlign: "center" }}>
        <div className="row">
          <div className="col-lg-12">
            <h2>Coming Soon ...</h2>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Hotel;
