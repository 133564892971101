import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./styles/Buy.css";
import Footer from "./Footer";
import ProductsList from "./ProductsList";
import { houses, sublinks, mainLinks, baths } from "../data";
import {commercial} from '../data';
import Scroll from './Scroll';
import SearchProductList from './SearchProductList';
import Slider from '@mui/material/Slider';
import Products from './test/Products'
import Size from './Size'
import Bedroom from './Bedroom'
import filterList from './test/filterList'
import filterBedroom from './test/filterBedroom'
import CommercialSpaceHeader from './CommercialSpaceHeader'
import Map from './Map'




// map: "nfksnfks",

function CommercialSpace() {

  const locations = require("../locations.json");
  const [datas, setDatas] = useState([]);
  const [datass, setDatass] = useState([]);
  // SHOW DIV
  // const [showDiv, setShowDiv] = useState(false)
  // const onShowDiv = (e) => {
  //   e.preventDefault()
  //   setShowDiv(true)
  // }

  // const emptyUrl = "s"


  // PRICE
  const [agirlik, setAgirlik] = useState([1, 1000000000]);
  const [prices, setPrices] = useState({
    hacim_min: 0,
    hacim_max: 1000000000,
  });
  useEffect(() => {
    let filteredPersons = commercial.filter((item) =>
      item.price * 1 >= agirlik[0] && item.price * 1 <= agirlik[1]
        ? true
        : false
    );
    if(agirlik[0] > 200000) {
      setDatas(filteredPersons);
    } else {
      setDatas(false)
    }
  }, [agirlik]);
    // Land Size
    const [agirliks, setAgirliks] = useState([1, 1000000]);
    const [pricess, setPricess] = useState({
      hacim_mins: 0,
      hacim_maxs: 1000000,
    });
    useEffect(() => {
      let filteredPersons = commercial.filter((item) =>
        item.prices * 1 >= agirliks[0] && item.prices * 1 <= agirliks[1]
          ? true
          : false
      );
      if(agirliks[0] > 200000) {
        setDatass(filteredPersons);
      } else {
        setDatass(false)
      }
    }, [agirliks]);




  const filterSort = houses.filter((person) => {
    return person.price > 60000;
  });



  // search
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  const filteredPersons = commercial.filter(
    person => {
      return (
        person
        .name
        .toLowerCase()
        .includes(searchField.toLowerCase()) ||
        person
        .address
        .toLowerCase()
        .includes(searchField.toLowerCase())
      );
    }
  );

  const handleChange = e => {
    setSearchField(e.target.value);
    if(e.target.value===""){
      setSearchShow(false);
    }
    else {
      setSearchShow(true);
    }
  };

  function searchList() {
  	if (searchShow) {
	  	return (
	  			<SearchProductList filteredPersons={filteredPersons} />
	  	);
	  } else {
      return <>Kindly type something that makes sense lol</>
    }
  }





  // Sizes BATHROOMS
  const [selectedSizes, setSelectedSizes] = useState([]);
  const setSize = (size) => {
    const sizes = [...selectedSizes];
    
    if(sizes.includes(size)) {
      const index = sizes.indexOf(size);
      sizes.splice(index, 1);
    }
    else {
      sizes.push(size);
    }
    setSelectedSizes(sizes);
    setProducts(filterList(sizes, 'size', commercial));
  }
  // BEDROOMS
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const setBedroom = (bedroom) => {
    const bedrooms = [...selectedBedrooms];
    
    if(bedrooms.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms.splice(index, 1);
    }
    else {
      bedrooms.push(bedroom);
    }
    setSelectedBedrooms(bedrooms);
    setProducts(filterBedroom(bedrooms, 'bedroom', commercial));
  }
  



  // SORT
  const [products, setProducts] = useState([]);
  const sortProducts = (method) => {
    const array = products;

    if(method === "Lowest to Highest") {
        array.sort(function(a, b){
          return a.price-b.price
      })
    }
    else if(method === "Highest to Lowest") {
        array.sort(function(a, b){
          return b.price-a.price
      })
    }
    else if(method === "Newest") {
      array.sort(function(a, b){
        return a.time-b.time
    })
  }
    setProducts(array);
  }

  // ...sort
  const [value, setValue] = useState('Select');

    const setList = (e) => {
        setValue(e.target.value);
        sortProducts(e.target.value);
    }

    useEffect(() => {
      setProducts(filterList([], null, commercial));
      
    }, [])


    if(products.length !== 0 ){
      <Scroll>
        <SearchProductList filteredPersons={products} />
      </Scroll>
    }




    //Slider func
    // const [agirlik, setAgirlik] =  useState([1,5000]);


    const Conditioning = ()=>{
      // setDatas(false);
      
      if(searchShow) {
        return searchList()
      }else if (datas){
        return <Products products={datas} sortProducts={datas}  />
      }else if (datass){
        return <Products products={datass} sortProducts={datass}  />
      }else if(sortProducts) {
        return <Products products={products} sortProducts={sortProducts}  />
      }
      
    }

    function searchList() {
      if (searchShow) {
        return (
          <>
            <SearchProductList filteredPersons={filteredPersons} />
          </>
        );
      }
    } 
  
  
  
    // function sortList() {
    //   if (data) {
    //     return (
    //       <>
    //         <SearchProductList filteredPersons={filterSort} />
    //       </>
    //     );
    //   }
    // }
    function PriceList() {
      if (datas.length !== 0) {
        return (
          <>
            <span className="p-2">{datas.length === 1 ? (<>{datas.length} house found</>) : (<>{datas.length} houses found</>) }</span>
            <SearchProductList filteredPersons={datas} />
          </>
        );
      }else{
        return(
          <>
            <h4>The is no house with this range come back later 😎</h4>
          </>
        )
      }
    }


    


  return (
    <>
      <Navbar />
      
      


      <div className="mt-5">

        <div className="container buy-top">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <form class="d-flex buy-search-top">
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Enter and Adress, Zip or Code"
                      aria-label="Search"
                      onChange = {handleChange}
                      style={{ display: "block", width: "100%", height: "36px", fontSize: "10px" }}
                    />
                    {/* <i class="fa fa-search seach-icon" aria-hidden="true"></i> */}
                  </form>
                </div>
                <CommercialSpaceHeader
                prices={prices}
                setPrices={setPrices}
                agirlik={agirlik}
                agirlik0={agirlik[0]}
                agirlik1={agirlik[1]}
                setAgirlik={setAgirlik}

                pricess={pricess}
                setPricess={setPricess}
                agirliks={agirliks}
                agirliks0={agirliks[0]}
                agirliks1={agirliks[1]}
                setAgirliks={setAgirliks}
                 />
                
              </div>
            </div>
          </div>
        </div>





      
        <div className="row map-top">
          {/* {houses[0].map} */}
          <div className="col-lg-6 col-md-6 map-left">
            <div className="map-section">
              
            {/* <iframe src="https://my.atlistmaps.com/map/a59598b8-f7f6-4e3f-a907-ef737069008b?share=true" 
            allow="geolocation" width="100%" height="400px" 
            frameborder="0" scrolling="no" allowfullscreen></iframe> */}

              <Map locations={locations} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className=" pb-4">
              {/* <h6 className="">Real Estate & Homes For Sale</h6> */}
              <div className="">
                <div className="d-flex buy-button">
                  {/* <button className="btn btn-warning btn-botton1 rounded-pill">
                    1,780 Agent listings
                  </button>
                  <button className="btn btn-success btn-botton2 rounded-pill">
                    120 Other listing
                  </button> */}
                  <div className="sort-list">
                    <p>Sort by&nbsp;: &nbsp;</p>
                    <select value={value} onChange={setList}>
                        <option value="Select">Select</option>
                        <option value="Highest to Lowest">Highest to Lowest</option>
                        <option value="Lowest to Highest">Value for Money</option>
                        <option value="Newest">Newest</option>
                        {/* <option value="Newest">Newest</option> */}
                    </select>
                </div>
                </div>
              </div>
            </div>

            <div className="row scroll-hotel">
              {/* <ProductsList /> */}
              {/* {searchShow ?  searchList() : <ProductsList sortProducts={sortProducts} /> } */}
                
              <Conditioning />

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CommercialSpace;