import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { collection,getDoc,getDocs,query,updateDoc,deleteDoc,doc,where} from 'firebase/firestore';
import db from "../../../components/Firebase";
import Button from '@mui/material/Button';
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../components/Firebase";

const sendVerifiedAgentEmail = httpsCallable(functions, 'sendVerifiedAgentEmail');


const handleVerificationClick = async (documentId) => {
    try {
      const docRef = doc(db, 'agentverificationrequest', documentId);
      await updateDoc(docRef, { verified: true });
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const email = docSnap.data().email;
          sendVerifiedAgentEmail(email);
          alert('Agent is Now Verified')
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
          alert('Agent not yet verified')
        }

      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('Agent not yet verified')
    }
  };

  const handleDelete = async (documentId) =>{
    await deleteDoc(doc(db, 'agentverificationrequest', documentId));
  }
  
const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'email',
    headerName: 'EMAIL',
    width: 150,
    editable: true,
  },
  {
    field: 'idType',
    headerName: 'ID TYPE',
    width: 150,
    editable: true,
  },
  {
    field: 'idNumber',
    headerName: 'ID NUMBER',
    width: 150,
    
  },
  {
    field: 'downloadURL',
    headerName: 'IMAGE',
    width: 210,
    
  },

  {
    field: 'verifyButton',
    headerName: '',
    width: 110,
    renderCell: (params) => (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleVerificationClick(params.row.id)}
      >
        Verify
      </Button>
    ),
  },

  {
    field: 'deleteButton',
    headerName: '',
    width: 110,
    renderCell: (params) => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDelete(params.row.id)}
      >
        Delete
      </Button>
    ),
  },

//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
];



export default function AgentVerifcation() {

    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const data = [];
          const q = query(collection(db, 'agentverificationrequest'), where("verified", "==", false));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            data.push({
              id: doc.id,
              ...doc.data(),
            });
          });
    
          setRows(data);
        };
    
        fetchData();
      }, []);

      

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}