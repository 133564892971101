import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./index.css";
import { UserContext } from "../../../App";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import db from '../../../components/Firebase';
import {getDocs,collection,query, where} from "firebase/firestore";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';




function HighestRated() {
  const [highestRatedProperties, setHighestRatedProperties] = useState ([]);
  const user = React.useContext(UserContext)
  const navigate = useNavigate();


  useEffect (async () => { 
    let charles = []
    
         const propertiesRef = collection(db, "properties");
         const q = query(propertiesRef, where("district", "==", 'Ikoyi'));
         const querySnapshot = await getDocs(q);
    
         querySnapshot.forEach((doc) => {charles.push(doc.data())
           charles = charles.sort((a,b)=>{return a.price-b.price})
           setHighestRatedProperties([...charles])  
          });
   },[]);

  
  function handleMove() {
    navigate("/admin/agent/properties/update");
  }
  function handleMoveProperties() {
    navigate("/admin/agent/properties/view");
  }
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Highest Rated Properties</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">Agent</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        {/*<section className="section profile p-5 table-responsive agent-main">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/Buying(1).png"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                         {secondsToDhms(data?.dateAdded.seconds)} hours ago 
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div className="" style={{ paddingRight: "10px" }}>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMove}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "yellow" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/hotel 1(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                         {secondsToDhms(data?.dateAdded.seconds)} hours ago 
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div className="" style={{ paddingRight: "10px" }}>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMove}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "yellow" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/hotel 3(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                         {secondsToDhms(data?.dateAdded.seconds)} hours ago
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div className="" style={{ paddingRight: "10px" }}>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMove}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "yellow" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/Hotel 2(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                         {secondsToDhms(data?.dateAdded.seconds)} hours ago 
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div className="" style={{ paddingRight: "10px" }}>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMove}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "yellow" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
         </section>*/}

{highestRatedProperties.map((x,i)=> ((
<Grid  key = {i} sx = {{m:1, p:1,width: 300, display:'inline-block'}}>
 <Grid item>

    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="140"
        image = {x.images}
        alt="image"
        />
      <CardContent>
        {/*<Typography gutterBottom variant="h5" component="div">
        {x.propertyType}
        </Typography>*/}
        <Typography variant="body2" color="text.secondary">
        {x.propertyDescription}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {x.price.toLocaleString("en-NG",{style:"currency",currency:"NGN"})}
        </Typography>
        <Typography variant="body2" color="text.secondary">
        {x.district}
        </Typography>
      </CardContent>
      <CardActions>
        <Button sx={{bgcolor:'#800080', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}>View</Button>
      </CardActions>
    </Card>
    </Grid>
  </Grid>
)))}
      </main>
    </>
  );
}

export default HighestRated;