import React, { useState, useEffect } from "react";
import { UserContext } from "../../../../App";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import db from "../../../Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
function ProfileOverview({ loading, setLoading }) {
  const user = React.useContext(UserContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    async function getSingleAgent() {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data(), "here is the data logged", user?.uid);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  }, [user]);

  useEffect(() => {
    setLoading(true);
    async function getSingleAgent() {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data(), "here is the data logged", user?.uid);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  }, [user]);
  return (
    <>
      <div
        className="tab-pane fade show active profile-overview"
        id="profile-overview"
      >
        <h5 className="card-title">About</h5>
        <p className="small fst-italic">
          {data?.about ? data?.about : "About details has not be uploaded yet"}
        </p>

        <h5 className="card-title">Profile Details</h5>

        <div className="row">
          <div className="col-lg-3 col-md-4 label ">Username</div>
          <div className="col-lg-9 col-md-8">
            {user ? user.displayName : null}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4 label">Country</div>
          <div className="col-lg-9 col-md-8">Nigeria</div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4 label">Phone</div>
          <div className="col-lg-9 col-md-8">
            {data?.phone ? data?.phone : "phone no not updated yet"}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4 label">Email</div>
          <div className="col-lg-9 col-md-8">{user ? user.email : null}</div>
        </div>
      </div>
    </>
  );
}

export default ProfileOverview;
