import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { httpsCallable } from "firebase/functions"
import { functions } from "./Firebase";

export default function AskChekamButton() {

    const [open, setOpen] = React.useState(false);


    const handleClickOpen = () => {setOpen(true);};
    const handleClose = () => {setOpen(false);};
    
    const sendEmail = httpsCallable(functions, 'sendEmail');

  return (
    <>
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab variant="extended" color="primary" onClick={handleClickOpen} sx={{mr:4, top: { lg: 400, md: 400, sm: 350, xs: 350 }, float:'right'}}>
        <NavigationIcon sx={{ mr: 1 }} />
        ASK CHEKAM
      </Fab>
    </Box>
    <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            const name = formJson.name;
            const message = formJson.message;
            sendEmail({name,email,message})
              .then((result) => {
                console.log(result.data);
              })
              .catch((error) => {
                console.error('Error calling Callable Function:', error);
              });
            alert('Your message has been submitted');
            handleClose();
          },
        }}
      >
        <DialogTitle>Ask Chekam</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can ask chekam for special checks and enquiries, please complete the form. Our Agent will send you a feedback
          </DialogContentText>
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="email"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
          id="message"
          name="message"
          label="Message"
          multiline
          rows={4}
          fullWidth
        />
        </DialogContent>
        <DialogActions>
          <Button variant="outline" color="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="secondary"type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}