import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import Button from '@mui/material/Button';
import { makeStyles} from '@mui/styles';
import { ThemeProvider,createTheme } from '@mui/material/styles';
import logo from '../images/chekamlogo.png';
import {Link,useNavigate} from 'react-router-dom';
import { UserContext } from "../App";


const theme = createTheme({
  palette: {
    primary: {
      light:'#FFCC00',
      main: '#FFFFFF',
      dark: '#800080',
    },
    secondary: {
      light:'#FFCC00',
      main: '#FFCC00',
      dark: '#800080',
    },
  },
});

const useStyles = makeStyles((theme) => ({

logo:{
    width:150,
    padding:10,
    marginRight:20,
    zIndex:3
  }
}));



export default function TenantAppbar() {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = React.useContext(UserContext)
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ flexGrow: 1, bgcolor:'primary' }}>
      <AppBar position="static">
        <Toolbar>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
           <Link   to = "/" > <img variant = 'contained' className = {classes.logo} src={logo} alt="Logo" /></Link>
        </Typography>
          
        </Toolbar>
      </AppBar>
    </Box>
    </ThemeProvider>
  );
}