import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { PaystackButton } from "react-paystack";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "../../../components/Firebase";
import { UserContext } from "../../../App";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import API_URL from "../../../config/index.js";
import axios from "axios";
function PromoteModal({
  setShow,
  show,
  properties,
  loading,
  setLoading,
  message,
  setMessage,
}) {
  const user = React.useContext(UserContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState([5000, 50, 100]);

  const publicKey = "pk_live_3edf5145dc961ec4b761a4b94c25490ed04dd4f6";

  const calculate = value * properties?.length;
  const amount = calculate * 100;

  let formated = moment(new Date()).format("l h:mm:ss A");
  // console.log(formated, "testing date")

  const expiredNextMonth = (date) => {
    // var date = new Date();
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      splittingdate[1]
    );
    return moment(monthexpireddate).format("l h:mm:ss A");
  };
  function nextWeek() {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let formated = moment(nextweek).format("l h:mm:ss A");
    return formated;
  }
  function getTomorrow() {
    var today = new Date();
    var tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );

    let formated = moment(tomorrow).format("l h:mm:ss A");
    return formated;
  }
  function getToday() {
    var today = new Date();

    let formated = moment(today).format("l h:mm:ss A");
    return formated;
  }

  const expireDaily = getTomorrow();
  const expireWeekly = nextWeek();
  const expireMonthly = expiredNextMonth();

  const today = getToday();
  console.log(today, "today is here");

  // console.log(expireDaily, "daily");
  // console.log(expireWeekly, "weekly");
  // console.log(expireMonthly, "monthly");

  const componentRatingsProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => {
      //   const propertiesRef = doc(db, "properties", user?.uid);
      if (amount === "5000") {
        properties?.map((proid) => {
          const propertiesRef = doc(db, "properties", proid);
          updateDoc(propertiesRef, {
            promotion: "daily",
            promotionExpiring: expireDaily,
            emailPromotion: email,
            sponsored: true,
            datePromoted: today,
            agentPromoted: name,
          }).then(() => {
            axios
              .post(API_URL, {
                name: name,
                email: email,
                phone: phone,
                email_type: "promotion-success",
                length: properties?.length,
                expiring: expireDaily,
                subscription: "daily",
              })
              .then(() => {
                setLoading(false);
                setShow(false);
                setMessage("Payment Successful... we'll get back to you");
              });
            //   console.log("updated", proid);
          });
        });
      } else if (amount === "10000") {
        properties?.map((proid) => {
          const propertiesRef = doc(db, "properties", proid);
          updateDoc(propertiesRef, {
            promotion: "weekly",
            promotionExpiring: expireWeekly,
            emailPromotion: email,
            sponsored: true,
            datePromoted: today,
            agentPromoted: name,
          }).then(() => {
            axios
              .post(API_URL, {
                name: name,
                email: email,
                phone: phone,
                email_type: "promotion-success",
                length: properties?.length,
                expiring: expireWeekly,
                subscription: "weekly",
              })
              .then(() => {
                setLoading(false);
                setShow(false);
                setMessage("Payment Successful... we'll get back to you");
              });
            //   console.log("updated", proid);
          });
        });
      } else if (amount === "15000") {
        properties?.map((proid) => {
          const propertiesRef = doc(db, "properties", proid);
          updateDoc(propertiesRef, {
            promotion: "monthly",
            promotionExpiring: expireMonthly,
            emailPromotion: email,
            sponsored: true,
            datePromoted: today,
            agentPromoted: name,
          }).then(() => {
            axios
              .post(API_URL, {
                name: name,
                email: email,
                phone: phone,
                email_type: "promotion-success",
                length: properties?.length,
                expiring: expireMonthly,
                subscription: "monthly",
              })
              .then(() => {
                setLoading(false);
                setShow(false);
                setMessage("Payment Successful... we'll get back to you");
              });
            //   console.log("updated", proid);
          });
        });
      }
      //   alert("Payment Successful! close and continue");
    },
    onClose: () => alert("closed"),
  };

  const handleSuscribe = () => {
    const userRef = doc(db, "users", user?.uid);
    updateDoc(userRef, { subscribed: "none", expiring: "expired" }).then(() => {
      alert("Updated successfully");
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    // console.log(event.target.value);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const urltype = urlParams.get("type");
  const newParams = urlParams.get("q");

  const handleRefresh = () => {
    return (window.location.href = `/selected-item?q=${newParams}`);
  };

  const handlePromotion = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login?login=redirectTrue", {
        state: {
          redirect: true,
          redirectUrl: `/selected-item?q=${newParams}&plan=${value}&name=${name}&phone=${phone}&email=${email}&type=suscribe`,
        },
      });
    }
    // else {
    //   navigate("/user/suscribe");
    // }
  };
  return (
    <div id="mode-modal">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Promotion plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                required
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
            </Form.Group>
            <div className="d-flex justify-content-between align-items-center">
              <strong>
                Promotion&nbsp;
                {properties?.length === 1
                  ? `of a property`
                  : `of ${properties?.length} properties`}
              </strong>

              <strong>&#8358; {calculate?.toLocaleString()}</strong>
            </div>
            <Form.Group
              className="mb-3"
              controlId="formBasicEmail"
              onChange={(e) => handleChange(e)}
            >
              <Form.Label>Subscription Package</Form.Label>
              <RadioGroup
                required
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="5000"
                  control={<Radio />}
                  label="N5,000 Weekly"
                />
                <FormControlLabel
                  value="15000"
                  label="N15,000 Monthly"
                  control={<Radio />}
                />
              </RadioGroup>
            </Form.Group>
            {!user ? (
              <Button onClick={(e) => handlePromotion(e)}>Pay now</Button>
            ) : (
              <PaystackButton {...componentRatingsProps} />
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          {/*
          <Button variant="primary" onClick={() => handleSuscribe()}>
            Understood
          </Button>
        */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PromoteModal;
