import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import ad from "../images/onlinead.webp";

import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
function ShortletPaginated({ handleId, data, type }) {
  const [agentid, setId] = useState(null);
  const [datas, setData] = useState(null);
  let naira = "\u20A6";
  const navigate = useNavigate();

  const handleAgentInfo = (id, e) => {
    setId(agentid);

    async function getSingleAgent() {
      const docRef = doc(db, "users", id?.agentId);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();
      return (window.location.href = `/properties/${id?.agentName}?n=${
        id?.agentId
      }&companyname=${agentData?.company}&image=${
        data?.photoURL === undefined || data?.photoURL === ""
          ? "none"
          : data?.photoUrl
      }`);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  };
  // useEffect(() => {
  //   if (datas) {
  //     return (window.location.href = `/properties/${datas?.agentName}?n=${id}&company-name=${datas?.company}`);
  //   }
  // }, [datas]);
  // //console.log(id);
  // useEffect(() => {
  //   async function getSingleAgent() {
  //     const docRef = doc(db, "users", id);
  //     const docSnap = await getDoc(docRef);
  //     // setLoading(false);
  //     setData(docSnap.data());
  //     console.log(doc?.data());
  //     if (docSnap.exists()) {
  //       //console.log("Document data:", docSnap.data());
  //     } else {
  //       //console.log("No such document!");
  //     }
  //   }
  //   getSingleAgent();
  //   // setLoading(false);
  // }, [agentid]);

  return (
    <>
      {data?.sponsored === true ? (
        <div
          className="col-lg-3 col-md-6 col-sm-6 pb-2 product-card"
          key={data?.id}
        >
          <div className="card card-style" style={{ height: "310px" }}>
            <div className="product-inner-card">
              <a
                // href="/single-buy"
                style={{ textDecoration: "none" }}
                // data-bs-toggle="modal"
                // data-bs-target="#mainmodal"
                onClick={(e) => handleId(data?.id, e)}
                // onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
              >
                <a>
                  <img
                    src={
                      data?.images === null || data?.images?.length === 0
                        ? "images/placeholder.png"
                        : data?.images[0]
                    }
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </a>
                <div className="top-house">
                  <p className="text-danger top-time text-white">
                    sponsored
                    {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                  </p>
                </div>
              </a>
            </div>

            <div className="card-body" style={{}}>
              <p className="card-text" style={{ fontSize: "12px" }}>
                <span className="price">
                  {naira} {data?.price?.toLocaleString()}
                </span>
                <span style={{marginLeft: "5px"}}>per night</span>
                <br />

                <span className="card-details">
                  {data?.numberOfBedrooms} Bds {data?.numberOfBathrooms} Ba -
                  House for {data?.propertyPurpose}
                </span>
                <br />
                <span className="card-address">
                  {data?.streetName && data?.streetName + ","} {data?.state}
                </span>
                <br />
                <span className="card-addresss">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "100%" }}
                  >
                    <span className="agent-name">
                      <strong
                        onClick={(e) => handleAgentInfo(data, e)}
                        style={{ cursor: "pointer" }}
                      >
                        {data?.agentName}
                      </strong>
                    </span>

                    <span className="ratings">
                      <StarRatings
                        rating={
                          data?.userRating
                            ? Number(data?.userRating)
                            : 0
                        }
                        // rating={5}
                        // changeRating={data?.starRating}
                        numberOfStars={5}
                        name="rating"
                        color="purple"
                        starRatedColor="yellow"
                        starHoverColor="purple"
                        starDimension="12px"
                      />
                    </span>
                  </div>
                  <div style={{ width: "100% !important" }}>
                    <p>
                      {data?.userRated?.length}{" "}
                      {data?.userRated?.length === 0 ||
                      data?.userRated?.length === 1
                        ? "review"
                        : "reviews"}
                    </p>
                  </div>
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="col-lg-3 col-md-6 col-sm-6 pb-4 product-card"
          key={data?.id}
        >
          <div className="card card-style" style={{ height: "310px" }}>
            <div className="product-inner-card">
              <a
                // href="/single-buy"
                style={{ textDecoration: "none" }}
                // data-bs-toggle="modal"
                // data-bs-target="#mainmodal"
                onClick={(e) => handleId(data?.id, e)}
                // onClick={() =>  openInNewTab(`/selected-item?q=${data?.id}`)}
              >
                <a>
                  <img
                    src={
                      data?.images === null || data?.images?.length === 0
                        ? "images/placeholder.png"
                        : data?.images[0]
                    }
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </a>
                <div className="top-house">
                  <p className="text-danger top-time text-white">
                    {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                  </p>
                </div>
              </a>
            </div>

            <div className="card-body" style={{}}>
              <p className="card-text" style={{ fontSize: "12px" }}>
                <span className="price">
                  {naira} {data?.price.toLocaleString()} 
                </span>
                <span style={{marginLeft: "5px"}}>per night</span>
                <br />

                <span className="card-details">
                  {data?.numberOfBedrooms} Bds {data?.numberOfBathrooms} Ba -
                  House for {data?.propertyPurpose}
                </span>
                <br />
                <span className="card-address">
                  {data?.streetName && data?.streetName + ","} {data?.state}
                </span>
                <br />

                <span className="card-address">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "100%" }}
                  >
                    <span className="agent-name">
                      <strong
                        // onClick={() =>
                        //   (window.location.href = `/properties/${data?.agentName}?n=${data?.id}`)
                        // }
                        onClick={(e) => handleAgentInfo(data, e)}
                        style={{ cursor: "pointer", marginRight: "10px" }}
                      >
                        {data?.agentName}
                      </strong>
                    </span>
                    <span className="ratings">
                      <StarRatings
                        rating={
                          data?.userRating
                            ? Number(data?.userRating)
                            : 0
                        }
                        // rating={5}
                        // changeRating={data?.starRating}
                        numberOfStars={5}
                        name="rating"
                        color="purple"
                        starRatedColor="yellow"
                        starHoverColor="purple"
                        starDimension="12px"
                      />
                    </span>
                  </div>
                  <div style={{ width: "100% !important" }}>
                    <p>
                      {data?.userRated?.length}{" "}
                      {data?.userRated?.length === 0 ||
                      data?.userRated?.length === 1
                        ? "review"
                        : "reviews"}
                    </p>
                  </div>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShortletPaginated;