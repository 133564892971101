import React, {useState, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import logo from '../images/chekamlogo.png';
import db from  './Firebase';
import Box from '@mui/material/Box';
//import Rating from '@mui/material/Rating';
import {getDocs,collection,deleteDoc,updateDoc,setDoc,update,writeBatch, addDoc,serverTimestamp,doc, getDoc,query, where, } from "firebase/firestore";
import {storage} from './Firebase'
import ImagesDropZone from "./ImagesDropZone";
import ImageElement from "./ImageElement";
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL, } from "firebase/storage";
//import InputAdornment from '@mui/material/InputAdornment';



function getProductId(length) {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charLength = chars.length;
  var result = '';
  for ( var i = 0; i < length; i++ ) {
     result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}



const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

let { mean,std } = require('mathjs');
/*async function handleDelete() {
  await deleteDoc(doc(db, "propertities", "DC"));
}*/




export default function Property() {
    
    /*const [chekam, setChekam] = useState ([]);
    const [imageList, setImageList] = useState([]);
    const [imageURL,setImageURL] = useState([]);


    const metadata = {
      contentType: 'image/jpeg'
    };

    const handleChangeOrderUp = (index) => {
      // If first, ignore
      if (index !== 0) {
         const newArray = [...imageList];
         const intermediate = newArray[index - 1];
         newArray[index - 1] = newArray[index];
         newArray[index] = intermediate;
         setImageList(newArray);
      }
    };
    
    const handleChangeOrderDown = (index) => {
      // If last, ignore
      if (index < imageList.length - 1) {
         const newArray = [...imageList];
         const intermediate = newArray[index + 1];
         newArray[index + 1] = newArray[index];
         newArray[index] = intermediate;
         setImageList(newArray);
      }
    };
    
    const changeImageField = (index, parameter, value) => {
      const newArray = [...imageList];
      newArray[index][parameter] = value;
      setImageList(newArray);
    };
    
    const handleDeleteImage = (index) => {
      //imageList[index].storageRef
        // .delete()
        // .then(() => {
            const newArray = [...imageList];
            newArray.splice(index, 1);
            setImageList(newArray);
        // })
        // .catch((error) => {
        //    console.log("Error deleting file:", error);
        // });
    };
    
    useEffect(() => {
      imageList.forEach((image, index) => {
         if (image.status === "FINISH" || image.status === "UPLOADING") return;
         changeImageField(index, "status", "UPLOADING");
    
         const  fileName = image.file.name
         const imagesRef = ref(storage,fileName);
         const uploadTask = uploadBytesResumable (imagesRef, image.file,metadata);
      
        uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // Handle successful uploads on complete
       
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          imageURL.push(downloadURL)
          changeImageField(index, "downloadURL", downloadURL);
          changeImageField(index, "status", "FINISH");
        });
      }
    );
      });
    });*/


  async function handleUpdate() {
  

    //const batch = writeBatch(db);

  const documents = []
  //const querySnapshot = await getDocs(collection(db, "properties"));

   const propertiesRef = collection(db, "properties");
   const q = query(propertiesRef, where("propertyPurpose","==","sale"));
   const querySnapshot = await getDocs(q)
   querySnapshot.forEach((doc) => {
    documents.push(doc.id);
  });


   async function updateNow  (i){
   const propertiesRef = doc(db, 'properties', i);
  
   
  
  // const propertiesRef = doc(db, 'properties', 'Zqybs6KoHHqozVGnN7oW');
   const docSnap = await getDoc(propertiesRef);
    if (docSnap.exists()) {



    
      {/*let propertyType = docSnap.data().propertyType,
      propertyPurpose = docSnap.data().propertyPurpose,
      verified = docSnap.data().verified,
      price = docSnap.data().price,
      agencyPercentage = docSnap.data().agencyFee,
      
      architectureScore = docSnap.data().architectureScore,
      securityScore = docSnap.data().securityScore,
      privacyLandlordRelationshipScore = docSnap.data().privacyLandlordRelationshipScore,
      compoundFacilitiesScore = docSnap.data().compoundFacilitiesScore,
      optimalLocationScore = docSnap.data().optimalLocationScore,
      estateFactorScore = docSnap.data().estateFactorScore,
      titledocument = doc.Snap.data().titledocument
      

     
      let agencyFee = (agencyPercentage/100)*price

      let overallScore = () => {
        if (propertyPurpose === 'rent') return (mean(securityScore,privacyLandlordRelationshipScore,compoundFacilitiesScore,architectureScore))
        else if (propertyPurpose === 'sale' && propertyType !== 'land') return ((optimalLocationScore*mean(securityScore,estateFactorScore,compoundFacilitiesScore,architectureScore,verified,titledocument))/5)
        else if (propertyType === 'land') return ((optimalLocationScore*mean(securityScore,estateFactorScore,compoundFacilitiesScore,architectureScore,verified,titledocument))/5)
        else if (propertyType === 'commercial space') return ((optimalLocationScore*mean(securityScore,estateFactorScore,compoundFacilitiesScore,architectureScore,verified))/5)
        //else if (propertyType === 'hotel') return (mean(securityScore(),starRating,estateFactorScore(),architectureScore(),verified))
        else return (0)
      }

     
     let valueForMoneyScore = ()=> { return overallScore()/price}
    */}



      await updateDoc (propertiesRef, {
        verificationStatus:"unverified"
        //propertyPurpose:"rental"
        //updated:serverTimestamp()
        //id:docSnap.id,
        //productId:getProductId(7)
        //images:imageURL,
        //agencyFee:agencyFee,
        //agentName:'Francisca'
       // dateAdded:serverTimestamp(),
        //overallScore:overallScore(),
        //valueForMoneyScore:valueForMoneyScore(),
        /*bathroomLength: 0,
        bathroomBreadth:0,
        bedroomLength: 0,
        bedroomBreadth:0,
        diningRoomLength: 0,
        diningRoomBreadth:0,
        livingRoomLength:0,
        livingRoomBreadth:0,
        kitchenTriangleLengthA:0,
        kitchenTriangleLengthB:0,
        kitchenTriangleLengthC:0,
        cautionFee:0,
        legalFee:0,
        commercialFloorSize:0,
        nonEstateEntryAndExit:0,
        sameStreetAsSensitiveLocation:0,
        securityScoreOfStreet:0
        //timestamp: serverTimestamp(),
      //last_updated:serverTimestamp()*/
    })
  

    //} else {
      // doc.data() will be undefined in this case
    //  console.log("No such document!");
    //}
  }
}
  for (const x of documents){updateNow(x)}
//updateNow()


    

    //const propertiesRef = doc(db, 'properties', 'wmZ5rlhhkn9pshtrAI7N');
    //console.log(propertiesRef)
    //setDoc(propertiesRef, { diningArea: 88 }, { merge: true });

     // await setDoc(doc(db, "properties", "wmZ5rlhhkn9pshtrAI7N"), {
     //   diningRoomArea:77
     // });

      
    }
  
    /*const percentile = (arr,num,x) => {arr = arr.filter((item)=>item.district===x.district);
      arr = arr.map((item)=>item.valueForMoneyScore);
      arr = arr.sort((a,b)=>a-b);
      return (arr.filter((item)=>item<=num).length/arr.length)*100}
     
    useEffect (async () => { 
     let charles = []
     
     const querySnapshot = await getDocs(collection(db, "properties"));
     querySnapshot.forEach((doc) => {charles.push(doc.data())
     charles = charles.sort((a,b)=>{return a.price-b.price})
     setChekam([...charles])  
    });
    },[]);*/
   
    
  return (
   
   <Box >
   {/*{chekam.map((x,i)=> ((
    
      <Grid  key = {i} sx = {{m:1, p:1, border:'solid',width: 300, display:'inline-block'}}>*/}
      
        {/*<Grid item>
          <ButtonBase sx={{ width: 150, height: 150 }}>
          {x.images?<img variant = 'contained' height={100} width={100}  src={x.images[0]} alt="Logo" />:null}
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography  key = {x}  component="div">
                {x.propertyType}
              </Typography>
              <Typography  component="div"> 
                {x.propertyPurpose}
              </Typography>
              <Typography  component="div">
                {x.propertyDescription}
              </Typography>*/}
              


{/*<Grid>
              <Grid item container xs={12} justify="center">
                  <ImagesDropZone setImageList={setImageList} />
              </Grid>
              {imageList.length > 0 && (
            <Box bgcolor="primary" >
               {imageList.map((image, index) => {
                  return (
                     <Grid item key={image.file.size + index}>
                        <ImageElement
                           image={image}
                           index={index}
                           isFirstElement={index === 0}
                          isLastElement={index === imageList.length - 1}
                          handleChangeOrderUp={handleChangeOrderUp}
                          handleChangeOrderDown={handleChangeOrderDown}
                          handleDeleteImage={handleDeleteImage}
                        />
                     </Grid>
                  );
               })}
            </Box>
         )}

              {/*<Typography component="legend" variant="body2"  gutterBottom>Rating <span>{x.overallScore.toFixed(2)}</span></Typography>
              <Rating size="small" name = "rating" value={x.overallScore} readOnly />
              <Typography component="div" >Value For Money: <span> {Math.round(percentile(chekam,x.valueForMoneyScore,x))}th percentile in {x.district}</span></Typography>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
              
              <Button size = 'small' variant ="contained" onClick={handleUpdate} >
                UPDATE
                </Button>
           {/* </Grid>*/}
         {/*</Grid>*/}
          {/*<Grid item>
            <Typography variant="subtitle1" >
              {x.price.toLocaleString("en-NG",{style:"currency",currency:"NGN"})}
            </Typography>
            </Grid>*/}
        {/*</Grid>*/}
      {/*</Grid>*/}
        {/*)))}*/}
    </Box>
         
    
  );
}