import React from "react";
import Header from "./AdminHeader";
import Sidebar from "./AdminSidebar";
import ModalDelete from "./ModalDelete";
import "./user.css";

function AllPropertymanager() {
  let agents = [
    {
      Name: "Alfreds Futterkiste",
      City: "Berlin",
      Country: "Germany",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Ana Trujillo Emparedados y helados",
      City: "México D.F.",
      Country: "Mexico",
      status: "Active",
      level: "User level 2",
    },
    {
      Name: "Antonio Moreno Taquería",
      City: "México D.F.",
      Country: "Mexico",
      status: "Active",
      level: "User level 2",
    },
    {
      Name: "Around the Horn",
      City: "London",
      Country: "UK",
      status: "Offline",
      level: "User level 1",
    },
    {
      Name: "B's Beverages",
      City: "London",
      Country: "UK",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Berglunds snabbköp",
      City: "Luleå",
      Country: "Sweden",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Blauer See Delikatessen",
      City: "Mannheim",
      Country: "Germany",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Blondel père et fils",
      City: "Strasbourg",
      Country: "France",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Bólido Comidas preparadas",
      City: "Madrid",
      Country: "Spain",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Bon app'",
      City: "Marseille",
      Country: "France",
      status: "Offline",
      level: "User level 1",
    },
    {
      Name: "Bottom-Dollar Marketse",
      City: "Tsawassen",
      Country: "Canada",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Cactus Comidas para llevar",
      City: "Buenos Aires",
      Country: "Argentina",
      status: "Active",
      level: "User level 1",
    },
    {
      Name: "Centro comercial Moctezuma",
      City: "México D.F.",
      Country: "Mexico",
      status: "Offline",
      level: "User level 1",
    },
    {
      Name: "Chop-suey Chinese",
      City: "Bern",
      Country: "Switzerland",
      status: "Offline",
      level: "User level 1",
    },
    {
      Name: "Comércio Mineiro",
      City: "São Paulo",
      status: "Active",
      Country: "Brazil",
      level: "User level 1",
    },
  ];
  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Property Manager</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              {/* <li className="breadcrumb-item">Property Manager</li> */}
              <li className="breadcrumb-item active">Admin</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive">
          <div
            className="container"
            data-ng-app="myApp"
            data-ng-controller="myCtrl"
          >
            <div className="row">
              <div className="col-md-2">Search:</div>
              <div className="col-md-10">
                <input type="text" className="search" data-ng-model="table" />
              </div>
            </div>
            <br />
            <table className="">
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Country</th>
                <th>Status</th>
                <th>View</th>
                <th>Delete</th>
              </tr>
              {agents?.map((agent) => {
                return (
                  <tr>
                    <td>{agent.Name}</td>
                    <td>{agent.City}</td>
                    <td>{agent.Country}</td>
                    <td>{agent.status}</td>
                    <td>
                      <a
                        href="/admin/single-properymanager"
                        className="btn btn-success"
                      >
                        View
                      </a>
                    </td>
                    <td>
                      <a
                        href=""
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                );
              })}
            </table>
            <ModalDelete type="PROPERTY MANAGER" name="Alfred Futterkiste" />
            <div
              data-pagination=""
              data-num-pages="numPages()"
              data-current-page="currentPage"
              data-max-size="maxSize"
              data-boundary-links="true"
            ></div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AllPropertymanager;