import React from "react";
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getAuth, signOut} from "firebase/auth";
import Button from '@mui/material/Button';


// uncle willy
function ManagerSidebar() {

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(getAuth()).then(() => {
      // Sign-out successful.
      navigate('/')
    }).catch((error) => {
      // An error happened.
    });
  }




  return (
    <>
      {/* <!-- ======= ManagerSidebar ======= --> */}
      <aside
        id="sidebar"
        className="sidebar"
        style={{ background: "purple", color: "#fff !important" }}
      >
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <a className="nav-link " href="/admin/propertymanager/">
              <i className="bi bi-grid"></i>
              <span>Dashboard</span>
            </a>
          </li>
          {/* <!-- End Dashboard Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-target="#components-nav"
              data-bs-toggle="collapse"
              href="#"
            >
              <i className="bi bi-menu-button-wide"></i>
              <span>Properties</span>
              <i className="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
              id="components-nav"
              className="nav-content collapse "
              data-bs-parent="#sidebar-nav"
            >
              <li>
                <a href="/admin/propertymanager/properties">
                  <i className="bi bi-circle text-white"></i>
                  <span className="text-white">My Properties</span>
                </a>
              </li>
              {/* <li>
                <a href="/admin/property-manager/properties/upload">
                  <i className="bi bi-circle text-white"></i>
                  <span className="text-white">Upload New Properties</span>
                </a>
              </li> */}
            </ul>
          </li>
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/admin/propertymanager/properties/TenantForm"
            >
              <i className="bi bi-person"></i>
              <span>Add Tenant</span>
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/admin/propertymanager/properties/SaveApplication"
            >
              <i className="bi bi-person"></i>
              <span>Send Tenant Form</span>
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link collapsed" href="/admin/agent">
              <i className="bi bi-person"></i>
              <span>Switch to Agent suite</span>
            </a>
          </li>
          {/* <!-- End Profile Page Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/admin/propertymanager/profile"
            >
              <i className="bi bi-person"></i>
              <span>Profile</span>
            </a>
          </li>
          {/* <!-- End Profile Page Nav --> */}

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/admin/propertymanager/faqs"
            >
              <i className="bi bi-question-circle"></i>
              <span>F.A.Q</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link collapsed">
              <i className="bi bi-box-arrow-left"></i>
              <Typography onClick={handleSignOut}>Logout</Typography>
            </a>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default ManagerSidebar;