import React, { useState, useEffect } from "react";
import "./styles/Buy.css";
import Footer from "./Footer";
import { Spinner } from "react-bootstrap";
import Scroll from "./Scroll";
import SearchProductList from "./SearchProductList";
import Products from "./test/Products";
import filterList from "./test/filterList";
import filterBedroom from "./test/filterBedroom";
import filterMaisonette from "./test/filterMaisonette";
import filterLand from "./test/filterLand";
import filterBungalow from "./test/filterBungalow";
import ShortletHeader from "./ShortletHeader";
import Navbar from "./Navbar";
import Select from "react-select";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { addDoc,collection,serverTimestamp,query,getDocs,where,orderBy,limit} from "firebase/firestore";
import db from "./Firebase";
import { useNavigate } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import ProductList from "./ProductsList";
import filterTerrace from "./test/filterTerrace";
import filterDetached from "./test/filterDetached";
import filterSemiDetached from "./test/filterSemiDetached";
import filterTriplex from "./test/filterTriplex";
import filterStudio from "./test/filterStudio";
import filterAlcoveStudio from "./test/filterAlcoveStudio";
import filterConvertibleStudio from "./test/filterConvertibleStudio";
import filterConvertibleLoft from "./test/filterConvertibleLoft";
import filterDuplexTriplex from "./test/filterDuplexTriplex";
import filterJuniorBedroom from "./test/filterJuniorBedroom";
import filterGarden from "./test/filterGarden";
import filterRailroad from "./test/filterRailroad";
import filterFloorThrough from "./test/filterFloorThrough";
import filterCommercial from "./test/filterCommercial";
import filterHouse from "./test/filterHouse";
import filterApartment from "./test/filterApartment";
import styled from "styled-components";
import { UserContext } from "../App";
import SuscriberModal from "./SuscriberModal";
import AskChekamButton from "./AskChekamButton";


function Shortlet() {
  const [modalshow, setModalShow] = useState(false);
  const location = useLocation();
  const [users, setUser] = useState(null);
  const [type, setType] = useState("none");
  const [select, setSelect] = useState("");
  const [show, setShow] = useState(false);
  const [stickyClass, setStickyClass] = useState("");
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const places = [];
  const [loading, setLoading] = useState(false);
  const [colorTrue, setColorTrue] = useState("");
  const [data, setData] = useState("");
  const [district, setDistrict] = useState(null);
  const [locations, setLocation] = useState(null);
  const [others, setOthers] = useState(null);
  const [verifiedYes, setVerifiedYes] = useState("");
  const [verifiedNo, setVerifiedNo] = useState("");

  const user = React.useContext(UserContext);

  const urlParams = new URLSearchParams(window.location.search);
  const homeSearch = location?.state?.propertySearch;
  useEffect(() => {
    async function getPlaces() {
      setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(propertiesRef,where("propertyPurpose", "==", "shortlet"),orderBy("dateAdded","desc"));
      const array = [];
      if (homeSearch) {
        getDocs(q).then((data) => {
          setLoading(false);

          data?.forEach((doc) => {
            places.push(doc.data());


            const filterSearchProperty = places?.filter((data) => {
              return (
                data?.district === homeSearch || data?.streetName === homeSearch
              );
            });

            const sponsoredProperty = filterSearchProperty?.filter((data) => {
              return data?.sponsored === true;
            });
            const notSponsoredProperty = filterSearchProperty?.filter(
              (data) => {
                return (
                  data?.sponsored === false || data?.sponsored === undefined
                );
              }
            );
            setType("search");
            setDetails([...sponsoredProperty, ...notSponsoredProperty]);
            setProducts([...sponsoredProperty, ...notSponsoredProperty]);
            setLocation(sponsoredProperty, ...[notSponsoredProperty]);
            setOthers(sponsoredProperty, ...[notSponsoredProperty]);
          });
        });

        // console.log(places)
      } else {
        getDocs(q).then((data) => {
          setLoading(false);

          data?.forEach((doc) => {
            places.push(doc.data());

            const sponsoredProperty = places?.filter((data) => {
              return data?.sponsored === true;
            });
            const notSponsoredProperty = places?.filter((data) => {
              return data?.sponsored === false || data?.sponsored === undefined;
            });
            setDetails([...sponsoredProperty, ...notSponsoredProperty]);
            setProducts([...sponsoredProperty, ...notSponsoredProperty]);
            setLocation(sponsoredProperty, ...[notSponsoredProperty]);
            setOthers(sponsoredProperty, ...[notSponsoredProperty]);
          });
          setLoading(false);
        });
      }
    }
    getPlaces();
  }, []);

  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        documents.push(doc.data().streetName);
        documents.push(doc.data().district);
      });

      let uniqueChars = [...new Set(documents)];
      setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);

  const [datas, setDatas] = useState([]);

  // PRICE
  const [agirlik, setAgirlik] = useState([1, 1000000000]);
  const [prices, setPrices] = useState({
    hacim_min: 0,
    hacim_max: 1000000000,
  });

  useEffect(() => {
    let filteredPersons = details?.filter(
      (item) => item.price * 1 >= agirlik[0] && item.price * 1 <= agirlik[1]
    );
    setProducts(filteredPersons);
    setOthers(filteredPersons);
  }, [agirlik]);

  const filteredVerified = (e) => {
    e.preventDefault();
    if (verifiedYes === "") {
      setVerifiedYes("true");
    } else {
      setVerifiedYes("");
    }
    let filtered = details?.filter((item) => item?.verificationStatus === "verified");
    setProducts(filtered);
  };

  const filteredUnVerified = (e) => {
    e.preventDefault();
    if (verifiedNo === "") {
      setVerifiedNo("true");
      setProducts(others);
    } else {
      setVerifiedNo("");
    }
    let filtered = details?.filter((item) => item?.verificationStatus === "unverified" || "pending");
    setProducts(filtered);
  };
  // console.log(urlParams);
  const urlsearch = urlParams.get("q");

  const filteredHouseBungalow = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Bungalow"
      );
      setProducts(filtered);
    } else {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Bungalow"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };

  const filteredHouseTerrace = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Terrace"
      );
      setProducts(filtered);
    } else {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Terrace"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };

  //filtered house detached
  const filteredHouseDetached = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi Detached"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi Detached"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house  semi detached
  const filteredHouseSemiDetached = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi-Detached"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi-Detached"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house maisonette
  const filteredHouseMaisonette = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Maisonette"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Maisonette"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house duplex
  const filteredHouseDuplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house triplex
  const filteredHouseTriplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Triplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Triplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment studio
  const filteredApartmentStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Studio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Studio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment alcove studio
  const filteredApartmentAlcoveStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "AlcoveStudio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "AlcoveStudio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment convertible studio
  const filteredApartmentConvertibleStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Convertible Studio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Convertible Studio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment convertible loft
  const filteredApartmentConvertibleLoft = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "ConvertibleLoft"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "ConvertibleLoft"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment duplex triplex
  const filteredApartmentDuplexTriplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex/Triplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex/Triplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment junior bedroom
  const filteredApartmentJuniorBedroom = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Junior 1 Bedroom"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Junior 1 Bedroom"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment garden
  const filteredApartmentGarden = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Garden Apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Garden Apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment railroad
  const filteredApartmentRailroad = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Railroad Apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Railroad Apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment floor through
  const filteredApartmentFloorThrough = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Floor-through"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Floor-through"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Land
  const filteredLand = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter((item) => item?.properType === "land");
      setProducts(filtered);
    } else {
      let filtered = details?.filter((item) => item?.propertyType === "land");
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered House
  const filteredHouse = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter((item) => item?.propertyType === "house");
      setProducts(filtered);
    } else {
      let filtered = details?.filter((item) => item?.propertyType === "house");
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Apartment
  const filteredApartment = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyType === "apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyType === "apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Commercial Space
  const filteredCommercialSpace = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Commercial-Space"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Commercial-Space"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };

  // search
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  useEffect(() => {
    setType("filter");
    const filteredPersons = details?.filter((person) => {
      return (
        person?.district?.toLowerCase().includes(searchField.toLowerCase()) ||
        person?.streetName?.toLowerCase().includes(searchField.toLowerCase())
      );
    });
    setProducts(filteredPersons);
    setOthers(filteredPersons);
  }, [searchField]);

  const handleChange = (opt) => {
    setType("filter");
    setSearchField(`${opt?.value}`);
  };

  // Sizes BATHROOMS
  const [selectedSizes, setSelectedSizes] = useState([]);
  const setSize = (size) => {
    const sizes = [...selectedSizes];

    if (sizes?.includes(size)) {
      const index = sizes.indexOf(size);
      sizes?.splice(index, 1);
    } else {
      sizes?.push(size);
    }
    setSelectedSizes(sizes);
    // setProducts(filterList(sizes, "numberOfBathrooms", details));

    setProducts(filterList(sizes, "numberOfBathrooms", details, others));
    // setDetails(filterList(sizes, "numberOfBathrooms", details, others));
  };
  // BEDROOMS
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const setBedroom = (bedroom) => {
    const bedrooms = [...selectedBedrooms];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedBedrooms(bedrooms);
    setProducts(filterBedroom(bedrooms, "numberOfBedrooms", details, others));
    // setDetails(filterBedroom(bedrooms, "numberOfBathrooms", details, others));
    // console.log(bedrooms);
  };

  // for bungalows
  const [selectedBungalows, setSelectedBungalows] = useState([]);
  const setBungalow = (bedroom) => {
    const bedrooms = [...selectedBungalows];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedBungalows(bedrooms);
    setProducts(
      filterBungalow(bedrooms, "numberOfBungalows", products, others)
    );
    // setDetails(filterBungalow(bedrooms, "numberOfBungalows", products, others));
  };

  // end for bungalows
  // for Terrace
  const [selectedTerrace, setSelectedTerrace] = useState([]);
  const setTerrace = (bedroom) => {
    const bedrooms = [...selectedTerrace];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedTerrace(bedrooms);
    setProducts(filterTerrace(bedrooms, "numberOfTerrace", products, others));
    // setDetails(filterTerrace(bedrooms, "numberOfTerrace", products, others));
  };

  // end for terrace

  // for Detached
  const [selectedDetached, setSelectedDetached] = useState([]);
  const setDetached = (bedroom) => {
    const bedrooms = [...selectedDetached];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedDetached(bedrooms);
    setProducts(filterDetached(bedrooms, "numberOfDetached", products, others));
    // setDetails(filterDetached(bedrooms, "numberOfDetached", products, others));
  };

  // end for detached
  // for semi detached
  const [selectedSemiDetached, setSelectedSemiDetached] = useState([]);
  const setSemiDetached = (bedroom) => {
    const bedrooms = [...selectedSemiDetached];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedSemiDetached(bedrooms);
    setProducts(
      filterDetached(bedrooms, "numberOfSemiDetached", products, others)
    );
    // setDetails(
    //   filterSemiDetached(bedrooms, "numberOfSemiDetached", products, others)
    // );
  };

  // end for semi detached
  // for maisonette
  const [selectedMaisonette, setSelectedMaisonette] = useState([]);
  const setMaisonette = (bedroom) => {
    const bedrooms = [...selectedMaisonette];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedMaisonette(bedrooms);
    setProducts(
      filterMaisonette(bedrooms, "numberOfMaisonette", products, others)
    );
    // setDetails(
    //   filterMaisonette(bedrooms, "numberOfMaisonette", products, others)
    // );
  };

  // end for maisonette
  // for triplex
  const [selectedTriplex, setSelectedTriplex] = useState([]);
  const setTriplex = (bedroom) => {
    const bedrooms = [...selectedTriplex];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedTriplex(bedrooms);
    setProducts(filterTriplex(bedrooms, "numberOfTriplex", products, others));
    // setDetails(filterTriplex(bedrooms, "numberOfTriplex", products, others));
  };
  // end for triplex

  // for studio
  const [selectedStudio, setSelectedStudio] = useState([]);
  const setStudio = (bedroom) => {
    const bedrooms = [...selectedStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedStudio(bedrooms);
    setProducts(filterStudio(bedrooms, "numberOfStudio", products, others));
    // setDetails(filterStudio(bedrooms, "numberOfStudio", products, others));
  };
  // end for studio
  // for alcove studio
  const [selectedAlcoveStudio, setSelectedAlcoveStudio] = useState([]);
  const setAlcoveStudio = (bedroom) => {
    const bedrooms = [...selectedAlcoveStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedAlcoveStudio(bedrooms);
    setProducts(
      filterAlcoveStudio(bedrooms, "numberOfAlcoveStudio", products, others)
    );
    // setDetails(
    //   filterAlcoveStudio(bedrooms, "numberOfAlcoveStudio", products, others)
    // );
  };
  // end for alcove studio

  // for convertible studio
  const [selectedConvertibleStudio, setSelectedConvertibleStudio] = useState(
    []
  );
  const setConvertibleStudio = (bedroom) => {
    const bedrooms = [...selectedConvertibleStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedConvertibleStudio(bedrooms);
    setProducts(
      filterConvertibleStudio(
        bedrooms,
        "numberOfConvertibleStudio",
        products,
        others
      )
    );
    // setDetails(
    //   filterConvertibleStudio(
    //     bedrooms,
    //     "numberOfConvertibleStudio",
    //     products,
    //     others
    //   )
    // );
  };
  // end for convertible studio

  // for convertible loft
  const [selectedConvertibleLoft, setSelectedConvertibleLoft] = useState([]);
  const setConvertibleLoft = (bedroom) => {
    const bedrooms = [...selectedConvertibleLoft];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedConvertibleLoft(bedrooms);
    setProducts(
      filterConvertibleLoft(
        bedrooms,
        "numberOfConvertibleLoft",
        products,
        others
      )
    );
    // setDetails(
    //   filterConvertibleLoft(
    //     bedrooms,
    //     "numberOfConvertibleLoft",
    //     products,
    //     others
    //   )
    // );
  };
  // end for convertible loft

  // for duplex triplex
  const [selectedDuplexTriplex, setSelectedDuplexTriplex] = useState([]);
  const setDuplexTriplex = (bedroom) => {
    const bedrooms = [...selectedDuplexTriplex];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedDuplexTriplex(bedrooms);
    setProducts(
      filterDuplexTriplex(bedrooms, "numberOfDuplexTriplex", products, others)
    );
    // setDetails(
    //   filterDuplexTriplex(bedrooms, "numberOfDuplexTriplex", products, others)
    // );
  };
  // end for duplex triplex

  // for junior bedroom
  const [selectedJuniorBedroom, setSelectedJuniorBedroom] = useState([]);
  const setJuniorBedroom = (bedroom) => {
    const bedrooms = [...selectedJuniorBedroom];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedJuniorBedroom(bedrooms);
    setProducts(
      filterJuniorBedroom(bedrooms, "numberOfJuniorBedroom", products, others)
    );
    // setDetails(
    //   filterJuniorBedroom(bedrooms, "numberOfJuniorBedroom", products, others)
    // );
  };
  // end for junior bedroom

  // for garden
  const [selectedGarden, setSelectedGarden] = useState([]);
  const setGarden = (bedroom) => {
    const bedrooms = [...selectedGarden];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedGarden(bedrooms);
    setProducts(filterGarden(bedrooms, "numberOfGarden", products, others));
    // setDetails(filterGarden(bedrooms, "numberOfGarden", products, others));
  };
  // end for garden

  // for railroad
  const [selectedRailroad, setSelectedRailroad] = useState([]);
  const setRailroad = (bedroom) => {
    const bedrooms = [...selectedRailroad];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedRailroad(bedrooms);
    setProducts(filterRailroad(bedrooms, "numberOfRailroad", products, others));
    // setDetails(filterRailroad(bedrooms, "numberOfRailroad", products, others));
  };
  // end for railroad

  // for floorthrough
  const [selectedFloorThrough, setSelectedFloorThrough] = useState([]);
  const setFloorThrough = (bedroom) => {
    const bedrooms = [...selectedFloorThrough];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedFloorThrough(bedrooms);
    setProducts(
      filterFloorThrough(bedrooms, "numberOfFloorThrough", products, others)
    );
    // setDetails(
    //   filterFloorThrough(bedrooms, "numberOfFloorThrough", products, others)
    // );
  };
  // end for floorThrough

  // for commercial Space
  const [selectedCommercial, setSelectedCommercial] = useState([]);
  const setCommercial = (bedroom) => {
    const bedrooms = [...selectedCommercial];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedCommercial(bedrooms);
    setProducts(
      filterCommercial(bedrooms, "numberOfCommercial", products, others)
    );
    // setDetails(
    //   filterCommercial(bedrooms, "numberOfCommercial", products, others)
    // );
  };

  // end for Commercial space
  // for Land
  const [selectedLand, setSelectedLand] = useState([]);
  const setLand = (bedroom) => {
    const bedrooms = [...selectedLand];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedLand(bedrooms);
    setProducts(filterLand(bedrooms, "numberOfLand", products, others));
    // setDetails(filterLand(bedrooms, "numberOfLand", products, others));
  };

  // end for land

  // for House
  const [selectedHouse, setSelectedHouse] = useState([]);
  const setHouse = (bedroom) => {
    const bedrooms = [...selectedHouse];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedHouse(bedrooms);
    setProducts(filterHouse(bedrooms, "numberOfHouse", products, others));
    // setDetails(filterHouse(bedrooms, "numberOfHouse", products, others));
  };

  // end for House

  // for Apartment
  const [selectedApartment, setSelectedApartment] = useState([]);
  const setApartment = (bedroom) => {
    const bedrooms = [...selectedApartment];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedApartment(bedrooms);
    setProducts(
      filterApartment(bedrooms, "numberOfApartment", products, others)
    );
    // setDetails(
    //   filterApartment(bedrooms, "numberOfApartment", products, others)
    // );
  };

  // end for Apartment

  // SORT
  const [products, setProducts] = useState([]);
  const sortProducts = (method) => {
    setType("filter");
    const array = products;

    if (method === "Lowest to Highest") {
      array?.sort(function (a, b) {
        // value for money data
        return a?.valueForMoneyScore - b?.valueForMoneyScore;
      });
    } else if (method === "Highest to Lowest") {
      array?.sort(function (a, b) {
        return b?.price - a?.price;
      });
    } else if (method === "Newest") {
      array?.sort(function (a, b) {
        return a?.last_updated.seconds - b?.last_updated.seconds;
      });
    } else if (method === "Select") {
      array?.sort(function (a, b) {
        return others;
      });
    }
    setProducts(array);
    setDetails(array);
  };

  // ...sort
  const [value, setValue] = useState("Select");
  const setList = (e) => {
    if (users?.subscribed === "none" || users?.subscribed === undefined) {
      setModalShow(true);
    } else {
      setValue(e.target.value);
      sortProducts(e.target.value);
    }
  };

  useEffect(() => {
    setProducts(filterList([], null, details));
  }, []);

  if (products?.length !== 0) {
    <Scroll>
      <SearchProductList filteredPersons={products} />
    </Scroll>;
  }

  //Slider func
  // const [agirlik, setAgirlik] =  useState([1,5000]);
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 150 ? setStickyClass("fixed-header") : setStickyClass("");
    }
  };
  const handleMainFilter = () => {
    let array = details;
    if (select === "unverified") {
      const filterUnverified = products?.filter((data) => {
        return data?.verificationStatus === "unverified" || "pending";
      })
      return filterUnverified;
    } else if (select === "mandate") {
      const filterMandate = products?.filter((data) => {
        return data?.directMandate === true;
      });
      return filterMandate;
    } else if (select === "premium") {
      const filterPremium = products?.filter((data) => {
        return data?.premium === true;
      });
      return filterPremium;
    } else {
      return products;
    }
  };

  // subscriber
  const handleSuscriber = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login");
    } else {
      navigate("/user/suscribe");
    }
  };

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      // setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();

      setUser(agentData);
    })();
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Real Estate & Homes For Shortlet | Chekam</title>
      </Helmet>
      <Navbar />

      <div className="mt-5s" style={{ width: "100%" }}>
        <div className="container buy-top">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <form
                    class="buy-search-top"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    {/* <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Enter an Address, Zip or Code"
                      aria-label="Search"
                      onChange={handleChange}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "36px",
                        fontSize: "10px",
                      }}
                    /> */}
                    <Select
                      options={district?.map((opp) => ({
                        label: opp,
                        value: opp,
                        // setData(opp?.value)
                      }))}
                      placeholder="Address, Zip or Code"
                      onChange={handleChange}
                    />
                  </form>
                </div>
                <div className={`col-lg-9 col-md-8 col-sm-12 ${stickyClass}`}>
                  <ShortletHeader
                    verifiedYes={verifiedYes}
                    verifiedNo={verifiedNo}
                    prices={prices}
                    setPrices={setPrices}
                    agirlik={agirlik}
                    agirlik0={agirlik[0]}
                    agirlik1={agirlik[1]}
                    setAgirlik={setAgirlik}
                    selectedSizes={selectedSizes}
                    setSize={setSize}
                    selectedBedrooms={selectedBedrooms}
                    selectedTerrace={selectedTerrace}
                    selectedBungalows={selectedBungalows}
                    selectedDetached={selectedDetached}
                    selectedSemiDetached={selectedSemiDetached}
                    selectedMaisonette={selectedMaisonette}
                    selectedTriplex={selectedTriplex}
                    selectedStudio={selectedStudio}
                    selectedAlcoveStudio={selectedAlcoveStudio}
                    selectedConvertibleStudio={selectedConvertibleStudio}
                    selectedConvertibleLoft={selectedConvertibleLoft}
                    selectedDuplexTriplex={selectedDuplexTriplex}
                    selectedJuniorBedroom={selectedJuniorBedroom}
                    selectedGarden={selectedGarden}
                    selectedRailroad={selectedRailroad}
                    selectedFloorThrough={selectedFloorThrough}
                    selectedCommercial={selectedCommercial}
                    selectedLand={selectedLand}
                    selectedHouse={selectedHouse}
                    selectedApartment={selectedApartment}
                    setBedroom={setBedroom}
                    setDetached={setDetached}
                    setTerrace={setTerrace}
                    setBungalow={setBungalow}
                    setSemiDetached={setSemiDetached}
                    setMaisonette={setMaisonette}
                    setTriplex={setTriplex}
                    setStudio={setStudio}
                    setAlcoveStudio={setAlcoveStudio}
                    setConvertibleStudio={setConvertibleStudio}
                    setConvetibleLoft={setConvertibleLoft}
                    setDuplexTriplex={setDuplexTriplex}
                    setJuniorBedroom={setJuniorBedroom}
                    setGarden={setGarden}
                    setRailroad={setRailroad}
                    setFloorThrough={setFloorThrough}
                    setLand={setLand}
                    setHouse={setHouse}
                    setApartment={setApartment}
                    setCommercial={setCommercial}
                    filteredVerified={filteredVerified}
                    filteredUnVerified={filteredUnVerified}
                    filteredHouseBungalow={filteredHouseBungalow}
                    filteredHouseTerrace={filteredHouseTerrace}
                    filteredHouseDetached={filteredHouseDetached}
                    filteredHouseSemiDetached={filteredHouseSemiDetached}
                    filteredHouseMaisonette={filteredHouseMaisonette}
                    filteredHouseDuplex={filteredHouseDuplex}
                    filteredHouseTriplex={filteredHouseTriplex}
                    filteredApartmentStudio={filteredApartmentStudio}
                    filteredApartmentAlcoveStudio={
                      filteredApartmentAlcoveStudio
                    }
                    filteredApartmentConvertibleStudio={
                      filteredApartmentConvertibleStudio
                    }
                    filteredApartmentConvertibleLoft={
                      filteredApartmentConvertibleLoft
                    }
                    filteredApartmentDuplexTriplex={
                      filteredApartmentDuplexTriplex
                    }
                    filteredApartmentJuniorBedroom={
                      filteredApartmentJuniorBedroom
                    }
                    filteredApartmentGarden={filteredApartmentGarden}
                    filteredApartmentRailroad={filteredApartmentRailroad}
                    filteredApartmentFloorThrough={
                      filteredApartmentFloorThrough
                    }
                    filteredLand={filteredLand}
                    filteredHouse={filteredHouse}
                    filteredApartment={filteredApartment}
                    filteredCommercialSpace={filteredCommercialSpace}
                    value={value}
                    setList={setList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="desktop-main map-top" id="main-premium">
          {/* {houses[0].map} */}
          <div className="col-lg-6 col-md-6 map-section">
            <div className="">{/* <Map locations={locations} /> */}</div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 hey-there">
            <div className="p-3 d-flex">
              <div
                className="col-lg-9 col-md-7 col-sm-6 col-xs-12"
                style={{ width: "100%" }}
              >
                <div className="category-options">
                  <button
                    href=""
                    alt="Switch to Agent listings"
                    className="option left-option active-option"
                    onClick={() => setSelect("unverified")}
                  >
                    Unverified
                  </button>
                  <button
                    href=""
                    style={{ background: "#ffc107", color: "#fff" }}
                    alt="Switch to Agent listings"
                    className="option right-option"
                    onClick={() => setSelect("premium")}
                  >
                    Premium
                  </button>
                  <button
                    href=""
                    style={{ background: "#800080", color: "#fff" }}
                    alt="Switch to Agent listings"
                    className="option right-end-option"
                    onClick={() => setSelect("mandate")}
                  >
                    Verified
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row  scroll-hotel">
                <Products
                  propertyType="shortlet"
                  products={select === "" ? products : handleMainFilter()}
                  sortProducts={select === "" ? products : handleMainFilter()}
                  type={type}
                  show={show}
                  setShow={setShow}
                />
              </div>
            )}
          </div>
        </div>

        <div className="row map-top" id="desktop-section">
          {/* {houses[0].map} */}
          <div className="col-lg-6 col-md-6 map-section">
            <div className="">{/* <Map locations={locations} /> */}</div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 hey-there">
            <MandateSortContainer className="p-3 d-flex">
              <MandateSection className="col-lg-9 col-md-7 col-sm-6 col-xs-12">
                <div className="category-options">
                  <button
                    href=""
                    id="left-option"
                    alt="Switch to Agent listings"
                    className="option left-option active-option"
                    onClick={() => setSelect("unverified")}
                  >
                    Unverified
                  </button>
                  <button
                    href=""
                    style={{ background: "#ffc107", color: "#fff" }}
                    alt="Switch to Agent listings"
                    className="option right-option"
                    onClick={() => setSelect("premium")}
                  >
                    Premium
                  </button>
                  <button
                    href=""
                    style={{ background: "#800080", color: "#fff" }}
                    alt="Switch to Agent listings"
                    className="option right-end-option"
                    onClick={() => setSelect("mandate")}
                  >
                    Verified
                  </button>
                </div>
              </MandateSection>

              <div
                className="modal fade"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Become a Subscriber
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body" style={{ textAlign: "center" }}>
                      Subscribe to use this feature
                      <br />
                      <button
                        className="btn btn-outline-warning selected-agent-btn text-white"
                        style={{
                          background: "purple",
                          width: "50%",
                          borderColor: "purple",
                        }}
                        onClick={(e) => handleSuscriber(e)}
                      >
                        Subscribe
                      </button>
                    </div>
                    {/*<div class="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn-close">Done</button>
      </div>*/}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12">
                <div className="sort-list p d-flex">
                  <span className="sort-text">Sort by:</span>

                  <select
                    value={value}
                    style={{ width: "100%" }}
                    onChange={setList}
                    className="form-control"
                  >
                    <option value="Select">Select</option>
                    <option value="Highest to Lowest">Highest Quality</option>
                    <option value="Lowest to Highest">
                      Highest Value for Money
                    </option>
                    {/* <option value="Newest">Newest</option> */}
                  </select>

                  {/*{user?.suscriber === undefined && (
                    <div class="dropdown">
                      <button
                        class="btn btn-light dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Select
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          Highest Quality
                        </a>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          Highest Value for Money
                        </a>
                      </div>
                    </div>
                  )}
                  {user?.suscriber ? (
                    <select
                      value={value}
                      style={{ width: "100%" }}
                      onChange={setList}
                      className="form-control"
                    >
                      <option value="Select">Select</option>
                      <option value="Highest to Lowest">Highest Quality</option>
                      <option value="Lowest to Highest">
                        Highest Value for Money
                      </option>
                    </select>
                  ) : (
                    <></>
                  )}*/}
                </div>
              </div>
            </MandateSortContainer>
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row scroll-hotel">
                <Products
                  propertyType="shortlet"
                  products={select === "" ? products : handleMainFilter()}
                  sortProducts={select === "" ? products : handleMainFilter()}
                  type={type}
                  show={show}
                  setShow={setShow}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SuscriberModal show={modalshow} setShow={setModalShow} />
      <AskChekamButton />  
      <Footer />
    </>
  );
}

const MandateSortContainer = styled.div`
  position: relative;
  left: -26%;

  @media (min-width: 772px) and (max-width: 995px) {
    position: relative;
    left: -17%;
  }
`;

const MandateSection = styled.div`
  padding-right: 49px;

  @media (min-width: 772px) and (max-width: 995px) {
    width: 78%;
  }
`;

const MandateAllButton = styled.button`
  background-color: white !important;
`;

export default Shortlet;
