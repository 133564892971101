import React from "react";
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getAuth, signOut} from "firebase/auth";
import { UserContext } from "../../../App";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../../images/chekamlogo.jpeg";

function ManagerHeader() {

  const user = React.useContext(UserContext)
  const navigate = useNavigate();


  function handleToggle() {
    // body
    document.querySelector(".bodies").classList.toggle("toggle-sidebar");
  }


  const handleSignOut = () => {
    signOut(getAuth()).then(() => {
      // Sign-out successful.
      navigate('/')
    }).catch((error) => {
      // An error happened.
    });
  }


  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
        <Link to="/" className="logo d-flex align-items-center">
            <img className="" style={{ width: "80px" }} src={logo} />
            {/* <img className="mobile-logo" style={{ width: "50px" }} src={mobileLogo} /> */}
          </Link>
          <BarIcon className="fa fa-bars" onClick={handleToggle}></BarIcon>
        </div>
        {/* <!-- End Logo --> */}

        <div className="search-bar">
           {/*<h3>Welcome Back </h3>*/}
        </div>
        {/* <!-- End Search Bar --> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {/* <!-- End Search Icon--> */}
            {/* <!-- End Notification Nav --> */}

            {/* <!-- End Messages Nav --> */}

            <li className="nav-item pe-3">
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                href="#"
                data-bs-toggle="dropdown"
              >
                 {/*<img
                  src=""
                  alt="No image uploaded"
                  className="rounded-circle"
                />*/}
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  Property Manager
                </span>
              </a>
              {/* <!-- End Profile Iamge Icon --> */}

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  {/*<h6>Davidson Jose</h6>*/}
                  <span>Property Manager</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/profile"
                  >
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="/admin/Property Manager/profile"
                  >
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <Typography onClick={handleSignOut}>Logout</Typography>
                  </a>
                </li>
              </ul>
              {/* <!-- End Profile Dropdown Items --> */}
            </li>
            {/* <!-- End Profile Nav --> */}
          </ul>
        </nav>
        {/* <!-- End Icons Navigation --> */}
      </header>
    </>
  );
}

const BarIcon = styled.i`
  font-size: 30px;
  color: purple;
`
export default ManagerHeader;