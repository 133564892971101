import { details } from "../../data";

export default function filterList(arr, method, prod, others) {
  // console.log(prod)
  if (arr?.length === 0) {
    // setDetails(others);
    // setProducts(others);
    return others;
  } else {
    return prod?.filter((product) => {
      const sizeArray = product?.numberOfBathrooms?.toString().split(" ");
      if (arr?.length > 0) {
        if (sizeArray?.some((r) => arr.indexOf(r) >= 0)) {
          return product;
        }
      } else {
        return prod;
      }
    });
  }
}