import React from "react";
import { useNavigate } from "react-router";
import Header from "../Header";
import Sidebar from "../Sidebar";

function BestDeals() {
  const navigate = useNavigate();
  const price = 5000;
  let naira = "\u20A6";
  function handleMoveProperties() {
    navigate("/admin/user/properties/view");
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">
        <div className="pagetitle">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Best Properties for...</a>
              </li>
              {/* <li className="breadcrumb-item">Users</li> */}
              <li className="breadcrumb-item active">User</li>
            </ol>
          </nav>
        </div>
        {/* <!-- End Page Title --> */}

        <section className="section profile p-5 table-responsive agent-main">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/Buying(1).png"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/hotel 1(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/hotel 3(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 pb-4 product-card"
              // key={data?.id}
            >
              <div className="card card-style agent-properties">
                <div className="product-inner-card">
                  <a
                    // href="/single-buy"
                    style={{ textDecoration: "none" }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#exampleModal"
                    // onClick={(e) => handleClick(data?.id, e)}
                    //   onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
                  >
                    <a>
                      <img
                        src="../../../images/Hotel 2(1).jpg"
                        className="card-img-top img-fluid"
                        alt="..."
                      />
                    </a>
                    <div className="top-house">
                      <p className="text-danger top-time text-white">
                        {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                      </p>
                    </div>
                  </a>
                </div>

                <div className="card-body" style={{}}>
                  <p className="card-text" style={{ fontSize: "12px" }}>
                    {naira} {price.toLocaleString()}
                    <br />
                    <span>3bd 5hr Money to buy</span>
                    <br />
                    <span>Ikeja, Lagos state</span>
                  </p>
                </div>
              </div>
              <div
                className="d-flex properties-btn"
                style={{ paddingLeft: "50px" }}
              >
                <div>
                  <button
                    className="btn text-white"
                    style={{ background: "purple" }}
                    onClick={handleMoveProperties}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BestDeals;