import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router";
import Box from "@mui/material/Box";

//import { purple } from '@mui/material/colors';

import { getDocs, collection, query, where } from "firebase/firestore";
import db from "../../../components/Firebase";

import Button from "@mui/material/Button";

import Slide from "@mui/material/Slide";
import { getAuth, signOut } from "firebase/auth";
import MuiAlert from "@mui/material/Alert";

import { serverTimestamp, doc, setDoc } from "firebase/firestore";

import Grid from "@mui/material/Grid";

import { Helmet } from "react-helmet";
import Navbar from "./Navbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DASDashboard() {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [myProperties, setMyProperties] = useState([]);
  const [verificationProperties, setverificationProperties] = useState([]);
  const [err, setErr] = useState(null);
  //const [propertyCount, setPropertyCount] = useState(0);

  const [open, setOpen] = React.useState(false);
  
  const handleSignOut = () => {
    signOut(getAuth())
      .then(() => {
        // Sign-out successful.
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };




  

  return (
    
    <div style={{ overflow: "scroll !important" }}>
      
      <Helmet>
        <title>DAS Dashboard</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
      </Helmet>
      <Navbar />
      <Box sx={{ mt: 15, mx: 5 }} >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Button 
              variant="contained" 
              fullWidth="true" 
              color="secondary"
              onClick={() => {
                navigate("/admin/DAS/Dashboard/DasProperties");
              }}
              >
              Properties to be Verified
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/admin/DAS/Dashboard/PropertyForm2");
              }}
            >
              Update Properties
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/");
              }}
            >
              Mails
            </Button>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/");
              }}
            >
              Property Check
            </Button>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={() => {
                navigate("/");
              }}
            >
              Send Feedback
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Button
              variant="contained"
              fullWidth="true"
              color="secondary"
              onClick={handleSignOut}
            >
              Log Out
            </Button>
          </Grid>
        </Grid>
      </Box>

          </div>
  );
}

export default DASDashboard;
