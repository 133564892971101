import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import ad from "../images/onlinead.webp";
// import styled from "styled-compomemt"
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
function HotelPaginated({ handleId, data, type }) {
  const [agentid, setId] = useState(null);
  const [datas, setData] = useState(null);
  let naira = "\u20A6";
  const navigate = useNavigate();

  const handleAgentInfo = (id, e) => {
    console.log(id, "al stuff");
    setId(agentid);

    async function getSingleAgent() {
      const docRef = doc(db, "users", id?.agentId);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      setData(docSnap.data()); 
      console.log(docSnap.data());
      let agentData = docSnap.data();
      return (window.location.href = `/properties/${id?.agentName}?n=${id?.agentId}&companyname=${agentData?.company}&image=${data?.photoURL === undefined ||data?.photoURL === "" ? "none" : data?.photoUrl }`);
      if (docSnap.exists()) {
        //console.log("Document data:", docSnap.data());
      } else {
        //console.log("No such document!");
      }
    }
    getSingleAgent();
  };
  console.log();
  return (
    <>



        <div
          className="col-lg-3 col-md-6 col-sm-6 pb-2 product-card"
          key={data?.id}
        >
          <div className="card card-style" style={{ height: "300px" }}>
            <div className="product-inner-card">
              <a
                // href="/single-buy"
                style={{ textDecoration: "none" }}
                // data-bs-toggle="modal"
                // data-bs-target="#mainmodal"
                onClick={(e) => handleId(data?.id, e)}
                // onClick={() => openInNewTab(`/selected-item?q=${data?.id}`)}
              >
                <a>
                  <img
                    src={
                      data?.images === null || data?.images?.length === 0
                        ? "images/placeholder.png"
                        : data?.images
                    }
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                </a>
                <div className="top-house">
                  <p className="text-danger top-time text-white">
                    {/* {secondsToDhms(data?.dateAdded.seconds)} hours ago */}
                  </p>
                </div>
              </a>
            </div>

            <div className="card-body" style={{}}>
              <p className="card-text" style={{ fontSize: "12px" }}>
                <span className="price">
                  {naira} {data?.price?.toLocaleString()}
                </span>
                <br />

                <span className="card-details">
                  {data?.numberOfBedrooms} Bds {data?.numberOfBathrooms} Ba -
                  House for {data?.propertyPurpose}
                </span>
                <br />
                <span className="card-address">
                  {data?.streetName && data?.streetName + ","} {data?.state}
                </span>
                <br />
                <span className="card-address">
                  <strong
                    onClick={(e) => handleAgentInfo(data, e)}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    {data?.agentName}
                  </strong>
                  {/*<span>{data?.dateAdded}</span>*/}
                </span>
              </p>

              {/*
            
              <div className="d-flex  justify-content-between" style={{ width: "100% !important" }}>
                <StarRatings
                  rating={data?.userRating ? Number(data?.userRating) : 0}
                  // rating={5}
                  // changeRating={data?.starRating}
                  numberOfStars={5}
                  name="rating"
                  color="purple"
                  // style={{ width: "300px" }}
                  starRatedColor="yellow"
                  starHoverColor="purple"
                  starDimension="10px"
                />
                <strong style={{ width: "100% !important", fontSize: "12px" }}>{data?.userRated?.length} user review</strong>
              </div>
            */}
            </div>
          </div>
        </div>
      
    </>
  );
}

export default HotelPaginated;
