import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ThemeProvider,createTheme,styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Property from './Property'
import logo from '../images/chekamlogo.png';
import {useNavigate, Routes, Route,Link} from 'react-router-dom';
import { makeStyles} from '@mui/styles';
import db from  './Firebase';
import { doc, addDoc,setDoc,getDoc,getDocs,collection } from "firebase/firestore";
import { getAuth, signOut} from "firebase/auth";


const theme = createTheme({
    palette: {
      primary: {
        light:'#FFCC00',
        main: '#800080',
        dark: '#800080',
      },
      secondary: {
        light:'#FFFFFF',
        main: '#FFCC00',
        dark: '#FFFFFF',
      },
    },
  });

  const auth = getAuth();
  const user = auth.currentUser;


  const handleSignOut = () => {
    signOut(getAuth()).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const useStyles = makeStyles((theme) => ({

    logo:{
        width:150,
        padding:10,
        marginRight:20,
        zIndex:3
      }
    }));

export default function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();

 
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{flexGrow: 1 }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width:'100vw' }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button onClick = {()=> navigate('/Dashboard/PropertyForm')} color="secondary" variant="contained" size="medium"><Link style={{ textDecoration: 'inherit'}} to = "./PropertyForm"></Link>Add Property</Button>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
            
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          {user?<Button color="secondary" variant="contained" size="medium" onClick = {handleSignOut}> Logout</Button>:<Button color="inherit"><Link style={{ textDecoration: 'inherit'}} to = "./Login">Sign up/Login</Link></Button>}
        </Toolbar>
      </AppBar>
      
      <Box
        component="main"
        sx={{ width: 'auto', p:3}}
      >
        <Toolbar />
        <Typography component="div" >
         <Property />
         
        </Typography>
        
      </Box>
    </Box>
    
    </ThemeProvider>
  );
}
