import React, { useState, useEffect } from "react";
import "./styles/Buy.css";
import Footer from "./Footer";
import { Spinner } from "react-bootstrap";
import Scroll from "./Scroll";
import SearchProductList from "./SearchProductList";
import Products from "./test/Products";
import Size from "./Size";
import Bedroom from "./Bedroom";
import filterList from "./test/filterList";
import filterBedroom from "./test/filterBedroom";
import filterMaisonette from "./test/filterMaisonette";
import filterLand from "./test/filterLand";
import filterBungalow from "./test/filterBungalow";
import AgentHeader from "./AgentHeader";
import Map from "./Map";
import Navbar from "./Navbar";
import Select from "react-select";
import { Helmet } from "react-helmet";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";
import { useNavigate } from "react-router";
import { doc, getDoc } from "firebase/firestore";
import ProductList from "./ProductsList";
import filterTerrace from "./test/filterTerrace";
import filterDetached from "./test/filterDetached";
import filterSemiDetached from "./test/filterSemiDetached";
import filterTriplex from "./test/filterTriplex";
import filterStudio from "./test/filterStudio";
import filterAlcoveStudio from "./test/filterAlcoveStudio";
import filterConvertibleStudio from "./test/filterConvertibleStudio";
import filterConvertibleLoft from "./test/filterConvertibleLoft";
import filterDuplexTriplex from "./test/filterDuplexTriplex";
import filterJuniorBedroom from "./test/filterJuniorBedroom";
import filterGarden from "./test/filterGarden";
import filterRailroad from "./test/filterRailroad";
import filterFloorThrough from "./test/filterFloorThrough";
import filterCommercial from "./test/filterCommercial";
import filterHouse from "./test/filterHouse";
import filterApartment from "./test/filterApartment";
import styled from "styled-components";

import { useLocation, useParams } from "react-router-dom";

import image from "../images/house.jpeg";

function AgentPropertiesAll() {
  const [select, setSelect] = useState("");
  const [type, setType] = useState("none");
  const location = useLocation();
  // const {id} = use
  const [stickyClass, setStickyClass] = useState("");
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const places = [];
  const [loading, setLoading] = useState(false);
  const [colorTrue, setColorTrue] = useState("");
  const [data, setData] = useState("");
  const [district, setDistrict] = useState(null);
  const [locations, setLocation] = useState(null);
  const [others, setOthers] = useState(null);
  const [verifiedYes, setVerifiedYes] = useState("");
  const [verifiedNo, setVerifiedNo] = useState("");
  const [agentdata, setAgentDetails] = useState(null);
  const { id } = useParams();
  // const [logintype, setLogintype] = useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const homeSearch = location?.state?.propertySearch;
  // const homeSearch = urlParams.get("h");

  const alltype = urlParams.get("type");

  const queryAgent = urlParams.get("n");

  const agentCompany = urlParams.get("company-name");

  useEffect(() => {
    (async () => {
      const docRef = doc(db, "users", queryAgent);
      const docSnap = await getDoc(docRef);
      // setLoading(false);
      // setData(docSnap.data());
      console.log(docSnap.data());
      let agentData = docSnap.data();

      setAgentDetails(agentData);
    })();
  }, []);
  useEffect(() => {
    async function getPlaces() {
      setLoading(true);
      const propertiesRef = collection(db, "properties");
      const array = [];

      getDocs(propertiesRef).then((data) => {
        data?.forEach((doc) => {
          places.push(doc.data());
          // console.log(doc.data(), "main properties");
          const agentProperties = places?.filter((data) => {
            return data?.agentId === queryAgent || data?.agentName === id;
          });
          setDetails(agentProperties);
          setProducts(agentProperties);
          setLocation(agentProperties);
          setOthers(agentProperties);
        });
        setLoading(false);
      });
    }
    getPlaces();
  }, []);

  console.log(details);

  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        documents.push(doc.data().streetName);
        documents.push(doc.data().district);
      });

      let uniqueChars = [...new Set(documents)];
      setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);

  // useEffect(() => {
  //   console.log(homeSearch, "here is homesearch");
  //   if (homeSearch) {
  //     const filterSearchProperty = details?.filter((data) => {
  //       console.log(data, "important search");
  //       return data?.district === homeSearch || data?.streetName === homeSearch;
  //     });
  //     console.log(filterSearchProperty, "let see");
  //     setDetails(filterSearchProperty);
  //     // setProducts(filterSearchProperty);
  //     setOthers(filterSearchProperty);
  //   }
  // },[homeSearch]);
  const [datas, setDatas] = useState([]);

  // PRICE
  const [agirlik, setAgirlik] = useState([1, 1000000000]);
  const [prices, setPrices] = useState({
    hacim_min: 0,
    hacim_max: 1000000000,
  });

  useEffect(() => {
    let filteredPersons = details?.filter(
      (item) => item.price * 1 >= agirlik[0] && item.price * 1 <= agirlik[1]
    );
    setProducts(filteredPersons);
    setOthers(filteredPersons);
  }, [agirlik]);

  const filteredVerified = (e) => {
    e.preventDefault();
    if (verifiedYes === "") {
      setVerifiedYes("true");
    } else {
      setVerifiedYes("");
    }
    let filtered = details?.filter((item) => item?.verified === 5);
    setProducts(filtered);
  };

  const filteredUnVerified = (e) => {
    e.preventDefault();
    if (verifiedNo === "") {
      setVerifiedNo("true");
      setProducts(others);
    } else {
      setVerifiedNo("");
    }
    let filtered = details?.filter((item) => item?.verified < 5);
    setProducts(filtered);
  };

  const urlsearch = urlParams.get("q");

  const filteredHouseBungalow = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Bungalow"
      );
      setProducts(filtered);
    } else {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Bungalow"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );

      setProducts(filteredLocation);
    }
  };

  const filteredHouseTerrace = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Terrace"
      );
      setProducts(filtered);
    } else {
      let filtered = products?.filter(
        (item) => item?.propertyDescription === "Terrace"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );

      setProducts(filteredLocation);
    }
  };

  //filtered house detached
  const filteredHouseDetached = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi Detached"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi Detached"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house  semi detached
  const filteredHouseSemiDetached = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi-Detached"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Semi-Detached"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house maisonette
  const filteredHouseMaisonette = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Maisonette"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Maisonette"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house duplex
  const filteredHouseDuplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered house triplex
  const filteredHouseTriplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Triplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Triplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment studio
  const filteredApartmentStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Studio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Studio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment alcove studio
  const filteredApartmentAlcoveStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "AlcoveStudio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "AlcoveStudio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment convertible studio
  const filteredApartmentConvertibleStudio = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Convertible Studio"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Convertible Studio"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment convertible loft
  const filteredApartmentConvertibleLoft = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "ConvertibleLoft"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "ConvertibleLoft"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment duplex triplex
  const filteredApartmentDuplexTriplex = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex/Triplex"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Duplex/Triplex"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment junior bedroom
  const filteredApartmentJuniorBedroom = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Junior 1 Bedroom"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Junior 1 Bedroom"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment garden
  const filteredApartmentGarden = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Garden Apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Garden Apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment railroad
  const filteredApartmentRailroad = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Railroad Apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Railroad Apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered apartment floor through
  const filteredApartmentFloorThrough = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Floor-through"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Floor-through"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Land
  const filteredLand = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter((item) => item?.properType === "land");
      setProducts(filtered);
    } else {
      let filtered = details?.filter((item) => item?.propertyType === "land");
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered House
  const filteredHouse = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter((item) => item?.propertyType === "house");
      setProducts(filtered);
    } else {
      let filtered = details?.filter((item) => item?.propertyType === "house");
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Apartment
  const filteredApartment = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyType === "apartment"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyType === "apartment"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };
  //filtered Commercial Space
  const filteredCommercialSpace = (e) => {
    e.preventDefault();
    if (urlsearch === null) {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Commercial-Space"
      );
      setProducts(filtered);
    } else {
      let filtered = details?.filter(
        (item) => item?.propertyDescription === "Commercial-Space"
      );
      let filteredLocation = filtered?.filter(
        (item) => item?.streetName || item?.district === urlsearch
      );
      setProducts(filteredLocation);
    }
  };

  // search
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  useEffect(() => {
    const filteredPersons = details?.filter((person) => {
      return (
        person?.district?.toLowerCase().includes(searchField.toLowerCase()) ||
        person?.streetName?.toLowerCase().includes(searchField.toLowerCase())
      );
    });
    setProducts(filteredPersons);
    setOthers(filteredPersons);
  }, [searchField]);

  const handleChange = (opt) => {
    setType("filter");
    // navigate(`/buy?q=${opt?.value}`);
    setSearchField(`${opt?.value}`);
  };

  // Sizes BATHROOMS
  const [selectedSizes, setSelectedSizes] = useState([]);
  const setSize = (size) => {
    const sizes = [...selectedSizes];

    if (sizes?.includes(size)) {
      const index = sizes.indexOf(size);
      sizes?.splice(index, 1);
    } else {
      sizes?.push(size);
    }
    setSelectedSizes(sizes);
    // setProducts(filterList(sizes, "numberOfBathrooms", details));

    setProducts(filterList(sizes, "numberOfBathrooms", products, details));
    setDetails(products);
    // setDetails(filterList(sizes, "numberOfBathrooms", details, others));
    // setProducts(filterBedroom(bedrooms, "numberOfBedrooms", products, details));
  };
  // BEDROOMS
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const setBedroom = (bedroom) => {
    const bedrooms = [...selectedBedrooms];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedBedrooms(bedrooms);
    setProducts(filterBedroom(bedrooms, "numberOfBedrooms", products, details));
    setDetails(products);
    // setDetails(filterBedroom(bedrooms, "numberOfBathrooms", details, others));
    // console.log(bedrooms);
  };

  // for bungalows
  const [selectedBungalows, setSelectedBungalows] = useState([]);
  const setBungalow = (bedroom) => {
    const bedrooms = [...selectedBungalows];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedBungalows(bedrooms);
    setProducts(
      filterBungalow(bedrooms, "numberOfBungalows", products, others)
    );
    // setDetails(filterBungalow(bedrooms, "numberOfBungalows", products, others));
  };

  // end for bungalows
  // for Terrace
  const [selectedTerrace, setSelectedTerrace] = useState([]);
  const setTerrace = (bedroom) => {
    const bedrooms = [...selectedTerrace];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedTerrace(bedrooms);
    setProducts(filterTerrace(bedrooms, "numberOfTerrace", products, others));
    // setDetails(filterTerrace(bedrooms, "numberOfTerrace", products, others));
  };

  // end for terrace

  // for Detached
  const [selectedDetached, setSelectedDetached] = useState([]);
  const setDetached = (bedroom) => {
    const bedrooms = [...selectedDetached];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedDetached(bedrooms);
    setProducts(filterDetached(bedrooms, "numberOfDetached", products, others));
    // setDetails(filterDetached(bedrooms, "numberOfDetached", products, others));
  };

  // end for detached
  // for semi detached
  const [selectedSemiDetached, setSelectedSemiDetached] = useState([]);
  const setSemiDetached = (bedroom) => {
    const bedrooms = [...selectedSemiDetached];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedSemiDetached(bedrooms);
    setProducts(
      filterDetached(bedrooms, "numberOfSemiDetached", products, others)
    );
    // setDetails(
    //   filterSemiDetached(bedrooms, "numberOfSemiDetached", products, others)
    // );
  };

  // end for semi detached
  // for maisonette
  const [selectedMaisonette, setSelectedMaisonette] = useState([]);
  const setMaisonette = (bedroom) => {
    const bedrooms = [...selectedMaisonette];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedMaisonette(bedrooms);
    setProducts(
      filterMaisonette(bedrooms, "numberOfMaisonette", products, others)
    );
    // setDetails(
    //   filterMaisonette(bedrooms, "numberOfMaisonette", products, others)
    // );
  };

  // end for maisonette
  // for triplex
  const [selectedTriplex, setSelectedTriplex] = useState([]);
  const setTriplex = (bedroom) => {
    const bedrooms = [...selectedTriplex];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedTriplex(bedrooms);
    setProducts(filterTriplex(bedrooms, "numberOfTriplex", products, others));
    // setDetails(filterTriplex(bedrooms, "numberOfTriplex", products, others));
  };
  // end for triplex

  // for studio
  const [selectedStudio, setSelectedStudio] = useState([]);
  const setStudio = (bedroom) => {
    const bedrooms = [...selectedStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedStudio(bedrooms);
    setProducts(filterStudio(bedrooms, "numberOfStudio", products, others));
    // setDetails(filterStudio(bedrooms, "numberOfStudio", products, others));
  };
  // end for studio
  // for alcove studio
  const [selectedAlcoveStudio, setSelectedAlcoveStudio] = useState([]);
  const setAlcoveStudio = (bedroom) => {
    const bedrooms = [...selectedAlcoveStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedAlcoveStudio(bedrooms);
    setProducts(
      filterAlcoveStudio(bedrooms, "numberOfAlcoveStudio", products, others)
    );
    // setDetails(
    //   filterAlcoveStudio(bedrooms, "numberOfAlcoveStudio", products, others)
    // );
  };
  // end for alcove studio

  // for convertible studio
  const [selectedConvertibleStudio, setSelectedConvertibleStudio] = useState(
    []
  );
  const setConvertibleStudio = (bedroom) => {
    const bedrooms = [...selectedConvertibleStudio];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedConvertibleStudio(bedrooms);
    setProducts(
      filterConvertibleStudio(
        bedrooms,
        "numberOfConvertibleStudio",
        products,
        others
      )
    );
    // setDetails(
    //   filterConvertibleStudio(
    //     bedrooms,
    //     "numberOfConvertibleStudio",
    //     products,
    //     others
    //   )
    // );
  };
  // end for convertible studio

  // for convertible loft
  const [selectedConvertibleLoft, setSelectedConvertibleLoft] = useState([]);
  const setConvertibleLoft = (bedroom) => {
    const bedrooms = [...selectedConvertibleLoft];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedConvertibleLoft(bedrooms);
    setProducts(
      filterConvertibleLoft(
        bedrooms,
        "numberOfConvertibleLoft",
        products,
        others
      )
    );
    // setDetails(
    //   filterConvertibleLoft(
    //     bedrooms,
    //     "numberOfConvertibleLoft",
    //     products,
    //     others
    //   )
    // );
  };
  // end for convertible loft

  // for duplex triplex
  const [selectedDuplexTriplex, setSelectedDuplexTriplex] = useState([]);
  const setDuplexTriplex = (bedroom) => {
    const bedrooms = [...selectedDuplexTriplex];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedDuplexTriplex(bedrooms);
    setProducts(
      filterDuplexTriplex(bedrooms, "numberOfDuplexTriplex", products, others)
    );
    // setDetails(
    //   filterDuplexTriplex(bedrooms, "numberOfDuplexTriplex", products, others)
    // );
  };
  // end for duplex triplex

  // for junior bedroom
  const [selectedJuniorBedroom, setSelectedJuniorBedroom] = useState([]);
  const setJuniorBedroom = (bedroom) => {
    const bedrooms = [...selectedJuniorBedroom];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedJuniorBedroom(bedrooms);
    setProducts(
      filterJuniorBedroom(bedrooms, "numberOfJuniorBedroom", products, others)
    );
    // setDetails(
    //   filterJuniorBedroom(bedrooms, "numberOfJuniorBedroom", products, others)
    // );
  };
  // end for junior bedroom

  // for garden
  const [selectedGarden, setSelectedGarden] = useState([]);
  const setGarden = (bedroom) => {
    const bedrooms = [...selectedGarden];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedGarden(bedrooms);
    setProducts(filterGarden(bedrooms, "numberOfGarden", products, others));
    // setDetails(filterGarden(bedrooms, "numberOfGarden", products, others));
  };
  // end for garden

  // for railroad
  const [selectedRailroad, setSelectedRailroad] = useState([]);
  const setRailroad = (bedroom) => {
    const bedrooms = [...selectedRailroad];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedRailroad(bedrooms);
    setProducts(filterRailroad(bedrooms, "numberOfRailroad", products, others));
    // setDetails(filterRailroad(bedrooms, "numberOfRailroad", products, others));
  };
  // end for railroad

  // for floorthrough
  const [selectedFloorThrough, setSelectedFloorThrough] = useState([]);
  const setFloorThrough = (bedroom) => {
    const bedrooms = [...selectedFloorThrough];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedFloorThrough(bedrooms);
    setProducts(
      filterFloorThrough(bedrooms, "numberOfFloorThrough", products, others)
    );
    // setDetails(
    //   filterFloorThrough(bedrooms, "numberOfFloorThrough", products, others)
    // );
  };
  // end for floorThrough

  // for commercial Space
  const [selectedCommercial, setSelectedCommercial] = useState([]);
  const setCommercial = (bedroom) => {
    const bedrooms = [...selectedCommercial];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedCommercial(bedrooms);
    setProducts(
      filterCommercial(bedrooms, "numberOfCommercial", products, others)
    );
    // setDetails(
    //   filterCommercial(bedrooms, "numberOfCommercial", products, others)
    // );
  };

  // end for Commercial space
  // for Land
  const [selectedLand, setSelectedLand] = useState([]);
  const setLand = (bedroom) => {
    const bedrooms = [...selectedLand];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedLand(bedrooms);
    setProducts(filterLand(bedrooms, "numberOfLand", products, others));
    // setDetails(filterLand(bedrooms, "numberOfLand", products, others));
  };

  // end for land

  // for House
  const [selectedHouse, setSelectedHouse] = useState([]);
  const setHouse = (bedroom) => {
    const bedrooms = [...selectedHouse];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedHouse(bedrooms);
    setProducts(filterHouse(bedrooms, "numberOfHouse", products, others));
    // setDetails(filterHouse(bedrooms, "numberOfHouse", products, others));
  };

  // end for House

  // for Apartment
  const [selectedApartment, setSelectedApartment] = useState([]);
  const setApartment = (bedroom) => {
    const bedrooms = [...selectedApartment];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedApartment(bedrooms);
    setProducts(
      filterApartment(bedrooms, "numberOfApartment", products, others)
    );
    // setDetails(
    //   filterApartment(bedrooms, "numberOfApartment", products, others)
    // );
  };

  // end for Apartment

  // SORT

  const sortProducts = (method) => {
    const array = products;
    setType("filter");
    if (method === "Lowest to Highest") {
      array?.sort(function (a, b) {
        // value for money data
        return a?.valueForMoneyScore - b?.valueForMoneyScore;
      });
    } else if (method === "Highest to Lowest") {
      array?.sort(function (a, b) {
        return b?.price - a?.price;
      });
    } else if (method === "Newest") {
      array?.sort(function (a, b) {
        return a?.last_updated.seconds - b?.last_updated.seconds;
      });
    } else if (method === "Select") {
      array?.sort(function (a, b) {
        return others;
      });
    }
    setProducts(array);
    setDetails(array);
  };

  // ...sort
  const [value, setValue] = useState("Select");

  const setList = (e) => {
    setValue(e.target.value);
    sortProducts(e.target.value);
  };

  useEffect(() => {
    setProducts(filterList([], null, details));
  }, []);

  if (products?.length !== 0) {
    <Scroll>
      <SearchProductList filteredPersons={products} />
    </Scroll>;
  }

  //Slider func
  // const [agirlik, setAgirlik] =  useState([1,5000]);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/buy?${searchField}`);
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 150 ? setStickyClass("fixed-header") : setStickyClass("");
    }
  };

  const handleMainFilter = () => {
    let array = details;
    if (select === "all") {
      return products;
    } else if (select === "mandate") {
      const filterMandate = products?.filter((data) => {
        return data?.directMandate === true;
      });
      return filterMandate;
    } else if (select === "premium") {
      const filterPremium = products?.filter((data) => {
        return data?.premium === true;
      });
      return filterPremium;
    } else {
      return products;
    }
  };

  console.log(select);

  return (
    <>
      <Helmet>
        <title>Real Estate & Homes For Sale | Chekam</title>
      </Helmet>

      <Navbar />

      {/*
      <section id="hero1" class="hero">
        <div class="inner">
          <div class="copy">
            <h1>Agent {id}</h1>
            <p>All Properties</p>
          </div>
        </div>
      </section>
      */}
      <div
        className="d-flex"
        style={{
          height: "150px",
          borderColor: "purple",
          borderWidth: "4px",
          paddingLeft: "50px",
          paddingTop: "20px",
          borderStyle: "solid",
        }}
      >
        <div>
          <img
            src={`${
              agentdata?.image ? agentdata?.image : "../../images/noprofile.png"
            }`}
            style={{ height: "100px", width: "100px", borderRadius: "100%" }}
            alt="profile pic"
          />
        </div>
        <div style={{ paddingLeft: "60px" }}>
          <h3
            style={{
              color: "purple",
              fontWeight: "bolder",
              width: "100% !important",
            }}
          >
            {agentCompany ? agentCompany : agentdata?.company}
          </h3>
          <br />
          <h5 style={{ color: "purple", fontWeight: "bold" }}>{id}</h5>
        </div>
      </div>
      <div className="mt-2">
        <div className="container buy-top">
          <div className="row pb-5">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <form
                    class="buy-search-top"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    {/* <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Enter an Address, Zip or Code"
                      aria-label="Search"
                      onChange={handleChange}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "36px",
                        fontSize: "10px",
                      }}
                    /> */}
                    <Select
                      options={district?.map((opp) => ({
                        label: opp,
                        value: opp,
                        // setData(opp?.value)
                      }))}
                      placeholder="Address, Zip or Code"
                      onChange={handleChange}
                    />
                  </form>
                </div>
                <div className={`col-lg-9 col-md-8 col-sm-12 ${stickyClass}`}>
                  <AgentHeader
                    verifiedYes={verifiedYes}
                    verifiedNo={verifiedNo}
                    prices={prices}
                    setPrices={setPrices}
                    agirlik={agirlik}
                    agirlik0={agirlik[0]}
                    agirlik1={agirlik[1]}
                    setAgirlik={setAgirlik}
                    selectedSizes={selectedSizes}
                    setSize={setSize}
                    selectedBedrooms={selectedBedrooms}
                    selectedTerrace={selectedTerrace}
                    selectedBungalows={selectedBungalows}
                    selectedDetached={selectedDetached}
                    selectedSemiDetached={selectedSemiDetached}
                    selectedMaisonette={selectedMaisonette}
                    selectedTriplex={selectedTriplex}
                    selectedStudio={selectedStudio}
                    selectedAlcoveStudio={selectedAlcoveStudio}
                    selectedConvertibleStudio={selectedConvertibleStudio}
                    selectedConvertibleLoft={selectedConvertibleLoft}
                    selectedDuplexTriplex={selectedDuplexTriplex}
                    selectedJuniorBedroom={selectedJuniorBedroom}
                    selectedGarden={selectedGarden}
                    selectedRailroad={selectedRailroad}
                    selectedFloorThrough={selectedFloorThrough}
                    selectedCommercial={selectedCommercial}
                    selectedLand={selectedLand}
                    selectedHouse={selectedHouse}
                    selectedApartment={selectedApartment}
                    setBedroom={setBedroom}
                    setDetached={setDetached}
                    setTerrace={setTerrace}
                    setBungalow={setBungalow}
                    setSemiDetached={setSemiDetached}
                    setMaisonette={setMaisonette}
                    setTriplex={setTriplex}
                    setStudio={setStudio}
                    setAlcoveStudio={setAlcoveStudio}
                    setConvertibleStudio={setConvertibleStudio}
                    setConvetibleLoft={setConvertibleLoft}
                    setDuplexTriplex={setDuplexTriplex}
                    setJuniorBedroom={setJuniorBedroom}
                    setGarden={setGarden}
                    setRailroad={setRailroad}
                    setFloorThrough={setFloorThrough}
                    setLand={setLand}
                    setHouse={setHouse}
                    setApartment={setApartment}
                    setCommercial={setCommercial}
                    filteredVerified={filteredVerified}
                    filteredUnVerified={filteredUnVerified}
                    filteredHouseBungalow={filteredHouseBungalow}
                    filteredHouseTerrace={filteredHouseTerrace}
                    filteredHouseDetached={filteredHouseDetached}
                    filteredHouseSemiDetached={filteredHouseSemiDetached}
                    filteredHouseMaisonette={filteredHouseMaisonette}
                    filteredHouseDuplex={filteredHouseDuplex}
                    filteredHouseTriplex={filteredHouseTriplex}
                    filteredApartmentStudio={filteredApartmentStudio}
                    filteredApartmentAlcoveStudio={
                      filteredApartmentAlcoveStudio
                    }
                    filteredApartmentConvertibleStudio={
                      filteredApartmentConvertibleStudio
                    }
                    filteredApartmentConvertibleLoft={
                      filteredApartmentConvertibleLoft
                    }
                    filteredApartmentDuplexTriplex={
                      filteredApartmentDuplexTriplex
                    }
                    filteredApartmentJuniorBedroom={
                      filteredApartmentJuniorBedroom
                    }
                    filteredApartmentGarden={filteredApartmentGarden}
                    filteredApartmentRailroad={filteredApartmentRailroad}
                    filteredApartmentFloorThrough={
                      filteredApartmentFloorThrough
                    }
                    filteredLand={filteredLand}
                    filteredHouse={filteredHouse}
                    filteredApartment={filteredApartment}
                    filteredCommercialSpace={filteredCommercialSpace}
                    value={value}
                    setList={setList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="desktop-main map-top" id="main-premium">
          <div className="col-lg-12 col-md-12 col-sm-12 hey-there">
            {/* slider test */}

            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row scroll-hotel">
                <Products
                  type={type}
                  propertyType="Buy"
                  products={select === "" ? products : handleMainFilter()}
                  sortProducts={select === "" ? products : handleMainFilter()}
                />
              </div>
            )}
          </div>
        </div>

        <div className="row map-top" id="desktop-section">
          <div className="col-lg-12 col-md-12 col-sm-12 hey-there">
            {loading ? (
              <div className="text-center mt-5 pt-5">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row scroll-hotel">
                <Products
                  type={type}
                  propertyType="All"
                  products={select === "" ? products : handleMainFilter()}
                  sortProducts={select === "" ? products : handleMainFilter()}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const MandateSortContainer = styled.div`
  position: relative;
  left: -26%;

  @media (min-width: 772px) and (max-width: 995px) {
    position: relative;
    left: -17%;
  }
`;

const MandateSection = styled.div`
  padding-right: 49px;

  @media (min-width: 772px) and (max-width: 995px) {
    width: 78%;
  }
`;

const MandateAllButton = styled.button`
  background-color: white !important;
`;

export default AgentPropertiesAll;
