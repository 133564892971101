import React from 'react';
import Footer from "./Footer";
import Navbar from "./Navbar";
import "./styles/AboutUs.css";
import { Helmet } from "react-helmet";

    


const EscrowTerms = ()=> {
return <>
    <Helmet>
        <title>Chekam | Escrow Terms or Use</title>
    </Helmet>
    <Navbar />
    <Body />
    <Footer />
</>
}

const Body= ()=> {
    return <>
    <main className = 'main'>

    <h1 className = 'Header'>Terms of Using the Chekam Escrow Service</h1>

    <div className = 'textbox' >  

<p>These Terms of Using the Chekam Escrow Service ("Terms of Using the Chekam Escrow Service" or "Agreement") 
shall serve as an agreement that sets forth the terms and conditions which will govern your use and participation 
in the transaction management and escrow services provided on and through Chekam.com (the "Services"). 
By selecting to utilize the Services you shall have also indicated your acceptance of these Terms of Using 
the Chekam Escrow Service and your intent and agreement to be bound by them. If you are unwilling to agree 
to these Terms of Using the Chekam Escrow Service, you shall discontinue further use of the Services. If you 
agree to these Terms of Using the Chekam Escrow Service, you will be bound as follows:</p>



<p><b>1.  Definitions</b> - "Account" means</p> 
<p>(i) an account of a Buyer from which payment for the Transaction and related fees will be obtained,</p> 
<p>(ii) an account of a Seller to which payment for the Transaction and other payments will be credited, or</p> 
<p>(iii) an account of a Broker to which payment for the Transaction and other payments will be credited 
and/or related fees will be obtained. "Agreement" refers to this Agreement, the then current operating 
rules contained on the Site and the Transaction Escrow Instructions. "Transaction Detail Screens" means 
those screens on the Site where Users provide all requested information in connection with a Transaction. 
"Escrow Instructions" or "General Instructions" means the document on the Site that contains the terms 
agreed upon on the Transaction Detail Screens, as well as the other terms and conditions of the escrow 
transaction including these Terms of Using the Chekam Escrow Service. "User" means Buyer(s), Seller(s), 
and Broker(s) participating in a Transaction. "Site" refers to the website for the Services which can 
be found at www.Chekam.com. Capitalized terms not defined herein shall have the same meaning as set 
forth in the General Escrow Instructions.</p>

<p><b>2.  Description of the Service</b> - The Services are Internet-based transaction management services performed by Chekam.com and escrow services provided by Chekam.com Ltd, a corporation registered in Nigeria, which acts as escrow agent in a Transaction, which Services are intended to facilitate the completion of the underlying transaction under the terms of this Agreement, the Site and the applicable Transaction Escrow Instructions.</p>

<p><b>3.  Limits on the Services</b> - The Services are only available for transactions relating to the purchase or rent of property listed on www.chekam.com. Limitations on the Services may apply and can be found on the Site or in the General Escrow Instructions or Transaction Escrow Instructions. Only registered Users may use the Services. In order to register, you must supply all information required on the Site. Applicable state or federal laws and regulations may further limit the Services.</p>

<p><b>4.  Prohibited Transactions</b> - Users shall not utilize the Site or the Services in connection with any Transaction that is illegal or involves any illegal items, or is for any illegal purpose; involves any obscene material; involves any munitions or firearm; involves pirated software, DVD or videos or item(s) otherwise infringing copyrighted works; involves illegal drugs or controlled substances; involves real property or any interest in real property, the sale or transfer of liquor licenses, the sale of a business (bulk sale), the transfer of title to a business entity, fund or joint control escrows, mobile homes or manufactured homes or the refinancing of either, reservation deposits of any kind, or promissory notes, mortgages or deeds of trust; or involves transactions directly or indirectly involving persons (individuals or entitChekam.com) with whom the user is prohibited from engaging pursuant to sanctions and export controls administered by any arm of the Nigerian Government. In addition, Chekam.com, in its sole discretion, may refuse to complete any Transaction that Chekam.com has reason to believe is unauthorized or made by someone other than you, may violate any law, rule or regulation, or if Chekam.com has reasonable cause not to honor it. Each User agrees to indemnify and hold Chekam.com harmless for losses resulting from any use or attempted use of the Services in violation of this Agreement.</p>

<p><b>5.  Rejection of Payment</b> - Since the use of a bank account, credit card or debit card account, or the making of an electronic funds transfer may be limited by your agreement with your financial institution and/or by applicable law, Chekam.com is not liable to any User if Chekam.com does not complete a Transaction as a result of any such limit, or if a financial institution fails to honor any credit or debit to or from an Account. Chekam.com may post operating rules related to payment on the Site and change such rules from time to time.</p>

<p><b>6.  General Conditions of Use</b> - If you arrive at the Site through entitChekam.com linked and/or integrated with Chekam.com or otherwise by or through a third party (e.g., an auction, exchange, or Internet-based intermediary that hosts electronic marketplaces and mediates transactions among businesses), then you authorize such third party to transfer relevant data to Chekam.com to facilitate the Transaction. You represent and warrant that all information you provide to Chekam.com or to such third party will be true, accurate and complete. The party entering into this Agreement on behalf of any User represents and warrants that he/she is authorized to do so and to bind the User and is a natural person of at least eighteen (18) years of age. In order to initiate and commence a Transaction, all Users to a Transaction must register at the Site, agree to all terms in the General Escrow Instructions and agree to the Transaction Escrow Instructions.</p>

<p><b>7.  Obligations of Sellers/Landlords/Property Managers (collective called the merchant)</b> - The Seller/Landlord/Property Manager, agrees to allow chekam collect their payment, as shown on the chekam.com website or as confirmed to chekam.com in writing by the landlord or his/her official representative, for the rent or purchase of any property they have listed on the chekam.com website , and agrees that upon being shown evidence of the deposit of such payment with chekam.com Ltd by the buyer/renter shall immediately give possession of the property that has been paid for to the buyer/renter by providing all necessary documents, keys and access materials required by the buyer/renter to take possession of the property</p>
<p><b>8.  Obligations of Buyers/Renters/User (collectively called “Customer”)</b> - The Customer agrees to pay all costs and fees associated with the purchase of the property, as shown on the “Escrow pop up” or as agreed in writing between the Customer, the Merchant and Chekam.com Ltd, to Chekam.com Ltd via the payment portals on its website to which the customer is directed from the “Escrow Pop up”</p>
<p><b>9.  Our Responsibilities</b> - Chekam.com is obligated to perform only those duties expressly described in this Agreement and the General Escrow Instructions. Chekam.com shall not be liable for any error in judgment, for any act taken or not taken, or for any mistake of fact or law, except for gross negligence or willful misconduct (subject to the limitations in Section 17 below). Chekam.com may rely upon any notice, demand, request, letter, certificate, agreement or any other document which purports to have been transmitted or signed by or on behalf of a User indicated as the sender or signatory thereof and shall have no duty to make any inquiry or investigation. In the event that Chekam.com is uncertain as to Chekam.com duties or rights under this Agreement, receives any instruction, demand or notice from any User or financial institution which, in Chekam.com's opinion, is in conflict with any of the provisions of this Agreement, or any dispute arises with respect to this Agreement or the Escrowed Funds, Chekam.com may (i) consult with counsel of our choice (including our own attorneys) and any actions taken or not taken based upon advice of counsel shall be deemed consented to by you, or (ii) refrain from taking any action other than to retain the funds in escrow for delivery in accordance with the written agreement of the Users, the final decision or award of an arbitrator pursuant to an arbitration commenced and conducted in accordance with the General Escrow Instructions or a final, non-appealable judgment of a court of competent jurisdiction, or (iii) discharge our duties under this Agreement by depositing all funds by interpleader action with a court of competent jurisdiction in accordance with the procedures outlined in the General Escrow Instructions.</p>

<p>Chekam.com may, at any time, give notice of Chekam.com's intent to resign as Escrow Agent. If, within ten (10) days of such notice, Chekam.com has not received notice from all Users in a Transaction that they have designated a substitute escrow agent (which notice shall identify the substitute escrow agent), Chekam.com may discharge Chekam.com duties under this Agreement by depositing all escrowed funds with a court of competent jurisdiction. If an alternate Escrow Agent is so designated, Chekam.com shall be discharged from Chekam.com duties under this Agreement, the General Escrow Instructions and the Transaction Escrow Instructions by delivering all escrowed funds to such person or entity. Upon payment of the escrow funds pursuant to this Agreement, Chekam.com shall be fully released from any and all liability and obligations with respect to the escrow funds and the Transaction.</p>

<p><b>10. Canceling a Transaction</b> - If a Transaction cannot be completed for any reason, including cancellation by Chekam.com for any reason, Chekam.com will notify each User in such Transaction by e-mail, to the e-mail address each has provided to Chekam.com. In Chekam.com's sole discretion, Chekam.com may cancel any Transaction if each User to a Transaction fails to agree on the terms as required in the Transaction Details Screens or any other screens on the website by clicking the "Agree" button as requested on the Site. You may cancel a Transaction as provided in this Agreement, on the General Escrow Instructions or in the Transaction Escrow Instructions.</p>

<p><b>11. Statements, Verification</b> - You agree that all disclosures and communications regarding this Agreement and the Service shall be made by e-mail or on the Site, unless the parties make other arrangements as set forth in the General Escrow Instructions.</p>

<p><b>12. Digital Identification</b> - You understand and agree that Chekam.com will create, issue, and verify a digital identification (a "Digital ID") for each User. This Digital ID is attached to each accepted electronic document and notification e-mails. You agree that your Digital ID is a valid "Electronic Signature." Please review the General Escrow Instructions for more information about Chekam.com's use of the Digital ID.</p>

<p><b>13. Fees</b> - Unless otherwise agreed upon by each User in the Transaction, Customer agrees to pay the fees for the Services that are disclosed on the Site at the time the completed Transaction Escrow Instructions are agreed to by all such Users, as well as any other fees, including, without limitation, third party service fees (e.g., shipping, appraisal, inspection, etc.). Once paid, Chekam.com fees are nonrefundable. Chekam.com fees may change from time to time in Chekam.com's absolute and sole discretion. Chekam.com is not responsible for payment of any sales, use, personal property or other governmental tax or levy imposed on any items purchased or sold through the Services or otherwise arising from the Transaction.</p>

<p><b>14. Security</b> - Chekam.com uses secure sockets layer ("SSL"), a security protocol that provides data encryption, server authentication, and message integrity for connections to the Internet to ensure that the data you provide Chekam.com is not transmitted over the Internet unencrypted and cannot be viewed by unauthorized individuals. Chekam.com has also implemented a security system requiring a user ID and a password to access your transactions on the Site. You agree not to give your password to any other person or entity and to protect it from being used or discovered by anyone else.</p>

<p><b>15. Disclaimers</b> - You expressly agree that your use of the Services is at your sole risk. The Services are provided on a strictly "as is" and "as available" basis. Chekam.com MAKES NO WARRANTY WITH REGARD TO THE UNDERLYING TRANSACTION, ANY ITEMS OBTAINED BY YOU THROUGH THE USE OF THE SITE OR THE SERVICES, THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, OR THAT THE SERVICES OR THE SITE WILL BE UNINTERRUPTED, TIMELY, OR ERROR FREE.

Chekam.com expressly disclaims any and all express and implied warrantChekam.com, including, but not limited to, the implied warrantChekam.com of merchantability, fitness for a particular purpose and non-infringement. Chekam.com shall not be liable or responsible for those guarantees, warrantChekam.com and representations, if any, offered by any Seller of items. No advice or information, whether oral or written, obtained by you from Chekam.com or through the Services shall create any warranty not expressly made herein.

You acknowledge and agree that Chekam.com does not endorse the website of any third party, or assume responsibility or liability for the accuracy of any material contained therein, or any infringement of third party intellectual property rights arising therefrom, or any fraud or other crime facilitated thereby. In no event will Chekam.com be liable for any act or omission of any third party, including, but not limited to, your financial institution, any payment system, any third party service provider, any provider of telecommunications services, Internet access or computer equipment or software, any mail or delivery service or any payment or clearing house system or for any circumstances beyond Chekam.com's control (including but not limited to, fire, flood or other natural disaster, war, riot, strike, terrorism, act of civil or military authority, equipment failure, computer virus, infiltration or hacking by a third party, or failure or interruption of electrical, telecommunications or other utility services).</p>

<p><b>16. Limitation of Liability</b> - TO THE EXTENT PERMITTED BY APPLICABLE LAW, NEITHER Chekam.com NOR ITS AFFILIATES, SHALL BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL, RELATING TO YOUR USE OF THE SITE OR THE SERVICES OR YOUR INABILITY TO USE THE SITE OR THE SERVICES.</p>

<p><b>17. Termination of Services</b> - Chekam.com may suspend or terminate your use of the Services at any time, without notice for any reason, in Chekam.com's sole discretion. Except as warranted by risk to the security, privacy or integrity of the Services, Chekam.com will attempt to provide you with prior notice of the suspension or termination of the Services by sending you an e-mail, but Chekam.com is not obligated to do so. You shall remain liable for all Transactions you initiate through the Services prior to such termination, and the performance of your obligations, including but not limited to, the delivery of the items and the payment of all amounts you owe prior to termination or discontinuation of your use of the Services. You agree to pay all costs and expenses (including reasonable attorneys' fees) that Chekam.com may incur in order to (a) collect any amounts you owe under this Agreement, the General Escrow Instructions, or the Transaction Escrow Instructions or (b) to initiate an arbitration or judicial proceeding to resolve a dispute between Buyer and Seller, as set forth in the General Escrow Instructions.</p>

<p><b>18. Non-Transferability of the Services</b> - You may not assign this Agreement or the Escrow Instructions to any other person or entity. Your right to use the Services shall not be sold or transferred to any other person or entity without the prior written consent of Chekam.com. Chekam.com may assign this Agreement upon notice to you. Any assignment or transfer in violation of this provision shall be null and void.</p>

<p><b>19. Modifications</b> - Chekam.com reserves the right to change this Agreement, or any portion of it, at any time, without prior notice, provided that no such change will apply to a Transaction once the Users to such Transaction have agreed to the Transaction Escrow Instructions. You understand that the most recent version of this Agreement will be located on the Site.</p>

<p><b>20. Notices</b> - Notices from Chekam.com to you will be given by e-mail, or by general posting on the Site. You may contact Chekam.com by filling out the customer support form or such other email address as Chekam.com posts as its address for notice on the Site in the most recent version of the Terms of Using the Chekam Escrow Service</p>

<p><b>21. Indemnification</b> - You agree to indemnify and hold Chekam.com, Chekam.com affiliates and their respective officers, directors, shareholders, employees and assigns, harmless from any claim, demand, expense or damage, including reasonable attorneys' fees and court costs, arising from or relating to your use of the Services or any violation of this Agreement, the rules contained on the Site or the Transaction Escrow Instructions, including, without limitation, payment of Chekam.com fees and any charge backs from a card organization or reversal or nonpayment of any credit or debit entry.</p>

<p><b>22. Miscellaneous</b> - In the event of any dispute, claim, question, or disagreement arising from or relating to, this Agreement or to the Underlying Transaction, or breach of any of them, you agree to resolve such dispute in the manner set forth in the General Escrow Instructions.

This Agreement shall be governed by the laws of the Federal Republic of Nigeria. Any dispute shall be resolved pursuant to the Dispute Resolution and Governing Law/Venue provisions of the General Escrow Instructions. This Agreement in conjunction with the General Escrow Instructions and rules contained on the Site constitutes the entire agreement between Chekam.com and you relating to the subject matter hereof and supersedes all prior or contemporaneous understandings, agreements, communications and/or advertising with respect to such subject matter. If there is a conflict between the terms and conditions of this Agreement, the rules contained on the Site, and/or the General Escrow Instructions, then the conflicting terms set forth in the General Escrow Instructions shall control first, these Terms of Using the Chekam Escrow Service shall control second and the rules contained on the Site shall control third. The General Escrow Instructions are incorporated herein by this reference.

Chekam.com's failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Chekam.com in writing.

If any term or other provision of this Agreement is invalid, illegal or incapable of being enforced by any law or public policy, all other terms and provisions of this Agreement shall nevertheless remain in full force and effect for so long as the economic or legal substance of the transactions contemplated hereby is not affected in any manner materially adverse to any party hereto.

Chekam.com may assign this Agreement to any current or future affiliated company and to any successor in interest. Chekam.com also may delegate certain of Chekam.com rights and responsibilitChekam.com under the Agreement to independent contractors or other third partChekam.com.

If you are a registered User of the Site, then each time you request the Services will constitute your agreement to these Terms of Using the Chekam Escrow Service, as amended from time to time in Chekam.com's sole discretion, and evidence that you have read, understood and accepted the then applicable Terms of Using the Chekam Escrow Service
</p>



























    </div>

    </main>

    </>
}

export default EscrowTerms