const Size = ({selectedSizes,setSize}) => {
    const sizes = [ '1', '2', '3', '4', '5'];


    

    return (
        <>
        {/* bathroom */}
            {
                sizes.map((size, index) => {
                    return (
                        <a 
                            className={ "size-bath" + (selectedSizes?.includes(size) ? " selected-size" : "")}
                            key={index}
                            onClick={() => setSize(size)}
                        >
                            {size}
                        </a>
                    )
                })
            }
            
        </>
    )
}

export default Size