import React, { useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Card, ImageListItemBar } from "@mui/material";
import { useState } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import db from "../../../components/Firebase";
import { getAuth, signOut } from "firebase/auth";
import { Helmet } from "react-helmet";
import Header from "./Header";
import moment from "moment";
import axios from "axios";
import API_URL from "../../../config/index.js";
import {
  doc,
  getDoc,
  updateDoc,
  FieldValue,
  arrayUnion,
} from "firebase/firestore";
import { addDoc, serverTimestamp } from "firebase/firestore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import { CircularProgress } from "@mui/material";
import PromoteModal from "./PromoteModal";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function PromoteProperty() {
  const auth = getAuth();
  const user = auth.currentUser;
  const [myProperties, setMyProperties] = useState([]);
  //   const selectedProperties = [""];
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [select, setSelect] = useState(false);
  const [all, setAll] = useState(false);
  let charles = [];

  useEffect(() => {
    async function getProperties() {
      setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(propertiesRef, where("agentEmail", "==", user.email));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        charles.push(doc.data());
        charles = charles.sort((a, b) => {
          return a.price - b.price;
        });
        setMyProperties([...charles]);
        setLoading(false);
      });
    }
    getProperties();
  }, [user]);

  const handleCheck = (data, e) => {
    setAll(false);
    if (e.currentTarget.checked) {
      //   alert(data?.id);
      setSelectedProperties([...selectedProperties, data?.id]);
      //   selectedProperties.push(data?.id);
      setSelect(true);
    } else {
      console.log(selectedProperties);
      if (selectedProperties.includes(data?.id)) {
        let all = selectedProperties?.filter((info) => {
          console.log(info, "here is info");

          return data?.id !== info;
        });

        setSelectedProperties([...all]);
      }
      setSelect(false);
      //   alert("not checked");
    }
  };

  const selectall = [];
  const handleAll = (e) => {
    // console.log(e.currentTarget.checked);
    if (e.currentTarget.checked) {
      setAll(true);
      //   const myProperties = []
      myProperties?.map((data) => {
        selectall.push(data?.id);
      });
      setSelectedProperties([...selectall]);
      //   setSelect(true);
    } else {
      setAll(false);
      setSelectedProperties([]);

      //   setSelect(false);
    }
  };
  console.log(selectedProperties);

  const expiredNextMonth = (date) => {
    // var date = new Date();
    var date = new Date();
    const formatting = moment(date).format("l");
    const splittingdate = formatting.split("/");
    var monthexpireddate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      splittingdate[1]
    );
    return moment(monthexpireddate).format("l h:mm:ss A");
  };
  function nextWeek() {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    let formated = moment(nextweek).format("l h:mm:ss A");
    return formated;
  }
  function getTomorrow() {
    var today = new Date();
    var tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );

    let formated = moment(tomorrow).format("l h:mm:ss A");
    return formated;
  }

  const expireDaily = getTomorrow();
  const expireWeekly = nextWeek();
  const expireMonthly = expiredNextMonth();
  const handlePromote = () => {
    setLoading(true);
    if (selectedProperties?.length === 0) {
      setLoading(false);
      setErr("Please select at least one property for promotion");
    } else {
      setLoading(false);
      setShow(true);
      // selectedProperties?.map((proid) => {
      //   const propertiesRef = doc(db, "properties", proid);
      //   updateDoc(propertiesRef, {
      //     promotion: "daily",
      //     promotionExpiring: "1/6/2023 3:39:58 AM",
      //     emailPromotion: "davidsonjosee313@gmail.com",
      //     sponsored: true,
      //     datePromoted: "1/6/2023 3:39:58 AM",
      //     agentPromoted: "Davidson jose",
      //   }).then(() => {
      //     axios
      //       .post(API_URL, {
      //         name: "davidsonjose",
      //         expiring: expireDaily,
      //         subscription: "daily",
      //         datePromoted: "1/6/2023 3:39:58 AM",
      //         agentPromoted: "Davidsonjose",
      //         email_type: "promotion-success",
      //         length: selectedProperties?.length,
      //         phone: "07083488928",
      //         email: "davidsonjosee313@gmail.com"
      //       })
      //       .then(() => {
      //         setLoading(false);
      //         setShow(false);
      //         setMessage("Payment Successful... we'll get back to you");
      //       });
      //     //   console.log("updated", proid);
      //   });
      // });
    }
  };

  console.log(myProperties);
  return (
    <>
      {/*
       */}
      <Header />
      <Helmet>
        <title>Agent Dashboard</title>
        <meta
          name="description"
          content="Chekam: The smartest way to real estate. Save time, solve all your housing and real estate needs with one click"
        />
      </Helmet>

      {loading && (
        <Dialog
          style={{ overflow: "hidden !important" }}
          open={loading === true}
          onClose={() => console.log("trying to close me")}
        >
          <CircularProgress color="secondary" className="m-5" />
        </Dialog>
      )}

      {err && (
        <Snackbar
          open={err}
          autoHideDuration={20000}
          onClose={() => setErr(null)}
        >
          <Alert
            onClose={() => setErr(null)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {err}
          </Alert>
        </Snackbar>
      )}
      {message && (
        <Snackbar
          open={message}
          autoHideDuration={20000}
          onClose={() => {
            setMessage(null);
          }}
        >
          <Alert
            onClose={() => {
              setMessage(null);
            }}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <div className="mt-5 pt-5 container d-flex align-items-center justify-items-center items-center justify-content-end ">
        <h4 className="ml-4 mt-2">Select All:</h4>
        <input
          type="checkbox"
          class="form-check-input"
          id="exampleCheck1"
          onChange={(e) => handleAll(e)}
          //   defaultChecked={all}
          checked={all}
        />
      </div>
      <div className="">
        <div className="container mb-5" style={{ display: "block !important" }}>
          <ImageList
            gap={12}
            sx={{
              mb: 8,
              gridTemplateColumns:
                "repeat(auto-fill, minmax(200px, 1fr)) !important",
            }}
          >
            {myProperties?.map((data) => (
              <Card
                // onMouseOver={() => console.log("you hover on me")}
                className="mb-4"
              >
                <ImageListItem sx={{ height: "100% !important" }}>
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70&, rgba(0,0,0,0)100%)",
                    }}
                    position="top"
                  />
                  <img
                    src={data?.mainImage[0]}
                    srcSet={data?.mainImage[0]}
                    className="image"
                    loading="lazy"
                    // onClick={(e) => handleImage(e, "img/newIMG/hs7.jpg")}
                  />

                  <div className="mx-3">
                    <strong>For {data?.propertyPurpose}</strong>
                    <p>&#8358;{parseInt(data?.price)?.toLocaleString()}</p>
                    <p>{data?.streetName}</p>

                    {/*
                
                    */}
                    <button
                      className="btn mb-4"
                      style={{ background: "purple", color: "white" }}
                    >
                      View
                    </button>
                  </div>

                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="exampleCheck1"
                    onChange={(e) => handleCheck(data, e)}
                    checked={selectedProperties?.includes(data?.id)}
                    // defaultChecked={select}
                    style={{
                      position: "absolute",
                      right: "46%",
                      top: "0%",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                  {/*
              <i
                className="fa fa-eye"
                style={{
                  position: "absolute",
                  right: "46%",
                  top: "50%",
                  color: "#fff",
                  cursor: "pointer",
                }}
              ></i>
            */}
                </ImageListItem>
              </Card>
            ))}
          </ImageList>
          {!loading && (
            <button
              className="w-100 btn"
              style={{ background: "purple", color: "white" }}
              onClick={(e) => handlePromote(e)}
            >
              Promote Now
            </button>
          )}
        </div>
      </div>

      <PromoteModal
        show={show}
        setShow={setShow}
        message={message}
        setMessage={setMessage}
        properties={selectedProperties}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export default PromoteProperty;
