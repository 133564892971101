import "./styles/Footer.css";
import apple from "../images/app.png";
import play from "../images/play.png";
import logo from "../images/chekam-logo-2.png";
import footer from "../images/footer.png";
import GoogleAds from "./GoogleAds";
import Adsense from 'react-adsense';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faCoffee} from '@fontawesome/free-brands-svg-icons'

const Footer = () => {
  return (
    <>
      <section className="footer-section">
        <div className="footer-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
              {/*<Adsense.Google
                client = "ca-pub-6674353005156641"
                slot = "7757906873"
                />*/}
              </div>
            </div>
          </div>
        </div>
        <div className="top-text">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">

                {/* footer top text */}

                <div sx = {{textAlign: 'centre', textDecoration: 'underline'}}>
                    <h1>Our Mission</h1> 
                </div>
                <div className="wb_Text9">
                  <p>
                    We aim to make the task of finding your dream property 10x easier, Whether you’re buying, selling or renting, we can help you
                    with the best options to make the best choice!
                  </p>
                </div>

                <div className="wb_Text9">
                 <a href = '/privacy-policy' ><p>Privacy Policy</p></a>
                 <a href = '/terms' ><p>Terms & Conditions</p></a>
                </div>
              

                {/* download app icons */}

                <div className = 'comingSoon' >
                    <h4 className = 'text-center' > Coming Soon...</h4> 
                </div>

                <div className="download-app text-center">
                  <div className="column">
                    <img src={apple} alt="" className="image10" />
                  </div>
                  <div className="column">
                    <img src={play} alt="" className="image10" />
                  </div>
                </div>

                {/* chekam logo */}
                <div className="layout-4">
                  <img src={logo} alt="" className="image12" />
                  <i className="fa fa-twitter twitter" aria-hidden="true"></i>
                  <i className="fa fa-facebook facebook" aria-hidden="true"></i>
                  <i
                    className="fa fa-instagram instagram"
                    aria-hidden="true"
                  ></i>
                </div>

                {/* footer image at the bottom */}
                <div className="layout-5">
                  <img src={footer} alt="" className="image13" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div></div> */}
      </section>
    </>
  );
};

export default Footer;
