import React, { useState, useEffect } from "react";
import "./styles/Buy.css";
import Footer from "./Footer";
import { Spinner } from "react-bootstrap";
import Scroll from "./Scroll";
import SearchProductList from "./SearchProductList";
import Products from "./test/Products";
import Size from "./Size";
import Bedroom from "./Bedroom";
import filterList from "./test/filterList";
import filterBedroom from "./test/filterBedroom";
import HouseHeader from "./HouseHeader";
import Map from "./Map";
import Navbar from "./Navbar";
import {
  addDoc,
  collection,
  serverTimestamp,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import db from "./Firebase";

// map: "nfksnfks",

function House() {
  const [details, setDetails] = useState(null);
  const places = [];
  const [loading, setLoading] = useState(false);
  
  const [locations, setLocation] = useState(null)

  useEffect(() => {
    async function getPlaces() {
      setLoading(true);
      const propertiesRef = collection(db, "properties");
      const q = query(propertiesRef, where("propertyType", "==", "house"));
      const array = [];
      getDocs(q).then((data) => {
        data?.forEach((doc) => {
          // let places = [];
          places.push(doc.data());
          setDetails([...places]);
          setProducts([...places]);
          setLocation([...places])
        });
        setLoading(false);
      });
    }
    getPlaces();
  }, []);


 
  const [datas, setDatas] = useState([]);

  // PRICE
  const [agirlik, setAgirlik] = useState([1, 1000000000]);
  const [prices, setPrices] = useState({
    hacim_min: 0,
    hacim_max: 1000000000,
  });


  useEffect(() => {
    let filteredPersons = details?.filter((item) =>
      item.price * 1 >= agirlik[0] && item.price * 1 <= agirlik[1]
        ? true
        : false
    );
    if (agirlik[0] > 200000) {
      setDatas(filteredPersons);
    } else {
      setDatas(false);
    }

  }, [agirlik]);


  // search
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  const filteredPersons = details?.filter((person) => {
    return (
      person?.name?.toLowerCase().includes(searchField.toLowerCase()) ||
      person?.streetName?.toLowerCase().includes(searchField.toLowerCase())
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (searchShow) {
      return <SearchProductList filteredPersons={filteredPersons} />;
    } else {
      return <>Kindly type something that makes sense lol</>;
    }
  }

  // Sizes BATHROOMS
  const [selectedSizes, setSelectedSizes] = useState([]);
  const setSize = (size) => {
    const sizes = [...selectedSizes];

    if (sizes?.includes(size)) {
      const index = sizes.indexOf(size);
      sizes?.splice(index, 1);
    } else {
      sizes?.push(size);
    }
    setSelectedSizes(sizes);
    setProducts(filterList(sizes, "size", details));
  };
  // BEDROOMS
  const [selectedBedrooms, setSelectedBedrooms] = useState([]);
  const setBedroom = (bedroom) => {
    const bedrooms = [...selectedBedrooms];

    if (bedrooms?.includes(bedroom)) {
      const index = bedrooms.indexOf(bedroom);
      bedrooms?.splice(index, 1);
    } else {
      bedrooms?.push(bedroom);
    }
    setSelectedBedrooms(bedrooms);
    setProducts(filterBedroom(bedrooms, "numberOfBedrooms", details));
  };

  // SORT
  const [products, setProducts] = useState([]);
  const sortProducts = (method) => {
    const array = products;

    if (method === "Lowest to Highest") {
      array?.sort(function (a, b) {
        // value for money data
        return a?.valueForMoneyScore - b?.valueForMoneyScore;
      });
    } else if (method === "Highest to Lowest") {
      array?.sort(function (a, b) {
        return b?.price - a?.price;
      });
    } else if (method === "Newest") {
      array?.sort(function (a, b) {
        return a?.last_updated.seconds - b?.last_updated.seconds;
      });
    }
    setProducts(array);
  };

  // ...sort
  const [value, setValue] = useState("Select");

  const setList = (e) => {
    setValue(e.target.value);
    sortProducts(e.target.value);
  };

  useEffect(() => {
    setProducts(filterList([], null, details));
  }, []);

  if (products?.length !== 0) {
    <Scroll>
      <SearchProductList filteredPersons={products} />
    </Scroll>;
  }

  //Slider func
  // const [agirlik, setAgirlik] =  useState([1,5000]);

  const Conditioning = () => {
    if (searchShow) {
      return searchList();
    } else if (datas) {
      return (
        <>
          <span className="p-2">
            {datas?.length === 0 ? (
              <>
                <>No houses found at this price range... try again later</>
              </>
            ) : (
              <>
                {datas?.length === 1 ? (
                  <>{datas?.length} house found</>
                ) : (
                  <>{datas?.length} houses found</>
                )}
              </>
            )}
          </span>
          <Products products={datas} sortProducts={datas} />;
        </>
      );
    } else if (sortProducts) {
      return <Products products={products} sortProducts={sortProducts} />;
    } else if (setSize || setBedroom) {
      return (
        <>
          <span className="p-2">
            {selectedSizes?.length === 1 ? (
              <>{selectedSizes?.length} house found</>
            ) : (
              <>{selectedSizes?.length} houses found</>
            )}
          </span>
          <Size selectedSizes={selectedSizes} setSize={setSize} />
          <Bedroom
            selectedBedrooms={selectedBedrooms}
            setBedroom={setBedroom}
          />
        </>
      );
    }

  };

  function searchList() {
    if (searchShow) {
      return (
        <>
          <SearchProductList filteredPersons={filteredPersons} />
        </>
      );
    }
  }



  return (
    <>
      <Navbar />

      <div className="mt-5">
        <div className="container buy-top">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4 col-sm-12">
                  <form class="d-flex buy-search-top">
                    <input
                      class="form-control me-2"
                      type="search"
                      placeholder="Enter and Adress, Zip or Code"
                      aria-label="Search"
                      onChange={handleChange}
                      style={{
                        display: "block",
                        width: "100%",
                        height: "36px",
                        fontSize: "10px",
                      }}
                    />
                    {/* <i class="fa fa-search seach-icon" aria-hidden="true"></i> */}
                  </form>
                </div>
                <HouseHeader
                  prices={prices}
                  setPrices={setPrices}
                  agirlik={agirlik}
                  agirlik0={agirlik[0]}
                  agirlik1={agirlik[1]}
                  setAgirlik={setAgirlik}
                  selectedSizes={selectedSizes}
                  setSize={setSize}
                  selectedBedrooms={selectedBedrooms}
                  setBedroom={setBedroom}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row map-top">
          {/* {houses[0].map} */}
          <div className="col-lg-6 col-md-6 map-section">
            <div className="">
              <Map locations={locations} />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className=" pb-4">
              {/* <h6 className="">Real Estate & Homes For Sale</h6> */}
              <div className="">
                <div className="d-flex buy-button">
                  <div className="sort-list">
                    <p>Sort by&nbsp;: &nbsp;</p>
                    <select value={value} onChange={setList}>
                      <option value="Select">Select</option>
                      <option value="Highest to Lowest">
                        Highest Quality
                      </option>
                      <option value="Lowest to Highest">Highest Value for Money</option>
                      {/* <option value="Newest">Newest</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="text-center mt-5 pt-5 ">
                <Spinner
                  animation="border"
                  className="text-center"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="row scroll-hotel">
                <Conditioning />
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default House;