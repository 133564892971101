import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import background from "../images/house.jpeg";
import mobileBackground from "../images/home.jpeg";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AskChekamButton from "./AskChekamButton";
import { useNavigate,  Link } from "react-router-dom";
import { ButtonGroup } from "@mui/material";
import { Helmet } from "react-helmet";
import Select from "react-select";
import CircleIcon from "@mui/icons-material/Circle";
import "./styles/Buy.css";
import {addDoc,collection,getDocs,} from "firebase/firestore";
import db from "./Firebase";
import BuyImage from "../images/Buying.png";
import timeImg from "../images/rent1.jpg";
import RentImg from "../images/rental.png";
import Paper from "@mui/material/Paper";


const theme = createTheme({
  palette: {
    primary: {
      light: "#FFCC00",
      main: "#FFFFFF",
      dark: "#800080",
    },
    secondary: {
      light: "#FFCC00",
      main: "#FFCC00",
      dark: "#800080",
    },
  },
});

export default function Home() {
  const navigate = useNavigate();
  const [district, setDistrict] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [buyVariant, setBuyVariant] = useState("contained");
  const [open, setOpen] = React.useState(false);

  // const addNewMessage = httpsCallable(functions, 'addNewMessage');
  // const sayHello = httpsCallable(functions, 'sayHello');
  // const sendEmail = httpsCallable(functions, 'sendEmail');
  // const addMessage = async (text) => {
  //   try {
  //     const response = await fetch(`https://chekam.com/chekam-a4366/us-central1/addMessage?text=${text}`);
  //     const data = await response.json();
  //     console.log(data.result); // Message with ID: {writeResult.id} added.
  //   } catch (error) {
  //     console.error('Error calling Cloud Function:', error);
  //   }
  // };
 
  // const randomNumber = async () => {
  //   try {
  //     const response = await fetch(`https://us-central1-chekam-a4366.cloudfunctions.net/randomNumber`);
  //     //const data = await response.json();
  //     console.log(response); 
  //   } catch (error) {
  //     console.error('Error calling Cloud Function:', error);
  //   }
  // };


  useEffect(() => {
    async function getPlaces() {
      const documents = [];
      const querySnapshot = await getDocs(collection(db, "properties"));
      querySnapshot.forEach((doc) => {
        documents.push(doc.data().streetName);
        documents.push(doc.data().district);
      });

      let uniqueChars = [...new Set(documents)];
      setDistrict(uniqueChars);
    }
    getPlaces();
  }, []);

  const handleChange = (opt) => {
    // navigate(`/buy?q=${opt?.value}`);
    setSearchField(`${opt?.value}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };



  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
          
        <Helmet>
          <title>
            Chekam: The smartest way to real estate. save time, solve all your
            housing and real estate needs with one click
          </title>
        </Helmet>
        <Navbar />
        <Box
          sx={{
            backgroundImage: {
              lg: `url(${background})`,
              xs: `url(${mobileBackground})`,
            },
            backgroundPosition: "initial",
            backgroundSize: {
              lg: "cover",
              md: "cover",
              sm: "cover",
              xs: "cover",
            },
            backgroundRepeat: "no-repeat",
            height: { lg: "100vh", md: "80vh", sm: "80vh", xs: "60vh" },
            width: "auto",
            m: "1vw",
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.5)",
              height: "100%",
              width: "100%",
              borderRadius: "20px",
            }}
          >
            <Box
              sx={{
                bgcolor: "primary",
                width: "50vw",
                height: "40vh",
                marginTop: { lg: "25vh", md: "22vh", sm: "18vh", xs: "18vh" },
                marginLeft: "25vw",
                position: "absolute",
              }}
            >
              <Grid container spacing={1} justifyContent="center" gutterButtom>
                <Grid item>
                  <Typography
                    noWrap="true"
                    variant="h2"
                    component="h3"
                    color="white"
                    align="center"
                    fontWeight="bold"
                    fontFamily="Raleway"
                    sx={{
                      fontSize: { lg: 50, md: 40, sm: 40, xs: 30 },
                      mb: { lg: 3, sm: 2 },
                      letterSpacing: { lg: "3px", md: "2px", sm: "2px" },
                    }}
                  >
                    Rent It
                    <CircleIcon
                      sx={{
                        color: "white[200]",
                        fontSize: {
                          lg: "20px",
                          md: "15px",
                          sm: "10px",
                          xs: "10px",
                        },
                        m: { lg: 2, md: 1.5, sm: 1, xs: 1 },
                      }}
                    />
                    Buy It
                    <CircleIcon
                      sx={{
                        color: "white[200]",
                        fontSize: {
                          lg: "20px",
                          md: "15px",
                          sm: "10px",
                          xs: "10px",
                        },
                        m: { lg: 2, md: 1.5, sm: 1, xs: 1 },
                      }}
                    />
                    Love It
                  </Typography>
                </Grid>

                <Grid item></Grid>

                <form
                  class=""
                  style={{ width: "100%", margin: "1rem" }}
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <Select
                    options={district?.map((opp) => ({
                      label: opp,
                      value: opp,
                      // setData(opp?.value)
                    }))}
                    placeholder="City or Area"
                    onChange={handleChange}
                    style={{ width: "100% !important " }}
                  />
                </form>
                <Grid item>
                  <ButtonGroup
                    sx={{
                      mt: 1,
                      bgcolor: "rgba(115,115,115,0.4)",
                      borderRadius: "12px",
                    }}
                    aria-label="outlined primary button group"
                  >
                    <Button
                      sx={{
                        color: "1a1a1a",
                        "&:hover": { bgcolor: "#FFFFFF", scale: "1.17" },
                        transition: "scale 0.5s ease",
                        width: { lg: 90, md: 70, sm: 50, xs: 28 },
                        fontSize: { lg: 20, md: 16, sm: 14, xs: 14 },
                        fontFamily: "Raleway",
                        borderRadius: "12px",
                        textTransform: "none",
                        mr: 2,
                      }}
                      variant={buyVariant}
                      onClick={() =>
                        navigate(`/buy`, {
                          state: { propertySearch: searchField },
                        })
                      }
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "e6e6e6",
                          fontWeight: "bold",
                        }}

                      >
                        Buy
                      </a>
                    </Button>

                    <Button
                      onMouseEnter={() => setBuyVariant("text")}
                      onMouseLeave={() => setBuyVariant("contained")}
                      sx={{
                        color: "#1a1a1a",
                        "&:hover": {
                          bgcolor: "rgb(128, 0 ,128)",
                          scale: "1.17",
                        },
                        transition: "scale 0.5s ease",
                        width: { lg: 90, md: 70, sm: 50, xs: 28 },
                        fontSize: { lg: 20, md: 16, sm: 14, xs: 14 },
                        fontFamily: "Raleway",
                        textTransform: "none",
                        mr: 2,
                        borderRadius: "12px",
                      }}
                      variant="text"
                      onClick={() =>
                        navigate(`/rent`, {
                          state: { propertySearch: searchField },
                        })
                      }
                    >
                      <a
                        href="/rent"
                        style={{ textDecoration: "none", color: "#e6e6e6" }}
                      >
                        Rent
                      </a>
                    </Button>

                    <Button
                      onMouseEnter={() => setBuyVariant("text")}
                      onMouseLeave={() => setBuyVariant("contained")}
                      sx={{
                        color: "#1a1a1a",
                        "&:hover": {
                          bgcolor: "rgb(128, 0 ,128)",
                          scale: "1.17",
                        },
                        transition: "scale 0.5s ease",
                        width: { lg: 110, md: 90, sm: 70, xs: 65 },
                        fontSize: { lg: 20, md: 16, sm: 11, xs: 10 },
                        fontFamily: "Raleway",
                        textTransform: "none",
                        mr: 2,
                        borderRadius: "12px",
                      }}
                      variant="text"
                      onClick={() =>
                        navigate(`/shortlet`, {
                          state: { propertySearch: searchField },
                        })
                      }
                    >
                      <a
                        href="/shortlet"
                        style={{ textDecoration: "none", color: "#e6e6e6" }}
                      >
                        Short-Let
                      </a>
                    </Button>

                    <Button
                      onMouseEnter={() => setBuyVariant("text")}
                      onMouseLeave={() => setBuyVariant("contained")}
                      sx={{
                        color: "#1a1a1a",
                        "&:hover": {
                          bgcolor: "rgb(128, 0 ,128)",
                          scale: "1.17",
                        },
                        transition: "scale 0.5s ease",
                        width: { lg: 130, md: 110, sm: 70, xs: 85 },
                        fontSize: { lg: 17, md: 13, sm: 11, xs: 10 },
                        fontFamily: "Raleway",
                        textTransform: "none",
                        mr: 2,
                        borderRadius: "12px",
                      }}
                      variant="text"
                      onClick={() =>
                        navigate(`/das`)
                      }
                    >
                      <a
                        href="/das"
                        style={{ textDecoration: "none", color: "#e6e6e6" }}
                      >
                        V.Insp & D.A.S
                      </a>
                    </Button>

                    <Button
                      onMouseEnter={() => setBuyVariant("text")}
                      onMouseLeave={() => setBuyVariant("contained")}
                      sx={{
                        color: "#1a1a1a",
                        "&:hover": {
                          bgcolor: "rgb(255, 202, 44)",
                          scale: "1.17",
                        },
                        transition: "scale 0.5s ease",
                        width: { lg: 90, md: 70, sm: 50, xs: 28 },
                        fontSize: { lg: 20, md: 16, sm: 14, xs: 14 },
                        fontFamily: "Raleway",
                        textTransform: "none",
                        borderRadius: "12px",
                      }}
                      variant="text"
                    >
                      <Link
                        style={{ textDecoration: "none", color: "#eeeeee" }}
                        to="/signuppro"
                      >
                        List
                      </Link>
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              
            </Box>
            {/* <Fab variant="extended" color="primary" onClick={handleClickOpen} sx={{mr:4, top: { lg: 400, md: 400, sm: 350, xs: 350 }, float:'right'}}>
                <NavigationIcon sx={{ mr: 1 }} />
                ASK CHEKAM
              </Fab> */}
              <AskChekamButton />
          </Box>
        </Box>
        <Box
          sx={{ ml: "15%", bgcolor: "#FFFFFF", width: "100%", mt: 4, mb: 4 }}
        >
          {/*<MediaCard />*/}
        </Box>

        <Typography
          variant="h2"
          color="text.secondary.main"
          sx={{
            fontSize: "2.3rem",
            fontWeight: "bold",
            fontFamily: "Raleway",
            letterSpacing: "3.5px",
            minWidth: "80%",
            display: "block",
            textAlign: "center",
            margin: "2% 10%",
          }}
        >
          Chekam can help you
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          xs={12}
          md={12}
          ml={4}
          mt={2}
        >
          <Grid item lg={4}>
            <Paper elevation={1} sx={{ width: "80%", borderRadius: "30px" }}>
              <Box
                sx={{ bgColor: "#234345", p: 3, textAlign: "center" }}
                align="center"
              >
                <Box
                  component="img"
                  sx={{
                    height: 100,
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="The house from the offer."
                  src={BuyImage}
                />
                
                <Typography
                  variant="h6"
                  color="text.secondary.main"
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontFamily: "Nunito Sans",
                    m: 1,
                  }}
                >
                  Buy smart!
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontSize: "1em",
                    fontWeight: "light",
                  }}
                >
                  Don't get frustrated into a bad choice. Use Chekams custom
                  algorithm to sort through the highest quality and
                  value-for-money properties on the market
                </Typography>

                <Link sx={{ textDecoration: "none" }} to="/buy">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      borderRadius: "7px",
                      mt: 2,
                      width: "80%",
                      "&:hover": { bgColor: "#800080" },
                    }}
                  >
                    <p
                      style={{
                        color: "#404040",
                        fontWeight: "450",
                        fontSize: "1.2rem",
                        textTransform: "none",
                        fontFamily: "Nunito Sans",
                        margin: 0,
                      }}
                    >
                      Buy
                    </p>
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>

          <Grid item lg={4}>
            <Paper elevation={3} sx={{ width: "82%", borderRadius: "30px" }}>
              <Box sx={{ mt: 3, p: 3, textAlign: "center" }} align="center">
                <Box
                  component="img"
                  sx={{
                    height: 100,
                    width: 200,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="The house from the offer."
                  src={RentImg}
                ></Box>

                <Typography
                  variant="h6"
                  color="text.secondary.main"
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    fontFamily: "Nunito Sans",
                    m: 1,
                  }}
                >
                  Rent smart!
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontSize: "0.92em",
                    fontWeight: "light",
                  }}
                >
                  Looking for an apartment can drive you MAD, unless you use
                  chekams custom algorithm to find only the best choices and
                  reduce the money , energy and time spent under the sun
                  searching randomly
                </Typography>

                <Link sx={{ textDecoration: "none" }} to="/rent">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      borderRadius: "7px",
                      mt: 2,
                      width: "80%",
                      "&:hover": { bgColor: "#800080" },
                    }}
                  >
                    <p
                      style={{
                        color: "#404040",
                        fontWeight: "450",
                        fontSize: "1.2rem",
                        textTransform: "none",
                        fontFamily: "Nunito Sans",
                        margin: 0,
                      }}
                    >
                      Rent
                    </p>
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>

          <Grid item lg={4}>
            <Paper elevation={3} sx={{ width: "82%", borderRadius: "30px" }}>
              <Box sx={{ mt: 3, p: 3, textAlign: "center" }} align="center">
                <Box
                  component="img"
                  sx={{
                    height: 100,
                    width: 200,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                    mb: 1,
                  }}
                  alt="The house from the offer."
                  src={timeImg}
                ></Box>

                <Typography
                  variant="h6"
                  color="text.secondary.main"
                  sx={{
                    fontSize: "1.8rem",
                    fontWeight: "bold",
                    fontFamily: "Nunito Sans",
                    m: 1,
                  }}
                >
                  Save Time & Money
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontSize: "0.92em",
                    fontWeight: "light",
                  }}
                >
                  Drastically reduce the time and money spent in hunting for you
                  next purchase or rent by utilizing chekams custom algorithm to
                  find the best quality and Highest Value-for money properties
                  on the market.
                </Typography>

                <Link sx={{ textDecoration: "none" }} to="/about-us">
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      borderRadius: "7px",
                      mt: 2,
                      width: "80%",
                      "&:hover": { bgColor: "#800080" },
                    }}
                  >
                    <p
                      style={{
                        color: "#404040",
                        fontWeight: "450",
                        fontSize: "1.2rem",
                        textTransform: "none",
                        fontFamily: "Nunito Sans",
                        margin: 0,
                      }}
                    >
                      About us
                    </p>
                  </Button>
                </Link>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ bgcolor: "#FFFFFF", m: 4 }}>
          <Typography
            gutterBottom
            variant="h5"
            color="text.secondary"
            align="center"
            fontFamily="Nunito sans"
          >
            Whether you’re buying, selling or renting, we can help you with best
            options to ease your big decision
          </Typography>
         
        </Box>
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  );
}