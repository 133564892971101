import { useState, useEffect } from "react"
import Slider from '@mui/material/Slider';
import { houses, sublinks, more, details } from "../data";
import Size from './Size'
import './styles/Buy.css'
import filterList from './test/filterList'



const LandHeader = ({agirlik0, agirlik1, prices, setPrices, setAgirlik,agirlik,
  agirlik0s, agirlik1s, pricess, setPricess, setAgirliks,agirliks,
}) => {
    // SHOW DIV
  const [showDiv, setShowDiv] = useState(false)
  const onShowDiv = (e) => {
    e.preventDefault()
    setShowDiv(true)
  }

  const dontRefresh = (e) => {
    e.preventDefault()
  }


  const myString = agirlik0.toString();

  if (agirlik0) {
  }
  const Remove = myString.substring(0, myString.length - 6);

  const handlingPrice = () => {
    // console.log(agirlik0.le)
    if (agirlik0.toString().length < 4) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length === 5) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length <= 6) {
      // do this
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
      // return agirlik0 + "K";
    } else if (agirlik0.toString().length === 7) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 8) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 9) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 10) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 9);
      return forThousand + "B+";
    }
    // else if (agirlik0.toString().length <= 9) {
    //   return agirlik0 + "M";
    // } else if (agirlik0.toString().length <= 10) {
    //   // do this
    //   return agirlik0 + "B";
    // }
    else {
      return agirlik0;
    }
  };
  const handlingPrice2 = () => {
    // console.log(agirlik0.le)
    if (agirlik0.toString().length < 4) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length === 5) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
    } else if (agirlik0.toString().length <= 6) {
      // do this
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 3);
      // do this
      return forThousand + "K";
      // return agirlik0 + "K";
    } else if (agirlik0.toString().length === 7) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 8) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 9) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 6);
      return forThousand + "M";
    } else if (agirlik0.toString().length === 10) {
      const thousandValue = agirlik0.toString();
      const forThousand = thousandValue.substring(0, thousandValue.length - 9);
      return forThousand + "B";
    }
    // else if (agirlik0.toString().length <= 9) {
    //   return agirlik0 + "M";
    // } else if (agirlik0.toString().length <= 10) {
    //   // do this
    //   return agirlik0 + "B";
    // }
    else {
      return agirlik0;
    }
  };

  
  
  // useEffect(() => {
  //   setProducts(filterList([], null));
  //   if(JSON.parse(localStorage.getItem("cart"))) {
  //     setCart(JSON.parse(localStorage.getItem("cart")));
  //   }
  // }, [])


    return (
        <>




            <div className="col-lg-8 d-flex buttons-horizontal">
              <div style={{ margin: "5px" }} className="single-button">
                <li className="dropdown main-dropdown" >
                   <a href="" className="ui-state-default buy-cat-button">For Sale</a>
                   <ul className="dropdown-menu overwrite-dropdown2"
                     aria-labelledby="navbarScrollingDropdown" style={{ width: "40%"}}>
                      <div className="">
                        <li>
                          <a href="/rent" className="dropdown-item" style={{width: "100%"}}>For Rent</a>
                        </li>
                        <li>
                          <a href="/hotel" className="dropdown-item" style={{width: "100%"}}>Hotel</a>
                        </li>
                    </div>
                  </ul>
                </li>
              </div>
                   <div style={{ margin: "5px" }}>
                    <div className="dropdown">
                      <li
                        className="dropdown main-dropdown  "
                        type="button"
                        id="dropdownMenuButton" 
                      >
                        <a href="" className="ui-state-default buy-cat-button">
                          Property Types
                        </a>
                      </li>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {sublinks.map((link) => {
                              const { links, title, url } = link;
                                return (
                              <li>
                                <a className="dropdown-item" href={url}>
                                {title} &raquo;
                                </a>
                                
                                  <ul className="dropdown-menu dropdown-submenu">
                                    {links.map((item) => {
                                      return (
                                      <li>
                                        <a className="dropdown-item" href={item.url}>
                                          {item.label} 
                                        </a>
                                      </li>
                                      );
                                    })}
                                  </ul>
                                  
                              </li>
                              );
                            })}
                          </ul>
                          
                    </div>
                  </div>









                  
                  

                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={dontRefresh}>
                       <a href="" className="ui-state-default buy-cat-button">
                         Land Size
                       </a>
                       <ul
                         className="dropdown-menu "
                         aria-labelledby="navbarScrollingDropdown"
                       >
                         {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                           <div className="kategoriler">
                       <span STYLE={{marginLeft: "5px"}}>
                         Land Size in SQM
                       </span>
                       <div className="kategori">
                         <div>
                           <input 
                             type="text"
                             className="price-input"
                             value={agirlik0s}
                             onChange={(e) => setAgirliks([e.target.value, agirlik1s])}
                           />
                           <input
                             type="text"
                             className="price-input"
                             value={agirlik1s}
                             onChange={(e) => setAgirliks([agirlik0s, e.target.value])}
                           />
                        </div>
                       <Slider
                          value={agirliks}
                          min={pricess.hacim_mins}
                          max={pricess.hacim_maxs}
                          style={{ color: "#800080" }}
                          onChange={(event, newValue) => setAgirliks(newValue)}
                          valueLabelDisplay="auto"
                          // step={100000}
                          // sort={1000000}
                          step="10000"
                        />
                      </div>
                          </div>
                      </ul>
                    </li>
                  </div>

                  <div style={{ margin: "5px" }}>
                    <li className="dropdown main-dropdown" onClick={dontRefresh}>
                      <a href="" className="ui-state-default buy-cat-button">
                        Price
                      </a>
                      <ul
                        className="dropdown-menu "
                        aria-labelledby="navbarScrollingDropdown"
                      >
                        {/* <input type="range" min="1" max="100" value="50" class="slider" id="myRange" /> */}
                        <div className="kategoriler">
                          <span>
                            Price <span>Range</span>
                          </span>
                          <div className="kategori">
                            <div>
                              <input
                                type="text"
                                className="price-input"
                                // value={agirlik0 + "k"}
                                value={handlingPrice()}
                                name={agirlik0}
                                onChange={(e) => setAgirlik([agirlik1, e.target.name])}
                                // value={agirlik1}
                                // onChange={(e) => setAgirlik([agirlik0, e.target.value])}
                                readOnly
                              />
                              <span className="price-space-input"></span>
                              <input
                                type="text"
                                className="price-input"
                                value={1 + "B+"}
                                name={agirlik1}
                                onChange={(e) => setAgirlik([agirlik0, e.target.name])}
                                readOnly
                              />
                            </div>
                            <Slider
                              value={agirlik}
                              min={prices.hacim_min}
                              max={prices.hacim_max}
                              style={{ color: "#800080" }}
                              onChange={(event, newValue) => setAgirlik(newValue)}
                              valueLabelDisplay="auto"
                              step="1000000"
                              // sort={1000000}
                              // step={1}
                            />
                          </div>
                        </div>
                      </ul>
                    </li>
                  </div>

                   {/* PRICE COMPONENT SLIDER 
                   <div style={{ margin: "5px" }}>
                     <li className="dropdown main-dropdown" onClick={onShowDiv}>
                       <a
                         href=""
                         className="ui-state-default buy-cat-button"
                       >
                         Price
                       </a>
                       {showDiv ? 
                       <ul className="dropdown-menu " aria-labelledby="navbarScrollingDropdown">
                       
                           <input type="range" class="slider" id="myRange" />
                          
                       </ul> : null}
                     </li>
                   </div> */}
                   <div style={{ margin: "5px" }}>
                    <div className="dropdown">
                      <li
                        className="dropdown main-dropdown"
                        type="button"
                        id="dropdownMenuButton"
                        aria-expanded="false"
                      >
                        <a className="ui-state-default buy-cat-button">
                          More
                        </a>
                      </li>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {more.map((link) => {
                              const { links, text, url } = link;
                                return (
                              <li>
                                <a className="dropdown-item" href={url}>
                                {text} &raquo;
                                </a>
                                
                                  <ul className="dropdown-menu dropdown-submenu">
                                    {links.map((item) => {
                                      return (
                                      <li>
                                        <a className="dropdown-item" href={item.url}>
                                          {item.label} 
                                        </a>
                                      </li>
                                      );
                                    })}
                                  </ul>
                                  
                              </li>
                              );
                            })}
                          </ul>
                    </div>
                   </div>


                 </div>
            
        </>
    )
}

export default LandHeader