import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import { UserContext } from "../../../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
//import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
// import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { purple } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {collection,query,getDocs,where,orderBy,deleteDoc,doc} from "firebase/firestore";
import db from "../../../components/Firebase";


function DasProperties() {
  const user = React.useContext(UserContext);
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }



  const [dasProperties, setDasProperties] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["productId"]);


  
let charles = [];
 const getVerificationRequests = async () =>  {
    
    const q = query(collection(db, "properties"), where("verificationStatus", "==", "pending"));
  
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {charles.push(doc.data())});
    setDasProperties([...charles]);
    setIsLoaded(true);
    
  }
  getVerificationRequests();

  return (
    <>
      <Navbar />
      
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { mt: 10, mx: 2, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          sx={{ "& label": { mt: "0" } }}
          color="secondary"
          helperText="product Id"
          id="outlined-search"
          label="SEARCH PROPERTIES "
          type="search"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />
      </Box>


      {!isLoaded ? (
        <LinearProgress color="secondary" />
      ) : (
        <Box sx={{ mx: 2 }}>
          {search(dasProperties).map((data, i) => (
            <Grid
              container
              spacing={2}
              key={i}
              sx={{ m: 1, p: 1, width: 300, display: "inline-block" }}
            >
              <Grid item>
                <Card sx={{ maxWidth: 345 }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        sx={{ bgcolor: purple[500] }}
                        aria-label="property"
                      ></Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={data.agentEmail}
                  />

                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      {data.productId}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {data.propertyType}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {data.propertyPurpose}
                    </Typography>
                  </CardContent>

                  <CardActions>
                  {/*<Button sx={{bgcolor:'#800080', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}  ><Link  style={{textDecoration: "none", color: "#FFFFFF"}}to="../Admin/DAS/PropertyForm2" >UPDATE</Link></Button>*/}
                  <Button  sx={{bgcolor:'#800080', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}  ><Link  style={{textDecoration: "none", color: "#FFFFFF"}}to="/admin/DAS/Dashboard/DasProperties/DasUpdate" state={{data}}>UPDATE</Link></Button>
                  {/*<Button sx={{bgcolor:'#FFCC00', color:'#FFFFFF', width:{lg:10,md:5,sm:4,xs:'14px'}}}  onClick = {()=>{handleDelete(x)}}> DELETE</Button>*/}
                  </CardActions>
                </Card>
              </Grid>

            </Grid>
          ))}
        </Box>
      )}
    </>
  );
}

export default DasProperties;