import React from "react";
import "./user.css";

function ModalDelete({ type, name }) {
  function handleNo() {
    document.querySelector(".modal").classList.remove("modal");
    window.location.reload();
  }
  function handleYes() {
    document.querySelector(".modal").classList.remove("modal");
    window.location.reload();
  }
  return (
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content admin-modal-delete">
          <div class="modal-body">
            <div className="container pt-5">
              <div className="row pb-1">
                <div class="">
                  <h3>
                    Are you sure you want to delete {type}: {name}
                  </h3>
                  <button className="btn btn-danger" onClick={handleYes}>
                    Yes
                  </button>
                  <button className="btn btn-success" onClick={handleNo}>
                    No
                  </button>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDelete;