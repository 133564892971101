import React from 'react';
import SearchCard from './SearchCard';
// import ProductsList from './ProductsList';

function SearchProductList({ filteredPersons }) {
  const filtered = filteredPersons.map(person =>  <SearchCard key={person.id} person={person}  />); 
  return (
    <div className='row scroll-hotel search-product-list'>
      {filtered}
    </div>
  );
}

export default SearchProductList;