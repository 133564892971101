import React, { useState } from "react";

function ModalApplication() {
  const [show, setShow] = useState(false);

  const handle = (e) => {
    e.preventDefault();
    if (show) {
      setShow(false);
    } else if (!show) {
      setShow(true);
    }
  };
  return (
    <div
      class="modal fade"
      id="exampleModa"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content admin-modal-delete">
          <div class="modal-body">
            <div className="container pt-5">
              <div className="row pb-1">
                <input
                  type="email"
                  className="form-control"
                  placeholder="enter recepient email address"
                />
                <br />
                <br />
                <h4 className="text-center mt-3">or</h4>
                <div className="text-center p-2">
                  <i
                    className="fa fa-clipboard display-2"
                    onClick={(e) => handle(e)}
                    aria-hidden="true"
                    style={{ color: "purple" }}
                  ></i>
                  {!show ? <>Copy Link to form</> : <></>}

                  {show ? (
                    <>
                      <p>Link has been copied</p>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <p>Copied</p> */}
              </div>
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalApplication;