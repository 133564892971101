// import h4 from '../images/h4.jpg'
import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "./styles/Savedsearches.css";

const Savedsearches = () => {
  const [data, setData] = useState();

  useEffect(() => {
    const getSavedItems = localStorage.getItem("houses");
    if (getSavedItems) {
      setData(JSON.parse(getSavedItems));
    }
  }, []);
  console.log(data);
  const handleDelete = (e) => {
    e.preventDefault();
    localStorage.clear("saved-houses");
    alert("Item cleared successfully");
    window.location.reload();
  };
//   console.log(data?.length)
  return (
    <>
      <Navbar />
      <section className="saved-section">
        {/* Header */}
        <div className="saved-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="saved-text">
                  <h2>Saved Searches</h2>
                  <p>
                    See your saved Items and search results you have indexed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Items */}
        <div className="saved-items-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="saved-card">
                  <div className="row">
                    {data === undefined ? (
                      <>
                        <p className="text-center display-4">You have no items in your saved searches</p>
                      </>
                    ) : (
                      <>
                        {data.map((datas) => {
                          console.log(datas);
                          return (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <img
                                  src={datas.imgPath}
                                  alt=""
                                  className="saved-item-img"
                                />
                                <div className="saved-item-text">
                                  <p>Saved 10 days ago</p>
                                  <h3>
                                    Marts penthouse <br /> {datas.price}
                                  </h3>
                                  <p>{datas.about}</p>
                                  {/* <p>Construction</p>
                                  <p>Chad Street Oba close Lagos</p>
                                  <p>
                                    <span>Listed By Harry and Smith</span>
                                  </p> */}
                                  <button>remove</button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="saved-button-bottom">
                  <button onClick={(e) => handleDelete(e)}>clear list</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Savedsearches;